import { useState, useEffect } from 'react';
import { useRootState } from 'store/StateProvider';

export function usePdfDialogData(
    changeTimeRange: React.Dispatch<React.SetStateAction<number>>,
    changePriceInformation: React.Dispatch<React.SetStateAction<boolean>>,
    changeIsCustomerInfoVisible: React.Dispatch<React.SetStateAction<boolean>>,
): {
    pdfReady: boolean;
    timeRangeString?: string;
    changeTimeRangeString?: React.Dispatch<React.SetStateAction<string>>;
    changePdfReady: React.Dispatch<React.SetStateAction<boolean>>;
    isPriceInformationChecked: boolean;
    isCustomerInfoVisibleChecked: boolean;
    changePriceInformationChecked: React.Dispatch<React.SetStateAction<boolean>>;
    changeIsCustomerInfoVisibleChecked: React.Dispatch<React.SetStateAction<boolean>>;
} {
    const {
        sourcesData: { tcoYear },
    } = useRootState();
    const [pdfReady, changePdfReady] = useState<boolean>(false);
    const [timeRangeString, changeTimeRangeString] = useState<string>(`${tcoYear}`);
    const [isPriceInformationChecked, changePriceInformationChecked] = useState<boolean>(true);
    const [isCustomerInfoVisibleChecked, changeIsCustomerInfoVisibleChecked] = useState<boolean>(true);

    // set the selected checkbox of timeRange in PDFDialog to latest selected tcoYear
    useEffect(() => {
        changeTimeRangeString(`${tcoYear}`);
    }, [tcoYear]);

    useEffect(() => {
        changePdfReady(false);
        changeTimeRange(parseInt(timeRangeString, 10));
        // TODO: change it to not use delays
        setTimeout(() => changePdfReady(true), 2000);
    }, [timeRangeString, changeTimeRange]);

    useEffect(() => {
        changePdfReady(false);
        changePriceInformation(isPriceInformationChecked);
        // TODO: change it to not use delays
        setTimeout(() => changePdfReady(true), 2000);
    }, [isPriceInformationChecked, changePriceInformation]);

    useEffect(() => {
        changePdfReady(false);
        changeIsCustomerInfoVisible(isCustomerInfoVisibleChecked);
        // TODO: change it to not use delays
        setTimeout(() => changePdfReady(true), 2000);
    }, [isCustomerInfoVisibleChecked, changeIsCustomerInfoVisible]);

    return {
        pdfReady,
        timeRangeString,
        changeTimeRangeString,
        changePdfReady,
        isPriceInformationChecked,
        isCustomerInfoVisibleChecked,
        changePriceInformationChecked,
        changeIsCustomerInfoVisibleChecked,
    };
}
