import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import Button from 'components/Button/Button';
import { useRootState } from 'store/StateProvider';
import useSources from 'views/Sources/hooks/useSources';
import { saveCustomerDetails } from 'store/slices/actions';
import { InputBaseStyled } from 'components/forms/Input/styles';
import { FormControl } from '@material-ui/core';
import { useGetExistingCustomerData } from 'hooks/query/useGetExistingCustomerData';

const CustomerSfdcOemId = ({ setIsLoading }): JSX.Element => {
    const {
        sourcesData: {
            customerDetails: { customerSfdcOemId, customername, customerType, fetchCustomerDetails },
        },
        auth: { isDruvaUser },
    } = useRootState();
    const { isFetching, data, error } = useGetExistingCustomerData(customerSfdcOemId, fetchCustomerDetails);
    const { dispatch } = useSources();

    useEffect(() => setIsLoading(isFetching), [isFetching]);

    useEffect(() => {
        if (!data?.success && !isFetching) {
            let errorMessage;
            if (data?.result?.errors?.length > 0) {
                errorMessage = data.result.errors?.map((errorObj) => `${errorObj.errorMessage}`)?.join(', ');
            } else if (data?.result?.message) {
                errorMessage = data?.result?.message;
            } else if (error) {
                errorMessage = error.message;
            }
            toast.error(errorMessage, {
                position: 'bottom-right',
            });
        }
    }, [data, isFetching]);

    return customerType === 'New' ? null : (
        <>
            <div className="details-item qa-label-customer-sfdc-oemid">Customer SFDC ID</div>
            <div className="details-item qa-input-customer-sfdc-oemid">
                <FormControl variant="outlined">
                    <InputBaseStyled
                        name="customerSfdcOemId"
                        style={{ width: '300px' }}
                        value={customerSfdcOemId}
                        onChange={(e) => {
                            const { value } = e.target;
                            dispatch(saveCustomerDetails({ customerSfdcOemId: value }));
                        }}
                        type="text"
                        disableUnderline
                        placeholder={isDruvaUser ? 'Account Case Safe ID' : 'OEM External ID'}
                        disabled={!!customername}
                        className="qa-input-customer-id"
                    />
                </FormControl>
                {customername ? null : (
                    <Button
                        className={`${
                            customerSfdcOemId ? 'get-details' : 'get-details-disabled'
                        } qa-button-fetch-details`}
                        handleClick={() => {
                            dispatch(saveCustomerDetails({ fetchCustomerDetails: true }));
                        }}
                        isDisabled={!customerSfdcOemId}
                    >
                        Get Details
                    </Button>
                )}
            </div>
        </>
    );
};

export default CustomerSfdcOemId;
