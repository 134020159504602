import React, { useState } from 'react';

import Button from 'components/Button/Button';
import PDFDialog from 'components/PDFDialog/PDFDialog';
import { exportPdfButton, exportPdfButtonDisabled } from 'components/Button/styles';

import { usePdfData } from 'hooks/usePdfData';
import useTilesData from 'components/Tiles/useTilesData';
import { useRootState } from 'store/StateProvider';
import { usePdfDataContext } from 'config/context/pdfDataContext';
import { usePdfDialogData } from 'hooks/usePdfDialogData';

type Props = {
    className?: string;
};

const PDFButton = ({ className }: Props): JSX.Element => {
    const [isDialogOpen, changeDialogOpen] = useState(false);
    const {
        sourcesData: { sources, tcoYear },
        sku,
        assumptions,
    } = useRootState();

    const {
        graphCompleted,
        backupData,
        backupACV,
        effectivePrice,
        tabIndex,
        timeRange,
        changeGraphCompleted,
        changeBackupData,
        changeBackupACV,
        changeTimeRange,
        changeTimeRangeInSummaryGraph,
        changePriceInformation,
        changeIsCustomerInfoVisible,
    } = usePdfDataContext();

    const {
        pdfReady,
        changePdfReady,
        isPriceInformationChecked,
        changePriceInformationChecked,
        isCustomerInfoVisibleChecked,
        changeIsCustomerInfoVisibleChecked,
    } = usePdfDialogData(changeTimeRange, changePriceInformation, changeIsCustomerInfoVisible);

    const tilesData = useTilesData();
    const { pdfDialogReady, summaryGraphUrl } = usePdfData(
        tilesData,
        sources,
        tcoYear,
        sku,
        assumptions,
        graphCompleted,
        backupData,
        backupACV,
        effectivePrice,
        tabIndex,
        timeRange,
        changeGraphCompleted,
        changeBackupData,
        changeBackupACV,
        changePdfReady,
        changeTimeRangeInSummaryGraph,
    );

    return (
        <>
            <Button
                className={className}
                style={pdfDialogReady ? exportPdfButton : exportPdfButtonDisabled}
                isDisabled={!pdfDialogReady}
                handleClick={() => changeDialogOpen(true)}
            >
                Export
            </Button>
            {/* TODO: change implementation to use existing dialog context */}
            {isDialogOpen && (
                <PDFDialog
                    isOpen={isDialogOpen}
                    summaryGraphUrl={summaryGraphUrl}
                    sources={sources}
                    backupData={backupData}
                    sku={sku}
                    backupACV={backupACV}
                    tilesData={tilesData}
                    pdfReady={pdfReady}
                    handleClose={() => changeDialogOpen(false)}
                    isPriceInformationChecked={isPriceInformationChecked}
                    isCustomerInfoVisibleChecked={isCustomerInfoVisibleChecked}
                    changePriceInformationChecked={changePriceInformationChecked}
                    changeIsCustomerInfoVisibleChecked={changeIsCustomerInfoVisibleChecked}
                />
            )}
        </>
    );
};

PDFButton.defaultProps = {
    className: null,
};

export default PDFButton;
