import { useAPIMutation } from 'hooks/useAPIMutation';
import { useRootState } from 'store/StateProvider';
import { SIZER_V2_BASE_URL } from 'views/constants';

const useDoSizing = () => {
    const {
        deploymentConfig: {
            directorySvcResponse: { endpoint, protocol },
        },
        deploymentConfig: { envData },
    } = useRootState();

    const url = `${SIZER_V2_BASE_URL}/sizing`;

    const mutation = useAPIMutation({
        key: ['doSizing'],
        url,
        baseUrl: protocol ? `${protocol}://${endpoint}` : envData.MAINLINE_DEP0_API_GATEWAY_URL,
    });
    return mutation;
};
export default useDoSizing;
