export const getDeploymentSummaryViewResponse = {
    results: {
        ltr: {
            dailyData: [
                { totalDaily: 18.64, targetTbDaily: 8.11 },
                { totalDaily: 37.71, targetTbDaily: 16.43 },
                { totalDaily: 56.73, targetTbDaily: 24.7 },
                { totalDaily: 76.34, targetTbDaily: 33.21 },
                { totalDaily: 95.58, targetTbDaily: 41.58 },
                { totalDaily: 115.74, targetTbDaily: 50.35 },
                { totalDaily: 135.13, targetTbDaily: 58.79 },
                { totalDaily: 155.87, targetTbDaily: 67.81 },
                { totalDaily: 175.47, targetTbDaily: 76.31 },
                { totalDaily: 196.7, targetTbDaily: 85.58 },
                { totalDaily: 216.51, targetTbDaily: 94.19 },
                { totalDaily: 238.29, targetTbDaily: 103.66 },
                { totalDaily: 258.25, targetTbDaily: 112.35 },
                { totalDaily: 280.58, targetTbDaily: 122.07 },
                { totalDaily: 300.71, targetTbDaily: 130.82 },
                { totalDaily: 310.26, targetTbDaily: 134.72 },
                { totalDaily: 319.58, targetTbDaily: 138.56 },
                { totalDaily: 328.85, targetTbDaily: 142.35 },
                { totalDaily: 338.08, targetTbDaily: 146.13 },
                { totalDaily: 347.35, targetTbDaily: 149.97 },
                { totalDaily: 356.55, targetTbDaily: 153.74 },
                { totalDaily: 365.78, targetTbDaily: 157.51 },
                { totalDaily: 382.84, targetTbDaily: 164.64 },
                { totalDaily: 390.68, targetTbDaily: 167.78 },
                { totalDaily: 398.54, targetTbDaily: 170.92 },
                { totalDaily: 406.43, targetTbDaily: 174.1 },
                { totalDaily: 414.25, targetTbDaily: 177.23 },
                { totalDaily: 422.11, targetTbDaily: 180.37 },
                { totalDaily: 429.99, targetTbDaily: 183.51 },
                { totalDaily: 447.04, targetTbDaily: 190.62 },
                { totalDaily: 448.5, targetTbDaily: 191.23 },
                { totalDaily: 449.92, targetTbDaily: 191.79 },
                { totalDaily: 451.37, targetTbDaily: 192.37 },
                { totalDaily: 452.81, targetTbDaily: 192.96 },
                { totalDaily: 454.24, targetTbDaily: 193.52 },
                { totalDaily: 455.68, targetTbDaily: 194.11 },
                { totalDaily: 457.15, targetTbDaily: 194.71 },
                { totalDaily: 457.24, targetTbDaily: 194.73 },
                { totalDaily: 457.37, targetTbDaily: 194.82 },
                { totalDaily: 457.5, targetTbDaily: 194.87 },
                { totalDaily: 457.6, targetTbDaily: 194.88 },
                { totalDaily: 457.72, targetTbDaily: 194.95 },
                { totalDaily: 457.87, targetTbDaily: 195.01 },
                { totalDaily: 457.99, targetTbDaily: 195.06 },
                { totalDaily: 458.1, targetTbDaily: 195.12 },
                { totalDaily: 458.21, targetTbDaily: 195.16 },
                { totalDaily: 458.37, targetTbDaily: 195.23 },
                { totalDaily: 458.47, targetTbDaily: 195.27 },
                { totalDaily: 458.6, targetTbDaily: 195.32 },
                { totalDaily: 458.68, targetTbDaily: 195.35 },
                { totalDaily: 458.79, targetTbDaily: 195.42 },
                { totalDaily: 458.91, targetTbDaily: 195.46 },
                { totalDaily: 459.06, targetTbDaily: 195.5 },
                { totalDaily: 459.21, targetTbDaily: 195.58 },
                { totalDaily: 459.3, targetTbDaily: 195.62 },
                { totalDaily: 459.45, targetTbDaily: 195.68 },
                { totalDaily: 459.54, targetTbDaily: 195.72 },
                { totalDaily: 459.64, targetTbDaily: 195.76 },
                { totalDaily: 459.79, targetTbDaily: 195.83 },
                { totalDaily: 459.9, targetTbDaily: 195.88 },
                { totalDaily: 460, targetTbDaily: 195.91 },
                { totalDaily: 490.7, targetTbDaily: 208.98 },
                { totalDaily: 490.87, targetTbDaily: 209.06 },
                { totalDaily: 491.01, targetTbDaily: 209.13 },
                { totalDaily: 491.09, targetTbDaily: 209.15 },
                { totalDaily: 491.22, targetTbDaily: 209.23 },
                { totalDaily: 491.37, targetTbDaily: 209.27 },
                { totalDaily: 491.49, targetTbDaily: 209.33 },
                { totalDaily: 491.65, targetTbDaily: 209.4 },
                { totalDaily: 491.74, targetTbDaily: 209.42 },
                { totalDaily: 491.88, targetTbDaily: 209.51 },
                { totalDaily: 492, targetTbDaily: 209.54 },
                { totalDaily: 492.15, targetTbDaily: 209.6 },
                { totalDaily: 492.3, targetTbDaily: 209.68 },
                { totalDaily: 492.42, targetTbDaily: 209.73 },
                { totalDaily: 492.54, targetTbDaily: 209.79 },
                { totalDaily: 492.64, targetTbDaily: 209.81 },
                { totalDaily: 492.78, targetTbDaily: 209.88 },
                { totalDaily: 492.91, targetTbDaily: 209.93 },
                { totalDaily: 493.03, targetTbDaily: 209.98 },
                { totalDaily: 493.16, targetTbDaily: 210.05 },
                { totalDaily: 493.29, targetTbDaily: 210.09 },
                { totalDaily: 493.45, targetTbDaily: 210.14 },
                { totalDaily: 493.54, targetTbDaily: 210.2 },
                { totalDaily: 493.66, targetTbDaily: 210.26 },
                { totalDaily: 493.81, targetTbDaily: 210.32 },
                { totalDaily: 493.95, targetTbDaily: 210.37 },
                { totalDaily: 494.06, targetTbDaily: 210.42 },
                { totalDaily: 494.18, targetTbDaily: 210.48 },
                { totalDaily: 494.37, targetTbDaily: 210.55 },
                { totalDaily: 494.44, targetTbDaily: 210.61 },
                { totalDaily: 525.16, targetTbDaily: 223.65 },
                { totalDaily: 525.29, targetTbDaily: 223.7 },
                { totalDaily: 525.45, targetTbDaily: 223.8 },
                { totalDaily: 525.54, targetTbDaily: 223.82 },
                { totalDaily: 525.75, targetTbDaily: 223.92 },
                { totalDaily: 525.91, targetTbDaily: 223.98 },
                { totalDaily: 526.01, targetTbDaily: 224.03 },
                { totalDaily: 526.16, targetTbDaily: 224.09 },
                { totalDaily: 526.28, targetTbDaily: 224.15 },
                { totalDaily: 526.43, targetTbDaily: 224.22 },
                { totalDaily: 526.56, targetTbDaily: 224.27 },
                { totalDaily: 526.68, targetTbDaily: 224.32 },
                { totalDaily: 526.85, targetTbDaily: 224.39 },
                { totalDaily: 526.98, targetTbDaily: 224.44 },
                { totalDaily: 527.12, targetTbDaily: 224.5 },
                { totalDaily: 527.26, targetTbDaily: 224.56 },
                { totalDaily: 527.39, targetTbDaily: 224.63 },
                { totalDaily: 527.52, targetTbDaily: 224.68 },
                { totalDaily: 527.66, targetTbDaily: 224.73 },
                { totalDaily: 527.79, targetTbDaily: 224.81 },
                { totalDaily: 527.91, targetTbDaily: 224.86 },
                { totalDaily: 528.08, targetTbDaily: 224.92 },
                { totalDaily: 528.23, targetTbDaily: 224.97 },
                { totalDaily: 528.32, targetTbDaily: 225.01 },
                { totalDaily: 528.48, targetTbDaily: 225.08 },
                { totalDaily: 528.66, targetTbDaily: 225.14 },
                { totalDaily: 528.77, targetTbDaily: 225.21 },
                { totalDaily: 528.9, targetTbDaily: 225.28 },
                { totalDaily: 529.01, targetTbDaily: 225.33 },
                { totalDaily: 529.14, targetTbDaily: 225.36 },
                { totalDaily: 530.1, targetTbDaily: 225.71 },
                { totalDaily: 530.25, targetTbDaily: 225.76 },
                { totalDaily: 530.39, targetTbDaily: 225.81 },
                { totalDaily: 530.51, targetTbDaily: 225.87 },
                { totalDaily: 530.67, targetTbDaily: 225.94 },
                { totalDaily: 530.8, targetTbDaily: 226 },
                { totalDaily: 530.97, targetTbDaily: 226.06 },
                { totalDaily: 531.08, targetTbDaily: 226.12 },
                { totalDaily: 531.21, targetTbDaily: 226.17 },
                { totalDaily: 531.35, targetTbDaily: 226.23 },
                { totalDaily: 531.48, targetTbDaily: 226.27 },
                { totalDaily: 531.66, targetTbDaily: 226.36 },
                { totalDaily: 531.79, targetTbDaily: 226.4 },
                { totalDaily: 531.91, targetTbDaily: 226.47 },
                { totalDaily: 532.03, targetTbDaily: 226.52 },
                { totalDaily: 532.19, targetTbDaily: 226.58 },
                { totalDaily: 532.36, targetTbDaily: 226.66 },
                { totalDaily: 532.48, targetTbDaily: 226.7 },
                { totalDaily: 532.64, targetTbDaily: 226.76 },
                { totalDaily: 532.74, targetTbDaily: 226.83 },
                { totalDaily: 532.87, targetTbDaily: 226.86 },
                { totalDaily: 533.03, targetTbDaily: 226.93 },
                { totalDaily: 533.21, targetTbDaily: 227.02 },
                { totalDaily: 533.32, targetTbDaily: 227.08 },
                { totalDaily: 533.41, targetTbDaily: 227.11 },
                { totalDaily: 533.6, targetTbDaily: 227.2 },
                { totalDaily: 533.72, targetTbDaily: 227.22 },
                { totalDaily: 533.85, targetTbDaily: 227.29 },
                { totalDaily: 534.01, targetTbDaily: 227.36 },
                { totalDaily: 534.12, targetTbDaily: 227.41 },
                { totalDaily: 535.1, targetTbDaily: 227.75 },
                { totalDaily: 535.23, targetTbDaily: 227.81 },
                { totalDaily: 535.36, targetTbDaily: 227.86 },
                { totalDaily: 535.5, targetTbDaily: 227.93 },
                { totalDaily: 535.64, targetTbDaily: 227.96 },
                { totalDaily: 535.77, targetTbDaily: 228.03 },
                { totalDaily: 535.92, targetTbDaily: 228.09 },
                { totalDaily: 536.09, targetTbDaily: 228.17 },
                { totalDaily: 536.24, targetTbDaily: 228.22 },
                { totalDaily: 536.39, targetTbDaily: 228.32 },
                { totalDaily: 536.47, targetTbDaily: 228.33 },
                { totalDaily: 536.64, targetTbDaily: 228.41 },
                { totalDaily: 536.76, targetTbDaily: 228.45 },
                { totalDaily: 536.9, targetTbDaily: 228.52 },
                { totalDaily: 537.04, targetTbDaily: 228.57 },
                { totalDaily: 537.2, targetTbDaily: 228.65 },
                { totalDaily: 537.34, targetTbDaily: 228.7 },
                { totalDaily: 537.46, targetTbDaily: 228.76 },
                { totalDaily: 537.66, targetTbDaily: 228.87 },
                { totalDaily: 537.78, targetTbDaily: 228.87 },
                { totalDaily: 537.89, targetTbDaily: 228.94 },
                { totalDaily: 538.01, targetTbDaily: 228.98 },
                { totalDaily: 538.16, targetTbDaily: 229.04 },
                { totalDaily: 538.31, targetTbDaily: 229.11 },
                { totalDaily: 538.46, targetTbDaily: 229.16 },
                { totalDaily: 538.61, targetTbDaily: 229.25 },
                { totalDaily: 538.76, targetTbDaily: 229.33 },
                { totalDaily: 538.9, targetTbDaily: 229.35 },
                { totalDaily: 539.06, targetTbDaily: 229.43 },
                { totalDaily: 539.19, targetTbDaily: 229.49 },
                { totalDaily: 540.1, targetTbDaily: 229.81 },
                { totalDaily: 540.25, targetTbDaily: 229.86 },
                { totalDaily: 540.37, targetTbDaily: 229.92 },
                { totalDaily: 540.53, targetTbDaily: 230 },
                { totalDaily: 540.66, targetTbDaily: 230.04 },
                { totalDaily: 540.8, targetTbDaily: 230.1 },
                { totalDaily: 540.93, targetTbDaily: 230.16 },
                { totalDaily: 541.05, targetTbDaily: 230.2 },
                { totalDaily: 541.24, targetTbDaily: 230.3 },
                { totalDaily: 541.38, targetTbDaily: 230.35 },
                { totalDaily: 541.49, targetTbDaily: 230.39 },
                { totalDaily: 541.67, targetTbDaily: 230.47 },
                { totalDaily: 541.81, targetTbDaily: 230.53 },
                { totalDaily: 541.89, targetTbDaily: 230.57 },
                { totalDaily: 542.06, targetTbDaily: 230.63 },
                { totalDaily: 542.2, targetTbDaily: 230.69 },
                { totalDaily: 542.37, targetTbDaily: 230.77 },
                { totalDaily: 542.51, targetTbDaily: 230.83 },
                { totalDaily: 542.65, targetTbDaily: 230.89 },
                { totalDaily: 542.79, targetTbDaily: 230.94 },
                { totalDaily: 542.91, targetTbDaily: 231 },
                { totalDaily: 543.06, targetTbDaily: 231.04 },
                { totalDaily: 543.22, targetTbDaily: 231.14 },
                { totalDaily: 543.36, targetTbDaily: 231.21 },
                { totalDaily: 543.52, targetTbDaily: 231.24 },
                { totalDaily: 543.68, targetTbDaily: 231.34 },
                { totalDaily: 543.78, targetTbDaily: 231.36 },
                { totalDaily: 543.93, targetTbDaily: 231.41 },
                { totalDaily: 544.04, targetTbDaily: 231.45 },
                { totalDaily: 544.21, targetTbDaily: 231.56 },
                { totalDaily: 545.14, targetTbDaily: 231.88 },
                { totalDaily: 545.31, targetTbDaily: 231.94 },
                { totalDaily: 545.44, targetTbDaily: 232.01 },
                { totalDaily: 545.58, targetTbDaily: 232.07 },
                { totalDaily: 545.74, targetTbDaily: 232.14 },
                { totalDaily: 545.85, targetTbDaily: 232.17 },
                { totalDaily: 545.98, targetTbDaily: 232.22 },
                { totalDaily: 546.16, targetTbDaily: 232.31 },
                { totalDaily: 546.3, targetTbDaily: 232.37 },
                { totalDaily: 546.45, targetTbDaily: 232.43 },
                { totalDaily: 546.61, targetTbDaily: 232.5 },
                { totalDaily: 546.73, targetTbDaily: 232.54 },
                { totalDaily: 546.84, targetTbDaily: 232.6 },
                { totalDaily: 546.99, targetTbDaily: 232.67 },
                { totalDaily: 547.19, targetTbDaily: 232.75 },
                { totalDaily: 547.33, targetTbDaily: 232.79 },
                { totalDaily: 547.44, targetTbDaily: 232.87 },
                { totalDaily: 547.58, targetTbDaily: 232.91 },
                { totalDaily: 547.75, targetTbDaily: 232.98 },
                { totalDaily: 547.88, targetTbDaily: 233.03 },
                { totalDaily: 548.04, targetTbDaily: 233.12 },
                { totalDaily: 548.16, targetTbDaily: 233.17 },
                { totalDaily: 548.29, targetTbDaily: 233.22 },
                { totalDaily: 548.41, targetTbDaily: 233.25 },
                { totalDaily: 548.55, targetTbDaily: 233.33 },
                { totalDaily: 548.7, targetTbDaily: 233.39 },
                { totalDaily: 548.84, targetTbDaily: 233.46 },
                { totalDaily: 549.07, targetTbDaily: 233.55 },
                { totalDaily: 549.19, targetTbDaily: 233.6 },
                { totalDaily: 549.33, targetTbDaily: 233.66 },
                { totalDaily: 550.24, targetTbDaily: 233.97 },
                { totalDaily: 550.37, targetTbDaily: 234.03 },
                { totalDaily: 550.52, targetTbDaily: 234.09 },
                { totalDaily: 550.66, targetTbDaily: 234.14 },
                { totalDaily: 550.83, targetTbDaily: 234.21 },
                { totalDaily: 550.93, targetTbDaily: 234.26 },
                { totalDaily: 551.09, targetTbDaily: 234.31 },
                { totalDaily: 551.27, targetTbDaily: 234.41 },
                { totalDaily: 551.4, targetTbDaily: 234.45 },
                { totalDaily: 551.53, targetTbDaily: 234.52 },
                { totalDaily: 551.69, targetTbDaily: 234.58 },
                { totalDaily: 551.84, targetTbDaily: 234.64 },
                { totalDaily: 551.95, targetTbDaily: 234.7 },
                { totalDaily: 552.12, targetTbDaily: 234.76 },
                { totalDaily: 552.27, targetTbDaily: 234.83 },
                { totalDaily: 552.38, targetTbDaily: 234.89 },
                { totalDaily: 552.58, targetTbDaily: 234.97 },
                { totalDaily: 552.74, targetTbDaily: 235.04 },
                { totalDaily: 552.85, targetTbDaily: 235.08 },
                { totalDaily: 552.99, targetTbDaily: 235.15 },
                { totalDaily: 553.14, targetTbDaily: 235.2 },
                { totalDaily: 553.32, targetTbDaily: 235.25 },
                { totalDaily: 553.43, targetTbDaily: 235.31 },
                { totalDaily: 553.6, targetTbDaily: 235.4 },
                { totalDaily: 553.72, targetTbDaily: 235.44 },
                { totalDaily: 553.86, targetTbDaily: 235.5 },
                { totalDaily: 554.02, targetTbDaily: 235.58 },
                { totalDaily: 554.16, targetTbDaily: 235.62 },
                { totalDaily: 554.29, targetTbDaily: 235.69 },
                { totalDaily: 554.44, targetTbDaily: 235.77 },
                { totalDaily: 555.39, targetTbDaily: 236.08 },
                { totalDaily: 555.51, targetTbDaily: 236.13 },
                { totalDaily: 555.71, targetTbDaily: 236.23 },
                { totalDaily: 555.81, targetTbDaily: 236.26 },
                { totalDaily: 555.97, targetTbDaily: 236.33 },
                { totalDaily: 556.08, targetTbDaily: 236.36 },
                { totalDaily: 556.22, targetTbDaily: 236.44 },
                { totalDaily: 556.35, targetTbDaily: 236.49 },
                { totalDaily: 556.54, targetTbDaily: 236.56 },
                { totalDaily: 556.74, targetTbDaily: 236.67 },
                { totalDaily: 556.86, targetTbDaily: 236.7 },
                { totalDaily: 556.96, targetTbDaily: 236.75 },
                { totalDaily: 557.16, targetTbDaily: 236.83 },
                { totalDaily: 557.29, targetTbDaily: 236.89 },
                { totalDaily: 557.41, targetTbDaily: 236.95 },
                { totalDaily: 557.54, targetTbDaily: 236.98 },
                { totalDaily: 557.7, targetTbDaily: 237.07 },
                { totalDaily: 557.88, targetTbDaily: 237.14 },
                { totalDaily: 557.98, targetTbDaily: 237.19 },
                { totalDaily: 558.17, targetTbDaily: 237.25 },
                { totalDaily: 558.29, targetTbDaily: 237.32 },
                { totalDaily: 558.45, targetTbDaily: 237.39 },
                { totalDaily: 558.6, targetTbDaily: 237.44 },
                { totalDaily: 558.75, targetTbDaily: 237.51 },
                { totalDaily: 558.86, targetTbDaily: 237.55 },
                { totalDaily: 559.03, targetTbDaily: 237.63 },
                { totalDaily: 559.16, targetTbDaily: 237.67 },
                { totalDaily: 559.33, targetTbDaily: 237.77 },
                { totalDaily: 559.47, targetTbDaily: 237.83 },
                { totalDaily: 559.62, targetTbDaily: 237.87 },
                { totalDaily: 560.54, targetTbDaily: 238.18 },
                { totalDaily: 560.66, targetTbDaily: 238.25 },
                { totalDaily: 560.86, targetTbDaily: 238.34 },
                { totalDaily: 560.96, targetTbDaily: 238.38 },
                { totalDaily: 561.14, targetTbDaily: 238.47 },
                { totalDaily: 561.3, targetTbDaily: 238.5 },
                { totalDaily: 561.47, targetTbDaily: 238.61 },
                { totalDaily: 561.58, targetTbDaily: 238.65 },
                { totalDaily: 561.73, targetTbDaily: 238.7 },
                { totalDaily: 561.9, targetTbDaily: 238.77 },
                { totalDaily: 562.02, targetTbDaily: 238.83 },
                { totalDaily: 562.15, targetTbDaily: 238.86 },
                { totalDaily: 562.32, targetTbDaily: 238.94 },
                { totalDaily: 562.46, targetTbDaily: 239.01 },
                { totalDaily: 562.6, targetTbDaily: 239.05 },
                { totalDaily: 562.76, targetTbDaily: 239.17 },
                { totalDaily: 562.92, targetTbDaily: 239.22 },
                { totalDaily: 563.05, targetTbDaily: 239.29 },
                { totalDaily: 563.22, targetTbDaily: 239.33 },
                { totalDaily: 563.39, targetTbDaily: 239.42 },
                { totalDaily: 563.48, targetTbDaily: 239.47 },
                { totalDaily: 563.65, targetTbDaily: 239.52 },
                { totalDaily: 563.77, targetTbDaily: 239.56 },
                { totalDaily: 563.97, targetTbDaily: 239.64 },
                { totalDaily: 564.09, targetTbDaily: 239.7 },
                { totalDaily: 564.23, targetTbDaily: 239.78 },
                { totalDaily: 564.35, targetTbDaily: 239.83 },
                { totalDaily: 564.51, targetTbDaily: 239.87 },
                { totalDaily: 564.65, targetTbDaily: 239.93 },
                { totalDaily: 564.8, targetTbDaily: 240 },
                { totalDaily: 565.75, targetTbDaily: 240.35 },
                { totalDaily: 565.92, targetTbDaily: 240.42 },
                { totalDaily: 566.06, targetTbDaily: 240.49 },
                { totalDaily: 566.18, targetTbDaily: 240.53 },
                { totalDaily: 566.37, targetTbDaily: 240.59 },
                { totalDaily: 566.5, targetTbDaily: 240.65 },
                { totalDaily: 566.62, targetTbDaily: 240.72 },
                { totalDaily: 566.79, targetTbDaily: 240.8 },
                { totalDaily: 566.98, targetTbDaily: 240.85 },
                { totalDaily: 567.13, targetTbDaily: 240.92 },
                { totalDaily: 567.26, targetTbDaily: 240.98 },
                { totalDaily: 567.41, targetTbDaily: 241.04 },
                { totalDaily: 567.55, targetTbDaily: 241.11 },
                { totalDaily: 567.69, targetTbDaily: 241.18 },
                { totalDaily: 567.79, targetTbDaily: 241.2 },
                { totalDaily: 568, targetTbDaily: 241.29 },
                { totalDaily: 568.16, targetTbDaily: 241.37 },
                { totalDaily: 568.28, targetTbDaily: 241.4 },
                { totalDaily: 568.44, targetTbDaily: 241.51 },
                { totalDaily: 568.62, targetTbDaily: 241.56 },
                { totalDaily: 568.74, targetTbDaily: 241.6 },
                { totalDaily: 568.87, targetTbDaily: 241.68 },
                { totalDaily: 569.04, targetTbDaily: 241.71 },
                { totalDaily: 569.18, targetTbDaily: 241.78 },
                { totalDaily: 569.29, targetTbDaily: 241.85 },
                { totalDaily: 569.45, targetTbDaily: 241.89 },
                { totalDaily: 569.7, targetTbDaily: 242.01 },
                { totalDaily: 569.82, targetTbDaily: 242.08 },
                { totalDaily: 569.95, targetTbDaily: 242.15 },
                { totalDaily: 570.13, targetTbDaily: 242.2 },
                { totalDaily: 571.04, targetTbDaily: 242.5 },
                { totalDaily: 571.16, targetTbDaily: 242.56 },
                { totalDaily: 571.29, targetTbDaily: 242.63 },
                { totalDaily: 571.5, targetTbDaily: 242.7 },
                { totalDaily: 571.63, targetTbDaily: 242.75 },
                { totalDaily: 706.87, targetTbDaily: 300.71 },
                { totalDaily: 707.05, targetTbDaily: 300.75 },
                { totalDaily: 707.24, targetTbDaily: 300.84 },
                { totalDaily: 707.44, targetTbDaily: 300.94 },
                { totalDaily: 707.62, targetTbDaily: 301.01 },
                { totalDaily: 707.8, targetTbDaily: 301.08 },
                { totalDaily: 707.99, targetTbDaily: 301.16 },
                { totalDaily: 708.18, targetTbDaily: 301.25 },
                { totalDaily: 708.35, targetTbDaily: 301.32 },
                { totalDaily: 708.54, targetTbDaily: 301.39 },
                { totalDaily: 708.71, targetTbDaily: 301.5 },
                { totalDaily: 708.94, targetTbDaily: 301.58 },
                { totalDaily: 709.07, targetTbDaily: 301.62 },
                { totalDaily: 709.3, targetTbDaily: 301.7 },
                { totalDaily: 709.48, targetTbDaily: 301.82 },
                { totalDaily: 709.65, targetTbDaily: 301.87 },
                { totalDaily: 709.86, targetTbDaily: 301.98 },
                { totalDaily: 710.06, targetTbDaily: 302.05 },
                { totalDaily: 710.2, targetTbDaily: 302.12 },
                { totalDaily: 710.43, targetTbDaily: 302.2 },
                { totalDaily: 710.58, targetTbDaily: 302.27 },
                { totalDaily: 710.77, targetTbDaily: 302.36 },
                { totalDaily: 710.9, targetTbDaily: 302.39 },
                { totalDaily: 711.11, targetTbDaily: 302.5 },
                { totalDaily: 711.27, targetTbDaily: 302.53 },
                { totalDaily: 711.48, targetTbDaily: 302.64 },
                { totalDaily: 711.68, targetTbDaily: 302.73 },
                { totalDaily: 711.84, targetTbDaily: 302.81 },
                { totalDaily: 712.04, targetTbDaily: 302.89 },
                { totalDaily: 712.23, targetTbDaily: 302.95 },
                { totalDaily: 712.42, targetTbDaily: 303.02 },
                { totalDaily: 712.65, targetTbDaily: 303.14 },
                { totalDaily: 712.81, targetTbDaily: 303.23 },
                { totalDaily: 713, targetTbDaily: 303.28 },
                { totalDaily: 713.14, targetTbDaily: 303.35 },
                { totalDaily: 713.39, targetTbDaily: 303.48 },
                { totalDaily: 713.53, targetTbDaily: 303.53 },
                { totalDaily: 713.73, targetTbDaily: 303.62 },
                { totalDaily: 713.93, targetTbDaily: 303.69 },
                { totalDaily: 714.07, targetTbDaily: 303.73 },
                { totalDaily: 714.3, targetTbDaily: 303.84 },
                { totalDaily: 714.5, targetTbDaily: 303.94 },
                { totalDaily: 714.72, targetTbDaily: 304.03 },
                { totalDaily: 714.87, targetTbDaily: 304.1 },
                { totalDaily: 715.07, targetTbDaily: 304.17 },
                { totalDaily: 715.2, targetTbDaily: 304.25 },
                { totalDaily: 715.4, targetTbDaily: 304.3 },
                { totalDaily: 715.54, targetTbDaily: 304.37 },
                { totalDaily: 715.76, targetTbDaily: 304.45 },
                { totalDaily: 715.99, targetTbDaily: 304.54 },
                { totalDaily: 716.18, targetTbDaily: 304.68 },
                { totalDaily: 716.34, targetTbDaily: 304.72 },
                { totalDaily: 716.54, targetTbDaily: 304.81 },
                { totalDaily: 716.71, targetTbDaily: 304.89 },
                { totalDaily: 716.9, targetTbDaily: 304.95 },
                { totalDaily: 717.09, targetTbDaily: 305.02 },
                { totalDaily: 717.3, targetTbDaily: 305.1 },
                { totalDaily: 717.47, targetTbDaily: 305.2 },
                { totalDaily: 717.66, targetTbDaily: 305.27 },
                { totalDaily: 717.84, targetTbDaily: 305.37 },
                { totalDaily: 718.01, targetTbDaily: 305.42 },
                { totalDaily: 718.21, targetTbDaily: 305.51 },
                { totalDaily: 718.42, targetTbDaily: 305.58 },
                { totalDaily: 718.63, targetTbDaily: 305.7 },
                { totalDaily: 718.79, targetTbDaily: 305.75 },
                { totalDaily: 718.98, targetTbDaily: 305.85 },
                { totalDaily: 719.18, targetTbDaily: 305.91 },
                { totalDaily: 719.36, targetTbDaily: 306.01 },
                { totalDaily: 719.57, targetTbDaily: 306.07 },
                { totalDaily: 719.77, targetTbDaily: 306.16 },
                { totalDaily: 719.9, targetTbDaily: 306.23 },
                { totalDaily: 720.1, targetTbDaily: 306.32 },
                { totalDaily: 720.29, targetTbDaily: 306.39 },
                { totalDaily: 720.5, targetTbDaily: 306.5 },
                { totalDaily: 720.68, targetTbDaily: 306.56 },
                { totalDaily: 720.83, targetTbDaily: 306.62 },
                { totalDaily: 721.04, targetTbDaily: 306.75 },
                { totalDaily: 721.22, targetTbDaily: 306.78 },
                { totalDaily: 721.4, targetTbDaily: 306.87 },
                { totalDaily: 721.57, targetTbDaily: 306.94 },
                { totalDaily: 721.75, targetTbDaily: 307.02 },
                { totalDaily: 721.95, targetTbDaily: 307.08 },
                { totalDaily: 722.14, targetTbDaily: 307.21 },
                { totalDaily: 722.36, targetTbDaily: 307.28 },
                { totalDaily: 722.53, targetTbDaily: 307.33 },
                { totalDaily: 722.75, targetTbDaily: 307.44 },
                { totalDaily: 722.9, targetTbDaily: 307.51 },
                { totalDaily: 723.08, targetTbDaily: 307.57 },
                { totalDaily: 723.31, targetTbDaily: 307.68 },
                { totalDaily: 723.5, targetTbDaily: 307.77 },
                { totalDaily: 723.72, targetTbDaily: 307.86 },
                { totalDaily: 723.89, targetTbDaily: 307.91 },
                { totalDaily: 724.09, targetTbDaily: 308.02 },
                { totalDaily: 724.28, targetTbDaily: 308.1 },
                { totalDaily: 724.48, targetTbDaily: 308.19 },
                { totalDaily: 724.59, targetTbDaily: 308.21 },
                { totalDaily: 724.79, targetTbDaily: 308.31 },
                { totalDaily: 725.03, targetTbDaily: 308.4 },
                { totalDaily: 725.23, targetTbDaily: 308.49 },
                { totalDaily: 725.4, targetTbDaily: 308.54 },
                { totalDaily: 725.6, targetTbDaily: 308.65 },
                { totalDaily: 725.77, targetTbDaily: 308.73 },
                { totalDaily: 725.93, targetTbDaily: 308.78 },
                { totalDaily: 726.14, targetTbDaily: 308.89 },
                { totalDaily: 726.32, targetTbDaily: 308.96 },
                { totalDaily: 726.48, targetTbDaily: 309.02 },
                { totalDaily: 726.72, targetTbDaily: 309.13 },
                { totalDaily: 726.94, targetTbDaily: 309.22 },
                { totalDaily: 727.11, targetTbDaily: 309.3 },
                { totalDaily: 727.27, targetTbDaily: 309.38 },
                { totalDaily: 727.47, targetTbDaily: 309.45 },
                { totalDaily: 727.65, targetTbDaily: 309.52 },
                { totalDaily: 727.83, targetTbDaily: 309.6 },
                { totalDaily: 728, targetTbDaily: 309.66 },
                { totalDaily: 728.22, targetTbDaily: 309.78 },
                { totalDaily: 728.41, targetTbDaily: 309.87 },
                { totalDaily: 728.64, targetTbDaily: 309.94 },
                { totalDaily: 728.79, targetTbDaily: 310.01 },
                { totalDaily: 729.01, targetTbDaily: 310.12 },
                { totalDaily: 729.19, targetTbDaily: 310.18 },
                { totalDaily: 729.33, targetTbDaily: 310.25 },
                { totalDaily: 729.54, targetTbDaily: 310.33 },
                { totalDaily: 729.76, targetTbDaily: 310.43 },
                { totalDaily: 729.92, targetTbDaily: 310.49 },
                { totalDaily: 730.13, targetTbDaily: 310.61 },
                { totalDaily: 730.35, targetTbDaily: 310.68 },
                { totalDaily: 730.55, targetTbDaily: 310.76 },
                { totalDaily: 730.71, targetTbDaily: 310.83 },
                { totalDaily: 730.95, targetTbDaily: 310.95 },
                { totalDaily: 731.09, targetTbDaily: 310.97 },
                { totalDaily: 731.23, targetTbDaily: 311.04 },
                { totalDaily: 731.48, targetTbDaily: 311.14 },
                { totalDaily: 731.67, targetTbDaily: 311.24 },
                { totalDaily: 731.81, targetTbDaily: 311.3 },
                { totalDaily: 732.05, targetTbDaily: 311.4 },
                { totalDaily: 732.22, targetTbDaily: 311.49 },
                { totalDaily: 732.47, targetTbDaily: 311.58 },
                { totalDaily: 732.59, targetTbDaily: 311.63 },
                { totalDaily: 732.76, targetTbDaily: 311.7 },
                { totalDaily: 732.99, targetTbDaily: 311.8 },
                { totalDaily: 733.22, targetTbDaily: 311.9 },
                { totalDaily: 733.39, targetTbDaily: 311.98 },
                { totalDaily: 733.55, targetTbDaily: 312.04 },
                { totalDaily: 733.75, targetTbDaily: 312.12 },
                { totalDaily: 733.95, targetTbDaily: 312.2 },
                { totalDaily: 734.13, targetTbDaily: 312.27 },
                { totalDaily: 734.34, targetTbDaily: 312.37 },
                { totalDaily: 734.57, targetTbDaily: 312.45 },
                { totalDaily: 734.74, targetTbDaily: 312.56 },
                { totalDaily: 734.94, targetTbDaily: 312.63 },
                { totalDaily: 735.1, targetTbDaily: 312.69 },
                { totalDaily: 735.27, targetTbDaily: 312.79 },
                { totalDaily: 735.45, targetTbDaily: 312.86 },
                { totalDaily: 735.66, targetTbDaily: 312.94 },
                { totalDaily: 735.91, targetTbDaily: 313.04 },
                { totalDaily: 736.12, targetTbDaily: 313.12 },
                { totalDaily: 736.29, targetTbDaily: 313.2 },
                { totalDaily: 736.45, targetTbDaily: 313.25 },
                { totalDaily: 736.65, targetTbDaily: 313.38 },
                { totalDaily: 736.82, targetTbDaily: 313.44 },
                { totalDaily: 737.01, targetTbDaily: 313.5 },
                { totalDaily: 737.21, targetTbDaily: 313.58 },
                { totalDaily: 737.43, targetTbDaily: 313.69 },
                { totalDaily: 737.64, targetTbDaily: 313.77 },
                { totalDaily: 737.86, targetTbDaily: 313.86 },
                { totalDaily: 738.01, targetTbDaily: 313.94 },
                { totalDaily: 738.21, targetTbDaily: 314.01 },
                { totalDaily: 738.39, targetTbDaily: 314.1 },
                { totalDaily: 738.55, targetTbDaily: 314.15 },
                { totalDaily: 738.75, targetTbDaily: 314.26 },
                { totalDaily: 738.98, targetTbDaily: 314.33 },
                { totalDaily: 739.18, targetTbDaily: 314.41 },
                { totalDaily: 739.31, targetTbDaily: 314.46 },
                { totalDaily: 739.54, targetTbDaily: 314.58 },
                { totalDaily: 739.73, targetTbDaily: 314.65 },
                { totalDaily: 739.92, targetTbDaily: 314.76 },
                { totalDaily: 740.07, targetTbDaily: 314.83 },
                { totalDaily: 740.3, targetTbDaily: 314.89 },
                { totalDaily: 740.52, targetTbDaily: 315 },
                { totalDaily: 740.69, targetTbDaily: 315.09 },
                { totalDaily: 740.88, targetTbDaily: 315.14 },
                { totalDaily: 741.08, targetTbDaily: 315.23 },
                { totalDaily: 741.26, targetTbDaily: 315.32 },
                { totalDaily: 741.47, targetTbDaily: 315.42 },
                { totalDaily: 741.65, targetTbDaily: 315.49 },
                { totalDaily: 741.89, targetTbDaily: 315.58 },
                { totalDaily: 742.03, targetTbDaily: 315.64 },
                { totalDaily: 742.26, targetTbDaily: 315.72 },
                { totalDaily: 742.5, targetTbDaily: 315.84 },
                { totalDaily: 742.63, targetTbDaily: 315.91 },
                { totalDaily: 742.85, targetTbDaily: 315.99 },
                { totalDaily: 743.03, targetTbDaily: 316.08 },
                { totalDaily: 743.21, targetTbDaily: 316.16 },
                { totalDaily: 743.38, targetTbDaily: 316.22 },
                { totalDaily: 743.58, targetTbDaily: 316.31 },
                { totalDaily: 743.8, targetTbDaily: 316.38 },
                { totalDaily: 743.98, targetTbDaily: 316.47 },
                { totalDaily: 744.19, targetTbDaily: 316.56 },
                { totalDaily: 744.4, targetTbDaily: 316.67 },
                { totalDaily: 744.56, targetTbDaily: 316.73 },
                { totalDaily: 744.75, targetTbDaily: 316.77 },
                { totalDaily: 744.98, targetTbDaily: 316.9 },
                { totalDaily: 745.17, targetTbDaily: 316.97 },
                { totalDaily: 745.39, targetTbDaily: 317.08 },
                { totalDaily: 745.59, targetTbDaily: 317.16 },
                { totalDaily: 745.76, targetTbDaily: 317.22 },
                { totalDaily: 745.92, targetTbDaily: 317.3 },
                { totalDaily: 746.13, targetTbDaily: 317.38 },
                { totalDaily: 746.33, targetTbDaily: 317.46 },
                { totalDaily: 746.5, targetTbDaily: 317.53 },
                { totalDaily: 746.74, targetTbDaily: 317.65 },
                { totalDaily: 746.96, targetTbDaily: 317.74 },
                { totalDaily: 747.14, targetTbDaily: 317.83 },
                { totalDaily: 747.33, targetTbDaily: 317.91 },
                { totalDaily: 747.48, targetTbDaily: 317.97 },
                { totalDaily: 747.68, targetTbDaily: 318.05 },
                { totalDaily: 747.83, targetTbDaily: 318.11 },
                { totalDaily: 748.09, targetTbDaily: 318.21 },
                { totalDaily: 748.33, targetTbDaily: 318.33 },
                { totalDaily: 748.5, targetTbDaily: 318.39 },
                { totalDaily: 748.68, targetTbDaily: 318.49 },
                { totalDaily: 748.89, targetTbDaily: 318.56 },
                { totalDaily: 749.05, targetTbDaily: 318.63 },
                { totalDaily: 749.21, targetTbDaily: 318.7 },
                { totalDaily: 749.47, targetTbDaily: 318.81 },
                { totalDaily: 749.64, targetTbDaily: 318.88 },
                { totalDaily: 749.83, targetTbDaily: 318.95 },
                { totalDaily: 750.03, targetTbDaily: 319.04 },
                { totalDaily: 750.25, targetTbDaily: 319.14 },
                { totalDaily: 750.4, targetTbDaily: 319.19 },
                { totalDaily: 750.59, targetTbDaily: 319.29 },
                { totalDaily: 750.83, targetTbDaily: 319.39 },
                { totalDaily: 750.99, targetTbDaily: 319.45 },
                { totalDaily: 751.21, targetTbDaily: 319.53 },
                { totalDaily: 751.44, targetTbDaily: 319.66 },
                { totalDaily: 751.63, targetTbDaily: 319.74 },
                { totalDaily: 751.8, targetTbDaily: 319.8 },
                { totalDaily: 752, targetTbDaily: 319.89 },
                { totalDaily: 752.23, targetTbDaily: 319.99 },
                { totalDaily: 752.43, targetTbDaily: 320.07 },
                { totalDaily: 752.58, targetTbDaily: 320.12 },
                { totalDaily: 752.79, targetTbDaily: 320.2 },
                { totalDaily: 753.03, targetTbDaily: 320.33 },
                { totalDaily: 753.17, targetTbDaily: 320.4 },
                { totalDaily: 753.37, targetTbDaily: 320.45 },
                { totalDaily: 753.58, targetTbDaily: 320.56 },
                { totalDaily: 753.77, targetTbDaily: 320.63 },
                { totalDaily: 753.95, targetTbDaily: 320.71 },
                { totalDaily: 754.15, targetTbDaily: 320.81 },
                { totalDaily: 754.36, targetTbDaily: 320.89 },
                { totalDaily: 754.54, targetTbDaily: 320.96 },
                { totalDaily: 754.78, targetTbDaily: 321.07 },
                { totalDaily: 754.98, targetTbDaily: 321.14 },
                { totalDaily: 755.12, targetTbDaily: 321.22 },
                { totalDaily: 755.32, targetTbDaily: 321.29 },
                { totalDaily: 755.52, targetTbDaily: 321.39 },
                { totalDaily: 755.71, targetTbDaily: 321.45 },
                { totalDaily: 755.93, targetTbDaily: 321.54 },
                { totalDaily: 756.16, targetTbDaily: 321.65 },
                { totalDaily: 756.39, targetTbDaily: 321.76 },
                { totalDaily: 756.52, targetTbDaily: 321.82 },
                { totalDaily: 756.73, targetTbDaily: 321.89 },
                { totalDaily: 756.91, targetTbDaily: 321.98 },
                { totalDaily: 757.11, targetTbDaily: 322.06 },
                { totalDaily: 757.27, targetTbDaily: 322.13 },
                { totalDaily: 757.51, targetTbDaily: 322.21 },
                { totalDaily: 757.73, targetTbDaily: 322.31 },
                { totalDaily: 757.9, targetTbDaily: 322.39 },
                { totalDaily: 758.12, targetTbDaily: 322.49 },
                { totalDaily: 758.28, targetTbDaily: 322.56 },
                { totalDaily: 758.49, targetTbDaily: 322.64 },
                { totalDaily: 758.64, targetTbDaily: 322.7 },
                { totalDaily: 758.89, targetTbDaily: 322.82 },
                { totalDaily: 759.14, targetTbDaily: 322.9 },
                { totalDaily: 759.36, targetTbDaily: 323 },
                { totalDaily: 759.56, targetTbDaily: 323.11 },
                { totalDaily: 759.71, targetTbDaily: 323.16 },
                { totalDaily: 759.89, targetTbDaily: 323.25 },
                { totalDaily: 760.04, targetTbDaily: 323.29 },
                { totalDaily: 760.29, targetTbDaily: 323.42 },
                { totalDaily: 760.5, targetTbDaily: 323.5 },
                { totalDaily: 760.7, targetTbDaily: 323.6 },
                { totalDaily: 760.9, targetTbDaily: 323.66 },
                { totalDaily: 761.11, targetTbDaily: 323.75 },
                { totalDaily: 761.26, targetTbDaily: 323.83 },
                { totalDaily: 761.44, targetTbDaily: 323.89 },
                { totalDaily: 761.69, targetTbDaily: 324 },
                { totalDaily: 761.89, targetTbDaily: 324.08 },
                { totalDaily: 762.08, targetTbDaily: 324.16 },
                { totalDaily: 762.28, targetTbDaily: 324.27 },
                { totalDaily: 762.49, targetTbDaily: 324.37 },
                { totalDaily: 762.64, targetTbDaily: 324.42 },
                { totalDaily: 762.91, targetTbDaily: 324.53 },
                { totalDaily: 763.1, targetTbDaily: 324.62 },
                { totalDaily: 763.27, targetTbDaily: 324.67 },
                { totalDaily: 763.48, targetTbDaily: 324.75 },
                { totalDaily: 763.69, targetTbDaily: 324.85 },
                { totalDaily: 763.86, targetTbDaily: 324.92 },
                { totalDaily: 764.08, targetTbDaily: 325.04 },
                { totalDaily: 764.26, targetTbDaily: 325.1 },
                { totalDaily: 764.45, targetTbDaily: 325.18 },
                { totalDaily: 764.71, targetTbDaily: 325.31 },
                { totalDaily: 764.87, targetTbDaily: 325.36 },
                { totalDaily: 765.03, targetTbDaily: 325.43 },
                { totalDaily: 765.25, targetTbDaily: 325.52 },
                { totalDaily: 765.49, targetTbDaily: 325.62 },
                { totalDaily: 765.68, targetTbDaily: 325.7 },
                { totalDaily: 765.87, targetTbDaily: 325.8 },
                { totalDaily: 766.04, targetTbDaily: 325.85 },
                { totalDaily: 766.28, targetTbDaily: 325.95 },
                { totalDaily: 766.44, targetTbDaily: 326.03 },
                { totalDaily: 766.68, targetTbDaily: 326.14 },
                { totalDaily: 766.88, targetTbDaily: 326.21 },
                { totalDaily: 767.1, targetTbDaily: 326.33 },
                { totalDaily: 767.27, targetTbDaily: 326.39 },
                { totalDaily: 767.47, targetTbDaily: 326.47 },
                { totalDaily: 767.68, targetTbDaily: 326.57 },
                { totalDaily: 767.84, targetTbDaily: 326.63 },
                { totalDaily: 768.06, targetTbDaily: 326.7 },
                { totalDaily: 768.32, targetTbDaily: 326.84 },
                { totalDaily: 768.47, targetTbDaily: 326.89 },
                { totalDaily: 768.68, targetTbDaily: 326.98 },
                { totalDaily: 768.88, targetTbDaily: 327.09 },
                { totalDaily: 769.06, targetTbDaily: 327.13 },
                { totalDaily: 769.28, targetTbDaily: 327.23 },
                { totalDaily: 769.44, targetTbDaily: 327.3 },
                { totalDaily: 769.67, targetTbDaily: 327.39 },
                { totalDaily: 769.94, targetTbDaily: 327.52 },
                { totalDaily: 770.12, targetTbDaily: 327.58 },
                { totalDaily: 770.31, targetTbDaily: 327.66 },
                { totalDaily: 770.52, targetTbDaily: 327.76 },
                { totalDaily: 770.68, targetTbDaily: 327.86 },
                { totalDaily: 770.85, targetTbDaily: 327.9 },
                { totalDaily: 771.08, targetTbDaily: 328.02 },
                { totalDaily: 771.33, targetTbDaily: 328.1 },
                { totalDaily: 771.48, targetTbDaily: 328.16 },
                { totalDaily: 771.68, targetTbDaily: 328.25 },
                { totalDaily: 771.9, targetTbDaily: 328.37 },
                { totalDaily: 772.05, targetTbDaily: 328.41 },
                { totalDaily: 772.25, targetTbDaily: 328.48 },
                { totalDaily: 772.5, targetTbDaily: 328.6 },
                { totalDaily: 772.72, targetTbDaily: 328.71 },
                { totalDaily: 772.9, targetTbDaily: 328.78 },
                { totalDaily: 773.11, targetTbDaily: 328.86 },
                { totalDaily: 773.31, targetTbDaily: 328.95 },
                { totalDaily: 773.54, targetTbDaily: 329.06 },
                { totalDaily: 773.7, targetTbDaily: 329.13 },
                { totalDaily: 773.94, targetTbDaily: 329.22 },
                { totalDaily: 774.15, targetTbDaily: 329.32 },
                { totalDaily: 774.3, targetTbDaily: 329.38 },
                { totalDaily: 774.54, targetTbDaily: 329.47 },
                { totalDaily: 774.74, targetTbDaily: 329.58 },
                { totalDaily: 774.92, targetTbDaily: 329.62 },
                { totalDaily: 775.14, targetTbDaily: 329.73 },
                { totalDaily: 775.32, targetTbDaily: 329.82 },
                { totalDaily: 775.51, targetTbDaily: 329.9 },
                { totalDaily: 775.72, targetTbDaily: 329.99 },
                { totalDaily: 775.95, targetTbDaily: 330.07 },
                { totalDaily: 776.12, targetTbDaily: 330.16 },
                { totalDaily: 776.33, targetTbDaily: 330.24 },
                { totalDaily: 776.57, targetTbDaily: 330.34 },
                { totalDaily: 776.76, targetTbDaily: 330.41 },
                { totalDaily: 776.92, targetTbDaily: 330.5 },
                { totalDaily: 777.15, targetTbDaily: 330.58 },
                { totalDaily: 777.38, targetTbDaily: 330.68 },
                { totalDaily: 916.33, targetTbDaily: 389.83 },
                { totalDaily: 916.52, targetTbDaily: 389.89 },
                { totalDaily: 916.88, targetTbDaily: 390.04 },
                { totalDaily: 917.05, targetTbDaily: 390.13 },
                { totalDaily: 917.24, targetTbDaily: 390.22 },
                { totalDaily: 917.51, targetTbDaily: 390.32 },
                { totalDaily: 917.72, targetTbDaily: 390.41 },
                { totalDaily: 917.97, targetTbDaily: 390.53 },
                { totalDaily: 918.25, targetTbDaily: 390.63 },
                { totalDaily: 918.55, targetTbDaily: 390.76 },
                { totalDaily: 918.73, targetTbDaily: 390.83 },
                { totalDaily: 918.95, targetTbDaily: 390.94 },
                { totalDaily: 919.18, targetTbDaily: 391.02 },
                { totalDaily: 919.4, targetTbDaily: 391.12 },
                { totalDaily: 919.64, targetTbDaily: 391.23 },
                { totalDaily: 919.95, targetTbDaily: 391.35 },
                { totalDaily: 920.17, targetTbDaily: 391.44 },
                { totalDaily: 920.43, targetTbDaily: 391.56 },
                { totalDaily: 920.65, targetTbDaily: 391.65 },
                { totalDaily: 920.9, targetTbDaily: 391.76 },
                { totalDaily: 921.11, targetTbDaily: 391.84 },
                { totalDaily: 921.35, targetTbDaily: 391.98 },
                { totalDaily: 921.65, targetTbDaily: 392.09 },
                { totalDaily: 921.85, targetTbDaily: 392.16 },
                { totalDaily: 922.01, targetTbDaily: 392.23 },
                { totalDaily: 922.32, targetTbDaily: 392.35 },
                { totalDaily: 922.55, targetTbDaily: 392.46 },
                { totalDaily: 922.76, targetTbDaily: 392.54 },
                { totalDaily: 923.05, targetTbDaily: 392.67 },
                { totalDaily: 923.26, targetTbDaily: 392.77 },
                { totalDaily: 923.52, targetTbDaily: 392.9 },
                { totalDaily: 923.79, targetTbDaily: 392.99 },
                { totalDaily: 924.01, targetTbDaily: 393.09 },
                { totalDaily: 924.2, targetTbDaily: 393.18 },
                { totalDaily: 924.45, targetTbDaily: 393.28 },
                { totalDaily: 924.71, targetTbDaily: 393.39 },
                { totalDaily: 924.97, targetTbDaily: 393.5 },
                { totalDaily: 925.23, targetTbDaily: 393.6 },
                { totalDaily: 925.48, targetTbDaily: 393.71 },
                { totalDaily: 925.67, targetTbDaily: 393.79 },
                { totalDaily: 925.95, targetTbDaily: 393.91 },
                { totalDaily: 926.16, targetTbDaily: 394 },
                { totalDaily: 926.45, targetTbDaily: 394.12 },
                { totalDaily: 926.65, targetTbDaily: 394.21 },
                { totalDaily: 926.89, targetTbDaily: 394.3 },
                { totalDaily: 927.17, targetTbDaily: 394.42 },
                { totalDaily: 927.36, targetTbDaily: 394.51 },
                { totalDaily: 927.59, targetTbDaily: 394.62 },
                { totalDaily: 927.87, targetTbDaily: 394.75 },
                { totalDaily: 928.1, targetTbDaily: 394.8 },
                { totalDaily: 928.33, targetTbDaily: 394.93 },
                { totalDaily: 928.6, targetTbDaily: 395.04 },
                { totalDaily: 928.83, targetTbDaily: 395.14 },
                { totalDaily: 929.08, targetTbDaily: 395.24 },
                { totalDaily: 929.33, targetTbDaily: 395.35 },
                { totalDaily: 929.58, targetTbDaily: 395.47 },
                { totalDaily: 929.82, targetTbDaily: 395.55 },
                { totalDaily: 930.07, targetTbDaily: 395.68 },
                { totalDaily: 930.3, targetTbDaily: 395.77 },
                { totalDaily: 930.55, targetTbDaily: 395.85 },
                { totalDaily: 930.84, targetTbDaily: 396 },
                { totalDaily: 931.07, targetTbDaily: 396.09 },
                { totalDaily: 931.31, targetTbDaily: 396.22 },
                { totalDaily: 931.54, targetTbDaily: 396.28 },
                { totalDaily: 931.74, targetTbDaily: 396.37 },
                { totalDaily: 932.03, targetTbDaily: 396.5 },
                { totalDaily: 932.22, targetTbDaily: 396.57 },
                { totalDaily: 932.5, targetTbDaily: 396.7 },
                { totalDaily: 932.82, targetTbDaily: 396.83 },
                { totalDaily: 932.99, targetTbDaily: 396.9 },
                { totalDaily: 933.23, targetTbDaily: 396.99 },
                { totalDaily: 933.48, targetTbDaily: 397.12 },
                { totalDaily: 933.68, targetTbDaily: 397.2 },
                { totalDaily: 933.9, targetTbDaily: 397.29 },
                { totalDaily: 934.18, targetTbDaily: 397.41 },
                { totalDaily: 934.46, targetTbDaily: 397.53 },
                { totalDaily: 934.71, targetTbDaily: 397.65 },
                { totalDaily: 934.93, targetTbDaily: 397.73 },
                { totalDaily: 935.18, targetTbDaily: 397.84 },
                { totalDaily: 935.42, targetTbDaily: 397.92 },
                { totalDaily: 935.64, targetTbDaily: 398.04 },
                { totalDaily: 935.88, targetTbDaily: 398.13 },
                { totalDaily: 936.15, targetTbDaily: 398.24 },
                { totalDaily: 936.41, targetTbDaily: 398.35 },
                { totalDaily: 936.59, targetTbDaily: 398.46 },
                { totalDaily: 936.92, targetTbDaily: 398.59 },
                { totalDaily: 937.14, targetTbDaily: 398.68 },
                { totalDaily: 937.39, targetTbDaily: 398.77 },
                { totalDaily: 937.67, targetTbDaily: 398.91 },
                { totalDaily: 937.87, targetTbDaily: 398.99 },
                { totalDaily: 938.15, targetTbDaily: 399.11 },
                { totalDaily: 938.37, targetTbDaily: 399.19 },
                { totalDaily: 938.58, targetTbDaily: 399.28 },
                { totalDaily: 938.83, targetTbDaily: 399.4 },
                { totalDaily: 939.12, targetTbDaily: 399.52 },
                { totalDaily: 939.33, targetTbDaily: 399.6 },
                { totalDaily: 939.6, targetTbDaily: 399.72 },
                { totalDaily: 939.82, targetTbDaily: 399.81 },
                { totalDaily: 940.02, targetTbDaily: 399.91 },
                { totalDaily: 940.32, targetTbDaily: 400.02 },
                { totalDaily: 940.55, targetTbDaily: 400.11 },
                { totalDaily: 940.82, targetTbDaily: 400.22 },
                { totalDaily: 941.03, targetTbDaily: 400.34 },
                { totalDaily: 941.3, targetTbDaily: 400.45 },
                { totalDaily: 941.54, targetTbDaily: 400.54 },
                { totalDaily: 941.78, targetTbDaily: 400.64 },
                { totalDaily: 942.04, targetTbDaily: 400.76 },
                { totalDaily: 942.29, targetTbDaily: 400.87 },
                { totalDaily: 942.56, targetTbDaily: 400.97 },
                { totalDaily: 942.79, targetTbDaily: 401.08 },
                { totalDaily: 943, targetTbDaily: 401.16 },
                { totalDaily: 943.24, targetTbDaily: 401.26 },
                { totalDaily: 943.5, targetTbDaily: 401.37 },
                { totalDaily: 943.78, targetTbDaily: 401.5 },
                { totalDaily: 944.04, targetTbDaily: 401.6 },
                { totalDaily: 944.27, targetTbDaily: 401.7 },
                { totalDaily: 944.5, targetTbDaily: 401.79 },
                { totalDaily: 944.73, targetTbDaily: 401.91 },
                { totalDaily: 944.97, targetTbDaily: 402.01 },
                { totalDaily: 945.25, targetTbDaily: 402.12 },
                { totalDaily: 945.51, targetTbDaily: 402.23 },
                { totalDaily: 945.75, targetTbDaily: 402.32 },
                { totalDaily: 946, targetTbDaily: 402.45 },
                { totalDaily: 946.19, targetTbDaily: 402.54 },
                { totalDaily: 946.49, targetTbDaily: 402.64 },
                { totalDaily: 946.75, targetTbDaily: 402.75 },
                { totalDaily: 946.98, targetTbDaily: 402.87 },
                { totalDaily: 947.25, targetTbDaily: 402.98 },
                { totalDaily: 947.5, targetTbDaily: 403.08 },
                { totalDaily: 947.7, targetTbDaily: 403.16 },
                { totalDaily: 948.01, targetTbDaily: 403.28 },
                { totalDaily: 948.19, targetTbDaily: 403.37 },
                { totalDaily: 948.43, targetTbDaily: 403.47 },
                { totalDaily: 948.72, targetTbDaily: 403.61 },
                { totalDaily: 948.97, targetTbDaily: 403.7 },
                { totalDaily: 949.21, targetTbDaily: 403.81 },
                { totalDaily: 949.42, targetTbDaily: 403.89 },
                { totalDaily: 949.7, targetTbDaily: 404.01 },
                { totalDaily: 949.87, targetTbDaily: 404.08 },
                { totalDaily: 950.15, targetTbDaily: 404.19 },
                { totalDaily: 950.44, targetTbDaily: 404.33 },
                { totalDaily: 950.7, targetTbDaily: 404.45 },
                { totalDaily: 950.92, targetTbDaily: 404.53 },
                { totalDaily: 951.23, targetTbDaily: 404.67 },
                { totalDaily: 951.43, targetTbDaily: 404.76 },
                { totalDaily: 951.64, targetTbDaily: 404.85 },
                { totalDaily: 951.95, targetTbDaily: 404.97 },
                { totalDaily: 952.17, targetTbDaily: 405.05 },
                { totalDaily: 952.41, targetTbDaily: 405.15 },
                { totalDaily: 952.68, targetTbDaily: 405.29 },
                { totalDaily: 952.88, targetTbDaily: 405.36 },
                { totalDaily: 953.15, targetTbDaily: 405.48 },
                { totalDaily: 953.43, targetTbDaily: 405.6 },
                { totalDaily: 953.67, targetTbDaily: 405.69 },
                { totalDaily: 953.94, targetTbDaily: 405.79 },
                { totalDaily: 954.19, targetTbDaily: 405.91 },
                { totalDaily: 954.44, targetTbDaily: 406.03 },
                { totalDaily: 954.66, targetTbDaily: 406.11 },
                { totalDaily: 954.92, targetTbDaily: 406.23 },
                { totalDaily: 955.2, targetTbDaily: 406.37 },
                { totalDaily: 955.41, targetTbDaily: 406.44 },
                { totalDaily: 955.63, targetTbDaily: 406.52 },
                { totalDaily: 955.93, targetTbDaily: 406.68 },
                { totalDaily: 956.13, targetTbDaily: 406.75 },
                { totalDaily: 956.43, targetTbDaily: 406.86 },
                { totalDaily: 956.7, targetTbDaily: 406.99 },
                { totalDaily: 956.94, targetTbDaily: 407.08 },
                { totalDaily: 957.16, targetTbDaily: 407.19 },
                { totalDaily: 957.38, targetTbDaily: 407.28 },
                { totalDaily: 957.62, targetTbDaily: 407.4 },
                { totalDaily: 957.9, targetTbDaily: 407.5 },
                { totalDaily: 958.17, targetTbDaily: 407.62 },
                { totalDaily: 958.43, targetTbDaily: 407.73 },
                { totalDaily: 958.69, targetTbDaily: 407.83 },
                { totalDaily: 958.9, targetTbDaily: 407.94 },
                { totalDaily: 959.16, targetTbDaily: 408.05 },
                { totalDaily: 959.36, targetTbDaily: 408.12 },
                { totalDaily: 959.64, targetTbDaily: 408.22 },
                { totalDaily: 959.95, targetTbDaily: 408.37 },
                { totalDaily: 960.17, targetTbDaily: 408.47 },
                { totalDaily: 960.41, targetTbDaily: 408.55 },
                { totalDaily: 960.67, targetTbDaily: 408.68 },
                { totalDaily: 960.91, targetTbDaily: 408.8 },
                { totalDaily: 961.2, targetTbDaily: 408.91 },
                { totalDaily: 961.42, targetTbDaily: 409 },
                { totalDaily: 961.66, targetTbDaily: 409.11 },
                { totalDaily: 961.95, targetTbDaily: 409.22 },
                { totalDaily: 962.18, targetTbDaily: 409.32 },
                { totalDaily: 962.41, targetTbDaily: 409.42 },
                { totalDaily: 962.69, targetTbDaily: 409.54 },
                { totalDaily: 962.95, targetTbDaily: 409.64 },
                { totalDaily: 963.21, targetTbDaily: 409.75 },
                { totalDaily: 963.48, targetTbDaily: 409.89 },
                { totalDaily: 963.69, targetTbDaily: 409.96 },
                { totalDaily: 963.95, targetTbDaily: 410.08 },
                { totalDaily: 964.21, targetTbDaily: 410.19 },
                { totalDaily: 964.46, targetTbDaily: 410.3 },
                { totalDaily: 964.68, targetTbDaily: 410.39 },
                { totalDaily: 964.93, targetTbDaily: 410.49 },
                { totalDaily: 965.19, targetTbDaily: 410.61 },
                { totalDaily: 965.44, targetTbDaily: 410.72 },
                { totalDaily: 965.68, targetTbDaily: 410.81 },
                { totalDaily: 965.97, targetTbDaily: 410.94 },
                { totalDaily: 966.22, targetTbDaily: 411.04 },
                { totalDaily: 966.46, targetTbDaily: 411.15 },
                { totalDaily: 966.71, targetTbDaily: 411.26 },
                { totalDaily: 966.99, targetTbDaily: 411.37 },
                { totalDaily: 967.2, targetTbDaily: 411.46 },
                { totalDaily: 967.49, targetTbDaily: 411.59 },
                { totalDaily: 967.75, targetTbDaily: 411.7 },
                { totalDaily: 967.96, targetTbDaily: 411.79 },
                { totalDaily: 968.22, targetTbDaily: 411.9 },
                { totalDaily: 968.46, targetTbDaily: 412.01 },
                { totalDaily: 968.73, targetTbDaily: 412.12 },
                { totalDaily: 969.01, targetTbDaily: 412.23 },
                { totalDaily: 969.26, targetTbDaily: 412.34 },
                { totalDaily: 969.5, targetTbDaily: 412.43 },
                { totalDaily: 969.77, targetTbDaily: 412.54 },
                { totalDaily: 969.98, targetTbDaily: 412.64 },
                { totalDaily: 970.21, targetTbDaily: 412.74 },
                { totalDaily: 970.46, targetTbDaily: 412.83 },
                { totalDaily: 970.8, targetTbDaily: 413 },
                { totalDaily: 971.04, targetTbDaily: 413.11 },
                { totalDaily: 971.27, targetTbDaily: 413.2 },
                { totalDaily: 971.54, targetTbDaily: 413.3 },
                { totalDaily: 971.75, targetTbDaily: 413.4 },
                { totalDaily: 971.98, targetTbDaily: 413.48 },
                { totalDaily: 972.28, targetTbDaily: 413.62 },
                { totalDaily: 972.56, targetTbDaily: 413.74 },
                { totalDaily: 972.78, targetTbDaily: 413.83 },
                { totalDaily: 973.05, targetTbDaily: 413.94 },
                { totalDaily: 973.28, targetTbDaily: 414.04 },
                { totalDaily: 973.53, targetTbDaily: 414.17 },
                { totalDaily: 973.81, targetTbDaily: 414.26 },
                { totalDaily: 974.08, targetTbDaily: 414.4 },
                { totalDaily: 974.31, targetTbDaily: 414.48 },
                { totalDaily: 974.6, targetTbDaily: 414.61 },
                { totalDaily: 974.83, targetTbDaily: 414.7 },
                { totalDaily: 975.05, targetTbDaily: 414.8 },
                { totalDaily: 975.36, targetTbDaily: 414.94 },
                { totalDaily: 975.59, targetTbDaily: 415.04 },
                { totalDaily: 975.82, targetTbDaily: 415.13 },
                { totalDaily: 976.12, targetTbDaily: 415.27 },
                { totalDaily: 976.37, targetTbDaily: 415.36 },
                { totalDaily: 976.59, targetTbDaily: 415.45 },
                { totalDaily: 976.85, targetTbDaily: 415.56 },
                { totalDaily: 977.1, targetTbDaily: 415.66 },
                { totalDaily: 977.33, targetTbDaily: 415.77 },
                { totalDaily: 977.61, targetTbDaily: 415.89 },
                { totalDaily: 977.87, targetTbDaily: 416 },
                { totalDaily: 978.16, targetTbDaily: 416.12 },
                { totalDaily: 978.4, targetTbDaily: 416.22 },
                { totalDaily: 978.68, targetTbDaily: 416.33 },
                { totalDaily: 978.89, targetTbDaily: 416.42 },
                { totalDaily: 979.16, targetTbDaily: 416.55 },
                { totalDaily: 979.41, targetTbDaily: 416.65 },
                { totalDaily: 979.64, targetTbDaily: 416.74 },
                { totalDaily: 979.96, targetTbDaily: 416.89 },
                { totalDaily: 980.23, targetTbDaily: 417 },
                { totalDaily: 980.45, targetTbDaily: 417.1 },
                { totalDaily: 980.71, targetTbDaily: 417.22 },
                { totalDaily: 980.98, targetTbDaily: 417.32 },
                { totalDaily: 981.18, targetTbDaily: 417.41 },
                { totalDaily: 981.47, targetTbDaily: 417.53 },
                { totalDaily: 981.7, targetTbDaily: 417.64 },
                { totalDaily: 981.96, targetTbDaily: 417.74 },
                { totalDaily: 982.26, targetTbDaily: 417.86 },
                { totalDaily: 982.47, targetTbDaily: 417.95 },
                { totalDaily: 982.74, targetTbDaily: 418.09 },
                { totalDaily: 982.98, targetTbDaily: 418.17 },
                { totalDaily: 983.26, targetTbDaily: 418.3 },
                { totalDaily: 983.51, targetTbDaily: 418.39 },
                { totalDaily: 983.83, targetTbDaily: 418.54 },
                { totalDaily: 984.04, targetTbDaily: 418.63 },
                { totalDaily: 984.3, targetTbDaily: 418.75 },
                { totalDaily: 984.52, targetTbDaily: 418.81 },
                { totalDaily: 984.83, targetTbDaily: 418.95 },
                { totalDaily: 985.04, targetTbDaily: 419.04 },
                { totalDaily: 985.31, targetTbDaily: 419.17 },
                { totalDaily: 985.57, targetTbDaily: 419.27 },
                { totalDaily: 985.85, targetTbDaily: 419.4 },
                { totalDaily: 986.04, targetTbDaily: 419.47 },
                { totalDaily: 986.33, targetTbDaily: 419.59 },
                { totalDaily: 986.58, targetTbDaily: 419.7 },
                { totalDaily: 986.83, targetTbDaily: 419.8 },
                { totalDaily: 987.11, targetTbDaily: 419.92 },
                { totalDaily: 987.38, targetTbDaily: 420.04 },
                { totalDaily: 987.65, targetTbDaily: 420.15 },
                { totalDaily: 987.89, targetTbDaily: 420.27 },
                { totalDaily: 988.14, targetTbDaily: 420.37 },
                { totalDaily: 988.44, targetTbDaily: 420.51 },
                { totalDaily: 988.64, targetTbDaily: 420.56 },
                { totalDaily: 988.9, targetTbDaily: 420.68 },
                { totalDaily: 989.17, targetTbDaily: 420.81 },
                { totalDaily: 989.44, targetTbDaily: 420.9 },
                { totalDaily: 989.68, targetTbDaily: 421.02 },
                { totalDaily: 989.9, targetTbDaily: 421.11 },
                { totalDaily: 990.15, targetTbDaily: 421.22 },
                { totalDaily: 990.45, targetTbDaily: 421.35 },
                { totalDaily: 990.7, targetTbDaily: 421.47 },
                { totalDaily: 990.97, targetTbDaily: 421.56 },
                { totalDaily: 991.26, targetTbDaily: 421.7 },
                { totalDaily: 991.47, targetTbDaily: 421.78 },
                { totalDaily: 991.77, targetTbDaily: 421.92 },
                { totalDaily: 992.05, targetTbDaily: 422.04 },
                { totalDaily: 992.26, targetTbDaily: 422.14 },
                { totalDaily: 992.55, targetTbDaily: 422.24 },
                { totalDaily: 992.79, targetTbDaily: 422.35 },
                { totalDaily: 993.05, targetTbDaily: 422.45 },
                { totalDaily: 993.31, targetTbDaily: 422.59 },
                { totalDaily: 993.56, targetTbDaily: 422.66 },
                { totalDaily: 993.84, targetTbDaily: 422.81 },
                { totalDaily: 994.11, targetTbDaily: 422.9 },
                { totalDaily: 994.36, targetTbDaily: 423.01 },
                { totalDaily: 994.67, targetTbDaily: 423.14 },
                { totalDaily: 994.92, targetTbDaily: 423.26 },
                { totalDaily: 995.18, targetTbDaily: 423.34 },
                { totalDaily: 995.4, targetTbDaily: 423.45 },
                { totalDaily: 995.61, targetTbDaily: 423.56 },
                { totalDaily: 995.97, targetTbDaily: 423.7 },
                { totalDaily: 996.17, targetTbDaily: 423.78 },
                { totalDaily: 996.44, targetTbDaily: 423.91 },
                { totalDaily: 996.71, targetTbDaily: 424.02 },
                { totalDaily: 996.97, targetTbDaily: 424.12 },
                { totalDaily: 997.2, targetTbDaily: 424.23 },
                { totalDaily: 997.45, targetTbDaily: 424.29 },
                { totalDaily: 997.68, targetTbDaily: 424.42 },
                { totalDaily: 998, targetTbDaily: 424.55 },
                { totalDaily: 998.25, targetTbDaily: 424.68 },
                { totalDaily: 998.51, targetTbDaily: 424.77 },
                { totalDaily: 998.79, targetTbDaily: 424.9 },
                { totalDaily: 999.03, targetTbDaily: 424.99 },
                { totalDaily: 999.3, targetTbDaily: 425.11 },
                { totalDaily: 999.55, targetTbDaily: 425.21 },
                { totalDaily: 999.82, targetTbDaily: 425.33 },
                { totalDaily: 1000.08, targetTbDaily: 425.43 },
                { totalDaily: 1000.35, targetTbDaily: 425.55 },
                { totalDaily: 1000.61, targetTbDaily: 425.69 },
                { totalDaily: 1000.83, targetTbDaily: 425.77 },
                { totalDaily: 1001.17, targetTbDaily: 425.9 },
                { totalDaily: 1001.4, targetTbDaily: 426 },
                { totalDaily: 1001.68, targetTbDaily: 426.12 },
                { totalDaily: 1001.94, targetTbDaily: 426.23 },
                { totalDaily: 1002.17, targetTbDaily: 426.36 },
                { totalDaily: 1002.42, targetTbDaily: 426.44 },
                { totalDaily: 1002.7, targetTbDaily: 426.56 },
                { totalDaily: 1002.95, targetTbDaily: 426.67 },
                { totalDaily: 1003.21, targetTbDaily: 426.78 },
                { totalDaily: 1003.47, targetTbDaily: 426.88 },
                { totalDaily: 1003.73, targetTbDaily: 427 },
                { totalDaily: 1004.03, targetTbDaily: 427.13 },
                { totalDaily: 1004.26, targetTbDaily: 427.21 },
                { totalDaily: 1004.54, targetTbDaily: 427.34 },
                { totalDaily: 1004.79, targetTbDaily: 427.45 },
                { totalDaily: 1005.07, targetTbDaily: 427.57 },
                { totalDaily: 1005.3, targetTbDaily: 427.66 },
                { totalDaily: 1005.59, targetTbDaily: 427.82 },
                { totalDaily: 1005.88, targetTbDaily: 427.9 },
                { totalDaily: 1006.11, targetTbDaily: 428 },
                { totalDaily: 1006.38, targetTbDaily: 428.13 },
                { totalDaily: 1006.62, targetTbDaily: 428.23 },
                { totalDaily: 1006.89, targetTbDaily: 428.34 },
                { totalDaily: 1007.2, targetTbDaily: 428.48 },
                { totalDaily: 1007.44, targetTbDaily: 428.55 },
                { totalDaily: 1007.71, targetTbDaily: 428.69 },
                { totalDaily: 1146.09, targetTbDaily: 487.6 },
                { totalDaily: 1146.42, targetTbDaily: 487.73 },
                { totalDaily: 1146.67, targetTbDaily: 487.83 },
                { totalDaily: 1146.97, targetTbDaily: 487.96 },
                { totalDaily: 1147.31, targetTbDaily: 488.11 },
                { totalDaily: 1147.58, targetTbDaily: 488.22 },
                { totalDaily: 1147.96, targetTbDaily: 488.37 },
                { totalDaily: 1148.21, targetTbDaily: 488.49 },
                { totalDaily: 1148.45, targetTbDaily: 488.59 },
                { totalDaily: 1148.78, targetTbDaily: 488.72 },
                { totalDaily: 1149.09, targetTbDaily: 488.87 },
                { totalDaily: 1149.38, targetTbDaily: 488.98 },
                { totalDaily: 1149.7, targetTbDaily: 489.14 },
                { totalDaily: 1149.99, targetTbDaily: 489.25 },
                { totalDaily: 1150.27, targetTbDaily: 489.35 },
                { totalDaily: 1150.61, targetTbDaily: 489.5 },
                { totalDaily: 1150.87, targetTbDaily: 489.59 },
                { totalDaily: 1151.2, targetTbDaily: 489.75 },
                { totalDaily: 1151.52, targetTbDaily: 489.89 },
                { totalDaily: 1151.8, targetTbDaily: 490.01 },
                { totalDaily: 1152.08, targetTbDaily: 490.15 },
                { totalDaily: 1152.4, targetTbDaily: 490.28 },
                { totalDaily: 1152.69, targetTbDaily: 490.41 },
                { totalDaily: 1152.99, targetTbDaily: 490.52 },
                { totalDaily: 1153.32, targetTbDaily: 490.66 },
                { totalDaily: 1153.66, targetTbDaily: 490.82 },
                { totalDaily: 1153.91, targetTbDaily: 490.92 },
                { totalDaily: 1154.22, targetTbDaily: 491.05 },
                { totalDaily: 1154.5, targetTbDaily: 491.16 },
                { totalDaily: 1154.78, targetTbDaily: 491.29 },
                { totalDaily: 1155.13, targetTbDaily: 491.41 },
                { totalDaily: 1155.38, targetTbDaily: 491.55 },
                { totalDaily: 1155.72, targetTbDaily: 491.69 },
                { totalDaily: 1156.02, targetTbDaily: 491.81 },
                { totalDaily: 1156.35, targetTbDaily: 491.93 },
                { totalDaily: 1156.62, targetTbDaily: 492.04 },
                { totalDaily: 1156.9, targetTbDaily: 492.18 },
                { totalDaily: 1157.23, targetTbDaily: 492.32 },
                { totalDaily: 1157.52, targetTbDaily: 492.44 },
                { totalDaily: 1157.82, targetTbDaily: 492.57 },
                { totalDaily: 1158.11, targetTbDaily: 492.7 },
                { totalDaily: 1158.42, targetTbDaily: 492.84 },
                { totalDaily: 1158.67, targetTbDaily: 492.95 },
                { totalDaily: 1159.03, targetTbDaily: 493.08 },
                { totalDaily: 1159.35, targetTbDaily: 493.22 },
                { totalDaily: 1159.68, targetTbDaily: 493.36 },
                { totalDaily: 1159.98, targetTbDaily: 493.49 },
                { totalDaily: 1160.25, targetTbDaily: 493.59 },
                { totalDaily: 1160.57, targetTbDaily: 493.73 },
                { totalDaily: 1160.82, targetTbDaily: 493.85 },
                { totalDaily: 1161.16, targetTbDaily: 494 },
                { totalDaily: 1161.47, targetTbDaily: 494.14 },
                { totalDaily: 1161.76, targetTbDaily: 494.26 },
                { totalDaily: 1162.1, targetTbDaily: 494.41 },
                { totalDaily: 1162.38, targetTbDaily: 494.51 },
                { totalDaily: 1162.69, targetTbDaily: 494.65 },
                { totalDaily: 1162.96, targetTbDaily: 494.77 },
                { totalDaily: 1163.29, targetTbDaily: 494.89 },
                { totalDaily: 1163.56, targetTbDaily: 495.02 },
                { totalDaily: 1163.88, targetTbDaily: 495.16 },
                { totalDaily: 1164.19, targetTbDaily: 495.29 },
                { totalDaily: 1164.5, targetTbDaily: 495.4 },
                { totalDaily: 1164.76, targetTbDaily: 495.53 },
                { totalDaily: 1165.07, targetTbDaily: 495.66 },
                { totalDaily: 1165.37, targetTbDaily: 495.78 },
                { totalDaily: 1165.69, targetTbDaily: 495.91 },
                { totalDaily: 1166.04, targetTbDaily: 496.06 },
                { totalDaily: 1166.35, targetTbDaily: 496.2 },
                { totalDaily: 1166.68, targetTbDaily: 496.35 },
                { totalDaily: 1166.96, targetTbDaily: 496.47 },
                { totalDaily: 1167.24, targetTbDaily: 496.59 },
                { totalDaily: 1167.57, targetTbDaily: 496.73 },
                { totalDaily: 1167.86, targetTbDaily: 496.84 },
                { totalDaily: 1168.15, targetTbDaily: 496.96 },
                { totalDaily: 1168.45, targetTbDaily: 497.09 },
                { totalDaily: 1168.76, targetTbDaily: 497.22 },
                { totalDaily: 1169.04, targetTbDaily: 497.35 },
                { totalDaily: 1169.35, targetTbDaily: 497.48 },
                { totalDaily: 1169.69, targetTbDaily: 497.63 },
                { totalDaily: 1170, targetTbDaily: 497.73 },
                { totalDaily: 1170.25, targetTbDaily: 497.85 },
                { totalDaily: 1170.58, targetTbDaily: 498 },
                { totalDaily: 1170.87, targetTbDaily: 498.13 },
                { totalDaily: 1171.22, targetTbDaily: 498.26 },
                { totalDaily: 1171.52, targetTbDaily: 498.4 },
                { totalDaily: 1171.81, targetTbDaily: 498.53 },
                { totalDaily: 1172.11, targetTbDaily: 498.66 },
                { totalDaily: 1172.44, targetTbDaily: 498.81 },
                { totalDaily: 1172.72, targetTbDaily: 498.93 },
                { totalDaily: 1173.08, targetTbDaily: 499.06 },
                { totalDaily: 1173.34, targetTbDaily: 499.17 },
                { totalDaily: 1173.72, targetTbDaily: 499.34 },
                { totalDaily: 1174, targetTbDaily: 499.45 },
                { totalDaily: 1174.28, targetTbDaily: 499.57 },
                { totalDaily: 1174.61, targetTbDaily: 499.72 },
                { totalDaily: 1174.86, targetTbDaily: 499.83 },
                { totalDaily: 1175.19, targetTbDaily: 499.95 },
                { totalDaily: 1175.49, targetTbDaily: 500.05 },
                { totalDaily: 1175.79, targetTbDaily: 500.21 },
                { totalDaily: 1176.08, targetTbDaily: 500.33 },
                { totalDaily: 1176.45, targetTbDaily: 500.47 },
                { totalDaily: 1176.76, targetTbDaily: 500.65 },
                { totalDaily: 1177.01, targetTbDaily: 500.75 },
                { totalDaily: 1177.33, targetTbDaily: 500.87 },
                { totalDaily: 1177.61, targetTbDaily: 500.99 },
                { totalDaily: 1177.91, targetTbDaily: 501.12 },
                { totalDaily: 1178.22, targetTbDaily: 501.25 },
                { totalDaily: 1178.52, targetTbDaily: 501.4 },
                { totalDaily: 1178.81, targetTbDaily: 501.51 },
                { totalDaily: 1179.22, targetTbDaily: 501.68 },
                { totalDaily: 1179.52, targetTbDaily: 501.81 },
                { totalDaily: 1179.83, targetTbDaily: 501.91 },
                { totalDaily: 1180.13, targetTbDaily: 502.08 },
                { totalDaily: 1180.43, targetTbDaily: 502.19 },
                { totalDaily: 1180.77, targetTbDaily: 502.33 },
                { totalDaily: 1181.06, targetTbDaily: 502.48 },
                { totalDaily: 1181.33, targetTbDaily: 502.58 },
                { totalDaily: 1181.66, targetTbDaily: 502.73 },
                { totalDaily: 1182.03, targetTbDaily: 502.89 },
                { totalDaily: 1182.3, targetTbDaily: 502.99 },
                { totalDaily: 1182.62, targetTbDaily: 503.12 },
                { totalDaily: 1182.91, targetTbDaily: 503.26 },
                { totalDaily: 1183.22, targetTbDaily: 503.37 },
                { totalDaily: 1183.51, targetTbDaily: 503.5 },
                { totalDaily: 1183.8, targetTbDaily: 503.63 },
                { totalDaily: 1184.12, targetTbDaily: 503.74 },
                { totalDaily: 1184.42, targetTbDaily: 503.9 },
                { totalDaily: 1184.72, targetTbDaily: 504.01 },
                { totalDaily: 1185.03, targetTbDaily: 504.14 },
                { totalDaily: 1185.35, targetTbDaily: 504.29 },
                { totalDaily: 1185.67, targetTbDaily: 504.42 },
                { totalDaily: 1186.02, targetTbDaily: 504.55 },
                { totalDaily: 1186.31, targetTbDaily: 504.69 },
                { totalDaily: 1186.6, targetTbDaily: 504.84 },
                { totalDaily: 1186.91, targetTbDaily: 504.95 },
                { totalDaily: 1187.19, targetTbDaily: 505.05 },
                { totalDaily: 1187.47, targetTbDaily: 505.19 },
                { totalDaily: 1187.82, targetTbDaily: 505.33 },
                { totalDaily: 1188.13, targetTbDaily: 505.46 },
                { totalDaily: 1188.47, targetTbDaily: 505.62 },
                { totalDaily: 1188.81, targetTbDaily: 505.74 },
                { totalDaily: 1189.08, targetTbDaily: 505.87 },
                { totalDaily: 1189.41, targetTbDaily: 506.01 },
                { totalDaily: 1189.68, targetTbDaily: 506.11 },
                { totalDaily: 1190, targetTbDaily: 506.25 },
                { totalDaily: 1190.33, targetTbDaily: 506.39 },
                { totalDaily: 1190.65, targetTbDaily: 506.54 },
                { totalDaily: 1190.97, targetTbDaily: 506.69 },
                { totalDaily: 1191.26, targetTbDaily: 506.8 },
                { totalDaily: 1191.56, targetTbDaily: 506.92 },
                { totalDaily: 1191.84, targetTbDaily: 507.04 },
                { totalDaily: 1192.18, targetTbDaily: 507.2 },
                { totalDaily: 1192.51, targetTbDaily: 507.32 },
                { totalDaily: 1192.83, targetTbDaily: 507.47 },
                { totalDaily: 1193.17, targetTbDaily: 507.61 },
                { totalDaily: 1193.43, targetTbDaily: 507.74 },
                { totalDaily: 1193.73, targetTbDaily: 507.84 },
                { totalDaily: 1194.08, targetTbDaily: 508.02 },
                { totalDaily: 1194.39, targetTbDaily: 508.14 },
                { totalDaily: 1194.68, targetTbDaily: 508.25 },
                { totalDaily: 1195.02, targetTbDaily: 508.41 },
                { totalDaily: 1195.31, targetTbDaily: 508.52 },
                { totalDaily: 1195.63, targetTbDaily: 508.66 },
                { totalDaily: 1195.96, targetTbDaily: 508.81 },
                { totalDaily: 1196.24, targetTbDaily: 508.91 },
                { totalDaily: 1196.58, targetTbDaily: 509.06 },
                { totalDaily: 1196.87, targetTbDaily: 509.19 },
                { totalDaily: 1197.17, targetTbDaily: 509.32 },
                { totalDaily: 1197.46, targetTbDaily: 509.44 },
                { totalDaily: 1197.77, targetTbDaily: 509.58 },
                { totalDaily: 1198.09, targetTbDaily: 509.7 },
                { totalDaily: 1198.41, targetTbDaily: 509.85 },
                { totalDaily: 1198.72, targetTbDaily: 509.96 },
                { totalDaily: 1199.07, targetTbDaily: 510.12 },
                { totalDaily: 1199.41, targetTbDaily: 510.26 },
                { totalDaily: 1199.71, targetTbDaily: 510.38 },
                { totalDaily: 1199.98, targetTbDaily: 510.52 },
                { totalDaily: 1200.33, targetTbDaily: 510.65 },
                { totalDaily: 1200.61, targetTbDaily: 510.76 },
                { totalDaily: 1200.97, targetTbDaily: 510.94 },
                { totalDaily: 1201.27, targetTbDaily: 511.06 },
                { totalDaily: 1201.56, targetTbDaily: 511.18 },
                { totalDaily: 1201.88, targetTbDaily: 511.32 },
                { totalDaily: 1202.21, targetTbDaily: 511.47 },
                { totalDaily: 1202.53, targetTbDaily: 511.6 },
                { totalDaily: 1202.81, targetTbDaily: 511.69 },
                { totalDaily: 1203.13, targetTbDaily: 511.85 },
                { totalDaily: 1203.45, targetTbDaily: 511.99 },
                { totalDaily: 1203.76, targetTbDaily: 512.12 },
                { totalDaily: 1204.07, targetTbDaily: 512.23 },
                { totalDaily: 1204.36, targetTbDaily: 512.38 },
                { totalDaily: 1204.72, targetTbDaily: 512.52 },
                { totalDaily: 1205.04, targetTbDaily: 512.66 },
                { totalDaily: 1205.32, targetTbDaily: 512.79 },
                { totalDaily: 1205.71, targetTbDaily: 512.95 },
                { totalDaily: 1205.99, targetTbDaily: 513.08 },
                { totalDaily: 1206.28, targetTbDaily: 513.17 },
                { totalDaily: 1206.59, targetTbDaily: 513.33 },
                { totalDaily: 1206.93, targetTbDaily: 513.47 },
                { totalDaily: 1207.22, targetTbDaily: 513.58 },
                { totalDaily: 1207.56, targetTbDaily: 513.74 },
                { totalDaily: 1207.88, targetTbDaily: 513.89 },
                { totalDaily: 1208.18, targetTbDaily: 514.01 },
                { totalDaily: 1208.51, targetTbDaily: 514.14 },
                { totalDaily: 1208.8, targetTbDaily: 514.27 },
                { totalDaily: 1209.13, targetTbDaily: 514.4 },
                { totalDaily: 1209.41, targetTbDaily: 514.52 },
                { totalDaily: 1209.71, targetTbDaily: 514.64 },
                { totalDaily: 1210.08, targetTbDaily: 514.81 },
                { totalDaily: 1210.4, targetTbDaily: 514.95 },
                { totalDaily: 1210.66, targetTbDaily: 515.07 },
                { totalDaily: 1211.06, targetTbDaily: 515.22 },
                { totalDaily: 1211.36, targetTbDaily: 515.36 },
                { totalDaily: 1211.66, targetTbDaily: 515.49 },
                { totalDaily: 1211.98, targetTbDaily: 515.61 },
                { totalDaily: 1212.22, targetTbDaily: 515.72 },
                { totalDaily: 1212.57, targetTbDaily: 515.86 },
                { totalDaily: 1212.91, targetTbDaily: 516.02 },
                { totalDaily: 1213.23, targetTbDaily: 516.14 },
                { totalDaily: 1213.58, targetTbDaily: 516.32 },
                { totalDaily: 1213.9, targetTbDaily: 516.45 },
                { totalDaily: 1214.2, targetTbDaily: 516.56 },
                { totalDaily: 1214.53, targetTbDaily: 516.67 },
                { totalDaily: 1214.82, targetTbDaily: 516.81 },
                { totalDaily: 1215.12, targetTbDaily: 516.95 },
                { totalDaily: 1215.44, targetTbDaily: 517.1 },
                { totalDaily: 1215.77, targetTbDaily: 517.22 },
                { totalDaily: 1216.06, targetTbDaily: 517.37 },
                { totalDaily: 1216.42, targetTbDaily: 517.5 },
                { totalDaily: 1216.7, targetTbDaily: 517.61 },
                { totalDaily: 1217.02, targetTbDaily: 517.77 },
                { totalDaily: 1217.38, targetTbDaily: 517.91 },
                { totalDaily: 1217.69, targetTbDaily: 518.04 },
                { totalDaily: 1217.97, targetTbDaily: 518.16 },
                { totalDaily: 1218.31, targetTbDaily: 518.32 },
                { totalDaily: 1218.58, targetTbDaily: 518.42 },
                { totalDaily: 1218.96, targetTbDaily: 518.59 },
                { totalDaily: 1219.31, targetTbDaily: 518.74 },
                { totalDaily: 1219.58, targetTbDaily: 518.86 },
                { totalDaily: 1219.95, targetTbDaily: 519 },
                { totalDaily: 1220.27, targetTbDaily: 519.15 },
                { totalDaily: 1220.55, targetTbDaily: 519.25 },
                { totalDaily: 1220.86, targetTbDaily: 519.4 },
                { totalDaily: 1221.15, targetTbDaily: 519.5 },
                { totalDaily: 1221.5, targetTbDaily: 519.66 },
                { totalDaily: 1221.81, targetTbDaily: 519.8 },
                { totalDaily: 1222.14, targetTbDaily: 519.91 },
                { totalDaily: 1222.47, targetTbDaily: 520.06 },
                { totalDaily: 1222.79, targetTbDaily: 520.23 },
                { totalDaily: 1223.06, targetTbDaily: 520.34 },
                { totalDaily: 1223.43, targetTbDaily: 520.48 },
                { totalDaily: 1223.74, targetTbDaily: 520.64 },
                { totalDaily: 1224.04, targetTbDaily: 520.76 },
                { totalDaily: 1224.35, targetTbDaily: 520.88 },
                { totalDaily: 1224.63, targetTbDaily: 521 },
                { totalDaily: 1224.98, targetTbDaily: 521.14 },
                { totalDaily: 1225.33, targetTbDaily: 521.29 },
                { totalDaily: 1225.65, targetTbDaily: 521.43 },
                { totalDaily: 1226, targetTbDaily: 521.59 },
                { totalDaily: 1226.3, targetTbDaily: 521.72 },
                { totalDaily: 1226.62, targetTbDaily: 521.84 },
                { totalDaily: 1226.9, targetTbDaily: 521.95 },
                { totalDaily: 1227.21, targetTbDaily: 522.09 },
                { totalDaily: 1227.56, targetTbDaily: 522.24 },
                { totalDaily: 1227.85, targetTbDaily: 522.37 },
                { totalDaily: 1228.24, targetTbDaily: 522.54 },
                { totalDaily: 1228.56, targetTbDaily: 522.67 },
                { totalDaily: 1228.84, targetTbDaily: 522.79 },
                { totalDaily: 1229.18, targetTbDaily: 522.95 },
                { totalDaily: 1229.52, targetTbDaily: 523.08 },
                { totalDaily: 1229.87, targetTbDaily: 523.2 },
                { totalDaily: 1230.15, targetTbDaily: 523.35 },
                { totalDaily: 1230.42, targetTbDaily: 523.47 },
                { totalDaily: 1230.83, targetTbDaily: 523.63 },
                { totalDaily: 1231.11, targetTbDaily: 523.77 },
                { totalDaily: 1231.44, targetTbDaily: 523.89 },
                { totalDaily: 1231.74, targetTbDaily: 524.02 },
                { totalDaily: 1232.06, targetTbDaily: 524.16 },
                { totalDaily: 1232.44, targetTbDaily: 524.33 },
                { totalDaily: 1232.74, targetTbDaily: 524.42 },
                { totalDaily: 1233.02, targetTbDaily: 524.56 },
                { totalDaily: 1233.32, targetTbDaily: 524.72 },
                { totalDaily: 1233.72, targetTbDaily: 524.86 },
                { totalDaily: 1234.04, targetTbDaily: 524.99 },
                { totalDaily: 1234.32, targetTbDaily: 525.12 },
                { totalDaily: 1234.66, targetTbDaily: 525.27 },
                { totalDaily: 1234.95, targetTbDaily: 525.38 },
                { totalDaily: 1235.27, targetTbDaily: 525.53 },
                { totalDaily: 1235.55, targetTbDaily: 525.64 },
                { totalDaily: 1235.88, targetTbDaily: 525.77 },
                { totalDaily: 1236.26, targetTbDaily: 525.96 },
                { totalDaily: 1236.58, targetTbDaily: 526.07 },
                { totalDaily: 1236.89, targetTbDaily: 526.2 },
                { totalDaily: 1237.24, targetTbDaily: 526.36 },
                { totalDaily: 1237.51, targetTbDaily: 526.49 },
                { totalDaily: 1237.83, targetTbDaily: 526.61 },
                { totalDaily: 1238.13, targetTbDaily: 526.75 },
                { totalDaily: 1238.53, targetTbDaily: 526.91 },
                { totalDaily: 1238.83, targetTbDaily: 527.04 },
                { totalDaily: 1239.17, targetTbDaily: 527.18 },
                { totalDaily: 1239.52, targetTbDaily: 527.33 },
                { totalDaily: 1239.83, targetTbDaily: 527.46 },
                { totalDaily: 1240.19, targetTbDaily: 527.63 },
                { totalDaily: 1240.48, targetTbDaily: 527.73 },
                { totalDaily: 1240.77, targetTbDaily: 527.85 },
                { totalDaily: 1241.11, targetTbDaily: 528.01 },
                { totalDaily: 1241.35, targetTbDaily: 528.12 },
                { totalDaily: 1241.76, targetTbDaily: 528.28 },
                { totalDaily: 1242.13, targetTbDaily: 528.43 },
                { totalDaily: 1242.41, targetTbDaily: 528.53 },
                { totalDaily: 1242.78, targetTbDaily: 528.73 },
                { totalDaily: 1243.07, targetTbDaily: 528.83 },
                { totalDaily: 1243.36, targetTbDaily: 528.98 },
                { totalDaily: 1243.72, targetTbDaily: 529.12 },
                { totalDaily: 1244.04, targetTbDaily: 529.26 },
                { totalDaily: 1244.33, targetTbDaily: 529.4 },
                { totalDaily: 1244.71, targetTbDaily: 529.53 },
                { totalDaily: 1245.01, targetTbDaily: 529.67 },
                { totalDaily: 1245.3, targetTbDaily: 529.8 },
                { totalDaily: 1245.66, targetTbDaily: 529.94 },
                { totalDaily: 1245.98, targetTbDaily: 530.07 },
                { totalDaily: 1246.31, targetTbDaily: 530.22 },
                { totalDaily: 1246.62, targetTbDaily: 530.34 },
                { totalDaily: 1246.94, targetTbDaily: 530.48 },
                { totalDaily: 1247.3, targetTbDaily: 530.64 },
                { totalDaily: 1247.57, targetTbDaily: 530.76 },
                { totalDaily: 1247.92, targetTbDaily: 530.9 },
                { totalDaily: 1248.3, targetTbDaily: 531.08 },
                { totalDaily: 1248.58, targetTbDaily: 531.18 },
                { totalDaily: 1248.86, targetTbDaily: 531.3 },
                { totalDaily: 1249.25, targetTbDaily: 531.46 },
                { totalDaily: 1249.55, targetTbDaily: 531.59 },
                { totalDaily: 1249.86, targetTbDaily: 531.73 },
                { totalDaily: 1250.2, targetTbDaily: 531.86 },
                { totalDaily: 1250.49, targetTbDaily: 532 },
                { totalDaily: 1250.91, targetTbDaily: 532.16 },
                { totalDaily: 1251.18, targetTbDaily: 532.31 },
                { totalDaily: 1251.54, targetTbDaily: 532.43 },
                { totalDaily: 1251.83, targetTbDaily: 532.59 },
                { totalDaily: 1252.12, targetTbDaily: 532.72 },
                { totalDaily: 1252.48, targetTbDaily: 532.84 },
                { totalDaily: 1252.83, targetTbDaily: 533 },
                { totalDaily: 1253.11, targetTbDaily: 533.13 },
                { totalDaily: 1253.49, targetTbDaily: 533.28 },
                { totalDaily: 1253.82, targetTbDaily: 533.41 },
                { totalDaily: 1254.17, targetTbDaily: 533.56 },
                { totalDaily: 1254.51, targetTbDaily: 533.7 },
                { totalDaily: 1254.79, targetTbDaily: 533.82 },
                { totalDaily: 1255.12, targetTbDaily: 533.98 },
                { totalDaily: 1255.43, targetTbDaily: 534.09 },
                { totalDaily: 1255.75, targetTbDaily: 534.22 },
                { totalDaily: 1256.08, targetTbDaily: 534.38 },
                { totalDaily: 1256.47, targetTbDaily: 534.54 },
                { totalDaily: 1256.76, targetTbDaily: 534.66 },
                { totalDaily: 1257.13, targetTbDaily: 534.82 },
                { totalDaily: 1257.42, targetTbDaily: 534.93 },
                { totalDaily: 1257.72, targetTbDaily: 535.06 },
                { totalDaily: 1258.04, targetTbDaily: 535.2 },
                { totalDaily: 1258.37, targetTbDaily: 535.34 },
                { totalDaily: 1258.74, targetTbDaily: 535.51 },
                { totalDaily: 1259.09, targetTbDaily: 535.66 },
                { totalDaily: 1259.42, targetTbDaily: 535.8 },
                { totalDaily: 1259.76, targetTbDaily: 535.95 },
                { totalDaily: 1260.07, targetTbDaily: 536.09 },
                { totalDaily: 1260.36, targetTbDaily: 536.22 },
                { totalDaily: 1264.73, targetTbDaily: 537.66 },
                { totalDaily: 1265.06, targetTbDaily: 537.85 },
                { totalDaily: 1265.36, targetTbDaily: 537.96 },
                { totalDaily: 1265.7, targetTbDaily: 538.11 },
                { totalDaily: 1266.01, targetTbDaily: 538.24 },
                { totalDaily: 1266.37, targetTbDaily: 538.38 },
                { totalDaily: 1266.74, targetTbDaily: 538.56 },
                { totalDaily: 1267, targetTbDaily: 538.64 },
                { totalDaily: 1267.36, targetTbDaily: 538.8 },
                { totalDaily: 1267.69, targetTbDaily: 538.94 },
                { totalDaily: 1267.98, targetTbDaily: 539.04 },
                { totalDaily: 1268.32, targetTbDaily: 539.23 },
                { totalDaily: 1268.66, targetTbDaily: 539.35 },
                { totalDaily: 1268.99, targetTbDaily: 539.49 },
                { totalDaily: 1269.31, targetTbDaily: 539.61 },
                { totalDaily: 1269.67, targetTbDaily: 539.78 },
                { totalDaily: 1270.03, targetTbDaily: 539.95 },
                { totalDaily: 1270.34, targetTbDaily: 540.07 },
                { totalDaily: 1270.66, targetTbDaily: 540.22 },
                { totalDaily: 1271.01, targetTbDaily: 540.35 },
                { totalDaily: 1271.35, targetTbDaily: 540.52 },
                { totalDaily: 1271.65, targetTbDaily: 540.64 },
                { totalDaily: 1272.02, targetTbDaily: 540.79 },
                { totalDaily: 1272.34, targetTbDaily: 540.91 },
                { totalDaily: 1272.65, targetTbDaily: 541.06 },
                { totalDaily: 1273.02, targetTbDaily: 541.2 },
                { totalDaily: 1273.34, targetTbDaily: 541.37 },
                { totalDaily: 1273.64, targetTbDaily: 541.49 },
                { totalDaily: 1274.02, targetTbDaily: 541.63 },
                { totalDaily: 1274.29, targetTbDaily: 541.74 },
                { totalDaily: 1274.62, targetTbDaily: 541.89 },
                { totalDaily: 1275.01, targetTbDaily: 542.06 },
                { totalDaily: 1275.33, targetTbDaily: 542.2 },
                { totalDaily: 1275.63, targetTbDaily: 542.32 },
                { totalDaily: 1275.96, targetTbDaily: 542.43 },
                { totalDaily: 1276.34, targetTbDaily: 542.63 },
                { totalDaily: 1276.64, targetTbDaily: 542.76 },
                { totalDaily: 1276.98, targetTbDaily: 542.9 },
                { totalDaily: 1277.34, targetTbDaily: 543.04 },
                { totalDaily: 1277.67, targetTbDaily: 543.18 },
                { totalDaily: 1277.98, targetTbDaily: 543.32 },
                { totalDaily: 1278.32, targetTbDaily: 543.48 },
                { totalDaily: 1278.66, targetTbDaily: 543.61 },
                { totalDaily: 1278.96, targetTbDaily: 543.72 },
                { totalDaily: 1279.33, targetTbDaily: 543.9 },
                { totalDaily: 1279.65, targetTbDaily: 544.04 },
                { totalDaily: 1279.97, targetTbDaily: 544.17 },
                { totalDaily: 1280.31, targetTbDaily: 544.28 },
                { totalDaily: 1280.71, targetTbDaily: 544.49 },
                { totalDaily: 1281, targetTbDaily: 544.6 },
                { totalDaily: 1281.32, targetTbDaily: 544.75 },
                { totalDaily: 1281.65, targetTbDaily: 544.89 },
                { totalDaily: 1281.92, targetTbDaily: 544.98 },
                { totalDaily: 1282.36, targetTbDaily: 545.18 },
                { totalDaily: 1282.68, targetTbDaily: 545.33 },
                { totalDaily: 1282.97, targetTbDaily: 545.44 },
                { totalDaily: 1283.39, targetTbDaily: 545.61 },
                { totalDaily: 1283.68, targetTbDaily: 545.74 },
                { totalDaily: 1284.01, targetTbDaily: 545.86 },
                { totalDaily: 1284.33, targetTbDaily: 546.03 },
                { totalDaily: 1284.7, targetTbDaily: 546.17 },
                { totalDaily: 1285.02, targetTbDaily: 546.31 },
                { totalDaily: 1285.33, targetTbDaily: 546.46 },
                { totalDaily: 1285.65, targetTbDaily: 546.58 },
                { totalDaily: 1286, targetTbDaily: 546.73 },
                { totalDaily: 1286.36, targetTbDaily: 546.9 },
                { totalDaily: 1286.69, targetTbDaily: 547.04 },
                { totalDaily: 1287.08, targetTbDaily: 547.19 },
                { totalDaily: 1287.37, targetTbDaily: 547.34 },
                { totalDaily: 1287.7, targetTbDaily: 547.45 },
                { totalDaily: 1288.04, targetTbDaily: 547.59 },
                { totalDaily: 1288.33, targetTbDaily: 547.73 },
                { totalDaily: 1288.67, targetTbDaily: 547.87 },
                { totalDaily: 1289.01, targetTbDaily: 548.01 },
                { totalDaily: 1289.4, targetTbDaily: 548.17 },
                { totalDaily: 1289.73, targetTbDaily: 548.31 },
                { totalDaily: 1290.09, targetTbDaily: 548.47 },
                { totalDaily: 1290.39, targetTbDaily: 548.59 },
                { totalDaily: 1290.7, targetTbDaily: 548.73 },
                { totalDaily: 1291.06, targetTbDaily: 548.88 },
                { totalDaily: 1291.42, targetTbDaily: 549.03 },
                { totalDaily: 1291.73, targetTbDaily: 549.18 },
                { totalDaily: 1292.1, targetTbDaily: 549.33 },
                { totalDaily: 1292.46, targetTbDaily: 549.47 },
                { totalDaily: 1292.75, targetTbDaily: 549.58 },
                { totalDaily: 1293.1, targetTbDaily: 549.79 },
                { totalDaily: 1293.43, targetTbDaily: 549.9 },
                { totalDaily: 1293.77, targetTbDaily: 550.01 },
                { totalDaily: 1294.1, targetTbDaily: 550.17 },
                { totalDaily: 1294.38, targetTbDaily: 550.3 },
                { totalDaily: 1294.83, targetTbDaily: 550.49 },
                { totalDaily: 1295.11, targetTbDaily: 550.62 },
                { totalDaily: 1295.45, targetTbDaily: 550.75 },
                { totalDaily: 1295.82, targetTbDaily: 550.91 },
                { totalDaily: 1296.1, targetTbDaily: 551.02 },
                { totalDaily: 1296.48, targetTbDaily: 551.18 },
                { totalDaily: 1296.82, targetTbDaily: 551.35 },
                { totalDaily: 1297.14, targetTbDaily: 551.47 },
                { totalDaily: 1297.51, targetTbDaily: 551.64 },
                { totalDaily: 1297.81, targetTbDaily: 551.74 },
                { totalDaily: 1298.17, targetTbDaily: 551.91 },
                { totalDaily: 1298.51, targetTbDaily: 552.04 },
                { totalDaily: 1298.88, targetTbDaily: 552.21 },
                { totalDaily: 1299.18, targetTbDaily: 552.34 },
                { totalDaily: 1299.55, targetTbDaily: 552.49 },
                { totalDaily: 1299.81, targetTbDaily: 552.61 },
                { totalDaily: 1300.14, targetTbDaily: 552.75 },
                { totalDaily: 1300.54, targetTbDaily: 552.91 },
                { totalDaily: 1300.85, targetTbDaily: 553.04 },
                { totalDaily: 1301.21, targetTbDaily: 553.2 },
                { totalDaily: 1301.56, targetTbDaily: 553.37 },
                { totalDaily: 1301.89, targetTbDaily: 553.47 },
                { totalDaily: 1302.22, targetTbDaily: 553.61 },
                { totalDaily: 1302.57, targetTbDaily: 553.77 },
                { totalDaily: 1302.86, targetTbDaily: 553.88 },
                { totalDaily: 1303.2, targetTbDaily: 554.04 },
                { totalDaily: 1303.59, targetTbDaily: 554.22 },
                { totalDaily: 1303.9, targetTbDaily: 554.33 },
                { totalDaily: 1304.28, targetTbDaily: 554.5 },
                { totalDaily: 1304.64, targetTbDaily: 554.66 },
                { totalDaily: 1305, targetTbDaily: 554.81 },
                { totalDaily: 1305.31, targetTbDaily: 554.95 },
                { totalDaily: 1305.6, targetTbDaily: 555.09 },
                { totalDaily: 1305.96, targetTbDaily: 555.24 },
                { totalDaily: 1306.35, targetTbDaily: 555.38 },
                { totalDaily: 1306.71, targetTbDaily: 555.53 },
                { totalDaily: 1307.01, targetTbDaily: 555.66 },
                { totalDaily: 1307.37, targetTbDaily: 555.81 },
                { totalDaily: 1307.74, targetTbDaily: 555.96 },
                { totalDaily: 1308.02, targetTbDaily: 556.09 },
                { totalDaily: 1308.38, targetTbDaily: 556.24 },
                { totalDaily: 1308.72, targetTbDaily: 556.4 },
                { totalDaily: 1309.04, targetTbDaily: 556.51 },
                { totalDaily: 1309.4, targetTbDaily: 556.66 },
                { totalDaily: 1309.78, targetTbDaily: 556.84 },
                { totalDaily: 1310.1, targetTbDaily: 556.98 },
                { totalDaily: 1310.46, targetTbDaily: 557.11 },
                { totalDaily: 1310.8, targetTbDaily: 557.28 },
                { totalDaily: 1311.08, targetTbDaily: 557.39 },
                { totalDaily: 1311.44, targetTbDaily: 557.55 },
                { totalDaily: 1311.75, targetTbDaily: 557.68 },
                { totalDaily: 1312.16, targetTbDaily: 557.86 },
                { totalDaily: 1312.46, targetTbDaily: 557.98 },
                { totalDaily: 1312.8, targetTbDaily: 558.12 },
                { totalDaily: 1313.18, targetTbDaily: 558.29 },
                { totalDaily: 1313.51, targetTbDaily: 558.43 },
                { totalDaily: 1313.84, targetTbDaily: 558.55 },
                { totalDaily: 1314.17, targetTbDaily: 558.72 },
                { totalDaily: 1314.57, targetTbDaily: 558.89 },
                { totalDaily: 1314.89, targetTbDaily: 559.02 },
                { totalDaily: 1315.21, targetTbDaily: 559.16 },
                { totalDaily: 1315.58, targetTbDaily: 559.29 },
                { totalDaily: 1315.88, targetTbDaily: 559.43 },
                { totalDaily: 1316.22, targetTbDaily: 559.57 },
                { totalDaily: 1316.57, targetTbDaily: 559.73 },
                { totalDaily: 1316.97, targetTbDaily: 559.9 },
                { totalDaily: 1317.3, targetTbDaily: 560.02 },
                { totalDaily: 1317.63, targetTbDaily: 560.19 },
                { totalDaily: 1317.98, targetTbDaily: 560.34 },
                { totalDaily: 1318.32, targetTbDaily: 560.47 },
                { totalDaily: 1318.66, targetTbDaily: 560.63 },
                { totalDaily: 1319.02, targetTbDaily: 560.76 },
                { totalDaily: 1319.36, targetTbDaily: 560.91 },
                { totalDaily: 1319.71, targetTbDaily: 561.07 },
                { totalDaily: 1320.07, targetTbDaily: 561.21 },
                { totalDaily: 1320.35, targetTbDaily: 561.33 },
                { totalDaily: 1320.8, targetTbDaily: 561.52 },
                { totalDaily: 1321.1, targetTbDaily: 561.65 },
                { totalDaily: 1321.44, targetTbDaily: 561.81 },
                { totalDaily: 1321.77, targetTbDaily: 561.91 },
                { totalDaily: 1322.07, targetTbDaily: 562.05 },
                { totalDaily: 1322.48, targetTbDaily: 562.24 },
                { totalDaily: 1322.78, targetTbDaily: 562.37 },
                { totalDaily: 1323.17, targetTbDaily: 562.52 },
                { totalDaily: 1323.53, targetTbDaily: 562.68 },
                { totalDaily: 1323.81, targetTbDaily: 562.8 },
                { totalDaily: 1324.24, targetTbDaily: 562.98 },
                { totalDaily: 1324.54, targetTbDaily: 563.12 },
                { totalDaily: 1324.86, targetTbDaily: 563.25 },
                { totalDaily: 1325.19, targetTbDaily: 563.4 },
                { totalDaily: 1325.53, targetTbDaily: 563.54 },
                { totalDaily: 1325.88, targetTbDaily: 563.69 },
                { totalDaily: 1326.23, targetTbDaily: 563.84 },
                { totalDaily: 1326.63, targetTbDaily: 564.01 },
                { totalDaily: 1326.97, targetTbDaily: 564.14 },
                { totalDaily: 1327.29, targetTbDaily: 564.29 },
                { totalDaily: 1327.65, targetTbDaily: 564.43 },
                { totalDaily: 1327.98, targetTbDaily: 564.58 },
                { totalDaily: 1328.33, targetTbDaily: 564.74 },
                { totalDaily: 1328.69, targetTbDaily: 564.89 },
                { totalDaily: 1329, targetTbDaily: 565.04 },
                { totalDaily: 1329.4, targetTbDaily: 565.17 },
                { totalDaily: 1329.76, targetTbDaily: 565.34 },
                { totalDaily: 1330.14, targetTbDaily: 565.51 },
                { totalDaily: 1330.46, targetTbDaily: 565.61 },
                { totalDaily: 1330.81, targetTbDaily: 565.77 },
                { totalDaily: 1331.1, targetTbDaily: 565.91 },
                { totalDaily: 1331.44, targetTbDaily: 566.04 },
                { totalDaily: 1331.81, targetTbDaily: 566.21 },
                { totalDaily: 1332.18, targetTbDaily: 566.36 },
                { totalDaily: 1332.5, targetTbDaily: 566.51 },
                { totalDaily: 1332.82, targetTbDaily: 566.64 },
                { totalDaily: 1333.22, targetTbDaily: 566.8 },
                { totalDaily: 1333.54, targetTbDaily: 566.93 },
                { totalDaily: 1333.91, targetTbDaily: 567.07 },
                { totalDaily: 1334.23, targetTbDaily: 567.22 },
                { totalDaily: 1334.57, targetTbDaily: 567.39 },
                { totalDaily: 1334.98, targetTbDaily: 567.55 },
                { totalDaily: 1335.31, targetTbDaily: 567.7 },
                { totalDaily: 1335.7, targetTbDaily: 567.84 },
                { totalDaily: 1336, targetTbDaily: 567.97 },
                { totalDaily: 1336.35, targetTbDaily: 568.14 },
                { totalDaily: 1336.72, targetTbDaily: 568.3 },
                { totalDaily: 1337.07, targetTbDaily: 568.44 },
                { totalDaily: 1337.4, targetTbDaily: 568.59 },
                { totalDaily: 1337.74, targetTbDaily: 568.73 },
                { totalDaily: 1338.11, targetTbDaily: 568.87 },
                { totalDaily: 1338.44, targetTbDaily: 569.03 },
                { totalDaily: 1338.81, targetTbDaily: 569.18 },
                { totalDaily: 1339.15, targetTbDaily: 569.32 },
                { totalDaily: 1339.48, targetTbDaily: 569.47 },
                { totalDaily: 1339.81, targetTbDaily: 569.62 },
                { totalDaily: 1340.15, targetTbDaily: 569.77 },
                { totalDaily: 1340.53, targetTbDaily: 569.93 },
                { totalDaily: 1340.86, targetTbDaily: 570.02 },
                { totalDaily: 1341.2, targetTbDaily: 570.2 },
                { totalDaily: 1341.58, targetTbDaily: 570.38 },
                { totalDaily: 1341.93, targetTbDaily: 570.48 },
                { totalDaily: 1342.3, targetTbDaily: 570.67 },
                { totalDaily: 1342.62, targetTbDaily: 570.8 },
                { totalDaily: 1342.99, targetTbDaily: 570.96 },
                { totalDaily: 1343.35, targetTbDaily: 571.11 },
                { totalDaily: 1343.72, targetTbDaily: 571.27 },
                { totalDaily: 1344.03, targetTbDaily: 571.41 },
                { totalDaily: 1344.39, targetTbDaily: 571.53 },
                { totalDaily: 1344.74, targetTbDaily: 571.7 },
                { totalDaily: 1345.1, targetTbDaily: 571.87 },
                { totalDaily: 1345.47, targetTbDaily: 572.02 },
                { totalDaily: 1345.79, targetTbDaily: 572.16 },
                { totalDaily: 1346.13, targetTbDaily: 572.3 },
                { totalDaily: 1346.52, targetTbDaily: 572.46 },
                { totalDaily: 1346.83, targetTbDaily: 572.59 },
                { totalDaily: 1347.19, targetTbDaily: 572.76 },
                { totalDaily: 1347.57, targetTbDaily: 572.91 },
                { totalDaily: 1347.89, targetTbDaily: 573.04 },
                { totalDaily: 1348.27, targetTbDaily: 573.2 },
                { totalDaily: 1348.6, targetTbDaily: 573.34 },
                { totalDaily: 1348.95, targetTbDaily: 573.5 },
                { totalDaily: 1349.33, targetTbDaily: 573.64 },
                { totalDaily: 1349.69, targetTbDaily: 573.8 },
                { totalDaily: 1350.06, targetTbDaily: 573.97 },
                { totalDaily: 1350.39, targetTbDaily: 574.12 },
                { totalDaily: 1350.77, targetTbDaily: 574.25 },
                { totalDaily: 1351.07, targetTbDaily: 574.41 },
                { totalDaily: 1351.46, targetTbDaily: 574.57 },
                { totalDaily: 1351.79, targetTbDaily: 574.68 },
                { totalDaily: 1352.12, targetTbDaily: 574.85 },
                { totalDaily: 1352.47, targetTbDaily: 574.97 },
                { totalDaily: 1352.83, targetTbDaily: 575.13 },
                { totalDaily: 1353.21, targetTbDaily: 575.29 },
                { totalDaily: 1353.59, targetTbDaily: 575.47 },
                { totalDaily: 1353.9, targetTbDaily: 575.61 },
                { totalDaily: 1354.2, targetTbDaily: 575.73 },
                { totalDaily: 1354.59, targetTbDaily: 575.89 },
                { totalDaily: 1354.92, targetTbDaily: 576.03 },
                { totalDaily: 1355.34, targetTbDaily: 576.19 },
                { totalDaily: 1355.65, targetTbDaily: 576.33 },
                { totalDaily: 1355.99, targetTbDaily: 576.49 },
                { totalDaily: 1356.39, targetTbDaily: 576.66 },
                { totalDaily: 1356.74, targetTbDaily: 576.8 },
                { totalDaily: 1357.1, targetTbDaily: 576.94 },
                { totalDaily: 1357.47, targetTbDaily: 577.12 },
                { totalDaily: 1357.81, targetTbDaily: 577.28 },
                { totalDaily: 1358.21, targetTbDaily: 577.42 },
                { totalDaily: 1358.52, targetTbDaily: 577.57 },
                { totalDaily: 1358.88, targetTbDaily: 577.73 },
                { totalDaily: 1359.23, targetTbDaily: 577.87 },
                { totalDaily: 1359.54, targetTbDaily: 578 },
                { totalDaily: 1359.91, targetTbDaily: 578.15 },
                { totalDaily: 1360.28, targetTbDaily: 578.3 },
                { totalDaily: 1360.65, targetTbDaily: 578.44 },
                { totalDaily: 1360.99, targetTbDaily: 578.63 },
                { totalDaily: 1361.33, targetTbDaily: 578.75 },
                { totalDaily: 1361.68, targetTbDaily: 578.88 },
                { totalDaily: 1362.02, targetTbDaily: 579.05 },
                { totalDaily: 1362.4, targetTbDaily: 579.21 },
                { totalDaily: 1362.75, targetTbDaily: 579.35 },
                { totalDaily: 1363.1, targetTbDaily: 579.51 },
                { totalDaily: 1363.48, targetTbDaily: 579.67 },
                { totalDaily: 1363.85, targetTbDaily: 579.82 },
                { totalDaily: 1364.23, targetTbDaily: 579.99 },
                { totalDaily: 1364.57, targetTbDaily: 580.14 },
                { totalDaily: 1364.9, targetTbDaily: 580.26 },
                { totalDaily: 1365.28, targetTbDaily: 580.43 },
                { totalDaily: 1365.62, targetTbDaily: 580.57 },
                { totalDaily: 1365.98, targetTbDaily: 580.75 },
                { totalDaily: 1366.34, targetTbDaily: 580.87 },
                { totalDaily: 1366.66, targetTbDaily: 581.03 },
                { totalDaily: 1367.06, targetTbDaily: 581.18 },
                { totalDaily: 1367.4, targetTbDaily: 581.33 },
                { totalDaily: 1367.81, targetTbDaily: 581.49 },
                { totalDaily: 1368.11, targetTbDaily: 581.63 },
                { totalDaily: 1368.48, targetTbDaily: 581.79 },
                { totalDaily: 1368.79, targetTbDaily: 581.95 },
                { totalDaily: 1369.16, targetTbDaily: 582.08 },
                { totalDaily: 1369.53, targetTbDaily: 582.23 },
                { totalDaily: 1369.97, targetTbDaily: 582.41 },
                { totalDaily: 1370.31, targetTbDaily: 582.57 },
                { totalDaily: 1370.63, targetTbDaily: 582.72 },
                { totalDaily: 1370.98, targetTbDaily: 582.84 },
                { totalDaily: 1371.3, targetTbDaily: 582.99 },
                { totalDaily: 1371.71, targetTbDaily: 583.16 },
                { totalDaily: 1372.03, targetTbDaily: 583.3 },
                { totalDaily: 1372.44, targetTbDaily: 583.49 },
                { totalDaily: 1372.73, targetTbDaily: 583.61 },
                { totalDaily: 1373.15, targetTbDaily: 583.78 },
                { totalDaily: 1373.49, targetTbDaily: 583.91 },
                { totalDaily: 1373.83, targetTbDaily: 584.07 },
                { totalDaily: 1374.18, targetTbDaily: 584.22 },
                { totalDaily: 1374.59, targetTbDaily: 584.39 },
                { totalDaily: 1374.92, targetTbDaily: 584.54 },
                { totalDaily: 1375.33, targetTbDaily: 584.7 },
                { totalDaily: 1375.64, targetTbDaily: 584.82 },
                { totalDaily: 1375.97, targetTbDaily: 585 },
                { totalDaily: 1376.37, targetTbDaily: 585.16 },
                { totalDaily: 1376.7, targetTbDaily: 585.29 },
                { totalDaily: 1377.07, targetTbDaily: 585.46 },
                { totalDaily: 1377.45, targetTbDaily: 585.62 },
                { totalDaily: 1377.83, targetTbDaily: 585.76 },
                { totalDaily: 1378.18, targetTbDaily: 585.93 },
                { totalDaily: 1378.51, targetTbDaily: 586.05 },
                { totalDaily: 1378.89, targetTbDaily: 586.22 },
                { totalDaily: 1379.24, targetTbDaily: 586.39 },
                { totalDaily: 1379.59, targetTbDaily: 586.52 },
                { totalDaily: 1379.93, targetTbDaily: 586.68 },
                { totalDaily: 1380.33, targetTbDaily: 586.83 },
                { totalDaily: 1380.68, targetTbDaily: 587 },
                { totalDaily: 1381.04, targetTbDaily: 587.16 },
                { totalDaily: 1381.4, targetTbDaily: 587.28 },
                { totalDaily: 1381.75, targetTbDaily: 587.42 },
                { totalDaily: 1382.14, targetTbDaily: 587.57 },
                { totalDaily: 1382.51, targetTbDaily: 587.75 },
                { totalDaily: 1382.83, targetTbDaily: 587.89 },
                { totalDaily: 1383.2, targetTbDaily: 588.06 },
                { totalDaily: 1383.55, targetTbDaily: 588.2 },
                { totalDaily: 1383.95, targetTbDaily: 588.36 },
                { totalDaily: 1384.32, targetTbDaily: 588.53 },
                { totalDaily: 1384.67, targetTbDaily: 588.66 },
                { totalDaily: 1385.04, targetTbDaily: 588.82 },
                { totalDaily: 1385.33, targetTbDaily: 588.96 },
                { totalDaily: 1385.75, targetTbDaily: 589.12 },
                { totalDaily: 1386.1, targetTbDaily: 589.28 },
                { totalDaily: 1386.5, targetTbDaily: 589.47 },
                { totalDaily: 1386.79, targetTbDaily: 589.6 },
                { totalDaily: 1387.17, targetTbDaily: 589.73 },
                { totalDaily: 1387.57, targetTbDaily: 589.91 },
                { totalDaily: 1387.9, targetTbDaily: 590.05 },
                { totalDaily: 1388.31, targetTbDaily: 590.22 },
                { totalDaily: 1388.62, targetTbDaily: 590.38 },
                { totalDaily: 1389.02, targetTbDaily: 590.53 },
                { totalDaily: 1389.33, targetTbDaily: 590.68 },
                { totalDaily: 1389.76, targetTbDaily: 590.86 },
                { totalDaily: 1390.09, targetTbDaily: 590.98 },
                { totalDaily: 1390.49, targetTbDaily: 591.15 },
            ],
            monthlyData: [
                { total: 447.04, target: 190.62, creditsMonthly: 90.89, cumulatedCredits: 90.89 },
                { total: 459.9, target: 195.88, creditsMonthly: 153.66, cumulatedCredits: 244.56 },
                { total: 494.37, target: 210.55, creditsMonthly: 165.23, cumulatedCredits: 409.8 },
                { total: 529.01, target: 225.33, creditsMonthly: 176.85, cumulatedCredits: 586.65 },
                { total: 534.01, target: 227.36, creditsMonthly: 178.84, cumulatedCredits: 765.49 },
                { total: 539.06, target: 229.43, creditsMonthly: 180.25, cumulatedCredits: 945.74 },
                { total: 544.04, target: 231.45, creditsMonthly: 181.86, cumulatedCredits: 1127.59 },
                { total: 549.19, target: 233.6, creditsMonthly: 183.48, cumulatedCredits: 1311.08 },
                { total: 554.29, target: 235.69, creditsMonthly: 185.33, cumulatedCredits: 1496.41 },
                { total: 559.47, target: 237.83, creditsMonthly: 186.97, cumulatedCredits: 1683.38 },
                { total: 564.65, target: 239.93, creditsMonthly: 188.77, cumulatedCredits: 1872.16 },
                { total: 569.95, target: 242.15, creditsMonthly: 190.31, cumulatedCredits: 2062.46 },
                { total: 711.11, target: 302.5, creditsMonthly: 228.45, cumulatedCredits: 2290.92 },
                { total: 716.71, target: 304.89, creditsMonthly: 239.57, cumulatedCredits: 2530.5 },
                { total: 722.36, target: 307.28, creditsMonthly: 241.71, cumulatedCredits: 2772.21 },
                { total: 728, target: 309.66, creditsMonthly: 243.56, cumulatedCredits: 3015.77 },
                { total: 733.75, target: 312.12, creditsMonthly: 245.29, cumulatedCredits: 3261.06 },
                { total: 739.54, target: 314.58, creditsMonthly: 247.34, cumulatedCredits: 3508.39 },
                { total: 745.39, target: 317.08, creditsMonthly: 249.08, cumulatedCredits: 3757.48 },
                { total: 751.21, target: 319.53, creditsMonthly: 251.15, cumulatedCredits: 4008.63 },
                { total: 757.11, target: 322.06, creditsMonthly: 253.22, cumulatedCredits: 4261.85 },
                { total: 763.1, target: 324.62, creditsMonthly: 255.29, cumulatedCredits: 4517.16 },
                { total: 769.06, target: 327.13, creditsMonthly: 257.17, cumulatedCredits: 4774.32 },
                { total: 775.14, target: 329.73, creditsMonthly: 259.11, cumulatedCredits: 5033.43 },
                { total: 920.65, target: 391.65, creditsMonthly: 290.82, cumulatedCredits: 5324.27 },
                { total: 927.87, target: 394.75, creditsMonthly: 310.28, cumulatedCredits: 5634.54 },
                { total: 935.18, target: 397.84, creditsMonthly: 312.64, cumulatedCredits: 5947.2 },
                { total: 942.56, target: 400.97, creditsMonthly: 315.08, cumulatedCredits: 6262.29 },
                { total: 949.87, target: 404.08, creditsMonthly: 317.65, cumulatedCredits: 6579.94 },
                { total: 957.38, target: 407.28, creditsMonthly: 320.3, cumulatedCredits: 6900.24 },
                { total: 964.93, target: 410.49, creditsMonthly: 322.68, cumulatedCredits: 7222.92 },
                { total: 972.56, target: 413.74, creditsMonthly: 325.16, cumulatedCredits: 7548.09 },
                { total: 980.23, target: 417, creditsMonthly: 327.7, cumulatedCredits: 7875.78 },
                { total: 987.89, target: 420.27, creditsMonthly: 330.41, cumulatedCredits: 8206.19 },
                { total: 995.61, target: 423.56, creditsMonthly: 332.98, cumulatedCredits: 8539.18 },
                { total: 1003.47, target: 426.88, creditsMonthly: 335.53, cumulatedCredits: 8874.71 },
                { total: 1149.99, target: 489.25, creditsMonthly: 359.93, cumulatedCredits: 9234.64 },
                { total: 1159.03, target: 493.08, creditsMonthly: 387.68, cumulatedCredits: 9622.33 },
                { total: 1168.15, target: 496.96, creditsMonthly: 390.58, cumulatedCredits: 10012.91 },
                { total: 1177.33, target: 500.87, creditsMonthly: 393.61, cumulatedCredits: 10406.52 },
                { total: 1186.6, target: 504.84, creditsMonthly: 396.98, cumulatedCredits: 10803.51 },
                { total: 1195.96, target: 508.81, creditsMonthly: 399.94, cumulatedCredits: 11203.45 },
                { total: 1205.32, target: 512.79, creditsMonthly: 403.1, cumulatedCredits: 11606.56 },
                { total: 1214.82, target: 516.81, creditsMonthly: 406.19, cumulatedCredits: 12012.75 },
                { total: 1224.35, target: 520.88, creditsMonthly: 409.54, cumulatedCredits: 12422.29 },
                { total: 1234.04, target: 524.99, creditsMonthly: 412.71, cumulatedCredits: 12835 },
                { total: 1243.72, target: 529.12, creditsMonthly: 415.84, cumulatedCredits: 13250.84 },
                { total: 1253.49, target: 533.28, creditsMonthly: 419.09, cumulatedCredits: 13669.93 },
                { total: 1267.36, target: 538.8, creditsMonthly: 422.81, cumulatedCredits: 14092.75 },
                { total: 1277.34, target: 543.04, creditsMonthly: 426.91, cumulatedCredits: 14519.66 },
                { total: 1287.37, target: 547.34, creditsMonthly: 430.26, cumulatedCredits: 14949.91 },
                { total: 1297.51, target: 551.64, creditsMonthly: 433.59, cumulatedCredits: 15383.51 },
                { total: 1307.74, target: 555.96, creditsMonthly: 436.97, cumulatedCredits: 15820.48 },
                { total: 1317.98, target: 560.34, creditsMonthly: 440.54, cumulatedCredits: 16261.02 },
                { total: 1328.33, target: 564.74, creditsMonthly: 443.87, cumulatedCredits: 16704.88 },
                { total: 1338.81, target: 569.18, creditsMonthly: 447.38, cumulatedCredits: 17152.28 },
                { total: 1349.33, target: 573.64, creditsMonthly: 451.06, cumulatedCredits: 17603.34 },
                { total: 1359.91, target: 578.15, creditsMonthly: 454.48, cumulatedCredits: 18057.81 },
                { total: 1370.63, target: 582.72, creditsMonthly: 457.94, cumulatedCredits: 18515.75 },
                { total: 1381.4, target: 587.28, creditsMonthly: 461.66, cumulatedCredits: 18977.42 },
            ],
        },
        noLtr: {
            dailyData: [
                { totalDaily: 2, targetTbDaily: 0.96 },
                { totalDaily: 4.04, targetTbDaily: 1.93 },
                { totalDaily: 6.08, targetTbDaily: 2.91 },
                { totalDaily: 8.18, targetTbDaily: 3.92 },
                { totalDaily: 10.24, targetTbDaily: 4.89 },
                { totalDaily: 12.39, targetTbDaily: 5.94 },
                { totalDaily: 14.48, targetTbDaily: 6.94 },
                { totalDaily: 16.7, targetTbDaily: 7.99 },
                { totalDaily: 18.79, targetTbDaily: 9 },
                { totalDaily: 21.08, targetTbDaily: 10.09 },
                { totalDaily: 23.2, targetTbDaily: 11.11 },
                { totalDaily: 25.54, targetTbDaily: 12.23 },
                { totalDaily: 27.68, targetTbDaily: 13.25 },
                { totalDaily: 30.07, targetTbDaily: 14.4 },
                { totalDaily: 32.23, targetTbDaily: 15.43 },
                { totalDaily: 32.53, targetTbDaily: 15.57 },
                { totalDaily: 32.82, targetTbDaily: 15.71 },
                { totalDaily: 33.11, targetTbDaily: 15.85 },
                { totalDaily: 33.39, targetTbDaily: 15.98 },
                { totalDaily: 33.68, targetTbDaily: 16.12 },
                { totalDaily: 33.97, targetTbDaily: 16.27 },
                { totalDaily: 34.26, targetTbDaily: 16.39 },
                { totalDaily: 35.16, targetTbDaily: 16.82 },
                { totalDaily: 35.17, targetTbDaily: 16.84 },
                { totalDaily: 35.18, targetTbDaily: 16.84 },
                { totalDaily: 35.19, targetTbDaily: 16.84 },
                { totalDaily: 35.19, targetTbDaily: 16.84 },
                { totalDaily: 35.2, targetTbDaily: 16.84 },
                { totalDaily: 35.22, targetTbDaily: 16.86 },
                { totalDaily: 36.12, targetTbDaily: 17.28 },
                { totalDaily: 36.12, targetTbDaily: 17.28 },
                { totalDaily: 36.14, targetTbDaily: 17.28 },
                { totalDaily: 36.15, targetTbDaily: 17.29 },
                { totalDaily: 36.15, targetTbDaily: 17.29 },
                { totalDaily: 36.15, targetTbDaily: 17.29 },
                { totalDaily: 36.18, targetTbDaily: 17.3 },
                { totalDaily: 36.2, targetTbDaily: 17.3 },
                { totalDaily: 36.2, targetTbDaily: 17.3 },
                { totalDaily: 36.22, targetTbDaily: 17.32 },
                { totalDaily: 36.22, targetTbDaily: 17.34 },
                { totalDaily: 36.24, targetTbDaily: 17.34 },
                { totalDaily: 36.24, targetTbDaily: 17.34 },
                { totalDaily: 36.26, targetTbDaily: 17.35 },
                { totalDaily: 36.26, targetTbDaily: 17.36 },
                { totalDaily: 36.28, targetTbDaily: 17.36 },
                { totalDaily: 36.28, targetTbDaily: 17.36 },
                { totalDaily: 36.29, targetTbDaily: 17.37 },
                { totalDaily: 36.29, targetTbDaily: 17.38 },
                { totalDaily: 36.31, targetTbDaily: 17.38 },
                { totalDaily: 36.31, targetTbDaily: 17.38 },
                { totalDaily: 36.32, targetTbDaily: 17.38 },
                { totalDaily: 36.33, targetTbDaily: 17.39 },
                { totalDaily: 36.34, targetTbDaily: 17.39 },
                { totalDaily: 36.34, targetTbDaily: 17.39 },
                { totalDaily: 36.36, targetTbDaily: 17.39 },
                { totalDaily: 36.39, targetTbDaily: 17.42 },
                { totalDaily: 36.39, targetTbDaily: 17.42 },
                { totalDaily: 36.4, targetTbDaily: 17.42 },
                { totalDaily: 36.4, targetTbDaily: 17.43 },
                { totalDaily: 36.42, targetTbDaily: 17.43 },
                { totalDaily: 36.42, targetTbDaily: 17.43 },
                { totalDaily: 38.84, targetTbDaily: 18.59 },
                { totalDaily: 38.86, targetTbDaily: 18.61 },
                { totalDaily: 38.88, targetTbDaily: 18.62 },
                { totalDaily: 38.88, targetTbDaily: 18.62 },
                { totalDaily: 38.9, targetTbDaily: 18.62 },
                { totalDaily: 38.9, targetTbDaily: 18.62 },
                { totalDaily: 38.92, targetTbDaily: 18.63 },
                { totalDaily: 38.94, targetTbDaily: 18.63 },
                { totalDaily: 38.95, targetTbDaily: 18.64 },
                { totalDaily: 38.96, targetTbDaily: 18.64 },
                { totalDaily: 38.97, targetTbDaily: 18.64 },
                { totalDaily: 38.97, targetTbDaily: 18.64 },
                { totalDaily: 38.98, targetTbDaily: 18.66 },
                { totalDaily: 39, targetTbDaily: 18.67 },
                { totalDaily: 39, targetTbDaily: 18.67 },
                { totalDaily: 39.01, targetTbDaily: 18.67 },
                { totalDaily: 39.02, targetTbDaily: 18.68 },
                { totalDaily: 39.03, targetTbDaily: 18.68 },
                { totalDaily: 39.03, targetTbDaily: 18.68 },
                { totalDaily: 39.04, targetTbDaily: 18.68 },
                { totalDaily: 39.04, targetTbDaily: 18.7 },
                { totalDaily: 39.06, targetTbDaily: 18.7 },
                { totalDaily: 39.07, targetTbDaily: 18.7 },
                { totalDaily: 39.08, targetTbDaily: 18.71 },
                { totalDaily: 39.08, targetTbDaily: 18.71 },
                { totalDaily: 39.1, targetTbDaily: 18.71 },
                { totalDaily: 39.1, targetTbDaily: 18.71 },
                { totalDaily: 39.12, targetTbDaily: 18.72 },
                { totalDaily: 39.15, targetTbDaily: 18.72 },
                { totalDaily: 39.15, targetTbDaily: 18.72 },
                { totalDaily: 41.58, targetTbDaily: 19.89 },
                { totalDaily: 41.59, targetTbDaily: 19.91 },
                { totalDaily: 41.6, targetTbDaily: 19.91 },
                { totalDaily: 41.6, targetTbDaily: 19.91 },
                { totalDaily: 41.61, targetTbDaily: 19.92 },
                { totalDaily: 41.63, targetTbDaily: 19.93 },
                { totalDaily: 41.64, targetTbDaily: 19.93 },
                { totalDaily: 41.64, targetTbDaily: 19.93 },
                { totalDaily: 41.66, targetTbDaily: 19.93 },
                { totalDaily: 41.68, targetTbDaily: 19.93 },
                { totalDaily: 41.69, targetTbDaily: 19.95 },
                { totalDaily: 41.69, targetTbDaily: 19.95 },
                { totalDaily: 41.72, targetTbDaily: 19.97 },
                { totalDaily: 41.73, targetTbDaily: 19.97 },
                { totalDaily: 41.73, targetTbDaily: 19.97 },
                { totalDaily: 41.73, targetTbDaily: 19.97 },
                { totalDaily: 41.75, targetTbDaily: 19.98 },
                { totalDaily: 41.76, targetTbDaily: 19.98 },
                { totalDaily: 41.76, targetTbDaily: 19.98 },
                { totalDaily: 41.79, targetTbDaily: 19.99 },
                { totalDaily: 41.8, targetTbDaily: 20 },
                { totalDaily: 41.81, targetTbDaily: 20 },
                { totalDaily: 41.81, targetTbDaily: 20 },
                { totalDaily: 41.84, targetTbDaily: 20.03 },
                { totalDaily: 41.85, targetTbDaily: 20.04 },
                { totalDaily: 41.86, targetTbDaily: 20.04 },
                { totalDaily: 41.87, targetTbDaily: 20.04 },
                { totalDaily: 41.88, targetTbDaily: 20.05 },
                { totalDaily: 41.89, targetTbDaily: 20.06 },
                { totalDaily: 41.89, targetTbDaily: 20.06 },
                { totalDaily: 41.91, targetTbDaily: 20.06 },
                { totalDaily: 41.92, targetTbDaily: 20.06 },
                { totalDaily: 41.94, targetTbDaily: 20.07 },
                { totalDaily: 41.94, targetTbDaily: 20.07 },
                { totalDaily: 41.95, targetTbDaily: 20.07 },
                { totalDaily: 41.97, targetTbDaily: 20.09 },
                { totalDaily: 41.98, targetTbDaily: 20.1 },
                { totalDaily: 41.98, targetTbDaily: 20.1 },
                { totalDaily: 41.98, targetTbDaily: 20.1 },
                { totalDaily: 42.01, targetTbDaily: 20.11 },
                { totalDaily: 42.01, targetTbDaily: 20.11 },
                { totalDaily: 42.03, targetTbDaily: 20.11 },
                { totalDaily: 42.05, targetTbDaily: 20.11 },
                { totalDaily: 42.06, targetTbDaily: 20.13 },
                { totalDaily: 42.06, targetTbDaily: 20.13 },
                { totalDaily: 42.08, targetTbDaily: 20.13 },
                { totalDaily: 42.09, targetTbDaily: 20.14 },
                { totalDaily: 42.1, targetTbDaily: 20.14 },
                { totalDaily: 42.1, targetTbDaily: 20.14 },
                { totalDaily: 42.11, targetTbDaily: 20.14 },
                { totalDaily: 42.12, targetTbDaily: 20.16 },
                { totalDaily: 42.13, targetTbDaily: 20.16 },
                { totalDaily: 42.14, targetTbDaily: 20.16 },
                { totalDaily: 42.16, targetTbDaily: 20.18 },
                { totalDaily: 42.17, targetTbDaily: 20.18 },
                { totalDaily: 42.18, targetTbDaily: 20.18 },
                { totalDaily: 42.19, targetTbDaily: 20.2 },
                { totalDaily: 42.2, targetTbDaily: 20.2 },
                { totalDaily: 42.22, targetTbDaily: 20.2 },
                { totalDaily: 42.22, targetTbDaily: 20.2 },
                { totalDaily: 42.23, targetTbDaily: 20.21 },
                { totalDaily: 42.25, targetTbDaily: 20.23 },
                { totalDaily: 42.26, targetTbDaily: 20.23 },
                { totalDaily: 42.26, targetTbDaily: 20.23 },
                { totalDaily: 42.28, targetTbDaily: 20.24 },
                { totalDaily: 42.29, targetTbDaily: 20.25 },
                { totalDaily: 42.3, targetTbDaily: 20.25 },
                { totalDaily: 42.31, targetTbDaily: 20.25 },
                { totalDaily: 42.32, targetTbDaily: 20.25 },
                { totalDaily: 42.33, targetTbDaily: 20.25 },
                { totalDaily: 42.33, targetTbDaily: 20.25 },
                { totalDaily: 42.36, targetTbDaily: 20.27 },
                { totalDaily: 42.37, targetTbDaily: 20.28 },
                { totalDaily: 42.38, targetTbDaily: 20.28 },
                { totalDaily: 42.38, targetTbDaily: 20.28 },
                { totalDaily: 42.39, targetTbDaily: 20.28 },
                { totalDaily: 42.41, targetTbDaily: 20.29 },
                { totalDaily: 42.42, targetTbDaily: 20.29 },
                { totalDaily: 42.43, targetTbDaily: 20.29 },
                { totalDaily: 42.45, targetTbDaily: 20.31 },
                { totalDaily: 42.46, targetTbDaily: 20.32 },
                { totalDaily: 42.46, targetTbDaily: 20.32 },
                { totalDaily: 42.47, targetTbDaily: 20.32 },
                { totalDaily: 42.49, targetTbDaily: 20.34 },
                { totalDaily: 42.5, targetTbDaily: 20.34 },
                { totalDaily: 42.51, targetTbDaily: 20.34 },
                { totalDaily: 42.53, targetTbDaily: 20.35 },
                { totalDaily: 42.55, targetTbDaily: 20.36 },
                { totalDaily: 42.56, targetTbDaily: 20.38 },
                { totalDaily: 42.57, targetTbDaily: 20.38 },
                { totalDaily: 42.58, targetTbDaily: 20.38 },
                { totalDaily: 42.59, targetTbDaily: 20.38 },
                { totalDaily: 42.59, targetTbDaily: 20.38 },
                { totalDaily: 42.59, targetTbDaily: 20.39 },
                { totalDaily: 42.61, targetTbDaily: 20.39 },
                { totalDaily: 42.62, targetTbDaily: 20.39 },
                { totalDaily: 42.62, targetTbDaily: 20.39 },
                { totalDaily: 42.63, targetTbDaily: 20.39 },
                { totalDaily: 42.66, targetTbDaily: 20.42 },
                { totalDaily: 42.66, targetTbDaily: 20.42 },
                { totalDaily: 42.67, targetTbDaily: 20.42 },
                { totalDaily: 42.69, targetTbDaily: 20.43 },
                { totalDaily: 42.71, targetTbDaily: 20.43 },
                { totalDaily: 42.71, targetTbDaily: 20.43 },
                { totalDaily: 42.72, targetTbDaily: 20.45 },
                { totalDaily: 42.73, targetTbDaily: 20.46 },
                { totalDaily: 42.76, targetTbDaily: 20.47 },
                { totalDaily: 42.76, targetTbDaily: 20.47 },
                { totalDaily: 42.77, targetTbDaily: 20.47 },
                { totalDaily: 42.79, targetTbDaily: 20.49 },
                { totalDaily: 42.79, targetTbDaily: 20.49 },
                { totalDaily: 42.81, targetTbDaily: 20.49 },
                { totalDaily: 42.83, targetTbDaily: 20.5 },
                { totalDaily: 42.84, targetTbDaily: 20.5 },
                { totalDaily: 42.84, targetTbDaily: 20.5 },
                { totalDaily: 42.85, targetTbDaily: 20.5 },
                { totalDaily: 42.86, targetTbDaily: 20.51 },
                { totalDaily: 42.87, targetTbDaily: 20.51 },
                { totalDaily: 42.87, targetTbDaily: 20.51 },
                { totalDaily: 42.89, targetTbDaily: 20.52 },
                { totalDaily: 42.9, targetTbDaily: 20.52 },
                { totalDaily: 42.9, targetTbDaily: 20.52 },
                { totalDaily: 42.91, targetTbDaily: 20.53 },
                { totalDaily: 42.93, targetTbDaily: 20.54 },
                { totalDaily: 42.95, targetTbDaily: 20.56 },
                { totalDaily: 42.95, targetTbDaily: 20.56 },
                { totalDaily: 42.96, targetTbDaily: 20.56 },
                { totalDaily: 42.97, targetTbDaily: 20.57 },
                { totalDaily: 42.99, targetTbDaily: 20.57 },
                { totalDaily: 43, targetTbDaily: 20.58 },
                { totalDaily: 43.02, targetTbDaily: 20.6 },
                { totalDaily: 43.03, targetTbDaily: 20.6 },
                { totalDaily: 43.03, targetTbDaily: 20.6 },
                { totalDaily: 43.05, targetTbDaily: 20.61 },
                { totalDaily: 43.07, targetTbDaily: 20.62 },
                { totalDaily: 43.08, targetTbDaily: 20.62 },
                { totalDaily: 43.09, targetTbDaily: 20.63 },
                { totalDaily: 43.09, targetTbDaily: 20.63 },
                { totalDaily: 43.11, targetTbDaily: 20.63 },
                { totalDaily: 43.12, targetTbDaily: 20.63 },
                { totalDaily: 43.13, targetTbDaily: 20.64 },
                { totalDaily: 43.14, targetTbDaily: 20.65 },
                { totalDaily: 43.15, targetTbDaily: 20.65 },
                { totalDaily: 43.15, targetTbDaily: 20.65 },
                { totalDaily: 43.16, targetTbDaily: 20.65 },
                { totalDaily: 43.17, targetTbDaily: 20.66 },
                { totalDaily: 43.18, targetTbDaily: 20.67 },
                { totalDaily: 43.19, targetTbDaily: 20.67 },
                { totalDaily: 43.22, targetTbDaily: 20.68 },
                { totalDaily: 43.23, targetTbDaily: 20.69 },
                { totalDaily: 43.23, targetTbDaily: 20.69 },
                { totalDaily: 43.25, targetTbDaily: 20.69 },
                { totalDaily: 43.26, targetTbDaily: 20.7 },
                { totalDaily: 43.28, targetTbDaily: 20.71 },
                { totalDaily: 43.28, targetTbDaily: 20.71 },
                { totalDaily: 43.3, targetTbDaily: 20.72 },
                { totalDaily: 43.31, targetTbDaily: 20.72 },
                { totalDaily: 43.32, targetTbDaily: 20.72 },
                { totalDaily: 43.34, targetTbDaily: 20.75 },
                { totalDaily: 43.35, targetTbDaily: 20.75 },
                { totalDaily: 43.36, targetTbDaily: 20.75 },
                { totalDaily: 43.36, targetTbDaily: 20.75 },
                { totalDaily: 43.38, targetTbDaily: 20.76 },
                { totalDaily: 43.39, targetTbDaily: 20.77 },
                { totalDaily: 43.41, targetTbDaily: 20.78 },
                { totalDaily: 43.41, targetTbDaily: 20.78 },
                { totalDaily: 43.43, targetTbDaily: 20.79 },
                { totalDaily: 43.44, targetTbDaily: 20.79 },
                { totalDaily: 43.44, targetTbDaily: 20.79 },
                { totalDaily: 43.46, targetTbDaily: 20.8 },
                { totalDaily: 43.47, targetTbDaily: 20.81 },
                { totalDaily: 43.48, targetTbDaily: 20.81 },
                { totalDaily: 43.48, targetTbDaily: 20.81 },
                { totalDaily: 43.49, targetTbDaily: 20.82 },
                { totalDaily: 43.5, targetTbDaily: 20.82 },
                { totalDaily: 43.52, targetTbDaily: 20.83 },
                { totalDaily: 43.54, targetTbDaily: 20.84 },
                { totalDaily: 43.55, targetTbDaily: 20.85 },
                { totalDaily: 43.56, targetTbDaily: 20.85 },
                { totalDaily: 43.57, targetTbDaily: 20.85 },
                { totalDaily: 43.58, targetTbDaily: 20.86 },
                { totalDaily: 43.59, targetTbDaily: 20.86 },
                { totalDaily: 43.61, targetTbDaily: 20.86 },
                { totalDaily: 43.61, targetTbDaily: 20.86 },
                { totalDaily: 43.63, targetTbDaily: 20.88 },
                { totalDaily: 43.64, targetTbDaily: 20.88 },
                { totalDaily: 43.64, targetTbDaily: 20.88 },
                { totalDaily: 43.65, targetTbDaily: 20.88 },
                { totalDaily: 43.66, targetTbDaily: 20.89 },
                { totalDaily: 43.68, targetTbDaily: 20.91 },
                { totalDaily: 43.68, targetTbDaily: 20.91 },
                { totalDaily: 43.69, targetTbDaily: 20.91 },
                { totalDaily: 43.73, targetTbDaily: 20.93 },
                { totalDaily: 43.73, targetTbDaily: 20.93 },
                { totalDaily: 43.74, targetTbDaily: 20.93 },
                { totalDaily: 43.75, targetTbDaily: 20.94 },
                { totalDaily: 43.77, targetTbDaily: 20.95 },
                { totalDaily: 43.77, targetTbDaily: 20.95 },
                { totalDaily: 43.78, targetTbDaily: 20.95 },
                { totalDaily: 43.79, targetTbDaily: 20.96 },
                { totalDaily: 43.8, targetTbDaily: 20.96 },
                { totalDaily: 43.82, targetTbDaily: 20.97 },
                { totalDaily: 43.83, targetTbDaily: 20.98 },
                { totalDaily: 43.84, targetTbDaily: 20.99 },
                { totalDaily: 43.84, targetTbDaily: 20.99 },
                { totalDaily: 43.86, targetTbDaily: 20.99 },
                { totalDaily: 43.89, targetTbDaily: 21.01 },
                { totalDaily: 43.9, targetTbDaily: 21.01 },
                { totalDaily: 43.91, targetTbDaily: 21.02 },
                { totalDaily: 43.92, targetTbDaily: 21.03 },
                { totalDaily: 43.93, targetTbDaily: 21.03 },
                { totalDaily: 43.93, targetTbDaily: 21.03 },
                { totalDaily: 43.94, targetTbDaily: 21.03 },
                { totalDaily: 43.95, targetTbDaily: 21.04 },
                { totalDaily: 43.98, targetTbDaily: 21.04 },
                { totalDaily: 43.98, targetTbDaily: 21.04 },
                { totalDaily: 44, targetTbDaily: 21.04 },
                { totalDaily: 44.01, targetTbDaily: 21.04 },
                { totalDaily: 44.02, targetTbDaily: 21.06 },
                { totalDaily: 44.03, targetTbDaily: 21.06 },
                { totalDaily: 44.04, targetTbDaily: 21.07 },
                { totalDaily: 44.05, targetTbDaily: 21.08 },
                { totalDaily: 44.05, targetTbDaily: 21.08 },
                { totalDaily: 44.07, targetTbDaily: 21.08 },
                { totalDaily: 44.09, targetTbDaily: 21.11 },
                { totalDaily: 44.09, targetTbDaily: 21.11 },
                { totalDaily: 44.12, targetTbDaily: 21.12 },
                { totalDaily: 44.13, targetTbDaily: 21.13 },
                { totalDaily: 44.14, targetTbDaily: 21.13 },
                { totalDaily: 44.14, targetTbDaily: 21.13 },
                { totalDaily: 44.15, targetTbDaily: 21.13 },
                { totalDaily: 44.16, targetTbDaily: 21.13 },
                { totalDaily: 44.17, targetTbDaily: 21.14 },
                { totalDaily: 44.18, targetTbDaily: 21.14 },
                { totalDaily: 44.2, targetTbDaily: 21.16 },
                { totalDaily: 44.21, targetTbDaily: 21.16 },
                { totalDaily: 44.21, targetTbDaily: 21.16 },
                { totalDaily: 44.23, targetTbDaily: 21.17 },
                { totalDaily: 44.23, targetTbDaily: 21.18 },
                { totalDaily: 44.26, targetTbDaily: 21.19 },
                { totalDaily: 44.27, targetTbDaily: 21.19 },
                { totalDaily: 44.28, targetTbDaily: 21.19 },
                { totalDaily: 44.29, targetTbDaily: 21.19 },
                { totalDaily: 44.3, targetTbDaily: 21.2 },
                { totalDaily: 44.31, targetTbDaily: 21.21 },
                { totalDaily: 44.32, targetTbDaily: 21.22 },
                { totalDaily: 44.33, targetTbDaily: 21.22 },
                { totalDaily: 44.35, targetTbDaily: 21.22 },
                { totalDaily: 44.36, targetTbDaily: 21.24 },
                { totalDaily: 44.37, targetTbDaily: 21.24 },
                { totalDaily: 44.37, targetTbDaily: 21.24 },
                { totalDaily: 44.4, targetTbDaily: 21.24 },
                { totalDaily: 44.41, targetTbDaily: 21.24 },
                { totalDaily: 44.42, targetTbDaily: 21.24 },
                { totalDaily: 44.43, targetTbDaily: 21.26 },
                { totalDaily: 44.44, targetTbDaily: 21.27 },
                { totalDaily: 44.45, targetTbDaily: 21.27 },
                { totalDaily: 44.45, targetTbDaily: 21.27 },
                { totalDaily: 44.47, targetTbDaily: 21.28 },
                { totalDaily: 44.49, targetTbDaily: 21.3 },
                { totalDaily: 44.5, targetTbDaily: 21.3 },
                { totalDaily: 44.51, targetTbDaily: 21.3 },
                { totalDaily: 44.52, targetTbDaily: 21.31 },
                { totalDaily: 44.53, targetTbDaily: 21.32 },
                { totalDaily: 44.54, targetTbDaily: 21.32 },
                { totalDaily: 44.56, targetTbDaily: 21.32 },
                { totalDaily: 44.58, targetTbDaily: 21.34 },
                { totalDaily: 44.59, targetTbDaily: 21.35 },
                { totalDaily: 44.6, targetTbDaily: 21.35 },
                { totalDaily: 44.62, targetTbDaily: 21.36 },
                { totalDaily: 44.63, targetTbDaily: 21.36 },
                { totalDaily: 44.63, targetTbDaily: 21.36 },
                { totalDaily: 44.64, targetTbDaily: 21.36 },
                { totalDaily: 44.65, targetTbDaily: 21.36 },
                { totalDaily: 44.66, targetTbDaily: 21.36 },
                { totalDaily: 55.65, targetTbDaily: 26.64 },
                { totalDaily: 55.66, targetTbDaily: 26.65 },
                { totalDaily: 55.67, targetTbDaily: 26.65 },
                { totalDaily: 55.67, targetTbDaily: 26.65 },
                { totalDaily: 55.7, targetTbDaily: 26.66 },
                { totalDaily: 55.71, targetTbDaily: 26.66 },
                { totalDaily: 55.74, targetTbDaily: 26.67 },
                { totalDaily: 55.75, targetTbDaily: 26.67 },
                { totalDaily: 55.78, targetTbDaily: 26.7 },
                { totalDaily: 55.8, targetTbDaily: 26.71 },
                { totalDaily: 55.81, targetTbDaily: 26.72 },
                { totalDaily: 55.82, targetTbDaily: 26.72 },
                { totalDaily: 55.83, targetTbDaily: 26.72 },
                { totalDaily: 55.84, targetTbDaily: 26.73 },
                { totalDaily: 55.86, targetTbDaily: 26.73 },
                { totalDaily: 55.88, targetTbDaily: 26.75 },
                { totalDaily: 55.91, targetTbDaily: 26.76 },
                { totalDaily: 55.91, targetTbDaily: 26.76 },
                { totalDaily: 55.92, targetTbDaily: 26.77 },
                { totalDaily: 55.93, targetTbDaily: 26.78 },
                { totalDaily: 55.94, targetTbDaily: 26.78 },
                { totalDaily: 55.96, targetTbDaily: 26.79 },
                { totalDaily: 55.97, targetTbDaily: 26.79 },
                { totalDaily: 55.98, targetTbDaily: 26.79 },
                { totalDaily: 55.98, targetTbDaily: 26.79 },
                { totalDaily: 55.98, targetTbDaily: 26.79 },
                { totalDaily: 56.02, targetTbDaily: 26.82 },
                { totalDaily: 56.04, targetTbDaily: 26.83 },
                { totalDaily: 56.06, targetTbDaily: 26.83 },
                { totalDaily: 56.07, targetTbDaily: 26.84 },
                { totalDaily: 56.08, targetTbDaily: 26.84 },
                { totalDaily: 56.09, targetTbDaily: 26.85 },
                { totalDaily: 56.09, targetTbDaily: 26.85 },
                { totalDaily: 56.14, targetTbDaily: 26.87 },
                { totalDaily: 56.14, targetTbDaily: 26.87 },
                { totalDaily: 56.17, targetTbDaily: 26.88 },
                { totalDaily: 56.18, targetTbDaily: 26.89 },
                { totalDaily: 56.2, targetTbDaily: 26.91 },
                { totalDaily: 56.2, targetTbDaily: 26.91 },
                { totalDaily: 56.23, targetTbDaily: 26.92 },
                { totalDaily: 56.26, targetTbDaily: 26.92 },
                { totalDaily: 56.26, targetTbDaily: 26.92 },
                { totalDaily: 56.27, targetTbDaily: 26.93 },
                { totalDaily: 56.28, targetTbDaily: 26.94 },
                { totalDaily: 56.29, targetTbDaily: 26.94 },
                { totalDaily: 56.29, targetTbDaily: 26.94 },
                { totalDaily: 56.31, targetTbDaily: 26.96 },
                { totalDaily: 56.33, targetTbDaily: 26.96 },
                { totalDaily: 56.34, targetTbDaily: 26.97 },
                { totalDaily: 56.35, targetTbDaily: 26.97 },
                { totalDaily: 56.38, targetTbDaily: 26.99 },
                { totalDaily: 56.41, targetTbDaily: 27 },
                { totalDaily: 56.41, targetTbDaily: 27 },
                { totalDaily: 56.42, targetTbDaily: 27.01 },
                { totalDaily: 56.44, targetTbDaily: 27.01 },
                { totalDaily: 56.44, targetTbDaily: 27.01 },
                { totalDaily: 56.47, targetTbDaily: 27.03 },
                { totalDaily: 56.48, targetTbDaily: 27.04 },
                { totalDaily: 56.48, targetTbDaily: 27.04 },
                { totalDaily: 56.52, targetTbDaily: 27.06 },
                { totalDaily: 56.54, targetTbDaily: 27.07 },
                { totalDaily: 56.55, targetTbDaily: 27.07 },
                { totalDaily: 56.56, targetTbDaily: 27.08 },
                { totalDaily: 56.58, targetTbDaily: 27.09 },
                { totalDaily: 56.59, targetTbDaily: 27.1 },
                { totalDaily: 56.61, targetTbDaily: 27.1 },
                { totalDaily: 56.62, targetTbDaily: 27.1 },
                { totalDaily: 56.64, targetTbDaily: 27.11 },
                { totalDaily: 56.65, targetTbDaily: 27.11 },
                { totalDaily: 56.65, targetTbDaily: 27.11 },
                { totalDaily: 56.68, targetTbDaily: 27.13 },
                { totalDaily: 56.71, targetTbDaily: 27.14 },
                { totalDaily: 56.71, targetTbDaily: 27.14 },
                { totalDaily: 56.72, targetTbDaily: 27.15 },
                { totalDaily: 56.73, targetTbDaily: 27.16 },
                { totalDaily: 56.73, targetTbDaily: 27.16 },
                { totalDaily: 56.76, targetTbDaily: 27.16 },
                { totalDaily: 56.79, targetTbDaily: 27.18 },
                { totalDaily: 56.79, targetTbDaily: 27.18 },
                { totalDaily: 56.79, targetTbDaily: 27.18 },
                { totalDaily: 56.81, targetTbDaily: 27.19 },
                { totalDaily: 56.85, targetTbDaily: 27.21 },
                { totalDaily: 56.86, targetTbDaily: 27.22 },
                { totalDaily: 56.88, targetTbDaily: 27.22 },
                { totalDaily: 56.89, targetTbDaily: 27.23 },
                { totalDaily: 56.9, targetTbDaily: 27.23 },
                { totalDaily: 56.9, targetTbDaily: 27.23 },
                { totalDaily: 56.92, targetTbDaily: 27.24 },
                { totalDaily: 56.95, targetTbDaily: 27.26 },
                { totalDaily: 56.95, targetTbDaily: 27.27 },
                { totalDaily: 56.95, targetTbDaily: 27.27 },
                { totalDaily: 56.97, targetTbDaily: 27.28 },
                { totalDaily: 57.01, targetTbDaily: 27.29 },
                { totalDaily: 57.02, targetTbDaily: 27.29 },
                { totalDaily: 57.04, targetTbDaily: 27.29 },
                { totalDaily: 57.05, targetTbDaily: 27.29 },
                { totalDaily: 57.05, targetTbDaily: 27.29 },
                { totalDaily: 57.06, targetTbDaily: 27.31 },
                { totalDaily: 57.09, targetTbDaily: 27.33 },
                { totalDaily: 57.11, targetTbDaily: 27.34 },
                { totalDaily: 57.12, targetTbDaily: 27.34 },
                { totalDaily: 57.14, targetTbDaily: 27.35 },
                { totalDaily: 57.15, targetTbDaily: 27.35 },
                { totalDaily: 57.16, targetTbDaily: 27.36 },
                { totalDaily: 57.18, targetTbDaily: 27.36 },
                { totalDaily: 57.21, targetTbDaily: 27.39 },
                { totalDaily: 57.22, targetTbDaily: 27.39 },
                { totalDaily: 57.23, targetTbDaily: 27.4 },
                { totalDaily: 57.23, targetTbDaily: 27.4 },
                { totalDaily: 57.27, targetTbDaily: 27.41 },
                { totalDaily: 57.27, targetTbDaily: 27.41 },
                { totalDaily: 57.29, targetTbDaily: 27.43 },
                { totalDaily: 57.31, targetTbDaily: 27.43 },
                { totalDaily: 57.33, targetTbDaily: 27.44 },
                { totalDaily: 57.33, targetTbDaily: 27.44 },
                { totalDaily: 57.34, targetTbDaily: 27.45 },
                { totalDaily: 57.36, targetTbDaily: 27.46 },
                { totalDaily: 57.38, targetTbDaily: 27.46 },
                { totalDaily: 57.4, targetTbDaily: 27.47 },
                { totalDaily: 57.41, targetTbDaily: 27.48 },
                { totalDaily: 57.42, targetTbDaily: 27.49 },
                { totalDaily: 57.44, targetTbDaily: 27.49 },
                { totalDaily: 57.45, targetTbDaily: 27.51 },
                { totalDaily: 57.47, targetTbDaily: 27.51 },
                { totalDaily: 57.5, targetTbDaily: 27.52 },
                { totalDaily: 57.51, targetTbDaily: 27.53 },
                { totalDaily: 57.52, targetTbDaily: 27.53 },
                { totalDaily: 57.52, targetTbDaily: 27.53 },
                { totalDaily: 57.54, targetTbDaily: 27.54 },
                { totalDaily: 57.56, targetTbDaily: 27.54 },
                { totalDaily: 57.56, targetTbDaily: 27.54 },
                { totalDaily: 57.58, targetTbDaily: 27.56 },
                { totalDaily: 57.6, targetTbDaily: 27.58 },
                { totalDaily: 57.61, targetTbDaily: 27.58 },
                { totalDaily: 57.63, targetTbDaily: 27.59 },
                { totalDaily: 57.64, targetTbDaily: 27.59 },
                { totalDaily: 57.67, targetTbDaily: 27.6 },
                { totalDaily: 57.67, targetTbDaily: 27.6 },
                { totalDaily: 57.68, targetTbDaily: 27.6 },
                { totalDaily: 57.7, targetTbDaily: 27.61 },
                { totalDaily: 57.7, targetTbDaily: 27.61 },
                { totalDaily: 57.74, targetTbDaily: 27.64 },
                { totalDaily: 57.76, targetTbDaily: 27.65 },
                { totalDaily: 57.77, targetTbDaily: 27.66 },
                { totalDaily: 57.78, targetTbDaily: 27.66 },
                { totalDaily: 57.79, targetTbDaily: 27.66 },
                { totalDaily: 57.81, targetTbDaily: 27.67 },
                { totalDaily: 57.81, targetTbDaily: 27.67 },
                { totalDaily: 57.84, targetTbDaily: 27.68 },
                { totalDaily: 57.87, targetTbDaily: 27.69 },
                { totalDaily: 57.87, targetTbDaily: 27.69 },
                { totalDaily: 57.88, targetTbDaily: 27.7 },
                { totalDaily: 57.9, targetTbDaily: 27.72 },
                { totalDaily: 57.91, targetTbDaily: 27.72 },
                { totalDaily: 57.93, targetTbDaily: 27.73 },
                { totalDaily: 57.94, targetTbDaily: 27.73 },
                { totalDaily: 57.97, targetTbDaily: 27.74 },
                { totalDaily: 57.97, targetTbDaily: 27.74 },
                { totalDaily: 57.99, targetTbDaily: 27.75 },
                { totalDaily: 58.01, targetTbDaily: 27.77 },
                { totalDaily: 58.02, targetTbDaily: 27.77 },
                { totalDaily: 58.04, targetTbDaily: 27.78 },
                { totalDaily: 58.07, targetTbDaily: 27.8 },
                { totalDaily: 58.09, targetTbDaily: 27.81 },
                { totalDaily: 58.09, targetTbDaily: 27.81 },
                { totalDaily: 58.09, targetTbDaily: 27.82 },
                { totalDaily: 58.13, targetTbDaily: 27.83 },
                { totalDaily: 58.13, targetTbDaily: 27.83 },
                { totalDaily: 58.15, targetTbDaily: 27.83 },
                { totalDaily: 58.17, targetTbDaily: 27.84 },
                { totalDaily: 58.18, targetTbDaily: 27.85 },
                { totalDaily: 58.19, targetTbDaily: 27.85 },
                { totalDaily: 58.2, targetTbDaily: 27.86 },
                { totalDaily: 58.22, targetTbDaily: 27.86 },
                { totalDaily: 58.23, targetTbDaily: 27.86 },
                { totalDaily: 58.25, targetTbDaily: 27.88 },
                { totalDaily: 58.26, targetTbDaily: 27.89 },
                { totalDaily: 58.28, targetTbDaily: 27.89 },
                { totalDaily: 58.3, targetTbDaily: 27.9 },
                { totalDaily: 58.32, targetTbDaily: 27.91 },
                { totalDaily: 58.34, targetTbDaily: 27.92 },
                { totalDaily: 58.34, targetTbDaily: 27.93 },
                { totalDaily: 58.37, targetTbDaily: 27.95 },
                { totalDaily: 58.39, targetTbDaily: 27.95 },
                { totalDaily: 58.39, targetTbDaily: 27.95 },
                { totalDaily: 58.42, targetTbDaily: 27.96 },
                { totalDaily: 58.43, targetTbDaily: 27.96 },
                { totalDaily: 58.43, targetTbDaily: 27.96 },
                { totalDaily: 58.45, targetTbDaily: 27.97 },
                { totalDaily: 58.45, targetTbDaily: 27.98 },
                { totalDaily: 58.49, targetTbDaily: 28 },
                { totalDaily: 58.5, targetTbDaily: 28 },
                { totalDaily: 58.51, targetTbDaily: 28.01 },
                { totalDaily: 58.53, targetTbDaily: 28.02 },
                { totalDaily: 58.53, targetTbDaily: 28.02 },
                { totalDaily: 58.55, targetTbDaily: 28.03 },
                { totalDaily: 58.56, targetTbDaily: 28.03 },
                { totalDaily: 58.56, targetTbDaily: 28.04 },
                { totalDaily: 58.59, targetTbDaily: 28.04 },
                { totalDaily: 58.61, targetTbDaily: 28.04 },
                { totalDaily: 58.62, targetTbDaily: 28.04 },
                { totalDaily: 58.64, targetTbDaily: 28.06 },
                { totalDaily: 58.67, targetTbDaily: 28.08 },
                { totalDaily: 58.68, targetTbDaily: 28.09 },
                { totalDaily: 58.7, targetTbDaily: 28.09 },
                { totalDaily: 58.72, targetTbDaily: 28.11 },
                { totalDaily: 58.73, targetTbDaily: 28.11 },
                { totalDaily: 58.73, targetTbDaily: 28.11 },
                { totalDaily: 58.76, targetTbDaily: 28.12 },
                { totalDaily: 58.79, targetTbDaily: 28.14 },
                { totalDaily: 58.79, targetTbDaily: 28.14 },
                { totalDaily: 58.81, targetTbDaily: 28.15 },
                { totalDaily: 58.83, targetTbDaily: 28.15 },
                { totalDaily: 58.83, targetTbDaily: 28.16 },
                { totalDaily: 58.85, targetTbDaily: 28.16 },
                { totalDaily: 58.86, targetTbDaily: 28.17 },
                { totalDaily: 58.87, targetTbDaily: 28.17 },
                { totalDaily: 58.88, targetTbDaily: 28.18 },
                { totalDaily: 58.89, targetTbDaily: 28.18 },
                { totalDaily: 58.92, targetTbDaily: 28.2 },
                { totalDaily: 58.93, targetTbDaily: 28.21 },
                { totalDaily: 58.95, targetTbDaily: 28.21 },
                { totalDaily: 58.98, targetTbDaily: 28.22 },
                { totalDaily: 58.98, targetTbDaily: 28.22 },
                { totalDaily: 59, targetTbDaily: 28.22 },
                { totalDaily: 59.02, targetTbDaily: 28.25 },
                { totalDaily: 59.04, targetTbDaily: 28.27 },
                { totalDaily: 59.04, targetTbDaily: 28.27 },
                { totalDaily: 59.07, targetTbDaily: 28.28 },
                { totalDaily: 59.09, targetTbDaily: 28.29 },
                { totalDaily: 59.1, targetTbDaily: 28.29 },
                { totalDaily: 59.13, targetTbDaily: 28.3 },
                { totalDaily: 59.14, targetTbDaily: 28.31 },
                { totalDaily: 59.16, targetTbDaily: 28.32 },
                { totalDaily: 59.17, targetTbDaily: 28.32 },
                { totalDaily: 59.18, targetTbDaily: 28.33 },
                { totalDaily: 59.19, targetTbDaily: 28.33 },
                { totalDaily: 59.2, targetTbDaily: 28.34 },
                { totalDaily: 59.2, targetTbDaily: 28.34 },
                { totalDaily: 59.25, targetTbDaily: 28.36 },
                { totalDaily: 59.25, targetTbDaily: 28.36 },
                { totalDaily: 59.26, targetTbDaily: 28.36 },
                { totalDaily: 59.28, targetTbDaily: 28.36 },
                { totalDaily: 59.29, targetTbDaily: 28.38 },
                { totalDaily: 59.3, targetTbDaily: 28.38 },
                { totalDaily: 59.32, targetTbDaily: 28.4 },
                { totalDaily: 59.34, targetTbDaily: 28.41 },
                { totalDaily: 59.36, targetTbDaily: 28.41 },
                { totalDaily: 59.37, targetTbDaily: 28.41 },
                { totalDaily: 59.41, targetTbDaily: 28.44 },
                { totalDaily: 59.41, targetTbDaily: 28.44 },
                { totalDaily: 59.43, targetTbDaily: 28.45 },
                { totalDaily: 59.45, targetTbDaily: 28.46 },
                { totalDaily: 59.45, targetTbDaily: 28.46 },
                { totalDaily: 59.46, targetTbDaily: 28.46 },
                { totalDaily: 59.48, targetTbDaily: 28.47 },
                { totalDaily: 59.48, targetTbDaily: 28.47 },
                { totalDaily: 59.51, targetTbDaily: 28.48 },
                { totalDaily: 59.52, targetTbDaily: 28.49 },
                { totalDaily: 59.55, targetTbDaily: 28.5 },
                { totalDaily: 59.56, targetTbDaily: 28.51 },
                { totalDaily: 59.58, targetTbDaily: 28.52 },
                { totalDaily: 59.6, targetTbDaily: 28.53 },
                { totalDaily: 59.6, targetTbDaily: 28.53 },
                { totalDaily: 59.62, targetTbDaily: 28.53 },
                { totalDaily: 59.64, targetTbDaily: 28.54 },
                { totalDaily: 59.64, targetTbDaily: 28.54 },
                { totalDaily: 59.67, targetTbDaily: 28.54 },
                { totalDaily: 59.68, targetTbDaily: 28.56 },
                { totalDaily: 59.68, targetTbDaily: 28.57 },
                { totalDaily: 59.71, targetTbDaily: 28.58 },
                { totalDaily: 59.75, targetTbDaily: 28.6 },
                { totalDaily: 59.77, targetTbDaily: 28.61 },
                { totalDaily: 59.78, targetTbDaily: 28.61 },
                { totalDaily: 59.79, targetTbDaily: 28.61 },
                { totalDaily: 59.8, targetTbDaily: 28.63 },
                { totalDaily: 59.8, targetTbDaily: 28.63 },
                { totalDaily: 59.83, targetTbDaily: 28.64 },
                { totalDaily: 59.84, targetTbDaily: 28.65 },
                { totalDaily: 59.84, targetTbDaily: 28.65 },
                { totalDaily: 59.86, targetTbDaily: 28.65 },
                { totalDaily: 59.9, targetTbDaily: 28.67 },
                { totalDaily: 59.91, targetTbDaily: 28.67 },
                { totalDaily: 59.92, targetTbDaily: 28.68 },
                { totalDaily: 59.93, targetTbDaily: 28.69 },
                { totalDaily: 59.95, targetTbDaily: 28.7 },
                { totalDaily: 59.95, targetTbDaily: 28.71 },
                { totalDaily: 59.99, targetTbDaily: 28.71 },
                { totalDaily: 60, targetTbDaily: 28.71 },
                { totalDaily: 60, targetTbDaily: 28.71 },
                { totalDaily: 60.05, targetTbDaily: 28.74 },
                { totalDaily: 60.06, targetTbDaily: 28.75 },
                { totalDaily: 60.06, targetTbDaily: 28.75 },
                { totalDaily: 60.08, targetTbDaily: 28.75 },
                { totalDaily: 60.09, targetTbDaily: 28.76 },
                { totalDaily: 60.1, targetTbDaily: 28.77 },
                { totalDaily: 60.12, targetTbDaily: 28.77 },
                { totalDaily: 60.15, targetTbDaily: 28.79 },
                { totalDaily: 60.16, targetTbDaily: 28.79 },
                { totalDaily: 60.17, targetTbDaily: 28.79 },
                { totalDaily: 60.18, targetTbDaily: 28.81 },
                { totalDaily: 60.2, targetTbDaily: 28.82 },
                { totalDaily: 60.22, targetTbDaily: 28.82 },
                { totalDaily: 60.23, targetTbDaily: 28.84 },
                { totalDaily: 60.25, targetTbDaily: 28.84 },
                { totalDaily: 60.25, targetTbDaily: 28.84 },
                { totalDaily: 60.27, targetTbDaily: 28.85 },
                { totalDaily: 60.31, targetTbDaily: 28.87 },
                { totalDaily: 60.31, targetTbDaily: 28.87 },
                { totalDaily: 60.32, targetTbDaily: 28.87 },
                { totalDaily: 60.33, targetTbDaily: 28.88 },
                { totalDaily: 60.34, targetTbDaily: 28.89 },
                { totalDaily: 60.37, targetTbDaily: 28.9 },
                { totalDaily: 60.4, targetTbDaily: 28.9 },
                { totalDaily: 60.41, targetTbDaily: 28.91 },
                { totalDaily: 60.42, targetTbDaily: 28.91 },
                { totalDaily: 60.43, targetTbDaily: 28.92 },
                { totalDaily: 60.46, targetTbDaily: 28.94 },
                { totalDaily: 60.46, targetTbDaily: 28.94 },
                { totalDaily: 60.48, targetTbDaily: 28.95 },
                { totalDaily: 60.51, targetTbDaily: 28.97 },
                { totalDaily: 60.52, targetTbDaily: 28.97 },
                { totalDaily: 60.53, targetTbDaily: 28.97 },
                { totalDaily: 60.54, targetTbDaily: 28.97 },
                { totalDaily: 60.54, targetTbDaily: 28.98 },
                { totalDaily: 60.57, targetTbDaily: 28.98 },
                { totalDaily: 60.59, targetTbDaily: 29.01 },
                { totalDaily: 60.62, targetTbDaily: 29.02 },
                { totalDaily: 60.64, targetTbDaily: 29.02 },
                { totalDaily: 60.65, targetTbDaily: 29.03 },
                { totalDaily: 60.67, targetTbDaily: 29.04 },
                { totalDaily: 60.68, targetTbDaily: 29.04 },
                { totalDaily: 60.7, targetTbDaily: 29.06 },
                { totalDaily: 60.7, targetTbDaily: 29.06 },
                { totalDaily: 60.7, targetTbDaily: 29.06 },
                { totalDaily: 60.73, targetTbDaily: 29.07 },
                { totalDaily: 60.75, targetTbDaily: 29.08 },
                { totalDaily: 60.77, targetTbDaily: 29.08 },
                { totalDaily: 60.78, targetTbDaily: 29.09 },
                { totalDaily: 60.79, targetTbDaily: 29.1 },
                { totalDaily: 60.81, targetTbDaily: 29.11 },
                { totalDaily: 60.83, targetTbDaily: 29.11 },
                { totalDaily: 60.85, targetTbDaily: 29.11 },
                { totalDaily: 60.87, targetTbDaily: 29.14 },
                { totalDaily: 60.88, targetTbDaily: 29.14 },
                { totalDaily: 60.9, targetTbDaily: 29.15 },
                { totalDaily: 60.92, targetTbDaily: 29.15 },
                { totalDaily: 60.93, targetTbDaily: 29.17 },
                { totalDaily: 60.97, targetTbDaily: 29.19 },
                { totalDaily: 60.98, targetTbDaily: 29.19 },
                { totalDaily: 60.98, targetTbDaily: 29.19 },
                { totalDaily: 60.98, targetTbDaily: 29.19 },
                { totalDaily: 61.02, targetTbDaily: 29.21 },
                { totalDaily: 61.03, targetTbDaily: 29.21 },
                { totalDaily: 61.04, targetTbDaily: 29.22 },
                { totalDaily: 61.06, targetTbDaily: 29.22 },
                { totalDaily: 61.06, targetTbDaily: 29.22 },
                { totalDaily: 61.08, targetTbDaily: 29.23 },
                { totalDaily: 61.09, targetTbDaily: 29.25 },
                { totalDaily: 61.12, targetTbDaily: 29.25 },
                { totalDaily: 61.13, targetTbDaily: 29.26 },
                { totalDaily: 61.14, targetTbDaily: 29.27 },
                { totalDaily: 61.16, targetTbDaily: 29.27 },
                { totalDaily: 61.17, targetTbDaily: 29.28 },
                { totalDaily: 61.19, targetTbDaily: 29.29 },
                { totalDaily: 72.16, targetTbDaily: 34.54 },
                { totalDaily: 72.18, targetTbDaily: 34.56 },
                { totalDaily: 72.22, targetTbDaily: 34.57 },
                { totalDaily: 72.22, targetTbDaily: 34.57 },
                { totalDaily: 72.23, targetTbDaily: 34.57 },
                { totalDaily: 72.27, targetTbDaily: 34.58 },
                { totalDaily: 72.3, targetTbDaily: 34.61 },
                { totalDaily: 72.31, targetTbDaily: 34.61 },
                { totalDaily: 72.31, targetTbDaily: 34.61 },
                { totalDaily: 72.34, targetTbDaily: 34.62 },
                { totalDaily: 72.34, targetTbDaily: 34.62 },
                { totalDaily: 72.36, targetTbDaily: 34.62 },
                { totalDaily: 72.38, targetTbDaily: 34.62 },
                { totalDaily: 72.4, targetTbDaily: 34.65 },
                { totalDaily: 72.42, targetTbDaily: 34.65 },
                { totalDaily: 72.44, targetTbDaily: 34.68 },
                { totalDaily: 72.45, targetTbDaily: 34.68 },
                { totalDaily: 72.48, targetTbDaily: 34.69 },
                { totalDaily: 72.51, targetTbDaily: 34.7 },
                { totalDaily: 72.52, targetTbDaily: 34.71 },
                { totalDaily: 72.53, targetTbDaily: 34.71 },
                { totalDaily: 72.56, targetTbDaily: 34.73 },
                { totalDaily: 72.59, targetTbDaily: 34.75 },
                { totalDaily: 72.59, targetTbDaily: 34.75 },
                { totalDaily: 72.61, targetTbDaily: 34.75 },
                { totalDaily: 72.64, targetTbDaily: 34.76 },
                { totalDaily: 72.65, targetTbDaily: 34.78 },
                { totalDaily: 72.68, targetTbDaily: 34.78 },
                { totalDaily: 72.7, targetTbDaily: 34.81 },
                { totalDaily: 72.72, targetTbDaily: 34.81 },
                { totalDaily: 72.75, targetTbDaily: 34.82 },
                { totalDaily: 72.76, targetTbDaily: 34.83 },
                { totalDaily: 72.78, targetTbDaily: 34.83 },
                { totalDaily: 72.79, targetTbDaily: 34.84 },
                { totalDaily: 72.8, targetTbDaily: 34.85 },
                { totalDaily: 72.81, targetTbDaily: 34.85 },
                { totalDaily: 72.84, targetTbDaily: 34.87 },
                { totalDaily: 72.87, targetTbDaily: 34.87 },
                { totalDaily: 72.88, targetTbDaily: 34.87 },
                { totalDaily: 72.89, targetTbDaily: 34.87 },
                { totalDaily: 72.92, targetTbDaily: 34.9 },
                { totalDaily: 72.94, targetTbDaily: 34.9 },
                { totalDaily: 72.96, targetTbDaily: 34.92 },
                { totalDaily: 72.97, targetTbDaily: 34.94 },
                { totalDaily: 73.01, targetTbDaily: 34.94 },
                { totalDaily: 73.01, targetTbDaily: 34.94 },
                { totalDaily: 73.03, targetTbDaily: 34.96 },
                { totalDaily: 73.06, targetTbDaily: 34.97 },
                { totalDaily: 73.06, targetTbDaily: 34.97 },
                { totalDaily: 73.09, targetTbDaily: 34.97 },
                { totalDaily: 73.11, targetTbDaily: 34.98 },
                { totalDaily: 73.13, targetTbDaily: 35 },
                { totalDaily: 73.15, targetTbDaily: 35.01 },
                { totalDaily: 73.16, targetTbDaily: 35.02 },
                { totalDaily: 73.19, targetTbDaily: 35.03 },
                { totalDaily: 73.2, targetTbDaily: 35.03 },
                { totalDaily: 73.22, targetTbDaily: 35.04 },
                { totalDaily: 73.25, targetTbDaily: 35.06 },
                { totalDaily: 73.26, targetTbDaily: 35.06 },
                { totalDaily: 73.28, targetTbDaily: 35.07 },
                { totalDaily: 73.3, targetTbDaily: 35.08 },
                { totalDaily: 73.33, targetTbDaily: 35.1 },
                { totalDaily: 73.34, targetTbDaily: 35.11 },
                { totalDaily: 73.36, targetTbDaily: 35.11 },
                { totalDaily: 73.37, targetTbDaily: 35.12 },
                { totalDaily: 73.4, targetTbDaily: 35.12 },
                { totalDaily: 73.45, targetTbDaily: 35.15 },
                { totalDaily: 73.45, targetTbDaily: 35.15 },
                { totalDaily: 73.47, targetTbDaily: 35.17 },
                { totalDaily: 73.47, targetTbDaily: 35.17 },
                { totalDaily: 73.49, targetTbDaily: 35.17 },
                { totalDaily: 73.52, targetTbDaily: 35.19 },
                { totalDaily: 73.54, targetTbDaily: 35.2 },
                { totalDaily: 73.56, targetTbDaily: 35.21 },
                { totalDaily: 73.58, targetTbDaily: 35.22 },
                { totalDaily: 73.59, targetTbDaily: 35.23 },
                { totalDaily: 73.61, targetTbDaily: 35.23 },
                { totalDaily: 73.63, targetTbDaily: 35.23 },
                { totalDaily: 73.65, targetTbDaily: 35.25 },
                { totalDaily: 73.66, targetTbDaily: 35.25 },
                { totalDaily: 73.67, targetTbDaily: 35.26 },
                { totalDaily: 73.71, targetTbDaily: 35.29 },
                { totalDaily: 73.72, targetTbDaily: 35.29 },
                { totalDaily: 73.75, targetTbDaily: 35.29 },
                { totalDaily: 73.78, targetTbDaily: 35.31 },
                { totalDaily: 73.8, targetTbDaily: 35.31 },
                { totalDaily: 73.81, targetTbDaily: 35.31 },
                { totalDaily: 73.83, targetTbDaily: 35.34 },
                { totalDaily: 73.84, targetTbDaily: 35.34 },
                { totalDaily: 73.86, targetTbDaily: 35.35 },
                { totalDaily: 73.88, targetTbDaily: 35.36 },
                { totalDaily: 73.91, targetTbDaily: 35.37 },
                { totalDaily: 73.91, targetTbDaily: 35.37 },
                { totalDaily: 73.93, targetTbDaily: 35.39 },
                { totalDaily: 73.95, targetTbDaily: 35.4 },
                { totalDaily: 73.98, targetTbDaily: 35.4 },
                { totalDaily: 74.01, targetTbDaily: 35.43 },
                { totalDaily: 74.02, targetTbDaily: 35.43 },
                { totalDaily: 74.03, targetTbDaily: 35.43 },
                { totalDaily: 74.05, targetTbDaily: 35.44 },
                { totalDaily: 74.08, targetTbDaily: 35.45 },
                { totalDaily: 74.08, targetTbDaily: 35.45 },
                { totalDaily: 74.09, targetTbDaily: 35.47 },
                { totalDaily: 74.12, targetTbDaily: 35.48 },
                { totalDaily: 74.13, targetTbDaily: 35.48 },
                { totalDaily: 74.15, targetTbDaily: 35.5 },
                { totalDaily: 74.2, targetTbDaily: 35.5 },
                { totalDaily: 74.22, targetTbDaily: 35.51 },
                { totalDaily: 74.23, targetTbDaily: 35.53 },
                { totalDaily: 74.25, targetTbDaily: 35.54 },
                { totalDaily: 74.25, targetTbDaily: 35.54 },
                { totalDaily: 74.28, targetTbDaily: 35.54 },
                { totalDaily: 74.3, targetTbDaily: 35.56 },
                { totalDaily: 74.31, targetTbDaily: 35.57 },
                { totalDaily: 74.33, targetTbDaily: 35.57 },
                { totalDaily: 74.36, targetTbDaily: 35.59 },
                { totalDaily: 74.38, targetTbDaily: 35.6 },
                { totalDaily: 74.4, targetTbDaily: 35.61 },
                { totalDaily: 74.42, targetTbDaily: 35.61 },
                { totalDaily: 74.45, targetTbDaily: 35.62 },
                { totalDaily: 74.47, targetTbDaily: 35.65 },
                { totalDaily: 74.48, targetTbDaily: 35.65 },
                { totalDaily: 74.51, targetTbDaily: 35.65 },
                { totalDaily: 74.52, targetTbDaily: 35.65 },
                { totalDaily: 74.55, targetTbDaily: 35.69 },
                { totalDaily: 74.56, targetTbDaily: 35.69 },
                { totalDaily: 74.56, targetTbDaily: 35.69 },
                { totalDaily: 74.59, targetTbDaily: 35.7 },
                { totalDaily: 74.62, targetTbDaily: 35.71 },
                { totalDaily: 74.64, targetTbDaily: 35.73 },
                { totalDaily: 74.66, targetTbDaily: 35.73 },
                { totalDaily: 74.67, targetTbDaily: 35.73 },
                { totalDaily: 74.69, targetTbDaily: 35.75 },
                { totalDaily: 74.72, targetTbDaily: 35.76 },
                { totalDaily: 74.75, targetTbDaily: 35.78 },
                { totalDaily: 74.76, targetTbDaily: 35.78 },
                { totalDaily: 74.77, targetTbDaily: 35.79 },
                { totalDaily: 74.78, targetTbDaily: 35.79 },
                { totalDaily: 74.8, targetTbDaily: 35.79 },
                { totalDaily: 74.84, targetTbDaily: 35.82 },
                { totalDaily: 74.87, targetTbDaily: 35.84 },
                { totalDaily: 74.88, targetTbDaily: 35.84 },
                { totalDaily: 74.9, targetTbDaily: 35.85 },
                { totalDaily: 74.92, targetTbDaily: 35.86 },
                { totalDaily: 74.94, targetTbDaily: 35.86 },
                { totalDaily: 74.95, targetTbDaily: 35.87 },
                { totalDaily: 74.98, targetTbDaily: 35.87 },
                { totalDaily: 74.98, targetTbDaily: 35.87 },
                { totalDaily: 74.98, targetTbDaily: 35.89 },
                { totalDaily: 75.02, targetTbDaily: 35.9 },
                { totalDaily: 75.04, targetTbDaily: 35.92 },
                { totalDaily: 75.06, targetTbDaily: 35.92 },
                { totalDaily: 75.08, targetTbDaily: 35.93 },
                { totalDaily: 75.09, targetTbDaily: 35.94 },
                { totalDaily: 75.12, targetTbDaily: 35.95 },
                { totalDaily: 75.13, targetTbDaily: 35.96 },
                { totalDaily: 75.16, targetTbDaily: 35.97 },
                { totalDaily: 75.17, targetTbDaily: 35.99 },
                { totalDaily: 75.21, targetTbDaily: 35.99 },
                { totalDaily: 75.22, targetTbDaily: 36.01 },
                { totalDaily: 75.23, targetTbDaily: 36.01 },
                { totalDaily: 75.27, targetTbDaily: 36.03 },
                { totalDaily: 75.28, targetTbDaily: 36.04 },
                { totalDaily: 75.31, targetTbDaily: 36.04 },
                { totalDaily: 75.33, targetTbDaily: 36.04 },
                { totalDaily: 75.34, targetTbDaily: 36.04 },
                { totalDaily: 75.37, targetTbDaily: 36.07 },
                { totalDaily: 75.38, targetTbDaily: 36.08 },
                { totalDaily: 75.4, targetTbDaily: 36.09 },
                { totalDaily: 75.41, targetTbDaily: 36.09 },
                { totalDaily: 75.42, targetTbDaily: 36.1 },
                { totalDaily: 75.44, targetTbDaily: 36.12 },
                { totalDaily: 75.48, targetTbDaily: 36.12 },
                { totalDaily: 75.51, targetTbDaily: 36.14 },
                { totalDaily: 75.52, targetTbDaily: 36.14 },
                { totalDaily: 75.55, targetTbDaily: 36.15 },
                { totalDaily: 75.56, targetTbDaily: 36.17 },
                { totalDaily: 75.58, targetTbDaily: 36.17 },
                { totalDaily: 75.59, targetTbDaily: 36.18 },
                { totalDaily: 75.62, targetTbDaily: 36.2 },
                { totalDaily: 75.64, targetTbDaily: 36.2 },
                { totalDaily: 75.65, targetTbDaily: 36.2 },
                { totalDaily: 75.69, targetTbDaily: 36.24 },
                { totalDaily: 75.72, targetTbDaily: 36.24 },
                { totalDaily: 75.73, targetTbDaily: 36.24 },
                { totalDaily: 75.76, targetTbDaily: 36.25 },
                { totalDaily: 75.77, targetTbDaily: 36.26 },
                { totalDaily: 75.77, targetTbDaily: 36.26 },
                { totalDaily: 75.8, targetTbDaily: 36.28 },
                { totalDaily: 75.81, targetTbDaily: 36.29 },
                { totalDaily: 75.81, targetTbDaily: 36.29 },
                { totalDaily: 75.84, targetTbDaily: 36.31 },
                { totalDaily: 75.88, targetTbDaily: 36.31 },
                { totalDaily: 75.88, targetTbDaily: 36.31 },
                { totalDaily: 75.9, targetTbDaily: 36.33 },
                { totalDaily: 75.94, targetTbDaily: 36.34 },
                { totalDaily: 75.95, targetTbDaily: 36.34 },
                { totalDaily: 75.97, targetTbDaily: 36.36 },
                { totalDaily: 76, targetTbDaily: 36.37 },
                { totalDaily: 76, targetTbDaily: 36.39 },
                { totalDaily: 76.03, targetTbDaily: 36.4 },
                { totalDaily: 76.06, targetTbDaily: 36.4 },
                { totalDaily: 76.06, targetTbDaily: 36.4 },
                { totalDaily: 76.09, targetTbDaily: 36.43 },
                { totalDaily: 76.11, targetTbDaily: 36.43 },
                { totalDaily: 76.13, targetTbDaily: 36.44 },
                { totalDaily: 76.16, targetTbDaily: 36.45 },
                { totalDaily: 76.17, targetTbDaily: 36.46 },
                { totalDaily: 76.18, targetTbDaily: 36.46 },
                { totalDaily: 76.22, targetTbDaily: 36.49 },
                { totalDaily: 76.23, targetTbDaily: 36.49 },
                { totalDaily: 76.25, targetTbDaily: 36.5 },
                { totalDaily: 76.27, targetTbDaily: 36.5 },
                { totalDaily: 76.28, targetTbDaily: 36.51 },
                { totalDaily: 76.28, targetTbDaily: 36.51 },
                { totalDaily: 76.33, targetTbDaily: 36.54 },
                { totalDaily: 76.36, targetTbDaily: 36.54 },
                { totalDaily: 76.36, targetTbDaily: 36.54 },
                { totalDaily: 76.39, targetTbDaily: 36.56 },
                { totalDaily: 76.42, targetTbDaily: 36.59 },
                { totalDaily: 76.42, targetTbDaily: 36.59 },
                { totalDaily: 76.47, targetTbDaily: 36.6 },
                { totalDaily: 76.47, targetTbDaily: 36.6 },
                { totalDaily: 76.49, targetTbDaily: 36.62 },
                { totalDaily: 76.51, targetTbDaily: 36.62 },
                { totalDaily: 76.53, targetTbDaily: 36.62 },
                { totalDaily: 76.55, targetTbDaily: 36.64 },
                { totalDaily: 76.56, targetTbDaily: 36.65 },
                { totalDaily: 76.59, targetTbDaily: 36.67 },
                { totalDaily: 76.61, targetTbDaily: 36.67 },
                { totalDaily: 76.64, targetTbDaily: 36.68 },
                { totalDaily: 76.65, targetTbDaily: 36.68 },
                { totalDaily: 76.65, targetTbDaily: 36.68 },
                { totalDaily: 76.69, targetTbDaily: 36.71 },
                { totalDaily: 76.72, targetTbDaily: 36.72 },
                { totalDaily: 76.72, targetTbDaily: 36.73 },
                { totalDaily: 76.75, targetTbDaily: 36.73 },
                { totalDaily: 76.78, targetTbDaily: 36.73 },
                { totalDaily: 76.78, targetTbDaily: 36.74 },
                { totalDaily: 76.83, targetTbDaily: 36.76 },
                { totalDaily: 76.84, targetTbDaily: 36.76 },
                { totalDaily: 76.84, targetTbDaily: 36.76 },
                { totalDaily: 76.88, targetTbDaily: 36.79 },
                { totalDaily: 76.9, targetTbDaily: 36.81 },
                { totalDaily: 76.91, targetTbDaily: 36.81 },
                { totalDaily: 76.93, targetTbDaily: 36.82 },
                { totalDaily: 76.94, targetTbDaily: 36.83 },
                { totalDaily: 76.96, targetTbDaily: 36.83 },
                { totalDaily: 76.99, targetTbDaily: 36.86 },
                { totalDaily: 77.02, targetTbDaily: 36.87 },
                { totalDaily: 77.02, targetTbDaily: 36.87 },
                { totalDaily: 77.06, targetTbDaily: 36.88 },
                { totalDaily: 77.07, targetTbDaily: 36.89 },
                { totalDaily: 77.08, targetTbDaily: 36.89 },
                { totalDaily: 77.11, targetTbDaily: 36.9 },
                { totalDaily: 77.13, targetTbDaily: 36.92 },
                { totalDaily: 77.14, targetTbDaily: 36.92 },
                { totalDaily: 77.16, targetTbDaily: 36.92 },
                { totalDaily: 77.2, targetTbDaily: 36.95 },
                { totalDaily: 77.22, targetTbDaily: 36.95 },
                { totalDaily: 77.23, targetTbDaily: 36.97 },
                { totalDaily: 77.25, targetTbDaily: 36.98 },
                { totalDaily: 77.28, targetTbDaily: 36.98 },
                { totalDaily: 77.29, targetTbDaily: 37 },
                { totalDaily: 77.31, targetTbDaily: 37.01 },
                { totalDaily: 77.34, targetTbDaily: 37.01 },
                { totalDaily: 77.34, targetTbDaily: 37.03 },
                { totalDaily: 77.38, targetTbDaily: 37.04 },
                { totalDaily: 77.39, targetTbDaily: 37.04 },
                { totalDaily: 77.41, targetTbDaily: 37.04 },
                { totalDaily: 77.44, targetTbDaily: 37.06 },
                { totalDaily: 77.46, targetTbDaily: 37.07 },
                { totalDaily: 77.48, targetTbDaily: 37.09 },
                { totalDaily: 77.51, targetTbDaily: 37.09 },
                { totalDaily: 77.53, targetTbDaily: 37.11 },
                { totalDaily: 77.53, targetTbDaily: 37.12 },
                { totalDaily: 77.56, targetTbDaily: 37.12 },
                { totalDaily: 77.58, targetTbDaily: 37.12 },
                { totalDaily: 77.59, targetTbDaily: 37.12 },
                { totalDaily: 77.61, targetTbDaily: 37.15 },
                { totalDaily: 77.63, targetTbDaily: 37.15 },
                { totalDaily: 77.65, targetTbDaily: 37.17 },
                { totalDaily: 77.67, targetTbDaily: 37.18 },
                { totalDaily: 77.7, targetTbDaily: 37.19 },
                { totalDaily: 77.72, targetTbDaily: 37.2 },
                { totalDaily: 77.74, targetTbDaily: 37.21 },
                { totalDaily: 77.77, targetTbDaily: 37.22 },
                { totalDaily: 77.78, targetTbDaily: 37.23 },
                { totalDaily: 77.8, targetTbDaily: 37.23 },
                { totalDaily: 77.81, targetTbDaily: 37.23 },
                { totalDaily: 77.87, targetTbDaily: 37.26 },
                { totalDaily: 77.87, targetTbDaily: 37.26 },
                { totalDaily: 77.88, targetTbDaily: 37.26 },
                { totalDaily: 77.91, targetTbDaily: 37.28 },
                { totalDaily: 77.92, targetTbDaily: 37.28 },
                { totalDaily: 77.95, targetTbDaily: 37.29 },
                { totalDaily: 77.97, targetTbDaily: 37.31 },
                { totalDaily: 77.97, targetTbDaily: 37.32 },
                { totalDaily: 78, targetTbDaily: 37.32 },
                { totalDaily: 78.03, targetTbDaily: 37.35 },
                { totalDaily: 78.04, targetTbDaily: 37.35 },
                { totalDaily: 78.06, targetTbDaily: 37.37 },
                { totalDaily: 78.06, targetTbDaily: 37.37 },
                { totalDaily: 78.09, targetTbDaily: 37.37 },
                { totalDaily: 78.13, targetTbDaily: 37.39 },
                { totalDaily: 78.14, targetTbDaily: 37.4 },
                { totalDaily: 78.16, targetTbDaily: 37.4 },
                { totalDaily: 78.19, targetTbDaily: 37.42 },
                { totalDaily: 78.22, targetTbDaily: 37.44 },
                { totalDaily: 78.22, targetTbDaily: 37.44 },
                { totalDaily: 78.25, targetTbDaily: 37.46 },
                { totalDaily: 78.28, targetTbDaily: 37.46 },
                { totalDaily: 78.29, targetTbDaily: 37.46 },
                { totalDaily: 78.31, targetTbDaily: 37.47 },
                { totalDaily: 78.34, targetTbDaily: 37.48 },
                { totalDaily: 78.36, targetTbDaily: 37.5 },
                { totalDaily: 78.38, targetTbDaily: 37.52 },
                { totalDaily: 78.39, targetTbDaily: 37.52 },
                { totalDaily: 78.4, targetTbDaily: 37.52 },
                { totalDaily: 78.42, targetTbDaily: 37.54 },
                { totalDaily: 78.44, targetTbDaily: 37.54 },
                { totalDaily: 78.47, targetTbDaily: 37.56 },
                { totalDaily: 78.48, targetTbDaily: 37.56 },
                { totalDaily: 78.52, targetTbDaily: 37.58 },
                { totalDaily: 78.53, targetTbDaily: 37.58 },
                { totalDaily: 78.55, targetTbDaily: 37.6 },
                { totalDaily: 78.58, targetTbDaily: 37.61 },
                { totalDaily: 78.59, targetTbDaily: 37.61 },
                { totalDaily: 78.61, targetTbDaily: 37.62 },
                { totalDaily: 78.64, targetTbDaily: 37.65 },
                { totalDaily: 78.66, targetTbDaily: 37.65 },
                { totalDaily: 78.7, targetTbDaily: 37.65 },
                { totalDaily: 78.72, targetTbDaily: 37.65 },
                { totalDaily: 78.73, targetTbDaily: 37.68 },
                { totalDaily: 78.75, targetTbDaily: 37.69 },
                { totalDaily: 78.75, targetTbDaily: 37.69 },
                { totalDaily: 78.79, targetTbDaily: 37.71 },
                { totalDaily: 78.81, targetTbDaily: 37.72 },
                { totalDaily: 78.81, targetTbDaily: 37.73 },
                { totalDaily: 78.84, targetTbDaily: 37.73 },
                { totalDaily: 78.86, targetTbDaily: 37.73 },
                { totalDaily: 78.88, targetTbDaily: 37.75 },
                { totalDaily: 78.9, targetTbDaily: 37.76 },
                { totalDaily: 78.93, targetTbDaily: 37.76 },
                { totalDaily: 78.93, targetTbDaily: 37.76 },
                { totalDaily: 78.96, targetTbDaily: 37.79 },
                { totalDaily: 79, targetTbDaily: 37.81 },
                { totalDaily: 79.01, targetTbDaily: 37.81 },
                { totalDaily: 79.03, targetTbDaily: 37.83 },
                { totalDaily: 79.05, targetTbDaily: 37.83 },
                { totalDaily: 79.06, targetTbDaily: 37.85 },
                { totalDaily: 79.09, targetTbDaily: 37.85 },
                { totalDaily: 79.11, targetTbDaily: 37.85 },
                { totalDaily: 79.13, targetTbDaily: 37.87 },
                { totalDaily: 79.15, targetTbDaily: 37.87 },
                { totalDaily: 79.17, targetTbDaily: 37.89 },
                { totalDaily: 79.19, targetTbDaily: 37.9 },
                { totalDaily: 79.21, targetTbDaily: 37.9 },
                { totalDaily: 79.23, targetTbDaily: 37.93 },
                { totalDaily: 79.25, targetTbDaily: 37.93 },
                { totalDaily: 79.28, targetTbDaily: 37.94 },
                { totalDaily: 79.28, targetTbDaily: 37.95 },
                { totalDaily: 79.31, targetTbDaily: 37.95 },
                { totalDaily: 79.34, targetTbDaily: 37.98 },
                { totalDaily: 79.36, targetTbDaily: 37.99 },
                { totalDaily: 90.28, targetTbDaily: 43.2 },
                { totalDaily: 90.3, targetTbDaily: 43.22 },
                { totalDaily: 90.34, targetTbDaily: 43.25 },
                { totalDaily: 90.37, targetTbDaily: 43.25 },
                { totalDaily: 90.39, targetTbDaily: 43.25 },
                { totalDaily: 90.4, targetTbDaily: 43.27 },
                { totalDaily: 90.43, targetTbDaily: 43.29 },
                { totalDaily: 90.45, targetTbDaily: 43.3 },
                { totalDaily: 90.48, targetTbDaily: 43.31 },
                { totalDaily: 90.5, targetTbDaily: 43.33 },
                { totalDaily: 90.52, targetTbDaily: 43.33 },
                { totalDaily: 90.53, targetTbDaily: 43.34 },
                { totalDaily: 90.57, targetTbDaily: 43.35 },
                { totalDaily: 90.59, targetTbDaily: 43.36 },
                { totalDaily: 90.6, targetTbDaily: 43.36 },
                { totalDaily: 90.63, targetTbDaily: 43.38 },
                { totalDaily: 90.66, targetTbDaily: 43.39 },
                { totalDaily: 90.69, targetTbDaily: 43.39 },
                { totalDaily: 90.72, targetTbDaily: 43.42 },
                { totalDaily: 90.74, targetTbDaily: 43.43 },
                { totalDaily: 90.76, targetTbDaily: 43.44 },
                { totalDaily: 90.8, targetTbDaily: 43.46 },
                { totalDaily: 90.81, targetTbDaily: 43.46 },
                { totalDaily: 90.83, targetTbDaily: 43.47 },
                { totalDaily: 90.85, targetTbDaily: 43.48 },
                { totalDaily: 90.87, targetTbDaily: 43.49 },
                { totalDaily: 90.91, targetTbDaily: 43.52 },
                { totalDaily: 90.92, targetTbDaily: 43.52 },
                { totalDaily: 90.94, targetTbDaily: 43.53 },
                { totalDaily: 90.97, targetTbDaily: 43.54 },
                { totalDaily: 90.99, targetTbDaily: 43.55 },
                { totalDaily: 91.02, targetTbDaily: 43.56 },
                { totalDaily: 91.04, targetTbDaily: 43.58 },
                { totalDaily: 91.06, targetTbDaily: 43.58 },
                { totalDaily: 91.07, targetTbDaily: 43.58 },
                { totalDaily: 91.09, targetTbDaily: 43.58 },
                { totalDaily: 91.13, targetTbDaily: 43.62 },
                { totalDaily: 91.14, targetTbDaily: 43.62 },
                { totalDaily: 91.18, targetTbDaily: 43.64 },
                { totalDaily: 91.19, targetTbDaily: 43.65 },
                { totalDaily: 91.22, targetTbDaily: 43.66 },
                { totalDaily: 91.26, targetTbDaily: 43.67 },
                { totalDaily: 91.27, targetTbDaily: 43.68 },
                { totalDaily: 91.29, targetTbDaily: 43.69 },
                { totalDaily: 91.31, targetTbDaily: 43.7 },
                { totalDaily: 91.34, targetTbDaily: 43.72 },
                { totalDaily: 91.37, targetTbDaily: 43.73 },
                { totalDaily: 91.38, targetTbDaily: 43.73 },
                { totalDaily: 91.39, targetTbDaily: 43.73 },
                { totalDaily: 91.43, targetTbDaily: 43.77 },
                { totalDaily: 91.47, targetTbDaily: 43.77 },
                { totalDaily: 91.5, targetTbDaily: 43.79 },
                { totalDaily: 91.52, targetTbDaily: 43.81 },
                { totalDaily: 91.54, targetTbDaily: 43.81 },
                { totalDaily: 91.57, targetTbDaily: 43.83 },
                { totalDaily: 91.59, targetTbDaily: 43.84 },
                { totalDaily: 91.61, targetTbDaily: 43.84 },
                { totalDaily: 91.63, targetTbDaily: 43.84 },
                { totalDaily: 91.65, targetTbDaily: 43.87 },
                { totalDaily: 91.68, targetTbDaily: 43.88 },
                { totalDaily: 91.7, targetTbDaily: 43.89 },
                { totalDaily: 91.72, targetTbDaily: 43.91 },
                { totalDaily: 91.74, targetTbDaily: 43.91 },
                { totalDaily: 91.77, targetTbDaily: 43.91 },
                { totalDaily: 91.8, targetTbDaily: 43.92 },
                { totalDaily: 91.83, targetTbDaily: 43.94 },
                { totalDaily: 91.86, targetTbDaily: 43.97 },
                { totalDaily: 91.88, targetTbDaily: 43.98 },
                { totalDaily: 91.91, targetTbDaily: 43.99 },
                { totalDaily: 91.93, targetTbDaily: 44.01 },
                { totalDaily: 91.94, targetTbDaily: 44.01 },
                { totalDaily: 91.98, targetTbDaily: 44.02 },
                { totalDaily: 92.01, targetTbDaily: 44.04 },
                { totalDaily: 92.02, targetTbDaily: 44.05 },
                { totalDaily: 92.04, targetTbDaily: 44.05 },
                { totalDaily: 92.06, targetTbDaily: 44.07 },
                { totalDaily: 92.09, targetTbDaily: 44.08 },
                { totalDaily: 92.11, targetTbDaily: 44.09 },
                { totalDaily: 92.14, targetTbDaily: 44.09 },
                { totalDaily: 92.15, targetTbDaily: 44.11 },
                { totalDaily: 92.17, targetTbDaily: 44.11 },
                { totalDaily: 92.21, targetTbDaily: 44.14 },
                { totalDaily: 92.23, targetTbDaily: 44.15 },
                { totalDaily: 92.25, targetTbDaily: 44.15 },
                { totalDaily: 92.28, targetTbDaily: 44.16 },
                { totalDaily: 92.31, targetTbDaily: 44.18 },
                { totalDaily: 92.33, targetTbDaily: 44.2 },
                { totalDaily: 92.36, targetTbDaily: 44.21 },
                { totalDaily: 92.39, targetTbDaily: 44.22 },
                { totalDaily: 92.41, targetTbDaily: 44.23 },
                { totalDaily: 92.44, targetTbDaily: 44.23 },
                { totalDaily: 92.46, targetTbDaily: 44.26 },
                { totalDaily: 92.47, targetTbDaily: 44.27 },
                { totalDaily: 92.5, targetTbDaily: 44.28 },
                { totalDaily: 92.53, targetTbDaily: 44.28 },
                { totalDaily: 92.56, targetTbDaily: 44.3 },
                { totalDaily: 92.56, targetTbDaily: 44.3 },
                { totalDaily: 92.59, targetTbDaily: 44.31 },
                { totalDaily: 92.61, targetTbDaily: 44.33 },
                { totalDaily: 92.63, targetTbDaily: 44.33 },
                { totalDaily: 92.67, targetTbDaily: 44.35 },
                { totalDaily: 92.69, targetTbDaily: 44.37 },
                { totalDaily: 92.71, targetTbDaily: 44.37 },
                { totalDaily: 92.74, targetTbDaily: 44.39 },
                { totalDaily: 92.77, targetTbDaily: 44.4 },
                { totalDaily: 92.79, targetTbDaily: 44.41 },
                { totalDaily: 92.8, targetTbDaily: 44.42 },
                { totalDaily: 92.83, targetTbDaily: 44.44 },
                { totalDaily: 92.86, targetTbDaily: 44.45 },
                { totalDaily: 92.9, targetTbDaily: 44.46 },
                { totalDaily: 92.91, targetTbDaily: 44.47 },
                { totalDaily: 92.95, targetTbDaily: 44.48 },
                { totalDaily: 92.97, targetTbDaily: 44.5 },
                { totalDaily: 92.98, targetTbDaily: 44.5 },
                { totalDaily: 93.01, targetTbDaily: 44.52 },
                { totalDaily: 93.04, targetTbDaily: 44.53 },
                { totalDaily: 93.06, targetTbDaily: 44.53 },
                { totalDaily: 93.08, targetTbDaily: 44.55 },
                { totalDaily: 93.1, targetTbDaily: 44.56 },
                { totalDaily: 93.11, targetTbDaily: 44.56 },
                { totalDaily: 93.15, targetTbDaily: 44.58 },
                { totalDaily: 93.18, targetTbDaily: 44.59 },
                { totalDaily: 93.19, targetTbDaily: 44.59 },
                { totalDaily: 93.22, targetTbDaily: 44.61 },
                { totalDaily: 93.25, targetTbDaily: 44.63 },
                { totalDaily: 93.28, targetTbDaily: 44.65 },
                { totalDaily: 93.3, targetTbDaily: 44.65 },
                { totalDaily: 93.31, targetTbDaily: 44.66 },
                { totalDaily: 93.35, targetTbDaily: 44.68 },
                { totalDaily: 93.37, targetTbDaily: 44.69 },
                { totalDaily: 93.41, targetTbDaily: 44.71 },
                { totalDaily: 93.43, targetTbDaily: 44.72 },
                { totalDaily: 93.44, targetTbDaily: 44.73 },
                { totalDaily: 93.48, targetTbDaily: 44.73 },
                { totalDaily: 93.5, targetTbDaily: 44.76 },
                { totalDaily: 93.51, targetTbDaily: 44.76 },
                { totalDaily: 93.54, targetTbDaily: 44.77 },
                { totalDaily: 93.57, targetTbDaily: 44.78 },
                { totalDaily: 93.58, targetTbDaily: 44.79 },
                { totalDaily: 93.61, targetTbDaily: 44.8 },
                { totalDaily: 93.64, targetTbDaily: 44.82 },
                { totalDaily: 93.66, targetTbDaily: 44.83 },
                { totalDaily: 93.69, targetTbDaily: 44.84 },
                { totalDaily: 93.71, targetTbDaily: 44.85 },
                { totalDaily: 93.75, targetTbDaily: 44.88 },
                { totalDaily: 93.77, targetTbDaily: 44.88 },
                { totalDaily: 93.8, targetTbDaily: 44.9 },
                { totalDaily: 93.83, targetTbDaily: 44.91 },
                { totalDaily: 93.85, targetTbDaily: 44.92 },
                { totalDaily: 93.87, targetTbDaily: 44.94 },
                { totalDaily: 93.9, targetTbDaily: 44.95 },
                { totalDaily: 93.92, targetTbDaily: 44.95 },
                { totalDaily: 93.93, targetTbDaily: 44.95 },
                { totalDaily: 93.97, targetTbDaily: 44.98 },
                { totalDaily: 93.97, targetTbDaily: 44.98 },
                { totalDaily: 94.01, targetTbDaily: 44.99 },
                { totalDaily: 94.03, targetTbDaily: 45.01 },
                { totalDaily: 94.06, targetTbDaily: 45.02 },
                { totalDaily: 94.09, targetTbDaily: 45.03 },
                { totalDaily: 94.11, targetTbDaily: 45.05 },
                { totalDaily: 94.14, targetTbDaily: 45.06 },
                { totalDaily: 94.16, targetTbDaily: 45.07 },
                { totalDaily: 94.19, targetTbDaily: 45.08 },
                { totalDaily: 94.21, targetTbDaily: 45.09 },
                { totalDaily: 94.23, targetTbDaily: 45.1 },
                { totalDaily: 94.26, targetTbDaily: 45.12 },
                { totalDaily: 94.29, targetTbDaily: 45.13 },
                { totalDaily: 94.3, targetTbDaily: 45.13 },
                { totalDaily: 94.32, targetTbDaily: 45.14 },
                { totalDaily: 94.36, targetTbDaily: 45.16 },
                { totalDaily: 94.37, targetTbDaily: 45.17 },
                { totalDaily: 94.39, targetTbDaily: 45.18 },
                { totalDaily: 94.42, targetTbDaily: 45.19 },
                { totalDaily: 94.45, targetTbDaily: 45.2 },
                { totalDaily: 94.46, targetTbDaily: 45.2 },
                { totalDaily: 94.49, targetTbDaily: 45.23 },
                { totalDaily: 94.51, targetTbDaily: 45.23 },
                { totalDaily: 94.52, targetTbDaily: 45.24 },
                { totalDaily: 94.56, targetTbDaily: 45.26 },
                { totalDaily: 94.61, targetTbDaily: 45.28 },
                { totalDaily: 94.63, targetTbDaily: 45.28 },
                { totalDaily: 94.66, targetTbDaily: 45.31 },
                { totalDaily: 94.68, targetTbDaily: 45.31 },
                { totalDaily: 94.69, targetTbDaily: 45.32 },
                { totalDaily: 94.72, targetTbDaily: 45.33 },
                { totalDaily: 94.75, targetTbDaily: 45.34 },
                { totalDaily: 94.77, targetTbDaily: 45.36 },
                { totalDaily: 94.79, targetTbDaily: 45.37 },
                { totalDaily: 94.81, targetTbDaily: 45.38 },
                { totalDaily: 94.83, targetTbDaily: 45.39 },
                { totalDaily: 94.87, targetTbDaily: 45.41 },
                { totalDaily: 94.9, targetTbDaily: 45.42 },
                { totalDaily: 94.92, targetTbDaily: 45.43 },
                { totalDaily: 94.94, targetTbDaily: 45.44 },
                { totalDaily: 94.98, targetTbDaily: 45.46 },
                { totalDaily: 95.01, targetTbDaily: 45.48 },
                { totalDaily: 95.02, targetTbDaily: 45.48 },
                { totalDaily: 95.05, targetTbDaily: 45.5 },
                { totalDaily: 95.08, targetTbDaily: 45.51 },
                { totalDaily: 95.09, targetTbDaily: 45.51 },
                { totalDaily: 95.13, targetTbDaily: 45.54 },
                { totalDaily: 95.15, targetTbDaily: 45.54 },
                { totalDaily: 95.17, targetTbDaily: 45.55 },
                { totalDaily: 95.2, targetTbDaily: 45.57 },
                { totalDaily: 95.22, targetTbDaily: 45.58 },
                { totalDaily: 95.24, targetTbDaily: 45.58 },
                { totalDaily: 95.27, targetTbDaily: 45.6 },
                { totalDaily: 95.29, targetTbDaily: 45.61 },
                { totalDaily: 95.32, targetTbDaily: 45.63 },
                { totalDaily: 95.35, targetTbDaily: 45.64 },
                { totalDaily: 95.37, targetTbDaily: 45.64 },
                { totalDaily: 95.41, targetTbDaily: 45.67 },
                { totalDaily: 95.43, targetTbDaily: 45.68 },
                { totalDaily: 95.45, targetTbDaily: 45.69 },
                { totalDaily: 95.47, targetTbDaily: 45.69 },
                { totalDaily: 95.49, targetTbDaily: 45.7 },
                { totalDaily: 95.52, targetTbDaily: 45.72 },
                { totalDaily: 95.55, targetTbDaily: 45.73 },
                { totalDaily: 95.56, targetTbDaily: 45.73 },
                { totalDaily: 95.6, targetTbDaily: 45.76 },
                { totalDaily: 95.62, targetTbDaily: 45.77 },
                { totalDaily: 95.63, targetTbDaily: 45.77 },
                { totalDaily: 95.66, targetTbDaily: 45.79 },
                { totalDaily: 95.68, targetTbDaily: 45.79 },
                { totalDaily: 95.71, targetTbDaily: 45.81 },
                { totalDaily: 95.75, targetTbDaily: 45.83 },
                { totalDaily: 95.77, targetTbDaily: 45.83 },
                { totalDaily: 95.79, targetTbDaily: 45.84 },
                { totalDaily: 95.83, targetTbDaily: 45.87 },
                { totalDaily: 95.85, targetTbDaily: 45.88 },
                { totalDaily: 95.87, targetTbDaily: 45.88 },
                { totalDaily: 95.89, targetTbDaily: 45.89 },
                { totalDaily: 95.92, targetTbDaily: 45.91 },
                { totalDaily: 95.94, targetTbDaily: 45.92 },
                { totalDaily: 95.96, targetTbDaily: 45.93 },
                { totalDaily: 95.98, targetTbDaily: 45.94 },
                { totalDaily: 96.01, targetTbDaily: 45.96 },
                { totalDaily: 96.03, targetTbDaily: 45.96 },
                { totalDaily: 96.07, targetTbDaily: 45.99 },
                { totalDaily: 96.09, targetTbDaily: 45.99 },
                { totalDaily: 96.11, targetTbDaily: 46 },
                { totalDaily: 96.13, targetTbDaily: 46.01 },
                { totalDaily: 96.17, targetTbDaily: 46.02 },
                { totalDaily: 96.2, targetTbDaily: 46.04 },
                { totalDaily: 96.23, targetTbDaily: 46.06 },
                { totalDaily: 96.24, targetTbDaily: 46.07 },
                { totalDaily: 96.25, targetTbDaily: 46.07 },
                { totalDaily: 96.31, targetTbDaily: 46.09 },
                { totalDaily: 96.33, targetTbDaily: 46.11 },
                { totalDaily: 96.35, targetTbDaily: 46.12 },
                { totalDaily: 96.39, targetTbDaily: 46.14 },
                { totalDaily: 96.41, targetTbDaily: 46.15 },
                { totalDaily: 96.42, targetTbDaily: 46.15 },
                { totalDaily: 96.44, targetTbDaily: 46.15 },
                { totalDaily: 96.46, targetTbDaily: 46.17 },
                { totalDaily: 96.49, targetTbDaily: 46.18 },
                { totalDaily: 96.5, targetTbDaily: 46.19 },
                { totalDaily: 96.53, targetTbDaily: 46.21 },
                { totalDaily: 96.58, targetTbDaily: 46.22 },
                { totalDaily: 96.6, targetTbDaily: 46.23 },
                { totalDaily: 96.62, targetTbDaily: 46.25 },
                { totalDaily: 96.64, targetTbDaily: 46.26 },
                { totalDaily: 96.67, targetTbDaily: 46.26 },
                { totalDaily: 96.7, targetTbDaily: 46.3 },
                { totalDaily: 96.72, targetTbDaily: 46.3 },
                { totalDaily: 96.74, targetTbDaily: 46.32 },
                { totalDaily: 96.78, targetTbDaily: 46.32 },
                { totalDaily: 96.8, targetTbDaily: 46.33 },
                { totalDaily: 96.83, targetTbDaily: 46.34 },
                { totalDaily: 96.85, targetTbDaily: 46.36 },
                { totalDaily: 96.88, targetTbDaily: 46.37 },
                { totalDaily: 96.91, targetTbDaily: 46.39 },
                { totalDaily: 96.92, targetTbDaily: 46.39 },
                { totalDaily: 96.96, targetTbDaily: 46.41 },
                { totalDaily: 96.97, targetTbDaily: 46.41 },
                { totalDaily: 96.99, targetTbDaily: 46.42 },
                { totalDaily: 97.03, targetTbDaily: 46.44 },
                { totalDaily: 97.04, targetTbDaily: 46.45 },
                { totalDaily: 97.08, targetTbDaily: 46.45 },
                { totalDaily: 97.1, targetTbDaily: 46.48 },
                { totalDaily: 97.14, targetTbDaily: 46.5 },
                { totalDaily: 97.16, targetTbDaily: 46.51 },
                { totalDaily: 97.2, targetTbDaily: 46.53 },
                { totalDaily: 97.2, targetTbDaily: 46.53 },
                { totalDaily: 97.23, targetTbDaily: 46.55 },
                { totalDaily: 97.27, targetTbDaily: 46.56 },
                { totalDaily: 97.27, targetTbDaily: 46.57 },
                { totalDaily: 97.31, targetTbDaily: 46.57 },
                { totalDaily: 97.33, targetTbDaily: 46.59 },
                { totalDaily: 97.35, targetTbDaily: 46.59 },
                { totalDaily: 97.38, targetTbDaily: 46.61 },
                { totalDaily: 97.39, targetTbDaily: 46.62 },
                { totalDaily: 97.43, targetTbDaily: 46.63 },
                { totalDaily: 97.46, targetTbDaily: 46.64 },
                { totalDaily: 97.47, targetTbDaily: 46.65 },
                { totalDaily: 97.51, targetTbDaily: 46.68 },
                { totalDaily: 97.54, targetTbDaily: 46.69 },
                { totalDaily: 97.56, targetTbDaily: 46.7 },
                { totalDaily: 97.59, targetTbDaily: 46.71 },
                { totalDaily: 97.59, targetTbDaily: 46.71 },
                { totalDaily: 97.64, targetTbDaily: 46.73 },
                { totalDaily: 97.66, targetTbDaily: 46.73 },
                { totalDaily: 97.69, targetTbDaily: 46.76 },
                { totalDaily: 97.71, targetTbDaily: 46.77 },
                { totalDaily: 97.74, targetTbDaily: 46.78 },
                { totalDaily: 97.77, targetTbDaily: 46.79 },
                { totalDaily: 97.8, targetTbDaily: 46.81 },
                { totalDaily: 97.8, targetTbDaily: 46.81 },
                { totalDaily: 97.83, targetTbDaily: 46.81 },
                { totalDaily: 97.85, targetTbDaily: 46.84 },
                { totalDaily: 97.88, targetTbDaily: 46.85 },
                { totalDaily: 97.9, targetTbDaily: 46.86 },
                { totalDaily: 97.93, targetTbDaily: 46.87 },
                { totalDaily: 97.95, targetTbDaily: 46.88 },
                { totalDaily: 97.97, targetTbDaily: 46.89 },
                { totalDaily: 98.01, targetTbDaily: 46.91 },
                { totalDaily: 98.05, targetTbDaily: 46.93 },
                { totalDaily: 98.06, targetTbDaily: 46.93 },
                { totalDaily: 98.09, targetTbDaily: 46.94 },
                { totalDaily: 98.13, targetTbDaily: 46.97 },
                { totalDaily: 98.14, targetTbDaily: 46.97 },
                { totalDaily: 98.17, targetTbDaily: 46.99 },
                { totalDaily: 98.21, targetTbDaily: 47 },
                { totalDaily: 98.21, targetTbDaily: 47 },
                { totalDaily: 98.23, targetTbDaily: 47.02 },
                { totalDaily: 98.28, targetTbDaily: 47.04 },
                { totalDaily: 98.31, targetTbDaily: 47.06 },
                { totalDaily: 98.33, targetTbDaily: 47.07 },
                { totalDaily: 98.35, targetTbDaily: 47.08 },
                { totalDaily: 98.37, targetTbDaily: 47.08 },
                { totalDaily: 98.4, targetTbDaily: 47.11 },
                { totalDaily: 98.41, targetTbDaily: 47.12 },
                { totalDaily: 98.45, targetTbDaily: 47.12 },
                { totalDaily: 98.47, targetTbDaily: 47.13 },
                { totalDaily: 98.5, targetTbDaily: 47.15 },
                { totalDaily: 98.55, targetTbDaily: 47.17 },
                { totalDaily: 98.55, targetTbDaily: 47.17 },
                { totalDaily: 98.6, targetTbDaily: 47.19 },
                { totalDaily: 98.62, targetTbDaily: 47.2 },
                { totalDaily: 98.65, targetTbDaily: 47.21 },
                { totalDaily: 98.66, targetTbDaily: 47.23 },
                { totalDaily: 98.69, targetTbDaily: 47.23 },
                { totalDaily: 98.71, targetTbDaily: 47.25 },
                { totalDaily: 98.75, targetTbDaily: 47.26 },
                { totalDaily: 98.76, targetTbDaily: 47.27 },
                { totalDaily: 98.8, targetTbDaily: 47.29 },
                { totalDaily: 98.83, targetTbDaily: 47.31 },
                { totalDaily: 98.85, targetTbDaily: 47.32 },
                { totalDaily: 98.87, targetTbDaily: 47.32 },
                { totalDaily: 98.89, targetTbDaily: 47.33 },
                { totalDaily: 98.91, targetTbDaily: 47.34 },
                { totalDaily: 98.94, targetTbDaily: 47.36 },
                { totalDaily: 98.96, targetTbDaily: 47.37 },
                { totalDaily: 99, targetTbDaily: 47.38 },
                { totalDaily: 99.02, targetTbDaily: 47.39 },
                { totalDaily: 99.04, targetTbDaily: 47.4 },
                { totalDaily: 99.06, targetTbDaily: 47.41 },
                { totalDaily: 99.08, targetTbDaily: 47.42 },
                { totalDaily: 99.1, targetTbDaily: 47.44 },
                { totalDaily: 99.14, targetTbDaily: 47.45 },
                { totalDaily: 99.17, targetTbDaily: 47.46 },
                { totalDaily: 99.19, targetTbDaily: 47.48 },
                { totalDaily: 99.24, targetTbDaily: 47.5 },
                { totalDaily: 99.26, targetTbDaily: 47.51 },
                { totalDaily: 99.29, targetTbDaily: 47.52 },
                { totalDaily: 99.3, targetTbDaily: 47.53 },
                { totalDaily: 99.34, targetTbDaily: 47.55 },
                { totalDaily: 99.37, targetTbDaily: 47.56 },
                { totalDaily: 99.38, targetTbDaily: 47.57 },
                { totalDaily: 99.4, targetTbDaily: 47.57 },
                { totalDaily: 99.43, targetTbDaily: 47.59 },
                { totalDaily: 99.46, targetTbDaily: 47.61 },
                { totalDaily: 99.49, targetTbDaily: 47.62 },
                { totalDaily: 99.51, targetTbDaily: 47.63 },
                { totalDaily: 99.54, targetTbDaily: 47.64 },
                { totalDaily: 99.55, targetTbDaily: 47.64 },
                { totalDaily: 99.6, targetTbDaily: 47.67 },
                { totalDaily: 99.62, targetTbDaily: 47.69 },
                { totalDaily: 99.63, targetTbDaily: 47.69 },
                { totalDaily: 99.66, targetTbDaily: 47.7 },
                { totalDaily: 99.69, targetTbDaily: 47.72 },
                { totalDaily: 99.73, targetTbDaily: 47.73 },
                { totalDaily: 99.77, targetTbDaily: 47.76 },
                { totalDaily: 99.79, targetTbDaily: 47.76 },
                { totalDaily: 99.81, targetTbDaily: 47.77 },
                { totalDaily: 99.85, targetTbDaily: 47.8 },
                { totalDaily: 99.85, targetTbDaily: 47.8 },
                { totalDaily: 99.88, targetTbDaily: 47.81 },
                { totalDaily: 99.9, targetTbDaily: 47.82 },
                { totalDaily: 99.93, targetTbDaily: 47.83 },
                { totalDaily: 99.94, targetTbDaily: 47.84 },
                { totalDaily: 99.98, targetTbDaily: 47.86 },
                { totalDaily: 100.01, targetTbDaily: 47.87 },
                { totalDaily: 100.03, targetTbDaily: 47.88 },
                { totalDaily: 100.06, targetTbDaily: 47.89 },
                { totalDaily: 100.1, targetTbDaily: 47.91 },
                { totalDaily: 100.11, targetTbDaily: 47.93 },
                { totalDaily: 100.15, targetTbDaily: 47.93 },
                { totalDaily: 100.18, targetTbDaily: 47.95 },
                { totalDaily: 100.18, targetTbDaily: 47.95 },
                { totalDaily: 100.23, targetTbDaily: 47.98 },
                { totalDaily: 100.26, targetTbDaily: 47.99 },
                { totalDaily: 100.26, targetTbDaily: 47.99 },
                { totalDaily: 100.31, targetTbDaily: 48.01 },
                { totalDaily: 100.33, targetTbDaily: 48.02 },
                { totalDaily: 100.34, targetTbDaily: 48.02 },
                { totalDaily: 100.38, targetTbDaily: 48.05 },
                { totalDaily: 100.39, targetTbDaily: 48.05 },
                { totalDaily: 100.41, targetTbDaily: 48.06 },
                { totalDaily: 100.46, targetTbDaily: 48.08 },
                { totalDaily: 100.47, targetTbDaily: 48.08 },
                { totalDaily: 100.5, targetTbDaily: 48.1 },
                { totalDaily: 100.53, targetTbDaily: 48.12 },
                { totalDaily: 100.58, targetTbDaily: 48.15 },
                { totalDaily: 100.59, targetTbDaily: 48.15 },
                { totalDaily: 100.61, targetTbDaily: 48.17 },
                { totalDaily: 100.64, targetTbDaily: 48.18 },
                { totalDaily: 100.65, targetTbDaily: 48.19 },
                { totalDaily: 100.69, targetTbDaily: 48.2 },
                { totalDaily: 100.73, targetTbDaily: 48.2 },
                { totalDaily: 100.73, targetTbDaily: 48.2 },
                { totalDaily: 100.77, targetTbDaily: 48.24 },
                { totalDaily: 100.79, targetTbDaily: 48.25 },
                { totalDaily: 100.8, targetTbDaily: 48.25 },
                { totalDaily: 100.84, targetTbDaily: 48.27 },
                { totalDaily: 100.88, targetTbDaily: 48.28 },
                { totalDaily: 100.9, targetTbDaily: 48.29 },
                { totalDaily: 100.93, targetTbDaily: 48.31 },
                { totalDaily: 100.96, targetTbDaily: 48.32 },
                { totalDaily: 100.97, targetTbDaily: 48.33 },
                { totalDaily: 101.01, targetTbDaily: 48.34 },
                { totalDaily: 101.04, targetTbDaily: 48.36 },
                { totalDaily: 101.06, targetTbDaily: 48.37 },
                { totalDaily: 101.1, targetTbDaily: 48.39 },
                { totalDaily: 101.12, targetTbDaily: 48.4 },
                { totalDaily: 101.14, targetTbDaily: 48.41 },
                { totalDaily: 101.16, targetTbDaily: 48.42 },
                { totalDaily: 101.19, targetTbDaily: 48.44 },
                { totalDaily: 101.21, targetTbDaily: 48.44 },
                { totalDaily: 101.24, targetTbDaily: 48.47 },
                { totalDaily: 101.27, targetTbDaily: 48.47 },
                { totalDaily: 101.3, targetTbDaily: 48.48 },
                { totalDaily: 101.33, targetTbDaily: 48.5 },
                { totalDaily: 101.34, targetTbDaily: 48.5 },
                { totalDaily: 101.37, targetTbDaily: 48.52 },
                { totalDaily: 101.39, targetTbDaily: 48.53 },
                { totalDaily: 101.44, targetTbDaily: 48.55 },
                { totalDaily: 101.46, targetTbDaily: 48.57 },
                { totalDaily: 101.48, targetTbDaily: 48.57 },
                { totalDaily: 101.51, targetTbDaily: 48.58 },
                { totalDaily: 101.54, targetTbDaily: 48.6 },
                { totalDaily: 101.56, targetTbDaily: 48.62 },
                { totalDaily: 101.59, targetTbDaily: 48.62 },
                { totalDaily: 101.6, targetTbDaily: 48.63 },
                { totalDaily: 101.63, targetTbDaily: 48.64 },
                { totalDaily: 101.67, targetTbDaily: 48.66 },
                { totalDaily: 101.69, targetTbDaily: 48.67 },
                { totalDaily: 101.73, targetTbDaily: 48.7 },
                { totalDaily: 101.76, targetTbDaily: 48.7 },
                { totalDaily: 101.78, targetTbDaily: 48.71 },
                { totalDaily: 101.81, targetTbDaily: 48.73 },
                { totalDaily: 101.83, targetTbDaily: 48.75 },
                { totalDaily: 101.86, targetTbDaily: 48.75 },
                { totalDaily: 101.88, targetTbDaily: 48.77 },
                { totalDaily: 101.9, targetTbDaily: 48.77 },
                { totalDaily: 101.93, targetTbDaily: 48.79 },
                { totalDaily: 101.96, targetTbDaily: 48.81 },
                { totalDaily: 102, targetTbDaily: 48.82 },
                { totalDaily: 102.02, targetTbDaily: 48.82 },
                { totalDaily: 102.05, targetTbDaily: 48.85 },
                { totalDaily: 102.07, targetTbDaily: 48.86 },
                { totalDaily: 102.09, targetTbDaily: 48.87 },
                { totalDaily: 102.12, targetTbDaily: 48.87 },
                { totalDaily: 102.14, targetTbDaily: 48.89 },
                { totalDaily: 102.18, targetTbDaily: 48.9 },
                { totalDaily: 102.21, targetTbDaily: 48.92 },
                { totalDaily: 102.22, targetTbDaily: 48.93 },
                { totalDaily: 102.25, targetTbDaily: 48.94 },
                { totalDaily: 102.29, targetTbDaily: 48.96 },
                { totalDaily: 102.29, targetTbDaily: 48.96 },
                { totalDaily: 102.31, targetTbDaily: 48.98 },
                { totalDaily: 102.35, targetTbDaily: 49 },
                { totalDaily: 102.37, targetTbDaily: 49 },
                { totalDaily: 102.42, targetTbDaily: 49.02 },
                { totalDaily: 102.44, targetTbDaily: 49.03 },
                { totalDaily: 102.46, targetTbDaily: 49.04 },
                { totalDaily: 102.49, targetTbDaily: 49.06 },
                { totalDaily: 102.51, targetTbDaily: 49.06 },
                { totalDaily: 102.54, targetTbDaily: 49.08 },
                { totalDaily: 102.58, targetTbDaily: 49.1 },
                { totalDaily: 102.61, targetTbDaily: 49.11 },
                { totalDaily: 102.62, targetTbDaily: 49.12 },
                { totalDaily: 102.67, targetTbDaily: 49.14 },
                { totalDaily: 102.68, targetTbDaily: 49.15 },
                { totalDaily: 102.71, targetTbDaily: 49.16 },
                { totalDaily: 102.73, targetTbDaily: 49.18 },
                { totalDaily: 102.76, targetTbDaily: 49.19 },
                { totalDaily: 102.79, targetTbDaily: 49.2 },
                { totalDaily: 102.82, targetTbDaily: 49.22 },
                { totalDaily: 102.84, targetTbDaily: 49.22 },
                { totalDaily: 102.87, targetTbDaily: 49.23 },
                { totalDaily: 102.9, targetTbDaily: 49.26 },
                { totalDaily: 102.93, targetTbDaily: 49.26 },
                { totalDaily: 102.94, targetTbDaily: 49.28 },
                { totalDaily: 102.97, targetTbDaily: 49.29 },
                { totalDaily: 103, targetTbDaily: 49.31 },
                { totalDaily: 103.04, targetTbDaily: 49.32 },
                { totalDaily: 103.06, targetTbDaily: 49.33 },
                { totalDaily: 103.07, targetTbDaily: 49.34 },
                { totalDaily: 103.12, targetTbDaily: 49.36 },
                { totalDaily: 103.15, targetTbDaily: 49.37 },
                { totalDaily: 103.18, targetTbDaily: 49.39 },
                { totalDaily: 103.19, targetTbDaily: 49.39 },
                { totalDaily: 103.25, targetTbDaily: 49.42 },
                { totalDaily: 103.26, targetTbDaily: 49.43 },
                { totalDaily: 103.28, targetTbDaily: 49.43 },
                { totalDaily: 103.32, targetTbDaily: 49.45 },
                { totalDaily: 103.32, targetTbDaily: 49.45 },
                { totalDaily: 103.35, targetTbDaily: 49.47 },
                { totalDaily: 103.38, targetTbDaily: 49.48 },
                { totalDaily: 103.4, targetTbDaily: 49.49 },
                { totalDaily: 103.44, targetTbDaily: 49.51 },
                { totalDaily: 103.46, targetTbDaily: 49.51 },
                { totalDaily: 103.48, targetTbDaily: 49.54 },
                { totalDaily: 103.52, targetTbDaily: 49.55 },
                { totalDaily: 103.55, targetTbDaily: 49.56 },
                { totalDaily: 103.57, targetTbDaily: 49.57 },
                { totalDaily: 103.59, targetTbDaily: 49.59 },
                { totalDaily: 103.63, targetTbDaily: 49.61 },
                { totalDaily: 103.66, targetTbDaily: 49.62 },
                { totalDaily: 103.68, targetTbDaily: 49.62 },
                { totalDaily: 103.72, targetTbDaily: 49.64 },
                { totalDaily: 103.72, targetTbDaily: 49.64 },
                { totalDaily: 103.76, targetTbDaily: 49.67 },
                { totalDaily: 103.78, targetTbDaily: 49.67 },
                { totalDaily: 103.79, targetTbDaily: 49.68 },
                { totalDaily: 103.83, targetTbDaily: 49.69 },
                { totalDaily: 103.86, targetTbDaily: 49.7 },
                { totalDaily: 103.88, targetTbDaily: 49.72 },
                { totalDaily: 103.91, targetTbDaily: 49.73 },
                { totalDaily: 103.94, targetTbDaily: 49.75 },
                { totalDaily: 103.97, targetTbDaily: 49.77 },
                { totalDaily: 104.01, targetTbDaily: 49.78 },
                { totalDaily: 104.03, targetTbDaily: 49.8 },
                { totalDaily: 104.06, targetTbDaily: 49.8 },
                { totalDaily: 104.1, targetTbDaily: 49.82 },
                { totalDaily: 104.13, targetTbDaily: 49.84 },
                { totalDaily: 104.14, targetTbDaily: 49.84 },
                { totalDaily: 104.18, targetTbDaily: 49.87 },
                { totalDaily: 104.18, targetTbDaily: 49.87 },
                { totalDaily: 104.22, targetTbDaily: 49.88 },
                { totalDaily: 104.25, targetTbDaily: 49.9 },
                { totalDaily: 104.26, targetTbDaily: 49.9 },
                { totalDaily: 104.31, targetTbDaily: 49.93 },
                { totalDaily: 104.33, targetTbDaily: 49.94 },
                { totalDaily: 104.36, targetTbDaily: 49.95 },
                { totalDaily: 104.4, targetTbDaily: 49.98 },
                { totalDaily: 104.42, targetTbDaily: 49.98 },
                { totalDaily: 104.44, targetTbDaily: 49.99 },
                { totalDaily: 104.47, targetTbDaily: 50 },
                { totalDaily: 104.51, targetTbDaily: 50.02 },
                { totalDaily: 104.54, targetTbDaily: 50.04 },
                { totalDaily: 104.55, targetTbDaily: 50.05 },
                { totalDaily: 104.59, targetTbDaily: 50.06 },
                { totalDaily: 104.6, targetTbDaily: 50.07 },
                { totalDaily: 104.63, targetTbDaily: 50.08 },
                { totalDaily: 104.66, targetTbDaily: 50.1 },
                { totalDaily: 104.67, targetTbDaily: 50.1 },
                { totalDaily: 104.71, targetTbDaily: 50.12 },
                { totalDaily: 104.72, targetTbDaily: 50.12 },
                { totalDaily: 104.75, targetTbDaily: 50.13 },
                { totalDaily: 104.8, targetTbDaily: 50.16 },
                { totalDaily: 104.82, targetTbDaily: 50.17 },
                { totalDaily: 104.87, targetTbDaily: 50.19 },
                { totalDaily: 104.89, targetTbDaily: 50.2 },
                { totalDaily: 104.9, targetTbDaily: 50.2 },
                { totalDaily: 104.94, targetTbDaily: 50.23 },
                { totalDaily: 104.96, targetTbDaily: 50.23 },
                { totalDaily: 105, targetTbDaily: 50.25 },
                { totalDaily: 105.02, targetTbDaily: 50.27 },
                { totalDaily: 105.04, targetTbDaily: 50.27 },
                { totalDaily: 105.07, targetTbDaily: 50.29 },
                { totalDaily: 105.09, targetTbDaily: 50.3 },
                { totalDaily: 105.13, targetTbDaily: 50.32 },
                { totalDaily: 105.16, targetTbDaily: 50.33 },
                { totalDaily: 105.18, targetTbDaily: 50.34 },
                { totalDaily: 105.21, targetTbDaily: 50.36 },
                { totalDaily: 105.22, targetTbDaily: 50.36 },
                { totalDaily: 105.27, targetTbDaily: 50.39 },
                { totalDaily: 105.28, targetTbDaily: 50.39 },
                { totalDaily: 105.3, targetTbDaily: 50.4 },
                { totalDaily: 105.35, targetTbDaily: 50.43 },
                { totalDaily: 105.35, targetTbDaily: 50.43 },
                { totalDaily: 105.41, targetTbDaily: 50.45 },
                { totalDaily: 105.43, targetTbDaily: 50.46 },
                { totalDaily: 105.44, targetTbDaily: 50.47 },
                { totalDaily: 105.48, targetTbDaily: 50.49 },
                { totalDaily: 105.51, targetTbDaily: 50.5 },
                { totalDaily: 105.52, targetTbDaily: 50.51 },
                { totalDaily: 105.55, targetTbDaily: 50.52 },
                { totalDaily: 105.6, targetTbDaily: 50.54 },
                { totalDaily: 105.63, targetTbDaily: 50.56 },
                { totalDaily: 105.65, targetTbDaily: 50.56 },
                { totalDaily: 105.68, targetTbDaily: 50.58 },
                { totalDaily: 105.69, targetTbDaily: 50.59 },
                { totalDaily: 105.73, targetTbDaily: 50.61 },
                { totalDaily: 105.75, targetTbDaily: 50.62 },
                { totalDaily: 105.77, targetTbDaily: 50.63 },
                { totalDaily: 105.82, targetTbDaily: 50.65 },
                { totalDaily: 105.83, targetTbDaily: 50.65 },
                { totalDaily: 105.86, targetTbDaily: 50.67 },
                { totalDaily: 105.88, targetTbDaily: 50.68 },
                { totalDaily: 105.91, targetTbDaily: 50.69 },
                { totalDaily: 105.96, targetTbDaily: 50.72 },
                { totalDaily: 105.98, targetTbDaily: 50.73 },
                { totalDaily: 106.02, targetTbDaily: 50.73 },
                { totalDaily: 106.04, targetTbDaily: 50.75 },
                { totalDaily: 106.07, targetTbDaily: 50.76 },
                { totalDaily: 106.1, targetTbDaily: 50.79 },
                { totalDaily: 106.11, targetTbDaily: 50.8 },
                { totalDaily: 106.15, targetTbDaily: 50.81 },
                { totalDaily: 106.17, targetTbDaily: 50.81 },
                { totalDaily: 106.19, targetTbDaily: 50.83 },
                { totalDaily: 106.22, targetTbDaily: 50.84 },
                { totalDaily: 106.24, targetTbDaily: 50.84 },
                { totalDaily: 106.3, targetTbDaily: 50.88 },
                { totalDaily: 106.31, targetTbDaily: 50.88 },
                { totalDaily: 106.33, targetTbDaily: 50.89 },
                { totalDaily: 106.38, targetTbDaily: 50.92 },
                { totalDaily: 106.39, targetTbDaily: 50.92 },
                { totalDaily: 106.43, targetTbDaily: 50.95 },
                { totalDaily: 106.46, targetTbDaily: 50.96 },
                { totalDaily: 106.47, targetTbDaily: 50.96 },
                { totalDaily: 106.51, targetTbDaily: 50.99 },
                { totalDaily: 106.55, targetTbDaily: 51 },
                { totalDaily: 106.58, targetTbDaily: 51.01 },
                { totalDaily: 106.6, targetTbDaily: 51.03 },
                { totalDaily: 106.63, targetTbDaily: 51.04 },
                { totalDaily: 106.64, targetTbDaily: 51.05 },
                { totalDaily: 106.68, targetTbDaily: 51.06 },
                { totalDaily: 106.71, targetTbDaily: 51.07 },
                { totalDaily: 106.72, targetTbDaily: 51.08 },
                { totalDaily: 106.76, targetTbDaily: 51.09 },
                { totalDaily: 106.77, targetTbDaily: 51.11 },
                { totalDaily: 106.8, targetTbDaily: 51.12 },
                { totalDaily: 106.84, targetTbDaily: 51.13 },
                { totalDaily: 106.84, targetTbDaily: 51.14 },
                { totalDaily: 106.9, targetTbDaily: 51.17 },
                { totalDaily: 106.92, targetTbDaily: 51.18 },
                { totalDaily: 106.94, targetTbDaily: 51.19 },
                { totalDaily: 106.98, targetTbDaily: 51.21 },
                { totalDaily: 107, targetTbDaily: 51.22 },
                { totalDaily: 107.02, targetTbDaily: 51.23 },
                { totalDaily: 107.06, targetTbDaily: 51.23 },
                { totalDaily: 107.09, targetTbDaily: 51.26 },
                { totalDaily: 107.12, targetTbDaily: 51.27 },
                { totalDaily: 107.16, targetTbDaily: 51.29 },
                { totalDaily: 107.17, targetTbDaily: 51.3 },
                { totalDaily: 107.19, targetTbDaily: 51.3 },
                { totalDaily: 107.22, targetTbDaily: 51.31 },
                { totalDaily: 107.24, targetTbDaily: 51.31 },
                { totalDaily: 107.28, targetTbDaily: 51.34 },
                { totalDaily: 107.32, targetTbDaily: 51.36 },
                { totalDaily: 107.32, targetTbDaily: 51.37 },
                { totalDaily: 107.38, targetTbDaily: 51.4 },
                { totalDaily: 107.4, targetTbDaily: 51.42 },
                { totalDaily: 107.43, targetTbDaily: 51.42 },
                { totalDaily: 107.46, targetTbDaily: 51.44 },
                { totalDaily: 107.48, targetTbDaily: 51.44 },
                { totalDaily: 107.52, targetTbDaily: 51.46 },
                { totalDaily: 107.56, targetTbDaily: 51.48 },
                { totalDaily: 107.57, targetTbDaily: 51.48 },
                { totalDaily: 107.61, targetTbDaily: 51.51 },
                { totalDaily: 107.63, targetTbDaily: 51.52 },
                { totalDaily: 107.65, targetTbDaily: 51.53 },
                { totalDaily: 107.68, targetTbDaily: 51.55 },
                { totalDaily: 107.71, targetTbDaily: 51.55 },
                { totalDaily: 107.74, targetTbDaily: 51.56 },
                { totalDaily: 107.76, targetTbDaily: 51.58 },
                { totalDaily: 107.8, targetTbDaily: 51.6 },
                { totalDaily: 107.82, targetTbDaily: 51.61 },
                { totalDaily: 107.85, targetTbDaily: 51.62 },
                { totalDaily: 107.87, targetTbDaily: 51.63 },
                { totalDaily: 107.9, targetTbDaily: 51.65 },
                { totalDaily: 107.96, targetTbDaily: 51.67 },
                { totalDaily: 107.97, targetTbDaily: 51.67 },
                { totalDaily: 108.01, targetTbDaily: 51.7 },
                { totalDaily: 108.03, targetTbDaily: 51.71 },
                { totalDaily: 108.05, targetTbDaily: 51.72 },
                { totalDaily: 108.08, targetTbDaily: 51.73 },
                { totalDaily: 108.09, targetTbDaily: 51.73 },
                { totalDaily: 108.13, targetTbDaily: 51.75 },
                { totalDaily: 108.14, targetTbDaily: 51.76 },
                { totalDaily: 108.18, targetTbDaily: 51.78 },
                { totalDaily: 108.2, targetTbDaily: 51.79 },
                { totalDaily: 108.25, targetTbDaily: 51.81 },
                { totalDaily: 108.27, targetTbDaily: 51.81 },
                { totalDaily: 108.29, targetTbDaily: 51.83 },
                { totalDaily: 108.33, targetTbDaily: 51.85 },
                { totalDaily: 108.34, targetTbDaily: 51.86 },
                { totalDaily: 108.4, targetTbDaily: 51.89 },
                { totalDaily: 108.42, targetTbDaily: 51.89 },
                { totalDaily: 108.44, targetTbDaily: 51.9 },
                { totalDaily: 108.47, targetTbDaily: 51.92 },
                { totalDaily: 108.49, targetTbDaily: 51.93 },
                { totalDaily: 108.52, targetTbDaily: 51.95 },
                { totalDaily: 108.55, targetTbDaily: 51.96 },
                { totalDaily: 108.58, targetTbDaily: 51.97 },
                { totalDaily: 108.61, targetTbDaily: 51.99 },
                { totalDaily: 108.64, targetTbDaily: 51.99 },
                { totalDaily: 108.65, targetTbDaily: 52 },
                { totalDaily: 108.69, targetTbDaily: 52.03 },
                { totalDaily: 108.73, targetTbDaily: 52.04 },
                { totalDaily: 108.76, targetTbDaily: 52.05 },
                { totalDaily: 108.79, targetTbDaily: 52.07 },
                { totalDaily: 108.83, targetTbDaily: 52.09 },
                { totalDaily: 108.83, targetTbDaily: 52.09 },
                { totalDaily: 108.88, targetTbDaily: 52.11 },
                { totalDaily: 108.89, targetTbDaily: 52.12 },
                { totalDaily: 108.92, targetTbDaily: 52.13 },
                { totalDaily: 108.96, targetTbDaily: 52.15 },
                { totalDaily: 108.97, targetTbDaily: 52.16 },
                { totalDaily: 109.01, targetTbDaily: 52.17 },
                { totalDaily: 109.04, targetTbDaily: 52.18 },
                { totalDaily: 109.05, targetTbDaily: 52.2 },
                { totalDaily: 109.1, targetTbDaily: 52.22 },
                { totalDaily: 109.13, targetTbDaily: 52.23 },
                { totalDaily: 109.16, targetTbDaily: 52.25 },
                { totalDaily: 109.16, targetTbDaily: 52.26 },
            ],
            monthlyData: [
                { total: 36.12, target: 17.28, creditsMonthly: 12.13, cumulatedCredits: 12.13 },
                { total: 36.42, target: 17.43, creditsMonthly: 17.19, cumulatedCredits: 29.32 },
                { total: 39.15, target: 18.72, creditsMonthly: 18.43, cumulatedCredits: 47.75 },
                { total: 41.89, target: 20.06, creditsMonthly: 19.61, cumulatedCredits: 67.36 },
                { total: 42.22, target: 20.2, creditsMonthly: 19.89, cumulatedCredits: 87.24 },
                { total: 42.56, target: 20.38, creditsMonthly: 20.1, cumulatedCredits: 107.35 },
                { total: 42.87, target: 20.51, creditsMonthly: 20.1, cumulatedCredits: 127.44 },
                { total: 43.22, target: 20.68, creditsMonthly: 20.19, cumulatedCredits: 147.63 },
                { total: 43.56, target: 20.85, creditsMonthly: 20.64, cumulatedCredits: 168.28 },
                { total: 43.91, target: 21.02, creditsMonthly: 20.7, cumulatedCredits: 188.98 },
                { total: 44.23, target: 21.18, creditsMonthly: 20.7, cumulatedCredits: 209.68 },
                { total: 44.6, target: 21.35, creditsMonthly: 20.91, cumulatedCredits: 230.59 },
                { total: 55.98, target: 26.79, creditsMonthly: 25.38, cumulatedCredits: 255.97 },
                { total: 56.42, target: 27.01, creditsMonthly: 26.46, cumulatedCredits: 282.41 },
                { total: 56.88, target: 27.22, creditsMonthly: 26.74, cumulatedCredits: 309.16 },
                { total: 57.33, target: 27.44, creditsMonthly: 27, cumulatedCredits: 336.17 },
                { total: 57.77, target: 27.66, creditsMonthly: 27.13, cumulatedCredits: 363.3 },
                { total: 58.22, target: 27.86, creditsMonthly: 27.34, cumulatedCredits: 390.64 },
                { total: 58.68, target: 28.09, creditsMonthly: 27.6, cumulatedCredits: 418.24 },
                { total: 59.16, target: 28.32, creditsMonthly: 27.89, cumulatedCredits: 446.13 },
                { total: 59.6, target: 28.53, creditsMonthly: 27.94, cumulatedCredits: 474.07 },
                { total: 60.08, target: 28.75, creditsMonthly: 28.29, cumulatedCredits: 502.37 },
                { total: 60.54, target: 28.97, creditsMonthly: 28.5, cumulatedCredits: 530.87 },
                { total: 61.03, target: 29.21, creditsMonthly: 28.58, cumulatedCredits: 559.45 },
                { total: 72.51, target: 34.7, creditsMonthly: 32.29, cumulatedCredits: 591.74 },
                { total: 73.06, target: 34.97, creditsMonthly: 34.27, cumulatedCredits: 626.01 },
                { total: 73.65, target: 35.25, creditsMonthly: 34.72, cumulatedCredits: 660.73 },
                { total: 74.23, target: 35.53, creditsMonthly: 34.84, cumulatedCredits: 695.57 },
                { total: 74.8, target: 35.79, creditsMonthly: 35.2, cumulatedCredits: 730.78 },
                { total: 75.4, target: 36.09, creditsMonthly: 35.45, cumulatedCredits: 766.23 },
                { total: 76, target: 36.37, creditsMonthly: 35.71, cumulatedCredits: 801.94 },
                { total: 76.59, target: 36.67, creditsMonthly: 36.08, cumulatedCredits: 838.02 },
                { total: 77.2, target: 36.95, creditsMonthly: 36.29, cumulatedCredits: 874.32 },
                { total: 77.8, target: 37.23, creditsMonthly: 36.59, cumulatedCredits: 910.91 },
                { total: 78.4, target: 37.52, creditsMonthly: 36.9, cumulatedCredits: 947.81 },
                { total: 79.03, target: 37.83, creditsMonthly: 37.15, cumulatedCredits: 984.96 },
                { total: 90.59, target: 43.36, creditsMonthly: 39.87, cumulatedCredits: 1024.84 },
                { total: 91.29, target: 43.69, creditsMonthly: 42.98, cumulatedCredits: 1067.82 },
                { total: 92.02, target: 44.05, creditsMonthly: 43.25, cumulatedCredits: 1111.07 },
                { total: 92.74, target: 44.39, creditsMonthly: 43.63, cumulatedCredits: 1154.71 },
                { total: 93.48, target: 44.73, creditsMonthly: 43.93, cumulatedCredits: 1198.65 },
                { total: 94.21, target: 45.09, creditsMonthly: 44.28, cumulatedCredits: 1242.92 },
                { total: 94.94, target: 45.44, creditsMonthly: 44.65, cumulatedCredits: 1287.58 },
                { total: 95.68, target: 45.79, creditsMonthly: 45.02, cumulatedCredits: 1332.6 },
                { total: 96.44, target: 46.15, creditsMonthly: 45.3, cumulatedCredits: 1377.9 },
                { total: 97.2, target: 46.53, creditsMonthly: 45.79, cumulatedCredits: 1423.69 },
                { total: 97.95, target: 46.88, creditsMonthly: 45.98, cumulatedCredits: 1469.67 },
                { total: 98.75, target: 47.26, creditsMonthly: 46.5, cumulatedCredits: 1516.17 },
                { total: 99.51, target: 47.63, creditsMonthly: 46.79, cumulatedCredits: 1562.96 },
                { total: 100.31, target: 48.01, creditsMonthly: 47.14, cumulatedCredits: 1610.1 },
                { total: 101.1, target: 48.39, creditsMonthly: 47.59, cumulatedCredits: 1657.69 },
                { total: 101.88, target: 48.77, creditsMonthly: 47.88, cumulatedCredits: 1705.57 },
                { total: 102.68, target: 49.15, creditsMonthly: 48.29, cumulatedCredits: 1753.86 },
                { total: 103.48, target: 49.54, creditsMonthly: 48.66, cumulatedCredits: 1802.52 },
                { total: 104.31, target: 49.93, creditsMonthly: 49.08, cumulatedCredits: 1851.6 },
                { total: 105.13, target: 50.32, creditsMonthly: 49.42, cumulatedCredits: 1901.02 },
                { total: 105.96, target: 50.72, creditsMonthly: 49.8, cumulatedCredits: 1950.82 },
                { total: 106.77, target: 51.11, creditsMonthly: 50.28, cumulatedCredits: 2001.1 },
                { total: 107.63, target: 51.52, creditsMonthly: 50.57, cumulatedCredits: 2051.67 },
                { total: 108.47, target: 51.92, creditsMonthly: 51, cumulatedCredits: 2102.67 },
            ],
        },
        dtc: {
            dailyData: [
                { totalDaily: 3.33, targetTbDaily: 3.33 },
                { totalDaily: 6.67, targetTbDaily: 6.67 },
                { totalDaily: 10, targetTbDaily: 10 },
                { totalDaily: 13.33, targetTbDaily: 13.33 },
                { totalDaily: 16.67, targetTbDaily: 16.67 },
                { totalDaily: 20, targetTbDaily: 20 },
                { totalDaily: 23.33, targetTbDaily: 23.33 },
                { totalDaily: 26.67, targetTbDaily: 26.67 },
                { totalDaily: 30, targetTbDaily: 30 },
                { totalDaily: 33.33, targetTbDaily: 33.33 },
                { totalDaily: 36.67, targetTbDaily: 36.67 },
                { totalDaily: 40, targetTbDaily: 40 },
                { totalDaily: 43.33, targetTbDaily: 43.33 },
                { totalDaily: 46.67, targetTbDaily: 46.67 },
                { totalDaily: 50, targetTbDaily: 50 },
                { totalDaily: 50.01, targetTbDaily: 50.01 },
                { totalDaily: 50.03, targetTbDaily: 50.03 },
                { totalDaily: 50.04, targetTbDaily: 50.04 },
                { totalDaily: 50.05, targetTbDaily: 50.05 },
                { totalDaily: 50.07, targetTbDaily: 50.07 },
                { totalDaily: 50.08, targetTbDaily: 50.08 },
                { totalDaily: 50.09, targetTbDaily: 50.09 },
                { totalDaily: 50.1, targetTbDaily: 50.1 },
                { totalDaily: 50.12, targetTbDaily: 50.12 },
                { totalDaily: 50.13, targetTbDaily: 50.13 },
                { totalDaily: 50.14, targetTbDaily: 50.14 },
                { totalDaily: 50.16, targetTbDaily: 50.16 },
                { totalDaily: 50.17, targetTbDaily: 50.17 },
                { totalDaily: 50.18, targetTbDaily: 50.18 },
                { totalDaily: 50.2, targetTbDaily: 50.2 },
                { totalDaily: 50.21, targetTbDaily: 50.21 },
                { totalDaily: 50.22, targetTbDaily: 50.22 },
                { totalDaily: 50.24, targetTbDaily: 50.24 },
                { totalDaily: 50.25, targetTbDaily: 50.25 },
                { totalDaily: 50.26, targetTbDaily: 50.26 },
                { totalDaily: 50.27, targetTbDaily: 50.27 },
                { totalDaily: 50.29, targetTbDaily: 50.29 },
                { totalDaily: 50.3, targetTbDaily: 50.3 },
                { totalDaily: 50.31, targetTbDaily: 50.31 },
                { totalDaily: 50.33, targetTbDaily: 50.33 },
                { totalDaily: 50.34, targetTbDaily: 50.34 },
                { totalDaily: 50.35, targetTbDaily: 50.35 },
                { totalDaily: 50.37, targetTbDaily: 50.37 },
                { totalDaily: 50.38, targetTbDaily: 50.38 },
                { totalDaily: 50.39, targetTbDaily: 50.39 },
                { totalDaily: 50.41, targetTbDaily: 50.41 },
                { totalDaily: 50.42, targetTbDaily: 50.42 },
                { totalDaily: 50.43, targetTbDaily: 50.43 },
                { totalDaily: 50.45, targetTbDaily: 50.45 },
                { totalDaily: 50.46, targetTbDaily: 50.46 },
                { totalDaily: 50.47, targetTbDaily: 50.47 },
                { totalDaily: 50.49, targetTbDaily: 50.49 },
                { totalDaily: 50.5, targetTbDaily: 50.5 },
                { totalDaily: 50.51, targetTbDaily: 50.51 },
                { totalDaily: 50.52, targetTbDaily: 50.52 },
                { totalDaily: 50.54, targetTbDaily: 50.54 },
                { totalDaily: 50.55, targetTbDaily: 50.55 },
                { totalDaily: 50.56, targetTbDaily: 50.56 },
                { totalDaily: 50.58, targetTbDaily: 50.58 },
                { totalDaily: 50.59, targetTbDaily: 50.59 },
                { totalDaily: 50.6, targetTbDaily: 50.6 },
                { totalDaily: 50.62, targetTbDaily: 50.62 },
                { totalDaily: 50.63, targetTbDaily: 50.63 },
                { totalDaily: 50.64, targetTbDaily: 50.64 },
                { totalDaily: 50.66, targetTbDaily: 50.66 },
                { totalDaily: 50.67, targetTbDaily: 50.67 },
                { totalDaily: 50.68, targetTbDaily: 50.68 },
                { totalDaily: 50.7, targetTbDaily: 50.7 },
                { totalDaily: 50.71, targetTbDaily: 50.71 },
                { totalDaily: 50.72, targetTbDaily: 50.72 },
                { totalDaily: 50.74, targetTbDaily: 50.74 },
                { totalDaily: 50.75, targetTbDaily: 50.75 },
                { totalDaily: 50.76, targetTbDaily: 50.76 },
                { totalDaily: 50.78, targetTbDaily: 50.78 },
                { totalDaily: 50.79, targetTbDaily: 50.79 },
                { totalDaily: 50.8, targetTbDaily: 50.8 },
                { totalDaily: 50.82, targetTbDaily: 50.82 },
                { totalDaily: 50.83, targetTbDaily: 50.83 },
                { totalDaily: 50.84, targetTbDaily: 50.84 },
                { totalDaily: 50.86, targetTbDaily: 50.86 },
                { totalDaily: 50.87, targetTbDaily: 50.87 },
                { totalDaily: 50.88, targetTbDaily: 50.88 },
                { totalDaily: 50.9, targetTbDaily: 50.9 },
                { totalDaily: 50.91, targetTbDaily: 50.91 },
                { totalDaily: 50.92, targetTbDaily: 50.92 },
                { totalDaily: 50.94, targetTbDaily: 50.94 },
                { totalDaily: 50.95, targetTbDaily: 50.95 },
                { totalDaily: 50.96, targetTbDaily: 50.96 },
                { totalDaily: 50.98, targetTbDaily: 50.98 },
                { totalDaily: 50.99, targetTbDaily: 50.99 },
                { totalDaily: 51, targetTbDaily: 51 },
                { totalDaily: 51.02, targetTbDaily: 51.02 },
                { totalDaily: 51.03, targetTbDaily: 51.03 },
                { totalDaily: 51.04, targetTbDaily: 51.04 },
                { totalDaily: 51.06, targetTbDaily: 51.06 },
                { totalDaily: 51.07, targetTbDaily: 51.07 },
                { totalDaily: 51.08, targetTbDaily: 51.08 },
                { totalDaily: 51.1, targetTbDaily: 51.1 },
                { totalDaily: 51.11, targetTbDaily: 51.11 },
                { totalDaily: 51.12, targetTbDaily: 51.12 },
                { totalDaily: 51.14, targetTbDaily: 51.14 },
                { totalDaily: 51.15, targetTbDaily: 51.15 },
                { totalDaily: 51.16, targetTbDaily: 51.16 },
                { totalDaily: 51.18, targetTbDaily: 51.18 },
                { totalDaily: 51.19, targetTbDaily: 51.19 },
                { totalDaily: 51.2, targetTbDaily: 51.2 },
                { totalDaily: 51.22, targetTbDaily: 51.22 },
                { totalDaily: 51.23, targetTbDaily: 51.23 },
                { totalDaily: 51.24, targetTbDaily: 51.24 },
                { totalDaily: 51.26, targetTbDaily: 51.26 },
                { totalDaily: 51.27, targetTbDaily: 51.27 },
                { totalDaily: 51.28, targetTbDaily: 51.28 },
                { totalDaily: 51.3, targetTbDaily: 51.3 },
                { totalDaily: 51.31, targetTbDaily: 51.31 },
                { totalDaily: 51.32, targetTbDaily: 51.32 },
                { totalDaily: 51.34, targetTbDaily: 51.34 },
                { totalDaily: 51.35, targetTbDaily: 51.35 },
                { totalDaily: 51.36, targetTbDaily: 51.36 },
                { totalDaily: 51.38, targetTbDaily: 51.38 },
                { totalDaily: 51.39, targetTbDaily: 51.39 },
                { totalDaily: 51.4, targetTbDaily: 51.4 },
                { totalDaily: 51.42, targetTbDaily: 51.42 },
                { totalDaily: 51.43, targetTbDaily: 51.43 },
                { totalDaily: 51.44, targetTbDaily: 51.44 },
                { totalDaily: 51.46, targetTbDaily: 51.46 },
                { totalDaily: 51.47, targetTbDaily: 51.47 },
                { totalDaily: 51.48, targetTbDaily: 51.48 },
                { totalDaily: 51.5, targetTbDaily: 51.5 },
                { totalDaily: 51.51, targetTbDaily: 51.51 },
                { totalDaily: 51.52, targetTbDaily: 51.52 },
                { totalDaily: 51.54, targetTbDaily: 51.54 },
                { totalDaily: 51.55, targetTbDaily: 51.55 },
                { totalDaily: 51.56, targetTbDaily: 51.56 },
                { totalDaily: 51.58, targetTbDaily: 51.58 },
                { totalDaily: 51.59, targetTbDaily: 51.59 },
                { totalDaily: 51.61, targetTbDaily: 51.61 },
                { totalDaily: 51.62, targetTbDaily: 51.62 },
                { totalDaily: 51.63, targetTbDaily: 51.63 },
                { totalDaily: 51.65, targetTbDaily: 51.65 },
                { totalDaily: 51.66, targetTbDaily: 51.66 },
                { totalDaily: 51.67, targetTbDaily: 51.67 },
                { totalDaily: 51.69, targetTbDaily: 51.69 },
                { totalDaily: 51.7, targetTbDaily: 51.7 },
                { totalDaily: 51.71, targetTbDaily: 51.71 },
                { totalDaily: 51.73, targetTbDaily: 51.73 },
                { totalDaily: 51.74, targetTbDaily: 51.74 },
                { totalDaily: 51.75, targetTbDaily: 51.75 },
                { totalDaily: 51.77, targetTbDaily: 51.77 },
                { totalDaily: 51.78, targetTbDaily: 51.78 },
                { totalDaily: 51.79, targetTbDaily: 51.79 },
                { totalDaily: 51.81, targetTbDaily: 51.81 },
                { totalDaily: 51.82, targetTbDaily: 51.82 },
                { totalDaily: 51.83, targetTbDaily: 51.83 },
                { totalDaily: 51.85, targetTbDaily: 51.85 },
                { totalDaily: 51.86, targetTbDaily: 51.86 },
                { totalDaily: 51.88, targetTbDaily: 51.88 },
                { totalDaily: 51.89, targetTbDaily: 51.89 },
                { totalDaily: 51.9, targetTbDaily: 51.9 },
                { totalDaily: 51.92, targetTbDaily: 51.92 },
                { totalDaily: 51.93, targetTbDaily: 51.93 },
                { totalDaily: 51.94, targetTbDaily: 51.94 },
                { totalDaily: 51.96, targetTbDaily: 51.96 },
                { totalDaily: 51.97, targetTbDaily: 51.97 },
                { totalDaily: 51.98, targetTbDaily: 51.98 },
                { totalDaily: 52, targetTbDaily: 52 },
                { totalDaily: 52.01, targetTbDaily: 52.01 },
                { totalDaily: 52.02, targetTbDaily: 52.02 },
                { totalDaily: 52.04, targetTbDaily: 52.04 },
                { totalDaily: 52.05, targetTbDaily: 52.05 },
                { totalDaily: 52.07, targetTbDaily: 52.07 },
                { totalDaily: 52.08, targetTbDaily: 52.08 },
                { totalDaily: 52.09, targetTbDaily: 52.09 },
                { totalDaily: 52.11, targetTbDaily: 52.11 },
                { totalDaily: 52.12, targetTbDaily: 52.12 },
                { totalDaily: 52.13, targetTbDaily: 52.13 },
                { totalDaily: 52.15, targetTbDaily: 52.15 },
                { totalDaily: 52.16, targetTbDaily: 52.16 },
                { totalDaily: 52.17, targetTbDaily: 52.17 },
                { totalDaily: 52.19, targetTbDaily: 52.19 },
                { totalDaily: 52.2, targetTbDaily: 52.2 },
                { totalDaily: 52.21, targetTbDaily: 52.21 },
                { totalDaily: 52.23, targetTbDaily: 52.23 },
                { totalDaily: 52.24, targetTbDaily: 52.24 },
                { totalDaily: 52.26, targetTbDaily: 52.26 },
                { totalDaily: 52.27, targetTbDaily: 52.27 },
                { totalDaily: 52.28, targetTbDaily: 52.28 },
                { totalDaily: 52.3, targetTbDaily: 52.3 },
                { totalDaily: 52.31, targetTbDaily: 52.31 },
                { totalDaily: 52.32, targetTbDaily: 52.32 },
                { totalDaily: 52.34, targetTbDaily: 52.34 },
                { totalDaily: 52.35, targetTbDaily: 52.35 },
                { totalDaily: 52.37, targetTbDaily: 52.37 },
                { totalDaily: 52.38, targetTbDaily: 52.38 },
                { totalDaily: 52.39, targetTbDaily: 52.39 },
                { totalDaily: 52.41, targetTbDaily: 52.41 },
                { totalDaily: 52.42, targetTbDaily: 52.42 },
                { totalDaily: 52.43, targetTbDaily: 52.43 },
                { totalDaily: 52.45, targetTbDaily: 52.45 },
                { totalDaily: 52.46, targetTbDaily: 52.46 },
                { totalDaily: 52.47, targetTbDaily: 52.47 },
                { totalDaily: 52.49, targetTbDaily: 52.49 },
                { totalDaily: 52.5, targetTbDaily: 52.5 },
                { totalDaily: 52.52, targetTbDaily: 52.52 },
                { totalDaily: 52.53, targetTbDaily: 52.53 },
                { totalDaily: 52.54, targetTbDaily: 52.54 },
                { totalDaily: 52.56, targetTbDaily: 52.56 },
                { totalDaily: 52.57, targetTbDaily: 52.57 },
                { totalDaily: 52.58, targetTbDaily: 52.58 },
                { totalDaily: 52.6, targetTbDaily: 52.6 },
                { totalDaily: 52.61, targetTbDaily: 52.61 },
                { totalDaily: 52.63, targetTbDaily: 52.63 },
                { totalDaily: 52.64, targetTbDaily: 52.64 },
                { totalDaily: 52.65, targetTbDaily: 52.65 },
                { totalDaily: 52.67, targetTbDaily: 52.67 },
                { totalDaily: 52.68, targetTbDaily: 52.68 },
                { totalDaily: 52.69, targetTbDaily: 52.69 },
                { totalDaily: 52.71, targetTbDaily: 52.71 },
                { totalDaily: 52.72, targetTbDaily: 52.72 },
                { totalDaily: 52.74, targetTbDaily: 52.74 },
                { totalDaily: 52.75, targetTbDaily: 52.75 },
                { totalDaily: 52.76, targetTbDaily: 52.76 },
                { totalDaily: 52.78, targetTbDaily: 52.78 },
                { totalDaily: 52.79, targetTbDaily: 52.79 },
                { totalDaily: 52.8, targetTbDaily: 52.8 },
                { totalDaily: 52.82, targetTbDaily: 52.82 },
                { totalDaily: 52.83, targetTbDaily: 52.83 },
                { totalDaily: 52.85, targetTbDaily: 52.85 },
                { totalDaily: 52.86, targetTbDaily: 52.86 },
                { totalDaily: 52.87, targetTbDaily: 52.87 },
                { totalDaily: 52.89, targetTbDaily: 52.89 },
                { totalDaily: 52.9, targetTbDaily: 52.9 },
                { totalDaily: 52.92, targetTbDaily: 52.92 },
                { totalDaily: 52.93, targetTbDaily: 52.93 },
                { totalDaily: 52.94, targetTbDaily: 52.94 },
                { totalDaily: 52.96, targetTbDaily: 52.96 },
                { totalDaily: 52.97, targetTbDaily: 52.97 },
                { totalDaily: 52.98, targetTbDaily: 52.98 },
                { totalDaily: 53, targetTbDaily: 53 },
                { totalDaily: 53.01, targetTbDaily: 53.01 },
                { totalDaily: 53.03, targetTbDaily: 53.03 },
                { totalDaily: 53.04, targetTbDaily: 53.04 },
                { totalDaily: 53.05, targetTbDaily: 53.05 },
                { totalDaily: 53.07, targetTbDaily: 53.07 },
                { totalDaily: 53.08, targetTbDaily: 53.08 },
                { totalDaily: 53.09, targetTbDaily: 53.09 },
                { totalDaily: 53.11, targetTbDaily: 53.11 },
                { totalDaily: 53.12, targetTbDaily: 53.12 },
                { totalDaily: 53.14, targetTbDaily: 53.14 },
                { totalDaily: 53.15, targetTbDaily: 53.15 },
                { totalDaily: 53.16, targetTbDaily: 53.16 },
                { totalDaily: 53.18, targetTbDaily: 53.18 },
                { totalDaily: 53.19, targetTbDaily: 53.19 },
                { totalDaily: 53.21, targetTbDaily: 53.21 },
                { totalDaily: 53.22, targetTbDaily: 53.22 },
                { totalDaily: 53.23, targetTbDaily: 53.23 },
                { totalDaily: 53.25, targetTbDaily: 53.25 },
                { totalDaily: 53.26, targetTbDaily: 53.26 },
                { totalDaily: 53.28, targetTbDaily: 53.28 },
                { totalDaily: 53.29, targetTbDaily: 53.29 },
                { totalDaily: 53.3, targetTbDaily: 53.3 },
                { totalDaily: 53.32, targetTbDaily: 53.32 },
                { totalDaily: 53.33, targetTbDaily: 53.33 },
                { totalDaily: 53.35, targetTbDaily: 53.35 },
                { totalDaily: 53.36, targetTbDaily: 53.36 },
                { totalDaily: 53.37, targetTbDaily: 53.37 },
                { totalDaily: 53.39, targetTbDaily: 53.39 },
                { totalDaily: 53.4, targetTbDaily: 53.4 },
                { totalDaily: 53.41, targetTbDaily: 53.41 },
                { totalDaily: 53.43, targetTbDaily: 53.43 },
                { totalDaily: 53.44, targetTbDaily: 53.44 },
                { totalDaily: 53.46, targetTbDaily: 53.46 },
                { totalDaily: 53.47, targetTbDaily: 53.47 },
                { totalDaily: 53.48, targetTbDaily: 53.48 },
                { totalDaily: 53.5, targetTbDaily: 53.5 },
                { totalDaily: 53.51, targetTbDaily: 53.51 },
                { totalDaily: 53.53, targetTbDaily: 53.53 },
                { totalDaily: 53.54, targetTbDaily: 53.54 },
                { totalDaily: 53.55, targetTbDaily: 53.55 },
                { totalDaily: 53.57, targetTbDaily: 53.57 },
                { totalDaily: 53.58, targetTbDaily: 53.58 },
                { totalDaily: 53.6, targetTbDaily: 53.6 },
                { totalDaily: 53.61, targetTbDaily: 53.61 },
                { totalDaily: 53.62, targetTbDaily: 53.62 },
                { totalDaily: 53.64, targetTbDaily: 53.64 },
                { totalDaily: 53.65, targetTbDaily: 53.65 },
                { totalDaily: 53.67, targetTbDaily: 53.67 },
                { totalDaily: 53.68, targetTbDaily: 53.68 },
                { totalDaily: 53.69, targetTbDaily: 53.69 },
                { totalDaily: 53.71, targetTbDaily: 53.71 },
                { totalDaily: 53.72, targetTbDaily: 53.72 },
                { totalDaily: 53.74, targetTbDaily: 53.74 },
                { totalDaily: 53.75, targetTbDaily: 53.75 },
                { totalDaily: 53.76, targetTbDaily: 53.76 },
                { totalDaily: 53.78, targetTbDaily: 53.78 },
                { totalDaily: 53.79, targetTbDaily: 53.79 },
                { totalDaily: 53.81, targetTbDaily: 53.81 },
                { totalDaily: 53.82, targetTbDaily: 53.82 },
                { totalDaily: 53.83, targetTbDaily: 53.83 },
                { totalDaily: 53.85, targetTbDaily: 53.85 },
                { totalDaily: 53.86, targetTbDaily: 53.86 },
                { totalDaily: 53.88, targetTbDaily: 53.88 },
                { totalDaily: 53.89, targetTbDaily: 53.89 },
                { totalDaily: 53.91, targetTbDaily: 53.91 },
                { totalDaily: 53.92, targetTbDaily: 53.92 },
                { totalDaily: 53.93, targetTbDaily: 53.93 },
                { totalDaily: 53.95, targetTbDaily: 53.95 },
                { totalDaily: 53.96, targetTbDaily: 53.96 },
                { totalDaily: 53.98, targetTbDaily: 53.98 },
                { totalDaily: 53.99, targetTbDaily: 53.99 },
                { totalDaily: 54, targetTbDaily: 54 },
                { totalDaily: 54.02, targetTbDaily: 54.02 },
                { totalDaily: 54.03, targetTbDaily: 54.03 },
                { totalDaily: 54.05, targetTbDaily: 54.05 },
                { totalDaily: 54.06, targetTbDaily: 54.06 },
                { totalDaily: 54.07, targetTbDaily: 54.07 },
                { totalDaily: 54.09, targetTbDaily: 54.09 },
                { totalDaily: 54.1, targetTbDaily: 54.1 },
                { totalDaily: 54.12, targetTbDaily: 54.12 },
                { totalDaily: 54.13, targetTbDaily: 54.13 },
                { totalDaily: 54.15, targetTbDaily: 54.15 },
                { totalDaily: 54.16, targetTbDaily: 54.16 },
                { totalDaily: 54.17, targetTbDaily: 54.17 },
                { totalDaily: 54.19, targetTbDaily: 54.19 },
                { totalDaily: 54.2, targetTbDaily: 54.2 },
                { totalDaily: 54.22, targetTbDaily: 54.22 },
                { totalDaily: 54.23, targetTbDaily: 54.23 },
                { totalDaily: 54.24, targetTbDaily: 54.24 },
                { totalDaily: 54.26, targetTbDaily: 54.26 },
                { totalDaily: 54.27, targetTbDaily: 54.27 },
                { totalDaily: 54.29, targetTbDaily: 54.29 },
                { totalDaily: 54.3, targetTbDaily: 54.3 },
                { totalDaily: 54.31, targetTbDaily: 54.31 },
                { totalDaily: 54.33, targetTbDaily: 54.33 },
                { totalDaily: 54.34, targetTbDaily: 54.34 },
                { totalDaily: 54.36, targetTbDaily: 54.36 },
                { totalDaily: 54.37, targetTbDaily: 54.37 },
                { totalDaily: 54.39, targetTbDaily: 54.39 },
                { totalDaily: 54.4, targetTbDaily: 54.4 },
                { totalDaily: 54.41, targetTbDaily: 54.41 },
                { totalDaily: 54.43, targetTbDaily: 54.43 },
                { totalDaily: 54.44, targetTbDaily: 54.44 },
                { totalDaily: 54.46, targetTbDaily: 54.46 },
                { totalDaily: 54.47, targetTbDaily: 54.47 },
                { totalDaily: 54.49, targetTbDaily: 54.49 },
                { totalDaily: 54.5, targetTbDaily: 54.5 },
                { totalDaily: 54.51, targetTbDaily: 54.51 },
                { totalDaily: 54.53, targetTbDaily: 54.53 },
                { totalDaily: 54.54, targetTbDaily: 54.54 },
                { totalDaily: 54.56, targetTbDaily: 54.56 },
                { totalDaily: 54.57, targetTbDaily: 54.57 },
                { totalDaily: 54.59, targetTbDaily: 54.59 },
                { totalDaily: 54.6, targetTbDaily: 54.6 },
                { totalDaily: 54.61, targetTbDaily: 54.61 },
                { totalDaily: 54.63, targetTbDaily: 54.63 },
                { totalDaily: 54.64, targetTbDaily: 54.64 },
                { totalDaily: 54.66, targetTbDaily: 54.66 },
                { totalDaily: 54.67, targetTbDaily: 54.67 },
                { totalDaily: 54.68, targetTbDaily: 54.68 },
                { totalDaily: 54.7, targetTbDaily: 54.7 },
                { totalDaily: 54.71, targetTbDaily: 54.71 },
                { totalDaily: 54.73, targetTbDaily: 54.73 },
                { totalDaily: 54.74, targetTbDaily: 54.74 },
                { totalDaily: 54.76, targetTbDaily: 54.76 },
                { totalDaily: 54.77, targetTbDaily: 54.77 },
                { totalDaily: 54.78, targetTbDaily: 54.78 },
                { totalDaily: 54.8, targetTbDaily: 54.8 },
                { totalDaily: 54.81, targetTbDaily: 54.81 },
                { totalDaily: 54.83, targetTbDaily: 54.83 },
                { totalDaily: 54.84, targetTbDaily: 54.84 },
                { totalDaily: 54.86, targetTbDaily: 54.86 },
                { totalDaily: 54.87, targetTbDaily: 54.87 },
                { totalDaily: 54.89, targetTbDaily: 54.89 },
                { totalDaily: 54.9, targetTbDaily: 54.9 },
                { totalDaily: 54.91, targetTbDaily: 54.91 },
                { totalDaily: 54.93, targetTbDaily: 54.93 },
                { totalDaily: 54.94, targetTbDaily: 54.94 },
                { totalDaily: 54.96, targetTbDaily: 54.96 },
                { totalDaily: 54.97, targetTbDaily: 54.97 },
                { totalDaily: 54.99, targetTbDaily: 54.99 },
                { totalDaily: 55, targetTbDaily: 55 },
                { totalDaily: 55.01, targetTbDaily: 55.01 },
                { totalDaily: 55.03, targetTbDaily: 55.03 },
                { totalDaily: 55.04, targetTbDaily: 55.04 },
                { totalDaily: 55.06, targetTbDaily: 55.06 },
                { totalDaily: 55.07, targetTbDaily: 55.07 },
                { totalDaily: 55.09, targetTbDaily: 55.09 },
                { totalDaily: 55.1, targetTbDaily: 55.1 },
                { totalDaily: 55.12, targetTbDaily: 55.12 },
                { totalDaily: 55.13, targetTbDaily: 55.13 },
                { totalDaily: 55.14, targetTbDaily: 55.14 },
                { totalDaily: 55.16, targetTbDaily: 55.16 },
                { totalDaily: 55.17, targetTbDaily: 55.17 },
                { totalDaily: 55.19, targetTbDaily: 55.19 },
                { totalDaily: 55.2, targetTbDaily: 55.2 },
                { totalDaily: 55.22, targetTbDaily: 55.22 },
                { totalDaily: 55.23, targetTbDaily: 55.23 },
                { totalDaily: 55.24, targetTbDaily: 55.24 },
                { totalDaily: 55.26, targetTbDaily: 55.26 },
                { totalDaily: 55.27, targetTbDaily: 55.27 },
                { totalDaily: 55.29, targetTbDaily: 55.29 },
                { totalDaily: 55.3, targetTbDaily: 55.3 },
                { totalDaily: 55.32, targetTbDaily: 55.32 },
                { totalDaily: 55.33, targetTbDaily: 55.33 },
                { totalDaily: 55.35, targetTbDaily: 55.35 },
                { totalDaily: 55.36, targetTbDaily: 55.36 },
                { totalDaily: 55.37, targetTbDaily: 55.37 },
                { totalDaily: 55.39, targetTbDaily: 55.39 },
                { totalDaily: 55.4, targetTbDaily: 55.4 },
                { totalDaily: 55.42, targetTbDaily: 55.42 },
                { totalDaily: 55.43, targetTbDaily: 55.43 },
                { totalDaily: 55.45, targetTbDaily: 55.45 },
                { totalDaily: 55.46, targetTbDaily: 55.46 },
                { totalDaily: 55.48, targetTbDaily: 55.48 },
                { totalDaily: 55.49, targetTbDaily: 55.49 },
                { totalDaily: 55.5, targetTbDaily: 55.5 },
                { totalDaily: 55.52, targetTbDaily: 55.52 },
                { totalDaily: 55.53, targetTbDaily: 55.53 },
                { totalDaily: 55.55, targetTbDaily: 55.55 },
                { totalDaily: 55.56, targetTbDaily: 55.56 },
                { totalDaily: 55.58, targetTbDaily: 55.58 },
                { totalDaily: 55.59, targetTbDaily: 55.59 },
                { totalDaily: 55.61, targetTbDaily: 55.61 },
                { totalDaily: 55.62, targetTbDaily: 55.62 },
                { totalDaily: 55.64, targetTbDaily: 55.64 },
                { totalDaily: 55.65, targetTbDaily: 55.65 },
                { totalDaily: 55.66, targetTbDaily: 55.66 },
                { totalDaily: 55.68, targetTbDaily: 55.68 },
                { totalDaily: 55.69, targetTbDaily: 55.69 },
                { totalDaily: 55.71, targetTbDaily: 55.71 },
                { totalDaily: 55.72, targetTbDaily: 55.72 },
                { totalDaily: 55.74, targetTbDaily: 55.74 },
                { totalDaily: 55.75, targetTbDaily: 55.75 },
                { totalDaily: 55.77, targetTbDaily: 55.77 },
                { totalDaily: 55.78, targetTbDaily: 55.78 },
                { totalDaily: 55.8, targetTbDaily: 55.8 },
                { totalDaily: 55.81, targetTbDaily: 55.81 },
                { totalDaily: 55.82, targetTbDaily: 55.82 },
                { totalDaily: 55.84, targetTbDaily: 55.84 },
                { totalDaily: 55.85, targetTbDaily: 55.85 },
                { totalDaily: 55.87, targetTbDaily: 55.87 },
                { totalDaily: 55.88, targetTbDaily: 55.88 },
                { totalDaily: 55.9, targetTbDaily: 55.9 },
                { totalDaily: 55.91, targetTbDaily: 55.91 },
                { totalDaily: 55.93, targetTbDaily: 55.93 },
                { totalDaily: 55.94, targetTbDaily: 55.94 },
                { totalDaily: 55.96, targetTbDaily: 55.96 },
                { totalDaily: 55.97, targetTbDaily: 55.97 },
                { totalDaily: 55.99, targetTbDaily: 55.99 },
                { totalDaily: 56, targetTbDaily: 56 },
                { totalDaily: 56.01, targetTbDaily: 56.01 },
                { totalDaily: 56.03, targetTbDaily: 56.03 },
                { totalDaily: 56.04, targetTbDaily: 56.04 },
                { totalDaily: 56.06, targetTbDaily: 56.06 },
                { totalDaily: 56.07, targetTbDaily: 56.07 },
                { totalDaily: 56.09, targetTbDaily: 56.09 },
                { totalDaily: 56.1, targetTbDaily: 56.1 },
                { totalDaily: 56.12, targetTbDaily: 56.12 },
                { totalDaily: 56.13, targetTbDaily: 56.13 },
                { totalDaily: 56.15, targetTbDaily: 56.15 },
                { totalDaily: 56.16, targetTbDaily: 56.16 },
                { totalDaily: 56.18, targetTbDaily: 56.18 },
                { totalDaily: 56.19, targetTbDaily: 56.19 },
                { totalDaily: 56.21, targetTbDaily: 56.21 },
                { totalDaily: 56.22, targetTbDaily: 56.22 },
                { totalDaily: 56.23, targetTbDaily: 56.23 },
                { totalDaily: 56.25, targetTbDaily: 56.25 },
                { totalDaily: 56.26, targetTbDaily: 56.26 },
                { totalDaily: 56.28, targetTbDaily: 56.28 },
                { totalDaily: 56.29, targetTbDaily: 56.29 },
                { totalDaily: 56.31, targetTbDaily: 56.31 },
                { totalDaily: 56.32, targetTbDaily: 56.32 },
                { totalDaily: 56.34, targetTbDaily: 56.34 },
                { totalDaily: 56.35, targetTbDaily: 56.35 },
                { totalDaily: 56.37, targetTbDaily: 56.37 },
                { totalDaily: 56.38, targetTbDaily: 56.38 },
                { totalDaily: 56.4, targetTbDaily: 56.4 },
                { totalDaily: 56.41, targetTbDaily: 56.41 },
                { totalDaily: 56.43, targetTbDaily: 56.43 },
                { totalDaily: 56.44, targetTbDaily: 56.44 },
                { totalDaily: 56.46, targetTbDaily: 56.46 },
                { totalDaily: 56.47, targetTbDaily: 56.47 },
                { totalDaily: 56.48, targetTbDaily: 56.48 },
                { totalDaily: 56.5, targetTbDaily: 56.5 },
                { totalDaily: 56.51, targetTbDaily: 56.51 },
                { totalDaily: 56.53, targetTbDaily: 56.53 },
                { totalDaily: 56.54, targetTbDaily: 56.54 },
                { totalDaily: 56.56, targetTbDaily: 56.56 },
                { totalDaily: 56.57, targetTbDaily: 56.57 },
                { totalDaily: 56.59, targetTbDaily: 56.59 },
                { totalDaily: 56.6, targetTbDaily: 56.6 },
                { totalDaily: 56.62, targetTbDaily: 56.62 },
                { totalDaily: 56.63, targetTbDaily: 56.63 },
                { totalDaily: 56.65, targetTbDaily: 56.65 },
                { totalDaily: 56.66, targetTbDaily: 56.66 },
                { totalDaily: 56.68, targetTbDaily: 56.68 },
                { totalDaily: 56.69, targetTbDaily: 56.69 },
                { totalDaily: 56.71, targetTbDaily: 56.71 },
                { totalDaily: 56.72, targetTbDaily: 56.72 },
                { totalDaily: 56.74, targetTbDaily: 56.74 },
                { totalDaily: 56.75, targetTbDaily: 56.75 },
                { totalDaily: 56.77, targetTbDaily: 56.77 },
                { totalDaily: 56.78, targetTbDaily: 56.78 },
                { totalDaily: 56.8, targetTbDaily: 56.8 },
                { totalDaily: 56.81, targetTbDaily: 56.81 },
                { totalDaily: 56.82, targetTbDaily: 56.82 },
                { totalDaily: 56.84, targetTbDaily: 56.84 },
                { totalDaily: 56.85, targetTbDaily: 56.85 },
                { totalDaily: 56.87, targetTbDaily: 56.87 },
                { totalDaily: 56.88, targetTbDaily: 56.88 },
                { totalDaily: 56.9, targetTbDaily: 56.9 },
                { totalDaily: 56.91, targetTbDaily: 56.91 },
                { totalDaily: 56.93, targetTbDaily: 56.93 },
                { totalDaily: 56.94, targetTbDaily: 56.94 },
                { totalDaily: 56.96, targetTbDaily: 56.96 },
                { totalDaily: 56.97, targetTbDaily: 56.97 },
                { totalDaily: 56.99, targetTbDaily: 56.99 },
                { totalDaily: 57, targetTbDaily: 57 },
                { totalDaily: 57.02, targetTbDaily: 57.02 },
                { totalDaily: 57.03, targetTbDaily: 57.03 },
                { totalDaily: 57.05, targetTbDaily: 57.05 },
                { totalDaily: 57.06, targetTbDaily: 57.06 },
                { totalDaily: 57.08, targetTbDaily: 57.08 },
                { totalDaily: 57.09, targetTbDaily: 57.09 },
                { totalDaily: 57.11, targetTbDaily: 57.11 },
                { totalDaily: 57.12, targetTbDaily: 57.12 },
                { totalDaily: 57.14, targetTbDaily: 57.14 },
                { totalDaily: 57.15, targetTbDaily: 57.15 },
                { totalDaily: 57.17, targetTbDaily: 57.17 },
                { totalDaily: 57.18, targetTbDaily: 57.18 },
                { totalDaily: 57.2, targetTbDaily: 57.2 },
                { totalDaily: 57.21, targetTbDaily: 57.21 },
                { totalDaily: 57.23, targetTbDaily: 57.23 },
                { totalDaily: 57.24, targetTbDaily: 57.24 },
                { totalDaily: 57.26, targetTbDaily: 57.26 },
                { totalDaily: 57.27, targetTbDaily: 57.27 },
                { totalDaily: 57.29, targetTbDaily: 57.29 },
                { totalDaily: 57.3, targetTbDaily: 57.3 },
                { totalDaily: 57.32, targetTbDaily: 57.32 },
                { totalDaily: 57.33, targetTbDaily: 57.33 },
                { totalDaily: 57.35, targetTbDaily: 57.35 },
                { totalDaily: 57.36, targetTbDaily: 57.36 },
                { totalDaily: 57.38, targetTbDaily: 57.38 },
                { totalDaily: 57.39, targetTbDaily: 57.39 },
                { totalDaily: 57.41, targetTbDaily: 57.41 },
                { totalDaily: 57.42, targetTbDaily: 57.42 },
                { totalDaily: 57.44, targetTbDaily: 57.44 },
                { totalDaily: 57.45, targetTbDaily: 57.45 },
                { totalDaily: 57.47, targetTbDaily: 57.47 },
                { totalDaily: 57.48, targetTbDaily: 57.48 },
                { totalDaily: 57.5, targetTbDaily: 57.5 },
                { totalDaily: 57.51, targetTbDaily: 57.51 },
                { totalDaily: 57.53, targetTbDaily: 57.53 },
                { totalDaily: 57.54, targetTbDaily: 57.54 },
                { totalDaily: 57.56, targetTbDaily: 57.56 },
                { totalDaily: 57.57, targetTbDaily: 57.57 },
                { totalDaily: 57.59, targetTbDaily: 57.59 },
                { totalDaily: 57.6, targetTbDaily: 57.6 },
                { totalDaily: 57.62, targetTbDaily: 57.62 },
                { totalDaily: 57.63, targetTbDaily: 57.63 },
                { totalDaily: 57.65, targetTbDaily: 57.65 },
                { totalDaily: 57.66, targetTbDaily: 57.66 },
                { totalDaily: 57.68, targetTbDaily: 57.68 },
                { totalDaily: 57.69, targetTbDaily: 57.69 },
                { totalDaily: 57.71, targetTbDaily: 57.71 },
                { totalDaily: 57.72, targetTbDaily: 57.72 },
                { totalDaily: 57.74, targetTbDaily: 57.74 },
                { totalDaily: 57.75, targetTbDaily: 57.75 },
                { totalDaily: 57.77, targetTbDaily: 57.77 },
                { totalDaily: 57.78, targetTbDaily: 57.78 },
                { totalDaily: 57.8, targetTbDaily: 57.8 },
                { totalDaily: 57.81, targetTbDaily: 57.81 },
                { totalDaily: 57.83, targetTbDaily: 57.83 },
                { totalDaily: 57.84, targetTbDaily: 57.84 },
                { totalDaily: 57.86, targetTbDaily: 57.86 },
                { totalDaily: 57.87, targetTbDaily: 57.87 },
                { totalDaily: 57.89, targetTbDaily: 57.89 },
                { totalDaily: 57.9, targetTbDaily: 57.9 },
                { totalDaily: 57.92, targetTbDaily: 57.92 },
                { totalDaily: 57.93, targetTbDaily: 57.93 },
                { totalDaily: 57.95, targetTbDaily: 57.95 },
                { totalDaily: 57.96, targetTbDaily: 57.96 },
                { totalDaily: 57.98, targetTbDaily: 57.98 },
                { totalDaily: 57.99, targetTbDaily: 57.99 },
                { totalDaily: 58.01, targetTbDaily: 58.01 },
                { totalDaily: 58.02, targetTbDaily: 58.02 },
                { totalDaily: 58.04, targetTbDaily: 58.04 },
                { totalDaily: 58.05, targetTbDaily: 58.05 },
                { totalDaily: 58.07, targetTbDaily: 58.07 },
                { totalDaily: 58.09, targetTbDaily: 58.09 },
                { totalDaily: 58.1, targetTbDaily: 58.1 },
                { totalDaily: 58.12, targetTbDaily: 58.12 },
                { totalDaily: 58.13, targetTbDaily: 58.13 },
                { totalDaily: 58.15, targetTbDaily: 58.15 },
                { totalDaily: 58.16, targetTbDaily: 58.16 },
                { totalDaily: 58.18, targetTbDaily: 58.18 },
                { totalDaily: 58.19, targetTbDaily: 58.19 },
                { totalDaily: 58.21, targetTbDaily: 58.21 },
                { totalDaily: 58.22, targetTbDaily: 58.22 },
                { totalDaily: 58.24, targetTbDaily: 58.24 },
                { totalDaily: 58.25, targetTbDaily: 58.25 },
                { totalDaily: 58.27, targetTbDaily: 58.27 },
                { totalDaily: 58.28, targetTbDaily: 58.28 },
                { totalDaily: 58.3, targetTbDaily: 58.3 },
                { totalDaily: 58.31, targetTbDaily: 58.31 },
                { totalDaily: 58.33, targetTbDaily: 58.33 },
                { totalDaily: 58.34, targetTbDaily: 58.34 },
                { totalDaily: 58.36, targetTbDaily: 58.36 },
                { totalDaily: 58.37, targetTbDaily: 58.37 },
                { totalDaily: 58.39, targetTbDaily: 58.39 },
                { totalDaily: 58.4, targetTbDaily: 58.4 },
                { totalDaily: 58.42, targetTbDaily: 58.42 },
                { totalDaily: 58.43, targetTbDaily: 58.43 },
                { totalDaily: 58.45, targetTbDaily: 58.45 },
                { totalDaily: 58.47, targetTbDaily: 58.47 },
                { totalDaily: 58.48, targetTbDaily: 58.48 },
                { totalDaily: 58.5, targetTbDaily: 58.5 },
                { totalDaily: 58.51, targetTbDaily: 58.51 },
                { totalDaily: 58.53, targetTbDaily: 58.53 },
                { totalDaily: 58.54, targetTbDaily: 58.54 },
                { totalDaily: 58.56, targetTbDaily: 58.56 },
                { totalDaily: 58.57, targetTbDaily: 58.57 },
                { totalDaily: 58.59, targetTbDaily: 58.59 },
                { totalDaily: 58.6, targetTbDaily: 58.6 },
                { totalDaily: 58.62, targetTbDaily: 58.62 },
                { totalDaily: 58.63, targetTbDaily: 58.63 },
                { totalDaily: 58.65, targetTbDaily: 58.65 },
                { totalDaily: 58.66, targetTbDaily: 58.66 },
                { totalDaily: 58.68, targetTbDaily: 58.68 },
                { totalDaily: 58.69, targetTbDaily: 58.69 },
                { totalDaily: 58.71, targetTbDaily: 58.71 },
                { totalDaily: 58.73, targetTbDaily: 58.73 },
                { totalDaily: 58.74, targetTbDaily: 58.74 },
                { totalDaily: 58.76, targetTbDaily: 58.76 },
                { totalDaily: 58.77, targetTbDaily: 58.77 },
                { totalDaily: 58.79, targetTbDaily: 58.79 },
                { totalDaily: 58.8, targetTbDaily: 58.8 },
                { totalDaily: 58.82, targetTbDaily: 58.82 },
                { totalDaily: 58.83, targetTbDaily: 58.83 },
                { totalDaily: 58.85, targetTbDaily: 58.85 },
                { totalDaily: 58.86, targetTbDaily: 58.86 },
                { totalDaily: 58.88, targetTbDaily: 58.88 },
                { totalDaily: 58.89, targetTbDaily: 58.89 },
                { totalDaily: 58.91, targetTbDaily: 58.91 },
                { totalDaily: 58.93, targetTbDaily: 58.93 },
                { totalDaily: 58.94, targetTbDaily: 58.94 },
                { totalDaily: 58.96, targetTbDaily: 58.96 },
                { totalDaily: 58.97, targetTbDaily: 58.97 },
                { totalDaily: 58.99, targetTbDaily: 58.99 },
                { totalDaily: 59, targetTbDaily: 59 },
                { totalDaily: 59.02, targetTbDaily: 59.02 },
                { totalDaily: 59.03, targetTbDaily: 59.03 },
                { totalDaily: 59.05, targetTbDaily: 59.05 },
                { totalDaily: 59.06, targetTbDaily: 59.06 },
                { totalDaily: 59.08, targetTbDaily: 59.08 },
                { totalDaily: 59.09, targetTbDaily: 59.09 },
                { totalDaily: 59.11, targetTbDaily: 59.11 },
                { totalDaily: 59.13, targetTbDaily: 59.13 },
                { totalDaily: 59.14, targetTbDaily: 59.14 },
                { totalDaily: 59.16, targetTbDaily: 59.16 },
                { totalDaily: 59.17, targetTbDaily: 59.17 },
                { totalDaily: 59.19, targetTbDaily: 59.19 },
                { totalDaily: 59.2, targetTbDaily: 59.2 },
                { totalDaily: 59.22, targetTbDaily: 59.22 },
                { totalDaily: 59.23, targetTbDaily: 59.23 },
                { totalDaily: 59.25, targetTbDaily: 59.25 },
                { totalDaily: 59.26, targetTbDaily: 59.26 },
                { totalDaily: 59.28, targetTbDaily: 59.28 },
                { totalDaily: 59.3, targetTbDaily: 59.3 },
                { totalDaily: 59.31, targetTbDaily: 59.31 },
                { totalDaily: 59.33, targetTbDaily: 59.33 },
                { totalDaily: 59.34, targetTbDaily: 59.34 },
                { totalDaily: 59.36, targetTbDaily: 59.36 },
                { totalDaily: 59.37, targetTbDaily: 59.37 },
                { totalDaily: 59.39, targetTbDaily: 59.39 },
                { totalDaily: 59.4, targetTbDaily: 59.4 },
                { totalDaily: 59.42, targetTbDaily: 59.42 },
                { totalDaily: 59.44, targetTbDaily: 59.44 },
                { totalDaily: 59.45, targetTbDaily: 59.45 },
                { totalDaily: 59.47, targetTbDaily: 59.47 },
                { totalDaily: 59.48, targetTbDaily: 59.48 },
                { totalDaily: 59.5, targetTbDaily: 59.5 },
                { totalDaily: 59.51, targetTbDaily: 59.51 },
                { totalDaily: 59.53, targetTbDaily: 59.53 },
                { totalDaily: 59.54, targetTbDaily: 59.54 },
                { totalDaily: 59.56, targetTbDaily: 59.56 },
                { totalDaily: 59.58, targetTbDaily: 59.58 },
                { totalDaily: 59.59, targetTbDaily: 59.59 },
                { totalDaily: 59.61, targetTbDaily: 59.61 },
                { totalDaily: 59.62, targetTbDaily: 59.62 },
                { totalDaily: 59.64, targetTbDaily: 59.64 },
                { totalDaily: 59.65, targetTbDaily: 59.65 },
                { totalDaily: 59.67, targetTbDaily: 59.67 },
                { totalDaily: 59.68, targetTbDaily: 59.68 },
                { totalDaily: 59.7, targetTbDaily: 59.7 },
                { totalDaily: 59.72, targetTbDaily: 59.72 },
                { totalDaily: 59.73, targetTbDaily: 59.73 },
                { totalDaily: 59.75, targetTbDaily: 59.75 },
                { totalDaily: 59.76, targetTbDaily: 59.76 },
                { totalDaily: 59.78, targetTbDaily: 59.78 },
                { totalDaily: 59.79, targetTbDaily: 59.79 },
                { totalDaily: 59.81, targetTbDaily: 59.81 },
                { totalDaily: 59.82, targetTbDaily: 59.82 },
                { totalDaily: 59.84, targetTbDaily: 59.84 },
                { totalDaily: 59.86, targetTbDaily: 59.86 },
                { totalDaily: 59.87, targetTbDaily: 59.87 },
                { totalDaily: 59.89, targetTbDaily: 59.89 },
                { totalDaily: 59.9, targetTbDaily: 59.9 },
                { totalDaily: 59.92, targetTbDaily: 59.92 },
                { totalDaily: 59.93, targetTbDaily: 59.93 },
                { totalDaily: 59.95, targetTbDaily: 59.95 },
                { totalDaily: 59.97, targetTbDaily: 59.97 },
                { totalDaily: 59.98, targetTbDaily: 59.98 },
                { totalDaily: 60, targetTbDaily: 60 },
                { totalDaily: 60.01, targetTbDaily: 60.01 },
                { totalDaily: 60.03, targetTbDaily: 60.03 },
                { totalDaily: 60.04, targetTbDaily: 60.04 },
                { totalDaily: 60.06, targetTbDaily: 60.06 },
                { totalDaily: 60.07, targetTbDaily: 60.07 },
                { totalDaily: 60.09, targetTbDaily: 60.09 },
                { totalDaily: 60.11, targetTbDaily: 60.11 },
                { totalDaily: 60.12, targetTbDaily: 60.12 },
                { totalDaily: 60.14, targetTbDaily: 60.14 },
                { totalDaily: 60.15, targetTbDaily: 60.15 },
                { totalDaily: 60.17, targetTbDaily: 60.17 },
                { totalDaily: 60.18, targetTbDaily: 60.18 },
                { totalDaily: 60.2, targetTbDaily: 60.2 },
                { totalDaily: 60.22, targetTbDaily: 60.22 },
                { totalDaily: 60.23, targetTbDaily: 60.23 },
                { totalDaily: 60.25, targetTbDaily: 60.25 },
                { totalDaily: 60.26, targetTbDaily: 60.26 },
                { totalDaily: 60.28, targetTbDaily: 60.28 },
                { totalDaily: 60.29, targetTbDaily: 60.29 },
                { totalDaily: 60.31, targetTbDaily: 60.31 },
                { totalDaily: 60.33, targetTbDaily: 60.33 },
                { totalDaily: 60.34, targetTbDaily: 60.34 },
                { totalDaily: 60.36, targetTbDaily: 60.36 },
                { totalDaily: 60.37, targetTbDaily: 60.37 },
                { totalDaily: 60.39, targetTbDaily: 60.39 },
                { totalDaily: 60.41, targetTbDaily: 60.41 },
                { totalDaily: 60.42, targetTbDaily: 60.42 },
                { totalDaily: 60.44, targetTbDaily: 60.44 },
                { totalDaily: 60.45, targetTbDaily: 60.45 },
                { totalDaily: 60.47, targetTbDaily: 60.47 },
                { totalDaily: 60.48, targetTbDaily: 60.48 },
                { totalDaily: 60.5, targetTbDaily: 60.5 },
                { totalDaily: 60.52, targetTbDaily: 60.52 },
                { totalDaily: 60.53, targetTbDaily: 60.53 },
                { totalDaily: 60.55, targetTbDaily: 60.55 },
                { totalDaily: 60.56, targetTbDaily: 60.56 },
                { totalDaily: 60.58, targetTbDaily: 60.58 },
                { totalDaily: 60.59, targetTbDaily: 60.59 },
                { totalDaily: 60.61, targetTbDaily: 60.61 },
                { totalDaily: 60.63, targetTbDaily: 60.63 },
                { totalDaily: 60.64, targetTbDaily: 60.64 },
                { totalDaily: 60.66, targetTbDaily: 60.66 },
                { totalDaily: 60.67, targetTbDaily: 60.67 },
                { totalDaily: 60.69, targetTbDaily: 60.69 },
                { totalDaily: 60.71, targetTbDaily: 60.71 },
                { totalDaily: 60.72, targetTbDaily: 60.72 },
                { totalDaily: 60.74, targetTbDaily: 60.74 },
                { totalDaily: 60.75, targetTbDaily: 60.75 },
                { totalDaily: 60.77, targetTbDaily: 60.77 },
                { totalDaily: 60.79, targetTbDaily: 60.79 },
                { totalDaily: 60.8, targetTbDaily: 60.8 },
                { totalDaily: 60.82, targetTbDaily: 60.82 },
                { totalDaily: 60.83, targetTbDaily: 60.83 },
                { totalDaily: 60.85, targetTbDaily: 60.85 },
                { totalDaily: 60.86, targetTbDaily: 60.86 },
                { totalDaily: 60.88, targetTbDaily: 60.88 },
                { totalDaily: 60.9, targetTbDaily: 60.9 },
                { totalDaily: 60.91, targetTbDaily: 60.91 },
                { totalDaily: 60.93, targetTbDaily: 60.93 },
                { totalDaily: 60.94, targetTbDaily: 60.94 },
                { totalDaily: 60.96, targetTbDaily: 60.96 },
                { totalDaily: 60.98, targetTbDaily: 60.98 },
                { totalDaily: 60.99, targetTbDaily: 60.99 },
                { totalDaily: 61.01, targetTbDaily: 61.01 },
                { totalDaily: 61.02, targetTbDaily: 61.02 },
                { totalDaily: 61.04, targetTbDaily: 61.04 },
                { totalDaily: 61.06, targetTbDaily: 61.06 },
                { totalDaily: 61.07, targetTbDaily: 61.07 },
                { totalDaily: 61.09, targetTbDaily: 61.09 },
                { totalDaily: 61.1, targetTbDaily: 61.1 },
                { totalDaily: 61.12, targetTbDaily: 61.12 },
                { totalDaily: 61.14, targetTbDaily: 61.14 },
                { totalDaily: 61.15, targetTbDaily: 61.15 },
                { totalDaily: 61.17, targetTbDaily: 61.17 },
                { totalDaily: 61.18, targetTbDaily: 61.18 },
                { totalDaily: 61.2, targetTbDaily: 61.2 },
                { totalDaily: 61.22, targetTbDaily: 61.22 },
                { totalDaily: 61.23, targetTbDaily: 61.23 },
                { totalDaily: 61.25, targetTbDaily: 61.25 },
                { totalDaily: 61.26, targetTbDaily: 61.26 },
                { totalDaily: 61.28, targetTbDaily: 61.28 },
                { totalDaily: 61.3, targetTbDaily: 61.3 },
                { totalDaily: 61.31, targetTbDaily: 61.31 },
                { totalDaily: 61.33, targetTbDaily: 61.33 },
                { totalDaily: 61.34, targetTbDaily: 61.34 },
                { totalDaily: 61.36, targetTbDaily: 61.36 },
                { totalDaily: 61.38, targetTbDaily: 61.38 },
                { totalDaily: 61.39, targetTbDaily: 61.39 },
                { totalDaily: 61.41, targetTbDaily: 61.41 },
                { totalDaily: 61.42, targetTbDaily: 61.42 },
                { totalDaily: 61.44, targetTbDaily: 61.44 },
                { totalDaily: 61.46, targetTbDaily: 61.46 },
                { totalDaily: 61.47, targetTbDaily: 61.47 },
                { totalDaily: 61.49, targetTbDaily: 61.49 },
                { totalDaily: 61.5, targetTbDaily: 61.5 },
                { totalDaily: 61.52, targetTbDaily: 61.52 },
                { totalDaily: 61.54, targetTbDaily: 61.54 },
                { totalDaily: 61.55, targetTbDaily: 61.55 },
                { totalDaily: 61.57, targetTbDaily: 61.57 },
                { totalDaily: 61.58, targetTbDaily: 61.58 },
                { totalDaily: 61.6, targetTbDaily: 61.6 },
                { totalDaily: 61.62, targetTbDaily: 61.62 },
                { totalDaily: 61.63, targetTbDaily: 61.63 },
                { totalDaily: 61.65, targetTbDaily: 61.65 },
                { totalDaily: 61.66, targetTbDaily: 61.66 },
                { totalDaily: 61.68, targetTbDaily: 61.68 },
                { totalDaily: 61.7, targetTbDaily: 61.7 },
                { totalDaily: 61.71, targetTbDaily: 61.71 },
                { totalDaily: 61.73, targetTbDaily: 61.73 },
                { totalDaily: 61.74, targetTbDaily: 61.74 },
                { totalDaily: 61.76, targetTbDaily: 61.76 },
                { totalDaily: 61.78, targetTbDaily: 61.78 },
                { totalDaily: 61.79, targetTbDaily: 61.79 },
                { totalDaily: 61.81, targetTbDaily: 61.81 },
                { totalDaily: 61.83, targetTbDaily: 61.83 },
                { totalDaily: 61.84, targetTbDaily: 61.84 },
                { totalDaily: 61.86, targetTbDaily: 61.86 },
                { totalDaily: 61.87, targetTbDaily: 61.87 },
                { totalDaily: 61.89, targetTbDaily: 61.89 },
                { totalDaily: 61.91, targetTbDaily: 61.91 },
                { totalDaily: 61.92, targetTbDaily: 61.92 },
                { totalDaily: 61.94, targetTbDaily: 61.94 },
                { totalDaily: 61.95, targetTbDaily: 61.95 },
                { totalDaily: 61.97, targetTbDaily: 61.97 },
                { totalDaily: 61.99, targetTbDaily: 61.99 },
                { totalDaily: 62, targetTbDaily: 62 },
                { totalDaily: 62.02, targetTbDaily: 62.02 },
                { totalDaily: 62.04, targetTbDaily: 62.04 },
                { totalDaily: 62.05, targetTbDaily: 62.05 },
                { totalDaily: 62.07, targetTbDaily: 62.07 },
                { totalDaily: 62.08, targetTbDaily: 62.08 },
                { totalDaily: 62.1, targetTbDaily: 62.1 },
                { totalDaily: 62.12, targetTbDaily: 62.12 },
                { totalDaily: 62.13, targetTbDaily: 62.13 },
                { totalDaily: 62.15, targetTbDaily: 62.15 },
                { totalDaily: 62.17, targetTbDaily: 62.17 },
                { totalDaily: 62.18, targetTbDaily: 62.18 },
                { totalDaily: 62.2, targetTbDaily: 62.2 },
                { totalDaily: 62.21, targetTbDaily: 62.21 },
                { totalDaily: 62.23, targetTbDaily: 62.23 },
                { totalDaily: 62.25, targetTbDaily: 62.25 },
                { totalDaily: 62.26, targetTbDaily: 62.26 },
                { totalDaily: 62.28, targetTbDaily: 62.28 },
                { totalDaily: 62.3, targetTbDaily: 62.3 },
                { totalDaily: 62.31, targetTbDaily: 62.31 },
                { totalDaily: 62.33, targetTbDaily: 62.33 },
                { totalDaily: 62.34, targetTbDaily: 62.34 },
                { totalDaily: 62.36, targetTbDaily: 62.36 },
                { totalDaily: 62.38, targetTbDaily: 62.38 },
                { totalDaily: 62.39, targetTbDaily: 62.39 },
                { totalDaily: 62.41, targetTbDaily: 62.41 },
                { totalDaily: 62.43, targetTbDaily: 62.43 },
                { totalDaily: 62.44, targetTbDaily: 62.44 },
                { totalDaily: 62.46, targetTbDaily: 62.46 },
                { totalDaily: 62.47, targetTbDaily: 62.47 },
                { totalDaily: 62.49, targetTbDaily: 62.49 },
                { totalDaily: 62.51, targetTbDaily: 62.51 },
                { totalDaily: 62.52, targetTbDaily: 62.52 },
                { totalDaily: 62.54, targetTbDaily: 62.54 },
                { totalDaily: 62.56, targetTbDaily: 62.56 },
                { totalDaily: 62.57, targetTbDaily: 62.57 },
                { totalDaily: 62.59, targetTbDaily: 62.59 },
                { totalDaily: 62.61, targetTbDaily: 62.61 },
                { totalDaily: 62.62, targetTbDaily: 62.62 },
                { totalDaily: 62.64, targetTbDaily: 62.64 },
                { totalDaily: 62.65, targetTbDaily: 62.65 },
                { totalDaily: 62.67, targetTbDaily: 62.67 },
                { totalDaily: 62.69, targetTbDaily: 62.69 },
                { totalDaily: 62.7, targetTbDaily: 62.7 },
                { totalDaily: 62.72, targetTbDaily: 62.72 },
                { totalDaily: 62.74, targetTbDaily: 62.74 },
                { totalDaily: 62.75, targetTbDaily: 62.75 },
                { totalDaily: 62.77, targetTbDaily: 62.77 },
                { totalDaily: 62.79, targetTbDaily: 62.79 },
                { totalDaily: 62.8, targetTbDaily: 62.8 },
                { totalDaily: 62.82, targetTbDaily: 62.82 },
                { totalDaily: 62.83, targetTbDaily: 62.83 },
                { totalDaily: 62.85, targetTbDaily: 62.85 },
                { totalDaily: 62.87, targetTbDaily: 62.87 },
                { totalDaily: 62.88, targetTbDaily: 62.88 },
                { totalDaily: 62.9, targetTbDaily: 62.9 },
                { totalDaily: 62.92, targetTbDaily: 62.92 },
                { totalDaily: 62.93, targetTbDaily: 62.93 },
                { totalDaily: 62.95, targetTbDaily: 62.95 },
                { totalDaily: 62.97, targetTbDaily: 62.97 },
                { totalDaily: 62.98, targetTbDaily: 62.98 },
                { totalDaily: 63, targetTbDaily: 63 },
                { totalDaily: 63.02, targetTbDaily: 63.02 },
                { totalDaily: 63.03, targetTbDaily: 63.03 },
                { totalDaily: 63.05, targetTbDaily: 63.05 },
                { totalDaily: 63.06, targetTbDaily: 63.06 },
                { totalDaily: 63.08, targetTbDaily: 63.08 },
                { totalDaily: 63.1, targetTbDaily: 63.1 },
                { totalDaily: 63.11, targetTbDaily: 63.11 },
                { totalDaily: 63.13, targetTbDaily: 63.13 },
                { totalDaily: 63.15, targetTbDaily: 63.15 },
                { totalDaily: 63.16, targetTbDaily: 63.16 },
                { totalDaily: 63.18, targetTbDaily: 63.18 },
                { totalDaily: 63.2, targetTbDaily: 63.2 },
                { totalDaily: 63.21, targetTbDaily: 63.21 },
                { totalDaily: 63.23, targetTbDaily: 63.23 },
                { totalDaily: 63.25, targetTbDaily: 63.25 },
                { totalDaily: 63.26, targetTbDaily: 63.26 },
                { totalDaily: 63.28, targetTbDaily: 63.28 },
                { totalDaily: 63.3, targetTbDaily: 63.3 },
                { totalDaily: 63.31, targetTbDaily: 63.31 },
                { totalDaily: 63.33, targetTbDaily: 63.33 },
                { totalDaily: 63.35, targetTbDaily: 63.35 },
                { totalDaily: 63.36, targetTbDaily: 63.36 },
                { totalDaily: 63.38, targetTbDaily: 63.38 },
                { totalDaily: 63.39, targetTbDaily: 63.39 },
                { totalDaily: 63.41, targetTbDaily: 63.41 },
                { totalDaily: 63.43, targetTbDaily: 63.43 },
                { totalDaily: 63.44, targetTbDaily: 63.44 },
                { totalDaily: 63.46, targetTbDaily: 63.46 },
                { totalDaily: 63.48, targetTbDaily: 63.48 },
                { totalDaily: 63.49, targetTbDaily: 63.49 },
                { totalDaily: 63.51, targetTbDaily: 63.51 },
                { totalDaily: 63.53, targetTbDaily: 63.53 },
                { totalDaily: 63.54, targetTbDaily: 63.54 },
                { totalDaily: 63.56, targetTbDaily: 63.56 },
                { totalDaily: 63.58, targetTbDaily: 63.58 },
                { totalDaily: 63.59, targetTbDaily: 63.59 },
                { totalDaily: 63.61, targetTbDaily: 63.61 },
                { totalDaily: 63.63, targetTbDaily: 63.63 },
                { totalDaily: 63.64, targetTbDaily: 63.64 },
                { totalDaily: 63.66, targetTbDaily: 63.66 },
                { totalDaily: 63.68, targetTbDaily: 63.68 },
                { totalDaily: 63.69, targetTbDaily: 63.69 },
                { totalDaily: 63.71, targetTbDaily: 63.71 },
                { totalDaily: 63.73, targetTbDaily: 63.73 },
                { totalDaily: 63.74, targetTbDaily: 63.74 },
                { totalDaily: 63.76, targetTbDaily: 63.76 },
                { totalDaily: 63.78, targetTbDaily: 63.78 },
                { totalDaily: 63.79, targetTbDaily: 63.79 },
                { totalDaily: 63.81, targetTbDaily: 63.81 },
                { totalDaily: 63.83, targetTbDaily: 63.83 },
                { totalDaily: 63.84, targetTbDaily: 63.84 },
                { totalDaily: 63.86, targetTbDaily: 63.86 },
                { totalDaily: 63.88, targetTbDaily: 63.88 },
                { totalDaily: 63.89, targetTbDaily: 63.89 },
                { totalDaily: 63.91, targetTbDaily: 63.91 },
                { totalDaily: 63.93, targetTbDaily: 63.93 },
                { totalDaily: 63.94, targetTbDaily: 63.94 },
                { totalDaily: 63.96, targetTbDaily: 63.96 },
                { totalDaily: 63.98, targetTbDaily: 63.98 },
                { totalDaily: 63.99, targetTbDaily: 63.99 },
                { totalDaily: 64.01, targetTbDaily: 64.01 },
                { totalDaily: 64.03, targetTbDaily: 64.03 },
                { totalDaily: 64.04, targetTbDaily: 64.04 },
                { totalDaily: 64.06, targetTbDaily: 64.06 },
                { totalDaily: 64.08, targetTbDaily: 64.08 },
                { totalDaily: 64.09, targetTbDaily: 64.09 },
                { totalDaily: 64.11, targetTbDaily: 64.11 },
                { totalDaily: 64.13, targetTbDaily: 64.13 },
                { totalDaily: 64.14, targetTbDaily: 64.14 },
                { totalDaily: 64.16, targetTbDaily: 64.16 },
                { totalDaily: 64.18, targetTbDaily: 64.18 },
                { totalDaily: 64.19, targetTbDaily: 64.19 },
                { totalDaily: 64.2, targetTbDaily: 64.2 },
                { totalDaily: 64.23, targetTbDaily: 64.23 },
                { totalDaily: 64.23, targetTbDaily: 64.23 },
                { totalDaily: 64.26, targetTbDaily: 64.26 },
                { totalDaily: 64.28, targetTbDaily: 64.28 },
                { totalDaily: 64.3, targetTbDaily: 64.3 },
                { totalDaily: 64.31, targetTbDaily: 64.31 },
                { totalDaily: 64.33, targetTbDaily: 64.33 },
                { totalDaily: 64.34, targetTbDaily: 64.34 },
                { totalDaily: 64.36, targetTbDaily: 64.36 },
                { totalDaily: 64.38, targetTbDaily: 64.38 },
                { totalDaily: 64.4, targetTbDaily: 64.4 },
                { totalDaily: 64.41, targetTbDaily: 64.41 },
                { totalDaily: 64.43, targetTbDaily: 64.43 },
                { totalDaily: 64.45, targetTbDaily: 64.45 },
                { totalDaily: 64.45, targetTbDaily: 64.45 },
                { totalDaily: 64.48, targetTbDaily: 64.48 },
                { totalDaily: 64.5, targetTbDaily: 64.5 },
                { totalDaily: 64.51, targetTbDaily: 64.51 },
                { totalDaily: 64.53, targetTbDaily: 64.53 },
                { totalDaily: 64.55, targetTbDaily: 64.55 },
                { totalDaily: 64.56, targetTbDaily: 64.56 },
                { totalDaily: 64.58, targetTbDaily: 64.58 },
                { totalDaily: 64.59, targetTbDaily: 64.59 },
                { totalDaily: 64.61, targetTbDaily: 64.61 },
                { totalDaily: 64.63, targetTbDaily: 64.63 },
                { totalDaily: 64.65, targetTbDaily: 64.65 },
                { totalDaily: 64.67, targetTbDaily: 64.67 },
                { totalDaily: 64.68, targetTbDaily: 64.68 },
                { totalDaily: 64.7, targetTbDaily: 64.7 },
                { totalDaily: 64.72, targetTbDaily: 64.72 },
                { totalDaily: 64.73, targetTbDaily: 64.73 },
                { totalDaily: 64.75, targetTbDaily: 64.75 },
                { totalDaily: 64.77, targetTbDaily: 64.77 },
                { totalDaily: 64.78, targetTbDaily: 64.78 },
                { totalDaily: 64.8, targetTbDaily: 64.8 },
                { totalDaily: 64.81, targetTbDaily: 64.81 },
                { totalDaily: 64.83, targetTbDaily: 64.83 },
                { totalDaily: 64.84, targetTbDaily: 64.84 },
                { totalDaily: 64.87, targetTbDaily: 64.87 },
                { totalDaily: 64.89, targetTbDaily: 64.89 },
                { totalDaily: 64.9, targetTbDaily: 64.9 },
                { totalDaily: 64.92, targetTbDaily: 64.92 },
                { totalDaily: 64.94, targetTbDaily: 64.94 },
                { totalDaily: 64.95, targetTbDaily: 64.95 },
                { totalDaily: 64.97, targetTbDaily: 64.97 },
                { totalDaily: 64.98, targetTbDaily: 64.98 },
                { totalDaily: 65, targetTbDaily: 65 },
                { totalDaily: 65.02, targetTbDaily: 65.02 },
                { totalDaily: 65.04, targetTbDaily: 65.04 },
                { totalDaily: 65.06, targetTbDaily: 65.06 },
                { totalDaily: 65.06, targetTbDaily: 65.06 },
                { totalDaily: 65.09, targetTbDaily: 65.09 },
                { totalDaily: 65.11, targetTbDaily: 65.11 },
                { totalDaily: 65.12, targetTbDaily: 65.12 },
                { totalDaily: 65.14, targetTbDaily: 65.14 },
                { totalDaily: 65.16, targetTbDaily: 65.16 },
                { totalDaily: 65.17, targetTbDaily: 65.17 },
                { totalDaily: 65.19, targetTbDaily: 65.19 },
                { totalDaily: 65.2, targetTbDaily: 65.2 },
                { totalDaily: 65.23, targetTbDaily: 65.23 },
                { totalDaily: 65.23, targetTbDaily: 65.23 },
                { totalDaily: 65.26, targetTbDaily: 65.26 },
                { totalDaily: 65.28, targetTbDaily: 65.28 },
                { totalDaily: 65.29, targetTbDaily: 65.29 },
                { totalDaily: 65.31, targetTbDaily: 65.31 },
                { totalDaily: 65.33, targetTbDaily: 65.33 },
                { totalDaily: 65.34, targetTbDaily: 65.34 },
                { totalDaily: 65.36, targetTbDaily: 65.36 },
                { totalDaily: 65.38, targetTbDaily: 65.38 },
                { totalDaily: 65.4, targetTbDaily: 65.4 },
                { totalDaily: 65.41, targetTbDaily: 65.41 },
                { totalDaily: 65.43, targetTbDaily: 65.43 },
                { totalDaily: 65.45, targetTbDaily: 65.45 },
                { totalDaily: 65.45, targetTbDaily: 65.45 },
                { totalDaily: 65.48, targetTbDaily: 65.48 },
                { totalDaily: 65.5, targetTbDaily: 65.5 },
                { totalDaily: 65.52, targetTbDaily: 65.52 },
                { totalDaily: 65.53, targetTbDaily: 65.53 },
                { totalDaily: 65.55, targetTbDaily: 65.55 },
                { totalDaily: 65.56, targetTbDaily: 65.56 },
                { totalDaily: 65.58, targetTbDaily: 65.58 },
                { totalDaily: 65.59, targetTbDaily: 65.59 },
                { totalDaily: 65.62, targetTbDaily: 65.62 },
                { totalDaily: 65.64, targetTbDaily: 65.64 },
                { totalDaily: 65.65, targetTbDaily: 65.65 },
                { totalDaily: 65.67, targetTbDaily: 65.67 },
                { totalDaily: 65.69, targetTbDaily: 65.69 },
                { totalDaily: 65.7, targetTbDaily: 65.7 },
                { totalDaily: 65.72, targetTbDaily: 65.72 },
                { totalDaily: 65.73, targetTbDaily: 65.73 },
                { totalDaily: 65.76, targetTbDaily: 65.76 },
                { totalDaily: 65.77, targetTbDaily: 65.77 },
                { totalDaily: 65.79, targetTbDaily: 65.79 },
                { totalDaily: 65.81, targetTbDaily: 65.81 },
                { totalDaily: 65.81, targetTbDaily: 65.81 },
                { totalDaily: 65.84, targetTbDaily: 65.84 },
                { totalDaily: 65.86, targetTbDaily: 65.86 },
                { totalDaily: 65.88, targetTbDaily: 65.88 },
                { totalDaily: 65.89, targetTbDaily: 65.89 },
                { totalDaily: 65.91, targetTbDaily: 65.91 },
                { totalDaily: 65.93, targetTbDaily: 65.93 },
                { totalDaily: 65.94, targetTbDaily: 65.94 },
                { totalDaily: 65.95, targetTbDaily: 65.95 },
                { totalDaily: 65.98, targetTbDaily: 65.98 },
                { totalDaily: 66, targetTbDaily: 66 },
                { totalDaily: 66.01, targetTbDaily: 66.01 },
                { totalDaily: 66.03, targetTbDaily: 66.03 },
                { totalDaily: 66.05, targetTbDaily: 66.05 },
                { totalDaily: 66.06, targetTbDaily: 66.06 },
                { totalDaily: 66.08, targetTbDaily: 66.08 },
                { totalDaily: 66.09, targetTbDaily: 66.09 },
                { totalDaily: 66.12, targetTbDaily: 66.12 },
                { totalDaily: 66.13, targetTbDaily: 66.13 },
                { totalDaily: 66.15, targetTbDaily: 66.15 },
                { totalDaily: 66.17, targetTbDaily: 66.17 },
                { totalDaily: 66.19, targetTbDaily: 66.19 },
                { totalDaily: 66.2, targetTbDaily: 66.2 },
                { totalDaily: 66.22, targetTbDaily: 66.22 },
                { totalDaily: 66.23, targetTbDaily: 66.23 },
                { totalDaily: 66.26, targetTbDaily: 66.26 },
                { totalDaily: 66.27, targetTbDaily: 66.27 },
                { totalDaily: 66.29, targetTbDaily: 66.29 },
                { totalDaily: 66.31, targetTbDaily: 66.31 },
                { totalDaily: 66.31, targetTbDaily: 66.31 },
                { totalDaily: 66.34, targetTbDaily: 66.34 },
                { totalDaily: 66.36, targetTbDaily: 66.36 },
                { totalDaily: 66.38, targetTbDaily: 66.38 },
                { totalDaily: 66.39, targetTbDaily: 66.39 },
                { totalDaily: 66.41, targetTbDaily: 66.41 },
                { totalDaily: 66.43, targetTbDaily: 66.43 },
                { totalDaily: 66.45, targetTbDaily: 66.45 },
                { totalDaily: 66.45, targetTbDaily: 66.45 },
                { totalDaily: 66.48, targetTbDaily: 66.48 },
                { totalDaily: 66.5, targetTbDaily: 66.5 },
                { totalDaily: 66.52, targetTbDaily: 66.52 },
                { totalDaily: 66.53, targetTbDaily: 66.53 },
                { totalDaily: 66.55, targetTbDaily: 66.55 },
                { totalDaily: 66.56, targetTbDaily: 66.56 },
                { totalDaily: 66.58, targetTbDaily: 66.58 },
                { totalDaily: 66.59, targetTbDaily: 66.59 },
                { totalDaily: 66.62, targetTbDaily: 66.62 },
                { totalDaily: 66.64, targetTbDaily: 66.64 },
                { totalDaily: 66.65, targetTbDaily: 66.65 },
                { totalDaily: 66.67, targetTbDaily: 66.67 },
                { totalDaily: 66.69, targetTbDaily: 66.69 },
                { totalDaily: 66.7, targetTbDaily: 66.7 },
                { totalDaily: 66.72, targetTbDaily: 66.72 },
                { totalDaily: 66.73, targetTbDaily: 66.73 },
                { totalDaily: 66.76, targetTbDaily: 66.76 },
                { totalDaily: 66.78, targetTbDaily: 66.78 },
                { totalDaily: 66.79, targetTbDaily: 66.79 },
                { totalDaily: 66.81, targetTbDaily: 66.81 },
                { totalDaily: 66.83, targetTbDaily: 66.83 },
                { totalDaily: 66.84, targetTbDaily: 66.84 },
                { totalDaily: 66.86, targetTbDaily: 66.86 },
                { totalDaily: 66.88, targetTbDaily: 66.88 },
                { totalDaily: 66.9, targetTbDaily: 66.9 },
                { totalDaily: 66.92, targetTbDaily: 66.92 },
                { totalDaily: 66.93, targetTbDaily: 66.93 },
                { totalDaily: 66.95, targetTbDaily: 66.95 },
                { totalDaily: 66.97, targetTbDaily: 66.97 },
                { totalDaily: 66.98, targetTbDaily: 66.98 },
                { totalDaily: 67, targetTbDaily: 67 },
                { totalDaily: 67.02, targetTbDaily: 67.02 },
                { totalDaily: 67.04, targetTbDaily: 67.04 },
                { totalDaily: 67.06, targetTbDaily: 67.06 },
                { totalDaily: 67.06, targetTbDaily: 67.06 },
                { totalDaily: 67.09, targetTbDaily: 67.09 },
                { totalDaily: 67.11, targetTbDaily: 67.11 },
                { totalDaily: 67.13, targetTbDaily: 67.13 },
                { totalDaily: 67.14, targetTbDaily: 67.14 },
                { totalDaily: 67.16, targetTbDaily: 67.16 },
                { totalDaily: 67.18, targetTbDaily: 67.18 },
                { totalDaily: 67.2, targetTbDaily: 67.2 },
                { totalDaily: 67.2, targetTbDaily: 67.2 },
                { totalDaily: 67.23, targetTbDaily: 67.23 },
                { totalDaily: 67.25, targetTbDaily: 67.25 },
                { totalDaily: 67.27, targetTbDaily: 67.27 },
                { totalDaily: 67.28, targetTbDaily: 67.28 },
                { totalDaily: 67.3, targetTbDaily: 67.3 },
                { totalDaily: 67.31, targetTbDaily: 67.31 },
                { totalDaily: 67.34, targetTbDaily: 67.34 },
                { totalDaily: 67.34, targetTbDaily: 67.34 },
                { totalDaily: 67.37, targetTbDaily: 67.37 },
                { totalDaily: 67.39, targetTbDaily: 67.39 },
                { totalDaily: 67.41, targetTbDaily: 67.41 },
                { totalDaily: 67.42, targetTbDaily: 67.42 },
                { totalDaily: 67.44, targetTbDaily: 67.44 },
                { totalDaily: 67.45, targetTbDaily: 67.45 },
                { totalDaily: 67.48, targetTbDaily: 67.48 },
                { totalDaily: 67.5, targetTbDaily: 67.5 },
                { totalDaily: 67.51, targetTbDaily: 67.51 },
                { totalDaily: 67.53, targetTbDaily: 67.53 },
                { totalDaily: 67.55, targetTbDaily: 67.55 },
                { totalDaily: 67.56, targetTbDaily: 67.56 },
                { totalDaily: 67.58, targetTbDaily: 67.58 },
                { totalDaily: 67.59, targetTbDaily: 67.59 },
                { totalDaily: 67.62, targetTbDaily: 67.62 },
                { totalDaily: 67.64, targetTbDaily: 67.64 },
                { totalDaily: 67.65, targetTbDaily: 67.65 },
                { totalDaily: 67.67, targetTbDaily: 67.67 },
                { totalDaily: 67.69, targetTbDaily: 67.69 },
                { totalDaily: 67.7, targetTbDaily: 67.7 },
                { totalDaily: 67.72, targetTbDaily: 67.72 },
                { totalDaily: 67.73, targetTbDaily: 67.73 },
                { totalDaily: 67.76, targetTbDaily: 67.76 },
                { totalDaily: 67.78, targetTbDaily: 67.78 },
                { totalDaily: 67.8, targetTbDaily: 67.8 },
                { totalDaily: 67.81, targetTbDaily: 67.81 },
                { totalDaily: 67.83, targetTbDaily: 67.83 },
                { totalDaily: 67.84, targetTbDaily: 67.84 },
                { totalDaily: 67.87, targetTbDaily: 67.87 },
                { totalDaily: 67.88, targetTbDaily: 67.88 },
                { totalDaily: 67.9, targetTbDaily: 67.9 },
                { totalDaily: 67.92, targetTbDaily: 67.92 },
                { totalDaily: 67.94, targetTbDaily: 67.94 },
                { totalDaily: 67.95, targetTbDaily: 67.95 },
                { totalDaily: 67.97, targetTbDaily: 67.97 },
                { totalDaily: 67.98, targetTbDaily: 67.98 },
                { totalDaily: 68.01, targetTbDaily: 68.01 },
                { totalDaily: 68.03, targetTbDaily: 68.03 },
                { totalDaily: 68.04, targetTbDaily: 68.04 },
                { totalDaily: 68.06, targetTbDaily: 68.06 },
                { totalDaily: 68.08, targetTbDaily: 68.08 },
                { totalDaily: 68.09, targetTbDaily: 68.09 },
                { totalDaily: 68.11, targetTbDaily: 68.11 },
                { totalDaily: 68.13, targetTbDaily: 68.13 },
                { totalDaily: 68.15, targetTbDaily: 68.15 },
                { totalDaily: 68.17, targetTbDaily: 68.17 },
                { totalDaily: 68.19, targetTbDaily: 68.19 },
                { totalDaily: 68.2, targetTbDaily: 68.2 },
                { totalDaily: 68.22, targetTbDaily: 68.22 },
                { totalDaily: 68.23, targetTbDaily: 68.23 },
                { totalDaily: 68.26, targetTbDaily: 68.26 },
                { totalDaily: 68.28, targetTbDaily: 68.28 },
                { totalDaily: 68.29, targetTbDaily: 68.29 },
                { totalDaily: 68.31, targetTbDaily: 68.31 },
                { totalDaily: 68.33, targetTbDaily: 68.33 },
                { totalDaily: 68.34, targetTbDaily: 68.34 },
                { totalDaily: 68.36, targetTbDaily: 68.36 },
                { totalDaily: 68.38, targetTbDaily: 68.38 },
                { totalDaily: 68.4, targetTbDaily: 68.4 },
                { totalDaily: 68.42, targetTbDaily: 68.42 },
                { totalDaily: 68.44, targetTbDaily: 68.44 },
                { totalDaily: 68.45, targetTbDaily: 68.45 },
                { totalDaily: 68.47, targetTbDaily: 68.47 },
                { totalDaily: 68.48, targetTbDaily: 68.48 },
                { totalDaily: 68.51, targetTbDaily: 68.51 },
                { totalDaily: 68.53, targetTbDaily: 68.53 },
                { totalDaily: 68.54, targetTbDaily: 68.54 },
                { totalDaily: 68.56, targetTbDaily: 68.56 },
                { totalDaily: 68.58, targetTbDaily: 68.58 },
                { totalDaily: 68.59, targetTbDaily: 68.59 },
                { totalDaily: 68.61, targetTbDaily: 68.61 },
                { totalDaily: 68.63, targetTbDaily: 68.63 },
                { totalDaily: 68.65, targetTbDaily: 68.65 },
                { totalDaily: 68.67, targetTbDaily: 68.67 },
                { totalDaily: 68.69, targetTbDaily: 68.69 },
                { totalDaily: 68.7, targetTbDaily: 68.7 },
                { totalDaily: 68.72, targetTbDaily: 68.72 },
                { totalDaily: 68.73, targetTbDaily: 68.73 },
                { totalDaily: 68.76, targetTbDaily: 68.76 },
                { totalDaily: 68.78, targetTbDaily: 68.78 },
                { totalDaily: 68.79, targetTbDaily: 68.79 },
                { totalDaily: 68.81, targetTbDaily: 68.81 },
                { totalDaily: 68.83, targetTbDaily: 68.83 },
                { totalDaily: 68.84, targetTbDaily: 68.84 },
                { totalDaily: 68.87, targetTbDaily: 68.87 },
                { totalDaily: 68.88, targetTbDaily: 68.88 },
                { totalDaily: 68.9, targetTbDaily: 68.9 },
                { totalDaily: 68.92, targetTbDaily: 68.92 },
                { totalDaily: 68.94, targetTbDaily: 68.94 },
                { totalDaily: 68.95, targetTbDaily: 68.95 },
                { totalDaily: 68.97, targetTbDaily: 68.97 },
                { totalDaily: 68.98, targetTbDaily: 68.98 },
                { totalDaily: 69.01, targetTbDaily: 69.01 },
                { totalDaily: 69.03, targetTbDaily: 69.03 },
                { totalDaily: 69.05, targetTbDaily: 69.05 },
                { totalDaily: 69.06, targetTbDaily: 69.06 },
                { totalDaily: 69.08, targetTbDaily: 69.08 },
                { totalDaily: 69.09, targetTbDaily: 69.09 },
                { totalDaily: 69.12, targetTbDaily: 69.12 },
                { totalDaily: 69.14, targetTbDaily: 69.14 },
                { totalDaily: 69.15, targetTbDaily: 69.15 },
                { totalDaily: 69.17, targetTbDaily: 69.17 },
                { totalDaily: 69.19, targetTbDaily: 69.19 },
                { totalDaily: 69.2, targetTbDaily: 69.2 },
                { totalDaily: 69.23, targetTbDaily: 69.23 },
                { totalDaily: 69.23, targetTbDaily: 69.23 },
                { totalDaily: 69.26, targetTbDaily: 69.26 },
                { totalDaily: 69.28, targetTbDaily: 69.28 },
                { totalDaily: 69.3, targetTbDaily: 69.3 },
                { totalDaily: 69.31, targetTbDaily: 69.31 },
                { totalDaily: 69.34, targetTbDaily: 69.34 },
                { totalDaily: 69.34, targetTbDaily: 69.34 },
                { totalDaily: 69.37, targetTbDaily: 69.37 },
                { totalDaily: 69.39, targetTbDaily: 69.39 },
                { totalDaily: 69.41, targetTbDaily: 69.41 },
                { totalDaily: 69.43, targetTbDaily: 69.43 },
                { totalDaily: 69.44, targetTbDaily: 69.44 },
                { totalDaily: 69.45, targetTbDaily: 69.45 },
                { totalDaily: 69.48, targetTbDaily: 69.48 },
                { totalDaily: 69.5, targetTbDaily: 69.5 },
                { totalDaily: 69.52, targetTbDaily: 69.52 },
                { totalDaily: 69.53, targetTbDaily: 69.53 },
                { totalDaily: 69.55, targetTbDaily: 69.55 },
                { totalDaily: 69.56, targetTbDaily: 69.56 },
                { totalDaily: 69.59, targetTbDaily: 69.59 },
                { totalDaily: 69.61, targetTbDaily: 69.61 },
                { totalDaily: 69.63, targetTbDaily: 69.63 },
                { totalDaily: 69.64, targetTbDaily: 69.64 },
                { totalDaily: 69.66, targetTbDaily: 69.66 },
                { totalDaily: 69.68, targetTbDaily: 69.68 },
                { totalDaily: 69.7, targetTbDaily: 69.7 },
                { totalDaily: 69.72, targetTbDaily: 69.72 },
                { totalDaily: 69.73, targetTbDaily: 69.73 },
                { totalDaily: 69.75, targetTbDaily: 69.75 },
                { totalDaily: 69.77, targetTbDaily: 69.77 },
                { totalDaily: 69.79, targetTbDaily: 69.79 },
                { totalDaily: 69.81, targetTbDaily: 69.81 },
                { totalDaily: 69.83, targetTbDaily: 69.83 },
                { totalDaily: 69.84, targetTbDaily: 69.84 },
                { totalDaily: 69.86, targetTbDaily: 69.86 },
                { totalDaily: 69.88, targetTbDaily: 69.88 },
                { totalDaily: 69.9, targetTbDaily: 69.9 },
                { totalDaily: 69.92, targetTbDaily: 69.92 },
                { totalDaily: 69.94, targetTbDaily: 69.94 },
                { totalDaily: 69.95, targetTbDaily: 69.95 },
                { totalDaily: 69.97, targetTbDaily: 69.97 },
                { totalDaily: 69.98, targetTbDaily: 69.98 },
                { totalDaily: 70.01, targetTbDaily: 70.01 },
                { totalDaily: 70.03, targetTbDaily: 70.03 },
                { totalDaily: 70.04, targetTbDaily: 70.04 },
                { totalDaily: 70.06, targetTbDaily: 70.06 },
                { totalDaily: 70.08, targetTbDaily: 70.08 },
                { totalDaily: 70.09, targetTbDaily: 70.09 },
                { totalDaily: 70.12, targetTbDaily: 70.12 },
                { totalDaily: 70.14, targetTbDaily: 70.14 },
                { totalDaily: 70.15, targetTbDaily: 70.15 },
                { totalDaily: 70.17, targetTbDaily: 70.17 },
                { totalDaily: 70.19, targetTbDaily: 70.19 },
                { totalDaily: 70.2, targetTbDaily: 70.2 },
                { totalDaily: 70.23, targetTbDaily: 70.23 },
                { totalDaily: 70.25, targetTbDaily: 70.25 },
                { totalDaily: 70.26, targetTbDaily: 70.26 },
                { totalDaily: 70.28, targetTbDaily: 70.28 },
                { totalDaily: 70.3, targetTbDaily: 70.3 },
                { totalDaily: 70.31, targetTbDaily: 70.31 },
                { totalDaily: 70.34, targetTbDaily: 70.34 },
                { totalDaily: 70.36, targetTbDaily: 70.36 },
                { totalDaily: 70.37, targetTbDaily: 70.37 },
                { totalDaily: 70.39, targetTbDaily: 70.39 },
                { totalDaily: 70.41, targetTbDaily: 70.41 },
                { totalDaily: 70.43, targetTbDaily: 70.43 },
                { totalDaily: 70.45, targetTbDaily: 70.45 },
                { totalDaily: 70.47, targetTbDaily: 70.47 },
                { totalDaily: 70.48, targetTbDaily: 70.48 },
                { totalDaily: 70.5, targetTbDaily: 70.5 },
                { totalDaily: 70.52, targetTbDaily: 70.52 },
                { totalDaily: 70.54, targetTbDaily: 70.54 },
                { totalDaily: 70.56, targetTbDaily: 70.56 },
                { totalDaily: 70.58, targetTbDaily: 70.58 },
                { totalDaily: 70.59, targetTbDaily: 70.59 },
                { totalDaily: 70.61, targetTbDaily: 70.61 },
                { totalDaily: 70.63, targetTbDaily: 70.63 },
                { totalDaily: 70.65, targetTbDaily: 70.65 },
                { totalDaily: 70.67, targetTbDaily: 70.67 },
                { totalDaily: 70.69, targetTbDaily: 70.69 },
                { totalDaily: 70.7, targetTbDaily: 70.7 },
                { totalDaily: 70.72, targetTbDaily: 70.72 },
                { totalDaily: 70.73, targetTbDaily: 70.73 },
                { totalDaily: 70.76, targetTbDaily: 70.76 },
                { totalDaily: 70.78, targetTbDaily: 70.78 },
                { totalDaily: 70.8, targetTbDaily: 70.8 },
                { totalDaily: 70.81, targetTbDaily: 70.81 },
                { totalDaily: 70.84, targetTbDaily: 70.84 },
                { totalDaily: 70.84, targetTbDaily: 70.84 },
                { totalDaily: 70.87, targetTbDaily: 70.87 },
                { totalDaily: 70.89, targetTbDaily: 70.89 },
                { totalDaily: 70.91, targetTbDaily: 70.91 },
                { totalDaily: 70.93, targetTbDaily: 70.93 },
                { totalDaily: 70.95, targetTbDaily: 70.95 },
                { totalDaily: 70.97, targetTbDaily: 70.97 },
                { totalDaily: 70.98, targetTbDaily: 70.98 },
                { totalDaily: 71, targetTbDaily: 71 },
                { totalDaily: 71.02, targetTbDaily: 71.02 },
                { totalDaily: 71.04, targetTbDaily: 71.04 },
                { totalDaily: 71.06, targetTbDaily: 71.06 },
                { totalDaily: 71.08, targetTbDaily: 71.08 },
                { totalDaily: 71.09, targetTbDaily: 71.09 },
                { totalDaily: 71.11, targetTbDaily: 71.11 },
                { totalDaily: 71.13, targetTbDaily: 71.13 },
                { totalDaily: 71.15, targetTbDaily: 71.15 },
                { totalDaily: 71.17, targetTbDaily: 71.17 },
                { totalDaily: 71.19, targetTbDaily: 71.19 },
                { totalDaily: 71.2, targetTbDaily: 71.2 },
                { totalDaily: 71.23, targetTbDaily: 71.23 },
                { totalDaily: 71.23, targetTbDaily: 71.23 },
                { totalDaily: 71.26, targetTbDaily: 71.26 },
                { totalDaily: 71.28, targetTbDaily: 71.28 },
                { totalDaily: 71.3, targetTbDaily: 71.3 },
                { totalDaily: 71.31, targetTbDaily: 71.31 },
                { totalDaily: 71.34, targetTbDaily: 71.34 },
                { totalDaily: 71.36, targetTbDaily: 71.36 },
                { totalDaily: 71.37, targetTbDaily: 71.37 },
                { totalDaily: 71.39, targetTbDaily: 71.39 },
                { totalDaily: 71.41, targetTbDaily: 71.41 },
                { totalDaily: 71.43, targetTbDaily: 71.43 },
                { totalDaily: 71.45, targetTbDaily: 71.45 },
                { totalDaily: 71.47, targetTbDaily: 71.47 },
                { totalDaily: 71.48, targetTbDaily: 71.48 },
                { totalDaily: 71.5, targetTbDaily: 71.5 },
                { totalDaily: 71.52, targetTbDaily: 71.52 },
                { totalDaily: 71.54, targetTbDaily: 71.54 },
                { totalDaily: 71.56, targetTbDaily: 71.56 },
                { totalDaily: 71.58, targetTbDaily: 71.58 },
                { totalDaily: 71.59, targetTbDaily: 71.59 },
                { totalDaily: 71.62, targetTbDaily: 71.62 },
                { totalDaily: 71.64, targetTbDaily: 71.64 },
                { totalDaily: 71.65, targetTbDaily: 71.65 },
                { totalDaily: 71.67, targetTbDaily: 71.67 },
                { totalDaily: 71.69, targetTbDaily: 71.69 },
                { totalDaily: 71.7, targetTbDaily: 71.7 },
                { totalDaily: 71.73, targetTbDaily: 71.73 },
                { totalDaily: 71.75, targetTbDaily: 71.75 },
                { totalDaily: 71.77, targetTbDaily: 71.77 },
                { totalDaily: 71.79, targetTbDaily: 71.79 },
                { totalDaily: 71.8, targetTbDaily: 71.8 },
                { totalDaily: 71.81, targetTbDaily: 71.81 },
                { totalDaily: 71.84, targetTbDaily: 71.84 },
                { totalDaily: 71.86, targetTbDaily: 71.86 },
                { totalDaily: 71.88, targetTbDaily: 71.88 },
                { totalDaily: 71.9, targetTbDaily: 71.9 },
                { totalDaily: 71.92, targetTbDaily: 71.92 },
                { totalDaily: 71.94, targetTbDaily: 71.94 },
                { totalDaily: 71.95, targetTbDaily: 71.95 },
                { totalDaily: 71.97, targetTbDaily: 71.97 },
                { totalDaily: 71.98, targetTbDaily: 71.98 },
                { totalDaily: 72.01, targetTbDaily: 72.01 },
                { totalDaily: 72.03, targetTbDaily: 72.03 },
                { totalDaily: 72.05, targetTbDaily: 72.05 },
                { totalDaily: 72.06, targetTbDaily: 72.06 },
                { totalDaily: 72.09, targetTbDaily: 72.09 },
                { totalDaily: 72.09, targetTbDaily: 72.09 },
                { totalDaily: 72.12, targetTbDaily: 72.12 },
                { totalDaily: 72.14, targetTbDaily: 72.14 },
                { totalDaily: 72.16, targetTbDaily: 72.16 },
                { totalDaily: 72.18, targetTbDaily: 72.18 },
                { totalDaily: 72.2, targetTbDaily: 72.2 },
                { totalDaily: 72.22, targetTbDaily: 72.22 },
                { totalDaily: 72.23, targetTbDaily: 72.23 },
                { totalDaily: 72.26, targetTbDaily: 72.26 },
                { totalDaily: 72.27, targetTbDaily: 72.27 },
                { totalDaily: 72.29, targetTbDaily: 72.29 },
                { totalDaily: 72.31, targetTbDaily: 72.31 },
                { totalDaily: 72.33, targetTbDaily: 72.33 },
                { totalDaily: 72.34, targetTbDaily: 72.34 },
                { totalDaily: 72.37, targetTbDaily: 72.37 },
                { totalDaily: 72.39, targetTbDaily: 72.39 },
                { totalDaily: 72.41, targetTbDaily: 72.41 },
                { totalDaily: 72.43, targetTbDaily: 72.43 },
                { totalDaily: 72.44, targetTbDaily: 72.44 },
                { totalDaily: 72.45, targetTbDaily: 72.45 },
                { totalDaily: 72.48, targetTbDaily: 72.48 },
                { totalDaily: 72.5, targetTbDaily: 72.5 },
                { totalDaily: 72.52, targetTbDaily: 72.52 },
                { totalDaily: 72.54, targetTbDaily: 72.54 },
                { totalDaily: 72.56, targetTbDaily: 72.56 },
                { totalDaily: 72.58, targetTbDaily: 72.58 },
                { totalDaily: 72.59, targetTbDaily: 72.59 },
                { totalDaily: 72.61, targetTbDaily: 72.61 },
                { totalDaily: 72.63, targetTbDaily: 72.63 },
                { totalDaily: 72.65, targetTbDaily: 72.65 },
                { totalDaily: 72.67, targetTbDaily: 72.67 },
                { totalDaily: 72.69, targetTbDaily: 72.69 },
                { totalDaily: 72.7, targetTbDaily: 72.7 },
                { totalDaily: 72.73, targetTbDaily: 72.73 },
                { totalDaily: 72.75, targetTbDaily: 72.75 },
                { totalDaily: 72.77, targetTbDaily: 72.77 },
                { totalDaily: 72.79, targetTbDaily: 72.79 },
                { totalDaily: 72.8, targetTbDaily: 72.8 },
                { totalDaily: 72.81, targetTbDaily: 72.81 },
                { totalDaily: 72.84, targetTbDaily: 72.84 },
                { totalDaily: 72.86, targetTbDaily: 72.86 },
                { totalDaily: 72.88, targetTbDaily: 72.88 },
                { totalDaily: 72.9, targetTbDaily: 72.9 },
                { totalDaily: 72.92, targetTbDaily: 72.92 },
                { totalDaily: 72.94, targetTbDaily: 72.94 },
                { totalDaily: 72.95, targetTbDaily: 72.95 },
                { totalDaily: 72.98, targetTbDaily: 72.98 },
                { totalDaily: 73, targetTbDaily: 73 },
                { totalDaily: 73.01, targetTbDaily: 73.01 },
                { totalDaily: 73.03, targetTbDaily: 73.03 },
                { totalDaily: 73.05, targetTbDaily: 73.05 },
                { totalDaily: 73.06, targetTbDaily: 73.06 },
                { totalDaily: 73.09, targetTbDaily: 73.09 },
                { totalDaily: 73.11, targetTbDaily: 73.11 },
                { totalDaily: 73.13, targetTbDaily: 73.13 },
                { totalDaily: 73.15, targetTbDaily: 73.15 },
                { totalDaily: 73.17, targetTbDaily: 73.17 },
                { totalDaily: 73.19, targetTbDaily: 73.19 },
                { totalDaily: 73.2, targetTbDaily: 73.2 },
                { totalDaily: 73.22, targetTbDaily: 73.22 },
                { totalDaily: 73.23, targetTbDaily: 73.23 },
                { totalDaily: 73.26, targetTbDaily: 73.26 },
                { totalDaily: 73.28, targetTbDaily: 73.28 },
                { totalDaily: 73.3, targetTbDaily: 73.3 },
                { totalDaily: 73.31, targetTbDaily: 73.31 },
                { totalDaily: 73.34, targetTbDaily: 73.34 },
                { totalDaily: 73.36, targetTbDaily: 73.36 },
                { totalDaily: 73.38, targetTbDaily: 73.38 },
                { totalDaily: 73.4, targetTbDaily: 73.4 },
                { totalDaily: 73.42, targetTbDaily: 73.42 },
                { totalDaily: 73.43, targetTbDaily: 73.43 },
                { totalDaily: 73.45, targetTbDaily: 73.45 },
                { totalDaily: 73.47, targetTbDaily: 73.47 },
                { totalDaily: 73.48, targetTbDaily: 73.48 },
                { totalDaily: 73.51, targetTbDaily: 73.51 },
                { totalDaily: 73.53, targetTbDaily: 73.53 },
                { totalDaily: 73.55, targetTbDaily: 73.55 },
                { totalDaily: 73.56, targetTbDaily: 73.56 },
                { totalDaily: 73.59, targetTbDaily: 73.59 },
                { totalDaily: 73.61, targetTbDaily: 73.61 },
                { totalDaily: 73.63, targetTbDaily: 73.63 },
                { totalDaily: 73.65, targetTbDaily: 73.65 },
                { totalDaily: 73.67, targetTbDaily: 73.67 },
                { totalDaily: 73.68, targetTbDaily: 73.68 },
                { totalDaily: 73.7, targetTbDaily: 73.7 },
                { totalDaily: 73.72, targetTbDaily: 73.72 },
                { totalDaily: 73.73, targetTbDaily: 73.73 },
                { totalDaily: 73.76, targetTbDaily: 73.76 },
                { totalDaily: 73.78, targetTbDaily: 73.78 },
                { totalDaily: 73.8, targetTbDaily: 73.8 },
                { totalDaily: 73.81, targetTbDaily: 73.81 },
                { totalDaily: 73.84, targetTbDaily: 73.84 },
                { totalDaily: 73.86, targetTbDaily: 73.86 },
                { totalDaily: 73.88, targetTbDaily: 73.88 },
                { totalDaily: 73.9, targetTbDaily: 73.9 },
                { totalDaily: 73.92, targetTbDaily: 73.92 },
                { totalDaily: 73.94, targetTbDaily: 73.94 },
                { totalDaily: 73.95, targetTbDaily: 73.95 },
                { totalDaily: 73.97, targetTbDaily: 73.97 },
                { totalDaily: 73.98, targetTbDaily: 73.98 },
                { totalDaily: 74.01, targetTbDaily: 74.01 },
                { totalDaily: 74.03, targetTbDaily: 74.03 },
                { totalDaily: 74.05, targetTbDaily: 74.05 },
                { totalDaily: 74.06, targetTbDaily: 74.06 },
                { totalDaily: 74.09, targetTbDaily: 74.09 },
                { totalDaily: 74.11, targetTbDaily: 74.11 },
                { totalDaily: 74.13, targetTbDaily: 74.13 },
                { totalDaily: 74.15, targetTbDaily: 74.15 },
                { totalDaily: 74.17, targetTbDaily: 74.17 },
                { totalDaily: 74.19, targetTbDaily: 74.19 },
                { totalDaily: 74.2, targetTbDaily: 74.2 },
                { totalDaily: 74.23, targetTbDaily: 74.23 },
                { totalDaily: 74.23, targetTbDaily: 74.23 },
                { totalDaily: 74.26, targetTbDaily: 74.26 },
                { totalDaily: 74.28, targetTbDaily: 74.28 },
                { totalDaily: 74.3, targetTbDaily: 74.3 },
                { totalDaily: 74.31, targetTbDaily: 74.31 },
                { totalDaily: 74.34, targetTbDaily: 74.34 },
                { totalDaily: 74.36, targetTbDaily: 74.36 },
                { totalDaily: 74.38, targetTbDaily: 74.38 },
                { totalDaily: 74.4, targetTbDaily: 74.4 },
                { totalDaily: 74.42, targetTbDaily: 74.42 },
                { totalDaily: 74.44, targetTbDaily: 74.44 },
                { totalDaily: 74.45, targetTbDaily: 74.45 },
                { totalDaily: 74.48, targetTbDaily: 74.48 },
                { totalDaily: 74.5, targetTbDaily: 74.5 },
                { totalDaily: 74.52, targetTbDaily: 74.52 },
                { totalDaily: 74.54, targetTbDaily: 74.54 },
                { totalDaily: 74.56, targetTbDaily: 74.56 },
                { totalDaily: 74.56, targetTbDaily: 74.56 },
                { totalDaily: 74.59, targetTbDaily: 74.59 },
                { totalDaily: 74.61, targetTbDaily: 74.61 },
                { totalDaily: 74.63, targetTbDaily: 74.63 },
                { totalDaily: 74.65, targetTbDaily: 74.65 },
                { totalDaily: 74.67, targetTbDaily: 74.67 },
                { totalDaily: 74.69, targetTbDaily: 74.69 },
                { totalDaily: 74.7, targetTbDaily: 74.7 },
                { totalDaily: 74.73, targetTbDaily: 74.73 },
                { totalDaily: 74.75, targetTbDaily: 74.75 },
                { totalDaily: 74.77, targetTbDaily: 74.77 },
                { totalDaily: 74.79, targetTbDaily: 74.79 },
                { totalDaily: 74.81, targetTbDaily: 74.81 },
                { totalDaily: 74.83, targetTbDaily: 74.83 },
                { totalDaily: 74.84, targetTbDaily: 74.84 },
                { totalDaily: 74.87, targetTbDaily: 74.87 },
                { totalDaily: 74.89, targetTbDaily: 74.89 },
                { totalDaily: 74.91, targetTbDaily: 74.91 },
                { totalDaily: 74.93, targetTbDaily: 74.93 },
                { totalDaily: 74.95, targetTbDaily: 74.95 },
                { totalDaily: 74.97, targetTbDaily: 74.97 },
                { totalDaily: 74.98, targetTbDaily: 74.98 },
                { totalDaily: 75, targetTbDaily: 75 },
                { totalDaily: 75.02, targetTbDaily: 75.02 },
                { totalDaily: 75.04, targetTbDaily: 75.04 },
                { totalDaily: 75.06, targetTbDaily: 75.06 },
                { totalDaily: 75.08, targetTbDaily: 75.08 },
                { totalDaily: 75.09, targetTbDaily: 75.09 },
                { totalDaily: 75.12, targetTbDaily: 75.12 },
                { totalDaily: 75.14, targetTbDaily: 75.14 },
                { totalDaily: 75.16, targetTbDaily: 75.16 },
                { totalDaily: 75.18, targetTbDaily: 75.18 },
                { totalDaily: 75.2, targetTbDaily: 75.2 },
                { totalDaily: 75.22, targetTbDaily: 75.22 },
                { totalDaily: 75.23, targetTbDaily: 75.23 },
                { totalDaily: 75.26, targetTbDaily: 75.26 },
                { totalDaily: 75.28, targetTbDaily: 75.28 },
                { totalDaily: 75.3, targetTbDaily: 75.3 },
                { totalDaily: 75.31, targetTbDaily: 75.31 },
                { totalDaily: 75.34, targetTbDaily: 75.34 },
                { totalDaily: 75.36, targetTbDaily: 75.36 },
                { totalDaily: 75.38, targetTbDaily: 75.38 },
                { totalDaily: 75.4, targetTbDaily: 75.4 },
                { totalDaily: 75.42, targetTbDaily: 75.42 },
                { totalDaily: 75.44, targetTbDaily: 75.44 },
                { totalDaily: 75.45, targetTbDaily: 75.45 },
                { totalDaily: 75.48, targetTbDaily: 75.48 },
                { totalDaily: 75.5, targetTbDaily: 75.5 },
                { totalDaily: 75.52, targetTbDaily: 75.52 },
                { totalDaily: 75.54, targetTbDaily: 75.54 },
                { totalDaily: 75.55, targetTbDaily: 75.55 },
                { totalDaily: 75.56, targetTbDaily: 75.56 },
                { totalDaily: 75.59, targetTbDaily: 75.59 },
                { totalDaily: 75.61, targetTbDaily: 75.61 },
                { totalDaily: 75.63, targetTbDaily: 75.63 },
                { totalDaily: 75.65, targetTbDaily: 75.65 },
                { totalDaily: 75.67, targetTbDaily: 75.67 },
                { totalDaily: 75.69, targetTbDaily: 75.69 },
                { totalDaily: 75.7, targetTbDaily: 75.7 },
                { totalDaily: 75.73, targetTbDaily: 75.73 },
                { totalDaily: 75.75, targetTbDaily: 75.75 },
                { totalDaily: 75.77, targetTbDaily: 75.77 },
                { totalDaily: 75.79, targetTbDaily: 75.79 },
                { totalDaily: 75.81, targetTbDaily: 75.81 },
                { totalDaily: 75.83, targetTbDaily: 75.83 },
                { totalDaily: 75.84, targetTbDaily: 75.84 },
                { totalDaily: 75.87, targetTbDaily: 75.87 },
                { totalDaily: 75.89, targetTbDaily: 75.89 },
                { totalDaily: 75.91, targetTbDaily: 75.91 },
                { totalDaily: 75.93, targetTbDaily: 75.93 },
                { totalDaily: 75.95, targetTbDaily: 75.95 },
                { totalDaily: 75.97, targetTbDaily: 75.97 },
                { totalDaily: 75.98, targetTbDaily: 75.98 },
                { totalDaily: 76.01, targetTbDaily: 76.01 },
                { totalDaily: 76.03, targetTbDaily: 76.03 },
                { totalDaily: 76.05, targetTbDaily: 76.05 },
                { totalDaily: 76.06, targetTbDaily: 76.06 },
                { totalDaily: 76.09, targetTbDaily: 76.09 },
                { totalDaily: 76.11, targetTbDaily: 76.11 },
                { totalDaily: 76.13, targetTbDaily: 76.13 },
                { totalDaily: 76.15, targetTbDaily: 76.15 },
                { totalDaily: 76.17, targetTbDaily: 76.17 },
                { totalDaily: 76.19, targetTbDaily: 76.19 },
                { totalDaily: 76.2, targetTbDaily: 76.2 },
                { totalDaily: 76.23, targetTbDaily: 76.23 },
                { totalDaily: 76.25, targetTbDaily: 76.25 },
                { totalDaily: 76.27, targetTbDaily: 76.27 },
                { totalDaily: 76.29, targetTbDaily: 76.29 },
                { totalDaily: 76.31, targetTbDaily: 76.31 },
                { totalDaily: 76.33, targetTbDaily: 76.33 },
                { totalDaily: 76.34, targetTbDaily: 76.34 },
                { totalDaily: 76.37, targetTbDaily: 76.37 },
                { totalDaily: 76.39, targetTbDaily: 76.39 },
                { totalDaily: 76.41, targetTbDaily: 76.41 },
                { totalDaily: 76.43, targetTbDaily: 76.43 },
                { totalDaily: 76.45, targetTbDaily: 76.45 },
                { totalDaily: 76.47, targetTbDaily: 76.47 },
                { totalDaily: 76.48, targetTbDaily: 76.48 },
                { totalDaily: 76.51, targetTbDaily: 76.51 },
                { totalDaily: 76.53, targetTbDaily: 76.53 },
                { totalDaily: 76.55, targetTbDaily: 76.55 },
                { totalDaily: 76.56, targetTbDaily: 76.56 },
                { totalDaily: 76.59, targetTbDaily: 76.59 },
                { totalDaily: 76.61, targetTbDaily: 76.61 },
                { totalDaily: 76.63, targetTbDaily: 76.63 },
                { totalDaily: 76.65, targetTbDaily: 76.65 },
                { totalDaily: 76.67, targetTbDaily: 76.67 },
                { totalDaily: 76.69, targetTbDaily: 76.69 },
                { totalDaily: 76.7, targetTbDaily: 76.7 },
                { totalDaily: 76.73, targetTbDaily: 76.73 },
                { totalDaily: 76.75, targetTbDaily: 76.75 },
                { totalDaily: 76.77, targetTbDaily: 76.77 },
                { totalDaily: 76.79, targetTbDaily: 76.79 },
                { totalDaily: 76.81, targetTbDaily: 76.81 },
                { totalDaily: 76.83, targetTbDaily: 76.83 },
                { totalDaily: 76.84, targetTbDaily: 76.84 },
                { totalDaily: 76.87, targetTbDaily: 76.87 },
                { totalDaily: 76.89, targetTbDaily: 76.89 },
                { totalDaily: 76.91, targetTbDaily: 76.91 },
                { totalDaily: 76.93, targetTbDaily: 76.93 },
                { totalDaily: 76.95, targetTbDaily: 76.95 },
                { totalDaily: 76.97, targetTbDaily: 76.97 },
                { totalDaily: 76.98, targetTbDaily: 76.98 },
                { totalDaily: 77.01, targetTbDaily: 77.01 },
                { totalDaily: 77.03, targetTbDaily: 77.03 },
                { totalDaily: 77.05, targetTbDaily: 77.05 },
                { totalDaily: 77.06, targetTbDaily: 77.06 },
                { totalDaily: 77.09, targetTbDaily: 77.09 },
                { totalDaily: 77.11, targetTbDaily: 77.11 },
                { totalDaily: 77.13, targetTbDaily: 77.13 },
                { totalDaily: 77.15, targetTbDaily: 77.15 },
                { totalDaily: 77.17, targetTbDaily: 77.17 },
                { totalDaily: 77.19, targetTbDaily: 77.19 },
                { totalDaily: 77.2, targetTbDaily: 77.2 },
                { totalDaily: 77.23, targetTbDaily: 77.23 },
                { totalDaily: 77.25, targetTbDaily: 77.25 },
                { totalDaily: 77.27, targetTbDaily: 77.27 },
                { totalDaily: 77.29, targetTbDaily: 77.29 },
                { totalDaily: 77.31, targetTbDaily: 77.31 },
                { totalDaily: 77.33, targetTbDaily: 77.33 },
                { totalDaily: 77.34, targetTbDaily: 77.34 },
                { totalDaily: 77.37, targetTbDaily: 77.37 },
                { totalDaily: 77.39, targetTbDaily: 77.39 },
                { totalDaily: 77.41, targetTbDaily: 77.41 },
                { totalDaily: 77.43, targetTbDaily: 77.43 },
                { totalDaily: 77.45, targetTbDaily: 77.45 },
                { totalDaily: 77.47, targetTbDaily: 77.47 },
                { totalDaily: 77.48, targetTbDaily: 77.48 },
                { totalDaily: 77.51, targetTbDaily: 77.51 },
                { totalDaily: 77.53, targetTbDaily: 77.53 },
                { totalDaily: 77.55, targetTbDaily: 77.55 },
                { totalDaily: 77.56, targetTbDaily: 77.56 },
                { totalDaily: 77.59, targetTbDaily: 77.59 },
                { totalDaily: 77.61, targetTbDaily: 77.61 },
                { totalDaily: 77.63, targetTbDaily: 77.63 },
                { totalDaily: 77.66, targetTbDaily: 77.66 },
                { totalDaily: 77.68, targetTbDaily: 77.68 },
                { totalDaily: 77.7, targetTbDaily: 77.7 },
                { totalDaily: 77.72, targetTbDaily: 77.72 },
                { totalDaily: 77.73, targetTbDaily: 77.73 },
                { totalDaily: 77.76, targetTbDaily: 77.76 },
                { totalDaily: 77.78, targetTbDaily: 77.78 },
                { totalDaily: 77.8, targetTbDaily: 77.8 },
                { totalDaily: 77.81, targetTbDaily: 77.81 },
                { totalDaily: 77.84, targetTbDaily: 77.84 },
                { totalDaily: 77.86, targetTbDaily: 77.86 },
                { totalDaily: 77.88, targetTbDaily: 77.88 },
                { totalDaily: 77.9, targetTbDaily: 77.9 },
                { totalDaily: 77.92, targetTbDaily: 77.92 },
                { totalDaily: 77.94, targetTbDaily: 77.94 },
                { totalDaily: 77.95, targetTbDaily: 77.95 },
                { totalDaily: 77.98, targetTbDaily: 77.98 },
                { totalDaily: 78, targetTbDaily: 78 },
                { totalDaily: 78.02, targetTbDaily: 78.02 },
                { totalDaily: 78.04, targetTbDaily: 78.04 },
                { totalDaily: 78.06, targetTbDaily: 78.06 },
                { totalDaily: 78.08, targetTbDaily: 78.08 },
                { totalDaily: 78.09, targetTbDaily: 78.09 },
                { totalDaily: 78.12, targetTbDaily: 78.12 },
                { totalDaily: 78.14, targetTbDaily: 78.14 },
                { totalDaily: 78.16, targetTbDaily: 78.16 },
                { totalDaily: 78.18, targetTbDaily: 78.18 },
                { totalDaily: 78.2, targetTbDaily: 78.2 },
                { totalDaily: 78.22, targetTbDaily: 78.22 },
                { totalDaily: 78.25, targetTbDaily: 78.25 },
                { totalDaily: 78.27, targetTbDaily: 78.27 },
                { totalDaily: 78.29, targetTbDaily: 78.29 },
                { totalDaily: 78.31, targetTbDaily: 78.31 },
                { totalDaily: 78.33, targetTbDaily: 78.33 },
                { totalDaily: 78.34, targetTbDaily: 78.34 },
                { totalDaily: 78.37, targetTbDaily: 78.37 },
                { totalDaily: 78.39, targetTbDaily: 78.39 },
                { totalDaily: 78.41, targetTbDaily: 78.41 },
                { totalDaily: 78.43, targetTbDaily: 78.43 },
                { totalDaily: 78.45, targetTbDaily: 78.45 },
                { totalDaily: 78.47, targetTbDaily: 78.47 },
                { totalDaily: 78.48, targetTbDaily: 78.48 },
                { totalDaily: 78.51, targetTbDaily: 78.51 },
                { totalDaily: 78.53, targetTbDaily: 78.53 },
                { totalDaily: 78.55, targetTbDaily: 78.55 },
                { totalDaily: 78.56, targetTbDaily: 78.56 },
                { totalDaily: 78.59, targetTbDaily: 78.59 },
                { totalDaily: 78.61, targetTbDaily: 78.61 },
                { totalDaily: 78.63, targetTbDaily: 78.63 },
                { totalDaily: 78.66, targetTbDaily: 78.66 },
                { totalDaily: 78.68, targetTbDaily: 78.68 },
                { totalDaily: 78.7, targetTbDaily: 78.7 },
                { totalDaily: 78.72, targetTbDaily: 78.72 },
                { totalDaily: 78.73, targetTbDaily: 78.73 },
                { totalDaily: 78.76, targetTbDaily: 78.76 },
                { totalDaily: 78.78, targetTbDaily: 78.78 },
                { totalDaily: 78.8, targetTbDaily: 78.8 },
                { totalDaily: 78.81, targetTbDaily: 78.81 },
                { totalDaily: 78.84, targetTbDaily: 78.84 },
                { totalDaily: 78.86, targetTbDaily: 78.86 },
                { totalDaily: 78.88, targetTbDaily: 78.88 },
                { totalDaily: 78.9, targetTbDaily: 78.9 },
                { totalDaily: 78.92, targetTbDaily: 78.92 },
                { totalDaily: 78.94, targetTbDaily: 78.94 },
                { totalDaily: 78.95, targetTbDaily: 78.95 },
                { totalDaily: 78.98, targetTbDaily: 78.98 },
                { totalDaily: 79.01, targetTbDaily: 79.01 },
                { totalDaily: 79.03, targetTbDaily: 79.03 },
                { totalDaily: 79.05, targetTbDaily: 79.05 },
                { totalDaily: 79.06, targetTbDaily: 79.06 },
                { totalDaily: 79.09, targetTbDaily: 79.09 },
                { totalDaily: 79.11, targetTbDaily: 79.11 },
                { totalDaily: 79.13, targetTbDaily: 79.13 },
                { totalDaily: 79.15, targetTbDaily: 79.15 },
                { totalDaily: 79.17, targetTbDaily: 79.17 },
                { totalDaily: 79.19, targetTbDaily: 79.19 },
                { totalDaily: 79.2, targetTbDaily: 79.2 },
                { totalDaily: 79.23, targetTbDaily: 79.23 },
                { totalDaily: 79.25, targetTbDaily: 79.25 },
                { totalDaily: 79.27, targetTbDaily: 79.27 },
                { totalDaily: 79.29, targetTbDaily: 79.29 },
                { totalDaily: 79.31, targetTbDaily: 79.31 },
                { totalDaily: 79.34, targetTbDaily: 79.34 },
                { totalDaily: 79.36, targetTbDaily: 79.36 },
                { totalDaily: 79.38, targetTbDaily: 79.38 },
                { totalDaily: 79.4, targetTbDaily: 79.4 },
                { totalDaily: 79.42, targetTbDaily: 79.42 },
                { totalDaily: 79.44, targetTbDaily: 79.44 },
                { totalDaily: 79.45, targetTbDaily: 79.45 },
                { totalDaily: 79.48, targetTbDaily: 79.48 },
                { totalDaily: 79.5, targetTbDaily: 79.5 },
                { totalDaily: 79.52, targetTbDaily: 79.52 },
                { totalDaily: 79.54, targetTbDaily: 79.54 },
                { totalDaily: 79.56, targetTbDaily: 79.56 },
                { totalDaily: 79.58, targetTbDaily: 79.58 },
                { totalDaily: 79.61, targetTbDaily: 79.61 },
                { totalDaily: 79.63, targetTbDaily: 79.63 },
                { totalDaily: 79.65, targetTbDaily: 79.65 },
                { totalDaily: 79.67, targetTbDaily: 79.67 },
                { totalDaily: 79.69, targetTbDaily: 79.69 },
                { totalDaily: 79.7, targetTbDaily: 79.7 },
                { totalDaily: 79.73, targetTbDaily: 79.73 },
                { totalDaily: 79.75, targetTbDaily: 79.75 },
                { totalDaily: 79.77, targetTbDaily: 79.77 },
                { totalDaily: 79.79, targetTbDaily: 79.79 },
                { totalDaily: 79.81, targetTbDaily: 79.81 },
                { totalDaily: 79.83, targetTbDaily: 79.83 },
                { totalDaily: 79.86, targetTbDaily: 79.86 },
                { totalDaily: 79.88, targetTbDaily: 79.88 },
                { totalDaily: 79.9, targetTbDaily: 79.9 },
                { totalDaily: 79.92, targetTbDaily: 79.92 },
                { totalDaily: 79.94, targetTbDaily: 79.94 },
                { totalDaily: 79.95, targetTbDaily: 79.95 },
                { totalDaily: 79.98, targetTbDaily: 79.98 },
                { totalDaily: 80, targetTbDaily: 80 },
                { totalDaily: 80.02, targetTbDaily: 80.02 },
                { totalDaily: 80.04, targetTbDaily: 80.04 },
                { totalDaily: 80.06, targetTbDaily: 80.06 },
                { totalDaily: 80.09, targetTbDaily: 80.09 },
                { totalDaily: 80.11, targetTbDaily: 80.11 },
                { totalDaily: 80.13, targetTbDaily: 80.13 },
                { totalDaily: 80.15, targetTbDaily: 80.15 },
                { totalDaily: 80.17, targetTbDaily: 80.17 },
                { totalDaily: 80.19, targetTbDaily: 80.19 },
                { totalDaily: 80.2, targetTbDaily: 80.2 },
            ],
            monthlyData: [
                { total: 50.2, target: 50.2, creditsMonthly: 18.87, cumulatedCredits: 18.87 },
                { total: 50.59, target: 50.59, creditsMonthly: 24.9, cumulatedCredits: 43.77 },
                { total: 50.99, target: 50.99, creditsMonthly: 25.05, cumulatedCredits: 68.81 },
                { total: 51.39, target: 51.39, creditsMonthly: 25.2, cumulatedCredits: 94.02 },
                { total: 51.79, target: 51.79, creditsMonthly: 25.49, cumulatedCredits: 119.51 },
                { total: 52.2, target: 52.2, creditsMonthly: 25.64, cumulatedCredits: 145.15 },
                { total: 52.61, target: 52.61, creditsMonthly: 25.8, cumulatedCredits: 170.95 },
                { total: 53.03, target: 53.03, creditsMonthly: 26.1, cumulatedCredits: 197.05 },
                { total: 53.44, target: 53.44, creditsMonthly: 26.26, cumulatedCredits: 223.31 },
                { total: 53.86, target: 53.86, creditsMonthly: 26.42, cumulatedCredits: 249.73 },
                { total: 54.29, target: 54.29, creditsMonthly: 26.7, cumulatedCredits: 276.43 },
                { total: 54.71, target: 54.71, creditsMonthly: 26.89, cumulatedCredits: 303.32 },
                { total: 55.14, target: 55.14, creditsMonthly: 27.07, cumulatedCredits: 330.39 },
                { total: 55.58, target: 55.58, creditsMonthly: 27.3, cumulatedCredits: 357.69 },
                { total: 56.01, target: 56.01, creditsMonthly: 27.54, cumulatedCredits: 385.23 },
                { total: 56.46, target: 56.46, creditsMonthly: 27.73, cumulatedCredits: 412.96 },
                { total: 56.9, target: 56.9, creditsMonthly: 27.92, cumulatedCredits: 440.88 },
                { total: 57.35, target: 57.35, creditsMonthly: 28.2, cumulatedCredits: 469.08 },
                { total: 57.8, target: 57.8, creditsMonthly: 28.41, cumulatedCredits: 497.49 },
                { total: 58.25, target: 58.25, creditsMonthly: 28.61, cumulatedCredits: 526.1 },
                { total: 58.71, target: 58.71, creditsMonthly: 28.81, cumulatedCredits: 554.91 },
                { total: 59.17, target: 59.17, creditsMonthly: 29.1, cumulatedCredits: 584.01 },
                { total: 59.64, target: 59.64, creditsMonthly: 29.31, cumulatedCredits: 613.32 },
                { total: 60.11, target: 60.11, creditsMonthly: 29.52, cumulatedCredits: 642.84 },
                { total: 60.58, target: 60.58, creditsMonthly: 29.74, cumulatedCredits: 672.58 },
                { total: 61.06, target: 61.06, creditsMonthly: 30, cumulatedCredits: 702.58 },
                { total: 61.54, target: 61.54, creditsMonthly: 30.26, cumulatedCredits: 732.84 },
                { total: 62.02, target: 62.02, creditsMonthly: 30.47, cumulatedCredits: 763.31 },
                { total: 62.51, target: 62.51, creditsMonthly: 30.7, cumulatedCredits: 794.01 },
                { total: 63, target: 63, creditsMonthly: 30.93, cumulatedCredits: 824.94 },
                { total: 63.49, target: 63.49, creditsMonthly: 31.2, cumulatedCredits: 856.14 },
                { total: 63.99, target: 63.99, creditsMonthly: 31.46, cumulatedCredits: 887.6 },
                { total: 64.5, target: 64.5, creditsMonthly: 31.7, cumulatedCredits: 919.3 },
                { total: 65, target: 65, creditsMonthly: 31.93, cumulatedCredits: 951.23 },
                { total: 65.52, target: 65.52, creditsMonthly: 32.18, cumulatedCredits: 983.41 },
                { total: 66.03, target: 66.03, creditsMonthly: 32.42, cumulatedCredits: 1015.83 },
                { total: 66.55, target: 66.55, creditsMonthly: 32.7, cumulatedCredits: 1048.53 },
                { total: 67.06, target: 67.06, creditsMonthly: 32.97, cumulatedCredits: 1081.5 },
                { total: 67.59, target: 67.59, creditsMonthly: 33.22, cumulatedCredits: 1114.72 },
                { total: 68.13, target: 68.13, creditsMonthly: 33.47, cumulatedCredits: 1148.2 },
                { total: 68.67, target: 68.67, creditsMonthly: 33.74, cumulatedCredits: 1181.94 },
                { total: 69.2, target: 69.2, creditsMonthly: 34, cumulatedCredits: 1215.94 },
                { total: 69.75, target: 69.75, creditsMonthly: 34.26, cumulatedCredits: 1250.2 },
                { total: 70.3, target: 70.3, creditsMonthly: 34.52, cumulatedCredits: 1284.72 },
                { total: 70.84, target: 70.84, creditsMonthly: 34.79, cumulatedCredits: 1319.52 },
                { total: 71.41, target: 71.41, creditsMonthly: 35.09, cumulatedCredits: 1354.61 },
                { total: 71.97, target: 71.97, creditsMonthly: 35.36, cumulatedCredits: 1389.98 },
                { total: 72.54, target: 72.54, creditsMonthly: 35.65, cumulatedCredits: 1425.63 },
                { total: 73.11, target: 73.11, creditsMonthly: 35.92, cumulatedCredits: 1461.55 },
                { total: 73.68, target: 73.68, creditsMonthly: 36.2, cumulatedCredits: 1497.75 },
                { total: 74.26, target: 74.26, creditsMonthly: 36.49, cumulatedCredits: 1534.24 },
                { total: 74.84, target: 74.84, creditsMonthly: 36.77, cumulatedCredits: 1571.01 },
                { total: 75.44, target: 75.44, creditsMonthly: 37.06, cumulatedCredits: 1608.07 },
                { total: 76.03, target: 76.03, creditsMonthly: 37.35, cumulatedCredits: 1645.42 },
                { total: 76.63, target: 76.63, creditsMonthly: 37.65, cumulatedCredits: 1683.07 },
                { total: 77.23, target: 77.23, creditsMonthly: 37.94, cumulatedCredits: 1721.01 },
                { total: 77.84, target: 77.84, creditsMonthly: 38.24, cumulatedCredits: 1759.25 },
                { total: 78.45, target: 78.45, creditsMonthly: 38.54, cumulatedCredits: 1797.79 },
                { total: 79.06, target: 79.06, creditsMonthly: 38.85, cumulatedCredits: 1836.64 },
                { total: 79.69, target: 79.69, creditsMonthly: 39.15, cumulatedCredits: 1875.79 },
            ],
        },
        allSources: {
            dailyData: [
                { totalDaily: 23.99, targetTbDaily: 12.39 },
                { totalDaily: 48.43, targetTbDaily: 25.03 },
                { totalDaily: 72.81, targetTbDaily: 37.61 },
                { totalDaily: 97.84, targetTbDaily: 50.46 },
                { totalDaily: 122.49, targetTbDaily: 63.15 },
                { totalDaily: 148.14, targetTbDaily: 76.29 },
                { totalDaily: 172.95, targetTbDaily: 89.06 },
                { totalDaily: 199.25, targetTbDaily: 102.47 },
                { totalDaily: 224.27, targetTbDaily: 115.31 },
                { totalDaily: 251.11, targetTbDaily: 129 },
                { totalDaily: 276.38, targetTbDaily: 141.97 },
                { totalDaily: 303.83, targetTbDaily: 155.9 },
                { totalDaily: 329.26, targetTbDaily: 168.92 },
                { totalDaily: 357.33, targetTbDaily: 183.14 },
                { totalDaily: 382.95, targetTbDaily: 196.25 },
                { totalDaily: 392.79, targetTbDaily: 200.3 },
                { totalDaily: 402.43, targetTbDaily: 204.3 },
                { totalDaily: 412, targetTbDaily: 208.24 },
                { totalDaily: 421.52, targetTbDaily: 212.16 },
                { totalDaily: 431.1, targetTbDaily: 216.16 },
                { totalDaily: 440.61, targetTbDaily: 220.08 },
                { totalDaily: 450.13, targetTbDaily: 224 },
                { totalDaily: 468.1, targetTbDaily: 231.57 },
                { totalDaily: 475.97, targetTbDaily: 234.74 },
                { totalDaily: 483.85, targetTbDaily: 237.9 },
                { totalDaily: 491.76, targetTbDaily: 241.08 },
                { totalDaily: 499.61, targetTbDaily: 244.22 },
                { totalDaily: 507.47, targetTbDaily: 247.38 },
                { totalDaily: 515.39, targetTbDaily: 250.55 },
                { totalDaily: 533.37, targetTbDaily: 258.11 },
                { totalDaily: 534.83, targetTbDaily: 258.73 },
                { totalDaily: 536.28, targetTbDaily: 259.3 },
                { totalDaily: 537.76, targetTbDaily: 259.9 },
                { totalDaily: 539.22, targetTbDaily: 260.52 },
                { totalDaily: 540.66, targetTbDaily: 261.09 },
                { totalDaily: 542.13, targetTbDaily: 261.7 },
                { totalDaily: 543.64, targetTbDaily: 262.32 },
                { totalDaily: 543.74, targetTbDaily: 262.36 },
                { totalDaily: 543.9, targetTbDaily: 262.45 },
                { totalDaily: 544.05, targetTbDaily: 262.53 },
                { totalDaily: 544.17, targetTbDaily: 262.56 },
                { totalDaily: 544.3, targetTbDaily: 262.64 },
                { totalDaily: 544.49, targetTbDaily: 262.73 },
                { totalDaily: 544.64, targetTbDaily: 262.8 },
                { totalDaily: 544.77, targetTbDaily: 262.87 },
                { totalDaily: 544.9, targetTbDaily: 262.93 },
                { totalDaily: 545.08, targetTbDaily: 263.02 },
                { totalDaily: 545.19, targetTbDaily: 263.08 },
                { totalDaily: 545.36, targetTbDaily: 263.14 },
                { totalDaily: 545.46, targetTbDaily: 263.19 },
                { totalDaily: 545.58, targetTbDaily: 263.27 },
                { totalDaily: 545.74, targetTbDaily: 263.33 },
                { totalDaily: 545.9, targetTbDaily: 263.4 },
                { totalDaily: 546.06, targetTbDaily: 263.5 },
                { totalDaily: 546.17, targetTbDaily: 263.53 },
                { totalDaily: 546.38, targetTbDaily: 263.64 },
                { totalDaily: 546.48, targetTbDaily: 263.69 },
                { totalDaily: 546.61, targetTbDaily: 263.74 },
                { totalDaily: 546.78, targetTbDaily: 263.84 },
                { totalDaily: 546.91, targetTbDaily: 263.9 },
                { totalDaily: 547.02, targetTbDaily: 263.94 },
                { totalDaily: 580.17, targetTbDaily: 278.19 },
                { totalDaily: 580.37, targetTbDaily: 278.28 },
                { totalDaily: 580.53, targetTbDaily: 278.39 },
                { totalDaily: 580.63, targetTbDaily: 278.43 },
                { totalDaily: 580.79, targetTbDaily: 278.52 },
                { totalDaily: 580.95, targetTbDaily: 278.57 },
                { totalDaily: 581.11, targetTbDaily: 278.65 },
                { totalDaily: 581.29, targetTbDaily: 278.74 },
                { totalDaily: 581.4, targetTbDaily: 278.79 },
                { totalDaily: 581.58, targetTbDaily: 278.89 },
                { totalDaily: 581.72, targetTbDaily: 278.94 },
                { totalDaily: 581.89, targetTbDaily: 279 },
                { totalDaily: 582.07, targetTbDaily: 279.12 },
                { totalDaily: 582.21, targetTbDaily: 279.19 },
                { totalDaily: 582.34, targetTbDaily: 279.26 },
                { totalDaily: 582.47, targetTbDaily: 279.31 },
                { totalDaily: 582.63, targetTbDaily: 279.39 },
                { totalDaily: 582.79, targetTbDaily: 279.45 },
                { totalDaily: 582.91, targetTbDaily: 279.51 },
                { totalDaily: 583.07, targetTbDaily: 279.61 },
                { totalDaily: 583.22, targetTbDaily: 279.67 },
                { totalDaily: 583.41, targetTbDaily: 279.74 },
                { totalDaily: 583.52, targetTbDaily: 279.82 },
                { totalDaily: 583.66, targetTbDaily: 279.89 },
                { totalDaily: 583.84, targetTbDaily: 279.97 },
                { totalDaily: 583.99, targetTbDaily: 280.02 },
                { totalDaily: 584.12, targetTbDaily: 280.09 },
                { totalDaily: 584.29, targetTbDaily: 280.18 },
                { totalDaily: 584.51, targetTbDaily: 280.27 },
                { totalDaily: 584.59, targetTbDaily: 280.34 },
                { totalDaily: 617.76, targetTbDaily: 294.57 },
                { totalDaily: 617.91, targetTbDaily: 294.65 },
                { totalDaily: 618.09, targetTbDaily: 294.75 },
                { totalDaily: 618.22, targetTbDaily: 294.79 },
                { totalDaily: 618.43, targetTbDaily: 294.89 },
                { totalDaily: 618.62, targetTbDaily: 295 },
                { totalDaily: 618.75, targetTbDaily: 295.05 },
                { totalDaily: 618.91, targetTbDaily: 295.13 },
                { totalDaily: 619.05, targetTbDaily: 295.21 },
                { totalDaily: 619.25, targetTbDaily: 295.3 },
                { totalDaily: 619.41, targetTbDaily: 295.38 },
                { totalDaily: 619.54, targetTbDaily: 295.43 },
                { totalDaily: 619.75, targetTbDaily: 295.54 },
                { totalDaily: 619.9, targetTbDaily: 295.6 },
                { totalDaily: 620.05, targetTbDaily: 295.66 },
                { totalDaily: 620.22, targetTbDaily: 295.75 },
                { totalDaily: 620.37, targetTbDaily: 295.84 },
                { totalDaily: 620.53, targetTbDaily: 295.89 },
                { totalDaily: 620.66, targetTbDaily: 295.98 },
                { totalDaily: 620.84, targetTbDaily: 296.08 },
                { totalDaily: 620.99, targetTbDaily: 296.15 },
                { totalDaily: 621.19, targetTbDaily: 296.22 },
                { totalDaily: 621.34, targetTbDaily: 296.29 },
                { totalDaily: 621.48, targetTbDaily: 296.36 },
                { totalDaily: 621.67, targetTbDaily: 296.45 },
                { totalDaily: 621.87, targetTbDaily: 296.52 },
                { totalDaily: 622, targetTbDaily: 296.6 },
                { totalDaily: 622.16, targetTbDaily: 296.71 },
                { totalDaily: 622.29, targetTbDaily: 296.77 },
                { totalDaily: 622.42, targetTbDaily: 296.82 },
                { totalDaily: 623.41, targetTbDaily: 297.19 },
                { totalDaily: 623.58, targetTbDaily: 297.25 },
                { totalDaily: 623.77, targetTbDaily: 297.32 },
                { totalDaily: 623.91, targetTbDaily: 297.39 },
                { totalDaily: 624.1, targetTbDaily: 297.46 },
                { totalDaily: 624.26, targetTbDaily: 297.56 },
                { totalDaily: 624.45, targetTbDaily: 297.66 },
                { totalDaily: 624.57, targetTbDaily: 297.73 },
                { totalDaily: 624.72, targetTbDaily: 297.79 },
                { totalDaily: 624.9, targetTbDaily: 297.87 },
                { totalDaily: 625.04, targetTbDaily: 297.93 },
                { totalDaily: 625.25, targetTbDaily: 298.02 },
                { totalDaily: 625.42, targetTbDaily: 298.09 },
                { totalDaily: 625.55, targetTbDaily: 298.19 },
                { totalDaily: 625.7, targetTbDaily: 298.26 },
                { totalDaily: 625.91, targetTbDaily: 298.34 },
                { totalDaily: 626.08, targetTbDaily: 298.43 },
                { totalDaily: 626.23, targetTbDaily: 298.51 },
                { totalDaily: 626.4, targetTbDaily: 298.58 },
                { totalDaily: 626.52, targetTbDaily: 298.64 },
                { totalDaily: 626.69, targetTbDaily: 298.7 },
                { totalDaily: 626.86, targetTbDaily: 298.78 },
                { totalDaily: 627.04, targetTbDaily: 298.89 },
                { totalDaily: 627.19, targetTbDaily: 298.99 },
                { totalDaily: 627.32, targetTbDaily: 299.02 },
                { totalDaily: 627.53, targetTbDaily: 299.14 },
                { totalDaily: 627.68, targetTbDaily: 299.19 },
                { totalDaily: 627.83, targetTbDaily: 299.27 },
                { totalDaily: 628.03, targetTbDaily: 299.35 },
                { totalDaily: 628.16, targetTbDaily: 299.41 },
                { totalDaily: 629.15, targetTbDaily: 299.78 },
                { totalDaily: 629.3, targetTbDaily: 299.87 },
                { totalDaily: 629.47, targetTbDaily: 299.94 },
                { totalDaily: 629.62, targetTbDaily: 300.02 },
                { totalDaily: 629.8, targetTbDaily: 300.07 },
                { totalDaily: 629.94, targetTbDaily: 300.16 },
                { totalDaily: 630.12, targetTbDaily: 300.24 },
                { totalDaily: 630.31, targetTbDaily: 300.34 },
                { totalDaily: 630.49, targetTbDaily: 300.4 },
                { totalDaily: 630.67, targetTbDaily: 300.51 },
                { totalDaily: 630.76, targetTbDaily: 300.55 },
                { totalDaily: 630.97, targetTbDaily: 300.64 },
                { totalDaily: 631.11, targetTbDaily: 300.7 },
                { totalDaily: 631.28, targetTbDaily: 300.79 },
                { totalDaily: 631.41, targetTbDaily: 300.87 },
                { totalDaily: 631.61, targetTbDaily: 300.95 },
                { totalDaily: 631.79, targetTbDaily: 301.04 },
                { totalDaily: 631.91, targetTbDaily: 301.11 },
                { totalDaily: 632.16, targetTbDaily: 301.24 },
                { totalDaily: 632.32, targetTbDaily: 301.26 },
                { totalDaily: 632.44, targetTbDaily: 301.35 },
                { totalDaily: 632.58, targetTbDaily: 301.41 },
                { totalDaily: 632.75, targetTbDaily: 301.48 },
                { totalDaily: 632.93, targetTbDaily: 301.57 },
                { totalDaily: 633.1, targetTbDaily: 301.66 },
                { totalDaily: 633.28, targetTbDaily: 301.76 },
                { totalDaily: 633.45, targetTbDaily: 301.86 },
                { totalDaily: 633.63, targetTbDaily: 301.91 },
                { totalDaily: 633.81, targetTbDaily: 302.02 },
                { totalDaily: 633.97, targetTbDaily: 302.08 },
                { totalDaily: 634.91, targetTbDaily: 302.41 },
                { totalDaily: 635.08, targetTbDaily: 302.48 },
                { totalDaily: 635.22, targetTbDaily: 302.56 },
                { totalDaily: 635.39, targetTbDaily: 302.65 },
                { totalDaily: 635.55, targetTbDaily: 302.72 },
                { totalDaily: 635.72, targetTbDaily: 302.79 },
                { totalDaily: 635.87, targetTbDaily: 302.86 },
                { totalDaily: 636.01, targetTbDaily: 302.92 },
                { totalDaily: 636.25, targetTbDaily: 303.06 },
                { totalDaily: 636.39, targetTbDaily: 303.12 },
                { totalDaily: 636.53, targetTbDaily: 303.18 },
                { totalDaily: 636.73, targetTbDaily: 303.28 },
                { totalDaily: 636.91, targetTbDaily: 303.37 },
                { totalDaily: 637.01, targetTbDaily: 303.42 },
                { totalDaily: 637.2, targetTbDaily: 303.51 },
                { totalDaily: 637.38, targetTbDaily: 303.58 },
                { totalDaily: 637.58, targetTbDaily: 303.69 },
                { totalDaily: 637.73, targetTbDaily: 303.77 },
                { totalDaily: 637.89, targetTbDaily: 303.83 },
                { totalDaily: 638.06, targetTbDaily: 303.92 },
                { totalDaily: 638.19, targetTbDaily: 303.99 },
                { totalDaily: 638.4, targetTbDaily: 304.06 },
                { totalDaily: 638.59, targetTbDaily: 304.17 },
                { totalDaily: 638.75, targetTbDaily: 304.25 },
                { totalDaily: 638.92, targetTbDaily: 304.31 },
                { totalDaily: 639.1, targetTbDaily: 304.4 },
                { totalDaily: 639.22, targetTbDaily: 304.46 },
                { totalDaily: 639.4, targetTbDaily: 304.53 },
                { totalDaily: 639.53, targetTbDaily: 304.59 },
                { totalDaily: 639.73, targetTbDaily: 304.71 },
                { totalDaily: 640.67, targetTbDaily: 305.05 },
                { totalDaily: 640.85, targetTbDaily: 305.12 },
                { totalDaily: 641.02, targetTbDaily: 305.2 },
                { totalDaily: 641.18, targetTbDaily: 305.29 },
                { totalDaily: 641.38, targetTbDaily: 305.39 },
                { totalDaily: 641.51, targetTbDaily: 305.45 },
                { totalDaily: 641.66, targetTbDaily: 305.5 },
                { totalDaily: 641.88, targetTbDaily: 305.62 },
                { totalDaily: 642.04, targetTbDaily: 305.69 },
                { totalDaily: 642.22, targetTbDaily: 305.77 },
                { totalDaily: 642.41, targetTbDaily: 305.88 },
                { totalDaily: 642.55, targetTbDaily: 305.92 },
                { totalDaily: 642.67, targetTbDaily: 306 },
                { totalDaily: 642.87, targetTbDaily: 306.1 },
                { totalDaily: 643.09, targetTbDaily: 306.2 },
                { totalDaily: 643.26, targetTbDaily: 306.26 },
                { totalDaily: 643.39, targetTbDaily: 306.35 },
                { totalDaily: 643.54, targetTbDaily: 306.4 },
                { totalDaily: 643.75, targetTbDaily: 306.5 },
                { totalDaily: 643.9, targetTbDaily: 306.56 },
                { totalDaily: 644.09, targetTbDaily: 306.68 },
                { totalDaily: 644.24, targetTbDaily: 306.75 },
                { totalDaily: 644.38, targetTbDaily: 306.81 },
                { totalDaily: 644.53, targetTbDaily: 306.86 },
                { totalDaily: 644.68, targetTbDaily: 306.95 },
                { totalDaily: 644.86, targetTbDaily: 307.02 },
                { totalDaily: 645.03, targetTbDaily: 307.12 },
                { totalDaily: 645.28, targetTbDaily: 307.23 },
                { totalDaily: 645.45, targetTbDaily: 307.31 },
                { totalDaily: 645.61, targetTbDaily: 307.39 },
                { totalDaily: 646.53, targetTbDaily: 307.7 },
                { totalDaily: 646.67, targetTbDaily: 307.78 },
                { totalDaily: 646.86, targetTbDaily: 307.87 },
                { totalDaily: 647.04, targetTbDaily: 307.95 },
                { totalDaily: 647.2, targetTbDaily: 308.04 },
                { totalDaily: 647.34, targetTbDaily: 308.11 },
                { totalDaily: 647.54, targetTbDaily: 308.2 },
                { totalDaily: 647.74, targetTbDaily: 308.3 },
                { totalDaily: 647.89, targetTbDaily: 308.35 },
                { totalDaily: 648.06, targetTbDaily: 308.45 },
                { totalDaily: 648.26, targetTbDaily: 308.52 },
                { totalDaily: 648.41, targetTbDaily: 308.6 },
                { totalDaily: 648.57, targetTbDaily: 308.68 },
                { totalDaily: 648.74, targetTbDaily: 308.76 },
                { totalDaily: 648.92, targetTbDaily: 308.86 },
                { totalDaily: 649.05, targetTbDaily: 308.91 },
                { totalDaily: 649.29, targetTbDaily: 309.03 },
                { totalDaily: 649.47, targetTbDaily: 309.12 },
                { totalDaily: 649.58, targetTbDaily: 309.17 },
                { totalDaily: 649.77, targetTbDaily: 309.26 },
                { totalDaily: 649.94, targetTbDaily: 309.35 },
                { totalDaily: 650.15, targetTbDaily: 309.42 },
                { totalDaily: 650.27, targetTbDaily: 309.46 },
                { totalDaily: 650.46, targetTbDaily: 309.58 },
                { totalDaily: 650.61, targetTbDaily: 309.64 },
                { totalDaily: 650.78, targetTbDaily: 309.73 },
                { totalDaily: 650.95, targetTbDaily: 309.82 },
                { totalDaily: 651.14, targetTbDaily: 309.89 },
                { totalDaily: 651.29, targetTbDaily: 309.98 },
                { totalDaily: 651.48, targetTbDaily: 310.08 },
                { totalDaily: 652.44, targetTbDaily: 310.4 },
                { totalDaily: 652.59, targetTbDaily: 310.48 },
                { totalDaily: 652.82, targetTbDaily: 310.6 },
                { totalDaily: 652.93, targetTbDaily: 310.64 },
                { totalDaily: 653.13, targetTbDaily: 310.74 },
                { totalDaily: 653.25, targetTbDaily: 310.79 },
                { totalDaily: 653.41, targetTbDaily: 310.88 },
                { totalDaily: 653.56, targetTbDaily: 310.95 },
                { totalDaily: 653.79, targetTbDaily: 311.03 },
                { totalDaily: 654.01, targetTbDaily: 311.17 },
                { totalDaily: 654.15, targetTbDaily: 311.22 },
                { totalDaily: 654.28, targetTbDaily: 311.28 },
                { totalDaily: 654.54, targetTbDaily: 311.4 },
                { totalDaily: 654.67, targetTbDaily: 311.46 },
                { totalDaily: 654.82, targetTbDaily: 311.53 },
                { totalDaily: 654.96, targetTbDaily: 311.6 },
                { totalDaily: 655.16, targetTbDaily: 311.71 },
                { totalDaily: 655.36, targetTbDaily: 311.8 },
                { totalDaily: 655.47, targetTbDaily: 311.86 },
                { totalDaily: 655.7, targetTbDaily: 311.95 },
                { totalDaily: 655.83, targetTbDaily: 312.02 },
                { totalDaily: 656.03, targetTbDaily: 312.13 },
                { totalDaily: 656.21, targetTbDaily: 312.2 },
                { totalDaily: 656.39, targetTbDaily: 312.28 },
                { totalDaily: 656.51, targetTbDaily: 312.35 },
                { totalDaily: 656.71, targetTbDaily: 312.44 },
                { totalDaily: 656.88, targetTbDaily: 312.51 },
                { totalDaily: 657.08, targetTbDaily: 312.63 },
                { totalDaily: 657.24, targetTbDaily: 312.7 },
                { totalDaily: 657.42, targetTbDaily: 312.78 },
                { totalDaily: 658.34, targetTbDaily: 313.09 },
                { totalDaily: 658.5, targetTbDaily: 313.19 },
                { totalDaily: 658.72, targetTbDaily: 313.29 },
                { totalDaily: 658.86, targetTbDaily: 313.35 },
                { totalDaily: 659.07, targetTbDaily: 313.46 },
                { totalDaily: 659.24, targetTbDaily: 313.52 },
                { totalDaily: 659.45, targetTbDaily: 313.64 },
                { totalDaily: 659.57, targetTbDaily: 313.7 },
                { totalDaily: 659.75, targetTbDaily: 313.77 },
                { totalDaily: 659.95, targetTbDaily: 313.87 },
                { totalDaily: 660.08, targetTbDaily: 313.94 },
                { totalDaily: 660.25, targetTbDaily: 314.01 },
                { totalDaily: 660.42, targetTbDaily: 314.08 },
                { totalDaily: 660.6, targetTbDaily: 314.16 },
                { totalDaily: 660.79, targetTbDaily: 314.26 },
                { totalDaily: 660.96, targetTbDaily: 314.38 },
                { totalDaily: 661.16, targetTbDaily: 314.45 },
                { totalDaily: 661.31, targetTbDaily: 314.55 },
                { totalDaily: 661.53, targetTbDaily: 314.62 },
                { totalDaily: 661.68, targetTbDaily: 314.7 },
                { totalDaily: 661.8, targetTbDaily: 314.77 },
                { totalDaily: 662.01, targetTbDaily: 314.84 },
                { totalDaily: 662.14, targetTbDaily: 314.89 },
                { totalDaily: 662.37, targetTbDaily: 315 },
                { totalDaily: 662.52, targetTbDaily: 315.08 },
                { totalDaily: 662.68, targetTbDaily: 315.18 },
                { totalDaily: 662.82, targetTbDaily: 315.25 },
                { totalDaily: 663.01, targetTbDaily: 315.31 },
                { totalDaily: 663.18, targetTbDaily: 315.39 },
                { totalDaily: 663.37, targetTbDaily: 315.5 },
                { totalDaily: 664.32, targetTbDaily: 315.83 },
                { totalDaily: 664.54, targetTbDaily: 315.94 },
                { totalDaily: 664.7, targetTbDaily: 316.02 },
                { totalDaily: 664.84, targetTbDaily: 316.08 },
                { totalDaily: 665.06, targetTbDaily: 316.15 },
                { totalDaily: 665.21, targetTbDaily: 316.26 },
                { totalDaily: 665.35, targetTbDaily: 316.34 },
                { totalDaily: 665.54, targetTbDaily: 316.44 },
                { totalDaily: 665.77, targetTbDaily: 316.52 },
                { totalDaily: 665.95, targetTbDaily: 316.6 },
                { totalDaily: 666.1, targetTbDaily: 316.67 },
                { totalDaily: 666.29, targetTbDaily: 316.76 },
                { totalDaily: 666.45, targetTbDaily: 316.85 },
                { totalDaily: 666.59, targetTbDaily: 316.93 },
                { totalDaily: 666.73, targetTbDaily: 316.98 },
                { totalDaily: 666.97, targetTbDaily: 317.08 },
                { totalDaily: 667.16, targetTbDaily: 317.18 },
                { totalDaily: 667.29, targetTbDaily: 317.23 },
                { totalDaily: 667.49, targetTbDaily: 317.34 },
                { totalDaily: 667.7, targetTbDaily: 317.45 },
                { totalDaily: 667.84, targetTbDaily: 317.5 },
                { totalDaily: 667.99, targetTbDaily: 317.59 },
                { totalDaily: 668.18, targetTbDaily: 317.66 },
                { totalDaily: 668.33, targetTbDaily: 317.74 },
                { totalDaily: 668.49, targetTbDaily: 317.83 },
                { totalDaily: 668.67, targetTbDaily: 317.88 },
                { totalDaily: 668.96, targetTbDaily: 318.03 },
                { totalDaily: 669.11, targetTbDaily: 318.13 },
                { totalDaily: 669.26, targetTbDaily: 318.2 },
                { totalDaily: 669.48, targetTbDaily: 318.27 },
                { totalDaily: 670.41, targetTbDaily: 318.62 },
                { totalDaily: 670.56, targetTbDaily: 318.69 },
                { totalDaily: 670.69, targetTbDaily: 318.77 },
                { totalDaily: 670.93, targetTbDaily: 318.86 },
                { totalDaily: 671.08, targetTbDaily: 318.91 },
                { totalDaily: 817.33, targetTbDaily: 382.16 },
                { totalDaily: 817.54, targetTbDaily: 382.23 },
                { totalDaily: 817.76, targetTbDaily: 382.33 },
                { totalDaily: 817.97, targetTbDaily: 382.45 },
                { totalDaily: 818.19, targetTbDaily: 382.54 },
                { totalDaily: 818.4, targetTbDaily: 382.63 },
                { totalDaily: 818.63, targetTbDaily: 382.73 },
                { totalDaily: 818.84, targetTbDaily: 382.83 },
                { totalDaily: 819.07, targetTbDaily: 382.95 },
                { totalDaily: 819.29, targetTbDaily: 383.06 },
                { totalDaily: 819.48, targetTbDaily: 383.18 },
                { totalDaily: 819.73, targetTbDaily: 383.28 },
                { totalDaily: 819.89, targetTbDaily: 383.33 },
                { totalDaily: 820.15, targetTbDaily: 383.45 },
                { totalDaily: 820.34, targetTbDaily: 383.55 },
                { totalDaily: 820.56, targetTbDaily: 383.65 },
                { totalDaily: 820.82, targetTbDaily: 383.78 },
                { totalDaily: 821.03, targetTbDaily: 383.87 },
                { totalDaily: 821.19, targetTbDaily: 383.95 },
                { totalDaily: 821.45, targetTbDaily: 384.07 },
                { totalDaily: 821.63, targetTbDaily: 384.16 },
                { totalDaily: 821.85, targetTbDaily: 384.27 },
                { totalDaily: 822, targetTbDaily: 384.31 },
                { totalDaily: 822.23, targetTbDaily: 384.43 },
                { totalDaily: 822.42, targetTbDaily: 384.49 },
                { totalDaily: 822.64, targetTbDaily: 384.61 },
                { totalDaily: 822.89, targetTbDaily: 384.73 },
                { totalDaily: 823.08, targetTbDaily: 384.84 },
                { totalDaily: 823.33, targetTbDaily: 384.95 },
                { totalDaily: 823.53, targetTbDaily: 385.02 },
                { totalDaily: 823.74, targetTbDaily: 385.11 },
                { totalDaily: 824.01, targetTbDaily: 385.26 },
                { totalDaily: 824.19, targetTbDaily: 385.35 },
                { totalDaily: 824.43, targetTbDaily: 385.45 },
                { totalDaily: 824.59, targetTbDaily: 385.52 },
                { totalDaily: 824.87, targetTbDaily: 385.68 },
                { totalDaily: 825.03, targetTbDaily: 385.75 },
                { totalDaily: 825.29, targetTbDaily: 385.88 },
                { totalDaily: 825.5, targetTbDaily: 385.96 },
                { totalDaily: 825.67, targetTbDaily: 386.04 },
                { totalDaily: 825.95, targetTbDaily: 386.15 },
                { totalDaily: 826.16, targetTbDaily: 386.26 },
                { totalDaily: 826.4, targetTbDaily: 386.38 },
                { totalDaily: 826.57, targetTbDaily: 386.47 },
                { totalDaily: 826.81, targetTbDaily: 386.56 },
                { totalDaily: 826.95, targetTbDaily: 386.65 },
                { totalDaily: 827.2, targetTbDaily: 386.74 },
                { totalDaily: 827.36, targetTbDaily: 386.82 },
                { totalDaily: 827.6, targetTbDaily: 386.92 },
                { totalDaily: 827.86, targetTbDaily: 387.04 },
                { totalDaily: 828.09, targetTbDaily: 387.2 },
                { totalDaily: 828.31, targetTbDaily: 387.27 },
                { totalDaily: 828.52, targetTbDaily: 387.37 },
                { totalDaily: 828.72, targetTbDaily: 387.48 },
                { totalDaily: 828.93, targetTbDaily: 387.56 },
                { totalDaily: 829.14, targetTbDaily: 387.65 },
                { totalDaily: 829.39, targetTbDaily: 387.77 },
                { totalDaily: 829.59, targetTbDaily: 387.89 },
                { totalDaily: 829.8, targetTbDaily: 387.96 },
                { totalDaily: 830.03, targetTbDaily: 388.09 },
                { totalDaily: 830.23, targetTbDaily: 388.17 },
                { totalDaily: 830.45, targetTbDaily: 388.28 },
                { totalDaily: 830.69, targetTbDaily: 388.37 },
                { totalDaily: 830.94, targetTbDaily: 388.51 },
                { totalDaily: 831.13, targetTbDaily: 388.59 },
                { totalDaily: 831.33, targetTbDaily: 388.7 },
                { totalDaily: 831.57, targetTbDaily: 388.78 },
                { totalDaily: 831.77, targetTbDaily: 388.9 },
                { totalDaily: 832.01, targetTbDaily: 388.98 },
                { totalDaily: 832.23, targetTbDaily: 389.08 },
                { totalDaily: 832.4, targetTbDaily: 389.18 },
                { totalDaily: 832.66, targetTbDaily: 389.29 },
                { totalDaily: 832.85, targetTbDaily: 389.39 },
                { totalDaily: 833.09, targetTbDaily: 389.52 },
                { totalDaily: 833.29, targetTbDaily: 389.61 },
                { totalDaily: 833.47, targetTbDaily: 389.67 },
                { totalDaily: 833.71, targetTbDaily: 389.82 },
                { totalDaily: 833.94, targetTbDaily: 389.9 },
                { totalDaily: 834.13, targetTbDaily: 389.99 },
                { totalDaily: 834.33, targetTbDaily: 390.08 },
                { totalDaily: 834.52, targetTbDaily: 390.18 },
                { totalDaily: 834.79, targetTbDaily: 390.29 },
                { totalDaily: 835.02, targetTbDaily: 390.43 },
                { totalDaily: 835.25, targetTbDaily: 390.51 },
                { totalDaily: 835.45, targetTbDaily: 390.6 },
                { totalDaily: 835.69, targetTbDaily: 390.71 },
                { totalDaily: 835.86, targetTbDaily: 390.79 },
                { totalDaily: 836.06, targetTbDaily: 390.88 },
                { totalDaily: 836.35, targetTbDaily: 391.03 },
                { totalDaily: 836.56, targetTbDaily: 391.15 },
                { totalDaily: 836.81, targetTbDaily: 391.24 },
                { totalDaily: 837, targetTbDaily: 391.34 },
                { totalDaily: 837.25, targetTbDaily: 391.46 },
                { totalDaily: 837.46, targetTbDaily: 391.56 },
                { totalDaily: 837.7, targetTbDaily: 391.67 },
                { totalDaily: 837.83, targetTbDaily: 391.72 },
                { totalDaily: 838.05, targetTbDaily: 391.82 },
                { totalDaily: 838.32, targetTbDaily: 391.94 },
                { totalDaily: 838.55, targetTbDaily: 392.06 },
                { totalDaily: 838.76, targetTbDaily: 392.15 },
                { totalDaily: 838.98, targetTbDaily: 392.25 },
                { totalDaily: 839.18, targetTbDaily: 392.36 },
                { totalDaily: 839.37, targetTbDaily: 392.43 },
                { totalDaily: 839.62, targetTbDaily: 392.55 },
                { totalDaily: 839.82, targetTbDaily: 392.65 },
                { totalDaily: 840.04, targetTbDaily: 392.76 },
                { totalDaily: 840.29, targetTbDaily: 392.87 },
                { totalDaily: 840.55, targetTbDaily: 392.99 },
                { totalDaily: 840.74, targetTbDaily: 393.08 },
                { totalDaily: 840.94, targetTbDaily: 393.19 },
                { totalDaily: 841.16, targetTbDaily: 393.27 },
                { totalDaily: 841.37, targetTbDaily: 393.38 },
                { totalDaily: 841.58, targetTbDaily: 393.47 },
                { totalDaily: 841.79, targetTbDaily: 393.57 },
                { totalDaily: 842.03, targetTbDaily: 393.69 },
                { totalDaily: 842.24, targetTbDaily: 393.81 },
                { totalDaily: 842.5, targetTbDaily: 393.91 },
                { totalDaily: 842.68, targetTbDaily: 393.97 },
                { totalDaily: 842.94, targetTbDaily: 394.12 },
                { totalDaily: 843.13, targetTbDaily: 394.2 },
                { totalDaily: 843.32, targetTbDaily: 394.3 },
                { totalDaily: 843.55, targetTbDaily: 394.39 },
                { totalDaily: 843.8, targetTbDaily: 394.53 },
                { totalDaily: 843.99, targetTbDaily: 394.6 },
                { totalDaily: 844.25, targetTbDaily: 394.75 },
                { totalDaily: 844.49, targetTbDaily: 394.84 },
                { totalDaily: 844.72, targetTbDaily: 394.94 },
                { totalDaily: 844.9, targetTbDaily: 395.02 },
                { totalDaily: 845.17, targetTbDaily: 395.16 },
                { totalDaily: 845.34, targetTbDaily: 395.21 },
                { totalDaily: 845.51, targetTbDaily: 395.29 },
                { totalDaily: 845.78, targetTbDaily: 395.43 },
                { totalDaily: 846.01, targetTbDaily: 395.56 },
                { totalDaily: 846.18, targetTbDaily: 395.63 },
                { totalDaily: 846.45, targetTbDaily: 395.75 },
                { totalDaily: 846.65, targetTbDaily: 395.85 },
                { totalDaily: 846.93, targetTbDaily: 395.99 },
                { totalDaily: 847.06, targetTbDaily: 396.04 },
                { totalDaily: 847.26, targetTbDaily: 396.13 },
                { totalDaily: 847.54, targetTbDaily: 396.26 },
                { totalDaily: 847.79, targetTbDaily: 396.37 },
                { totalDaily: 848, targetTbDaily: 396.49 },
                { totalDaily: 848.19, targetTbDaily: 396.57 },
                { totalDaily: 848.42, targetTbDaily: 396.68 },
                { totalDaily: 848.64, targetTbDaily: 396.77 },
                { totalDaily: 848.84, targetTbDaily: 396.86 },
                { totalDaily: 849.09, targetTbDaily: 396.98 },
                { totalDaily: 849.34, targetTbDaily: 397.08 },
                { totalDaily: 849.56, targetTbDaily: 397.21 },
                { totalDaily: 849.8, targetTbDaily: 397.3 },
                { totalDaily: 849.97, targetTbDaily: 397.38 },
                { totalDaily: 850.17, targetTbDaily: 397.51 },
                { totalDaily: 850.38, targetTbDaily: 397.61 },
                { totalDaily: 850.62, targetTbDaily: 397.71 },
                { totalDaily: 850.9, targetTbDaily: 397.84 },
                { totalDaily: 851.14, targetTbDaily: 397.93 },
                { totalDaily: 851.35, targetTbDaily: 398.03 },
                { totalDaily: 851.53, targetTbDaily: 398.1 },
                { totalDaily: 851.76, targetTbDaily: 398.25 },
                { totalDaily: 851.96, targetTbDaily: 398.35 },
                { totalDaily: 852.18, targetTbDaily: 398.42 },
                { totalDaily: 852.41, targetTbDaily: 398.54 },
                { totalDaily: 852.68, targetTbDaily: 398.67 },
                { totalDaily: 852.93, targetTbDaily: 398.79 },
                { totalDaily: 853.16, targetTbDaily: 398.88 },
                { totalDaily: 853.35, targetTbDaily: 398.99 },
                { totalDaily: 853.58, targetTbDaily: 399.08 },
                { totalDaily: 853.78, targetTbDaily: 399.2 },
                { totalDaily: 853.97, targetTbDaily: 399.26 },
                { totalDaily: 854.2, targetTbDaily: 399.39 },
                { totalDaily: 854.45, targetTbDaily: 399.49 },
                { totalDaily: 854.69, targetTbDaily: 399.6 },
                { totalDaily: 854.84, targetTbDaily: 399.66 },
                { totalDaily: 855.11, targetTbDaily: 399.79 },
                { totalDaily: 855.32, targetTbDaily: 399.88 },
                { totalDaily: 855.56, targetTbDaily: 400.03 },
                { totalDaily: 855.72, targetTbDaily: 400.11 },
                { totalDaily: 855.99, targetTbDaily: 400.2 },
                { totalDaily: 856.24, targetTbDaily: 400.32 },
                { totalDaily: 856.44, targetTbDaily: 400.44 },
                { totalDaily: 856.67, targetTbDaily: 400.52 },
                { totalDaily: 856.9, targetTbDaily: 400.63 },
                { totalDaily: 857.12, targetTbDaily: 400.75 },
                { totalDaily: 857.36, targetTbDaily: 400.87 },
                { totalDaily: 857.55, targetTbDaily: 400.95 },
                { totalDaily: 857.84, targetTbDaily: 401.07 },
                { totalDaily: 858, targetTbDaily: 401.14 },
                { totalDaily: 858.25, targetTbDaily: 401.26 },
                { totalDaily: 858.53, targetTbDaily: 401.38 },
                { totalDaily: 858.69, targetTbDaily: 401.48 },
                { totalDaily: 858.94, targetTbDaily: 401.59 },
                { totalDaily: 859.15, targetTbDaily: 401.7 },
                { totalDaily: 859.35, targetTbDaily: 401.8 },
                { totalDaily: 859.56, targetTbDaily: 401.9 },
                { totalDaily: 859.76, targetTbDaily: 401.99 },
                { totalDaily: 860.03, targetTbDaily: 402.09 },
                { totalDaily: 860.23, targetTbDaily: 402.19 },
                { totalDaily: 860.47, targetTbDaily: 402.3 },
                { totalDaily: 860.72, targetTbDaily: 402.43 },
                { totalDaily: 860.91, targetTbDaily: 402.52 },
                { totalDaily: 861.12, targetTbDaily: 402.59 },
                { totalDaily: 861.38, targetTbDaily: 402.74 },
                { totalDaily: 861.62, targetTbDaily: 402.85 },
                { totalDaily: 861.86, targetTbDaily: 402.97 },
                { totalDaily: 862.1, targetTbDaily: 403.06 },
                { totalDaily: 862.31, targetTbDaily: 403.16 },
                { totalDaily: 862.49, targetTbDaily: 403.25 },
                { totalDaily: 862.72, targetTbDaily: 403.35 },
                { totalDaily: 862.96, targetTbDaily: 403.47 },
                { totalDaily: 863.18, targetTbDaily: 403.57 },
                { totalDaily: 863.42, targetTbDaily: 403.7 },
                { totalDaily: 863.69, targetTbDaily: 403.81 },
                { totalDaily: 863.9, targetTbDaily: 403.91 },
                { totalDaily: 864.12, targetTbDaily: 404.03 },
                { totalDaily: 864.29, targetTbDaily: 404.09 },
                { totalDaily: 864.52, targetTbDaily: 404.19 },
                { totalDaily: 864.69, targetTbDaily: 404.27 },
                { totalDaily: 864.97, targetTbDaily: 404.41 },
                { totalDaily: 865.24, targetTbDaily: 404.52 },
                { totalDaily: 865.45, targetTbDaily: 404.63 },
                { totalDaily: 865.67, targetTbDaily: 404.75 },
                { totalDaily: 865.91, targetTbDaily: 404.84 },
                { totalDaily: 866.12, targetTbDaily: 404.95 },
                { totalDaily: 866.3, targetTbDaily: 405.04 },
                { totalDaily: 866.58, targetTbDaily: 405.16 },
                { totalDaily: 866.79, targetTbDaily: 405.25 },
                { totalDaily: 867.02, targetTbDaily: 405.37 },
                { totalDaily: 867.23, targetTbDaily: 405.48 },
                { totalDaily: 867.51, targetTbDaily: 405.61 },
                { totalDaily: 867.68, targetTbDaily: 405.67 },
                { totalDaily: 867.9, targetTbDaily: 405.78 },
                { totalDaily: 868.18, targetTbDaily: 405.92 },
                { totalDaily: 868.37, targetTbDaily: 406.01 },
                { totalDaily: 868.62, targetTbDaily: 406.12 },
                { totalDaily: 868.87, targetTbDaily: 406.24 },
                { totalDaily: 869.08, targetTbDaily: 406.34 },
                { totalDaily: 869.29, targetTbDaily: 406.42 },
                { totalDaily: 869.51, targetTbDaily: 406.55 },
                { totalDaily: 869.78, targetTbDaily: 406.66 },
                { totalDaily: 870.02, targetTbDaily: 406.77 },
                { totalDaily: 870.2, targetTbDaily: 406.85 },
                { totalDaily: 870.42, targetTbDaily: 406.93 },
                { totalDaily: 870.7, targetTbDaily: 407.09 },
                { totalDaily: 870.86, targetTbDaily: 407.18 },
                { totalDaily: 871.09, targetTbDaily: 407.26 },
                { totalDaily: 871.35, targetTbDaily: 407.4 },
                { totalDaily: 871.56, targetTbDaily: 407.5 },
                { totalDaily: 871.78, targetTbDaily: 407.6 },
                { totalDaily: 872, targetTbDaily: 407.7 },
                { totalDaily: 872.26, targetTbDaily: 407.84 },
                { totalDaily: 872.45, targetTbDaily: 407.92 },
                { totalDaily: 872.73, targetTbDaily: 408.05 },
                { totalDaily: 872.98, targetTbDaily: 408.15 },
                { totalDaily: 873.13, targetTbDaily: 408.24 },
                { totalDaily: 873.35, targetTbDaily: 408.32 },
                { totalDaily: 873.59, targetTbDaily: 408.45 },
                { totalDaily: 873.81, targetTbDaily: 408.53 },
                { totalDaily: 874.05, targetTbDaily: 408.66 },
                { totalDaily: 874.32, targetTbDaily: 408.79 },
                { totalDaily: 874.58, targetTbDaily: 408.91 },
                { totalDaily: 874.74, targetTbDaily: 408.99 },
                { totalDaily: 874.99, targetTbDaily: 409.1 },
                { totalDaily: 875.2, targetTbDaily: 409.2 },
                { totalDaily: 875.42, targetTbDaily: 409.3 },
                { totalDaily: 875.62, targetTbDaily: 409.39 },
                { totalDaily: 875.89, targetTbDaily: 409.51 },
                { totalDaily: 876.13, targetTbDaily: 409.61 },
                { totalDaily: 876.33, targetTbDaily: 409.71 },
                { totalDaily: 876.58, targetTbDaily: 409.84 },
                { totalDaily: 876.77, targetTbDaily: 409.93 },
                { totalDaily: 877.02, targetTbDaily: 410.05 },
                { totalDaily: 877.22, targetTbDaily: 410.14 },
                { totalDaily: 877.51, targetTbDaily: 410.28 },
                { totalDaily: 877.78, targetTbDaily: 410.38 },
                { totalDaily: 878.02, targetTbDaily: 410.51 },
                { totalDaily: 878.25, targetTbDaily: 410.63 },
                { totalDaily: 878.42, targetTbDaily: 410.69 },
                { totalDaily: 878.65, targetTbDaily: 410.82 },
                { totalDaily: 878.82, targetTbDaily: 410.88 },
                { totalDaily: 879.1, targetTbDaily: 411.03 },
                { totalDaily: 879.34, targetTbDaily: 411.12 },
                { totalDaily: 879.59, targetTbDaily: 411.26 },
                { totalDaily: 879.81, targetTbDaily: 411.34 },
                { totalDaily: 880.05, targetTbDaily: 411.46 },
                { totalDaily: 880.22, targetTbDaily: 411.55 },
                { totalDaily: 880.44, targetTbDaily: 411.65 },
                { totalDaily: 880.72, targetTbDaily: 411.77 },
                { totalDaily: 880.96, targetTbDaily: 411.87 },
                { totalDaily: 881.17, targetTbDaily: 411.97 },
                { totalDaily: 881.39, targetTbDaily: 412.09 },
                { totalDaily: 881.68, targetTbDaily: 412.24 },
                { totalDaily: 881.84, targetTbDaily: 412.31 },
                { totalDaily: 882.14, targetTbDaily: 412.44 },
                { totalDaily: 882.36, targetTbDaily: 412.54 },
                { totalDaily: 882.54, targetTbDaily: 412.62 },
                { totalDaily: 882.78, targetTbDaily: 412.72 },
                { totalDaily: 883.03, targetTbDaily: 412.85 },
                { totalDaily: 883.24, targetTbDaily: 412.96 },
                { totalDaily: 883.49, targetTbDaily: 413.09 },
                { totalDaily: 883.69, targetTbDaily: 413.16 },
                { totalDaily: 883.92, targetTbDaily: 413.26 },
                { totalDaily: 884.21, targetTbDaily: 413.43 },
                { totalDaily: 884.4, targetTbDaily: 413.49 },
                { totalDaily: 884.6, targetTbDaily: 413.6 },
                { totalDaily: 884.84, targetTbDaily: 413.71 },
                { totalDaily: 885.1, targetTbDaily: 413.82 },
                { totalDaily: 885.32, targetTbDaily: 413.93 },
                { totalDaily: 885.57, targetTbDaily: 414.05 },
                { totalDaily: 885.76, targetTbDaily: 414.11 },
                { totalDaily: 886.02, targetTbDaily: 414.26 },
                { totalDaily: 886.21, targetTbDaily: 414.35 },
                { totalDaily: 886.49, targetTbDaily: 414.48 },
                { totalDaily: 886.72, targetTbDaily: 414.58 },
                { totalDaily: 886.98, targetTbDaily: 414.71 },
                { totalDaily: 887.19, targetTbDaily: 414.81 },
                { totalDaily: 887.41, targetTbDaily: 414.89 },
                { totalDaily: 887.65, targetTbDaily: 415.02 },
                { totalDaily: 887.85, targetTbDaily: 415.11 },
                { totalDaily: 888.07, targetTbDaily: 415.22 },
                { totalDaily: 888.38, targetTbDaily: 415.38 },
                { totalDaily: 888.57, targetTbDaily: 415.45 },
                { totalDaily: 888.82, targetTbDaily: 415.56 },
                { totalDaily: 889.03, targetTbDaily: 415.68 },
                { totalDaily: 889.24, targetTbDaily: 415.74 },
                { totalDaily: 889.47, targetTbDaily: 415.86 },
                { totalDaily: 889.68, targetTbDaily: 415.95 },
                { totalDaily: 889.95, targetTbDaily: 416.09 },
                { totalDaily: 890.27, targetTbDaily: 416.23 },
                { totalDaily: 890.48, targetTbDaily: 416.32 },
                { totalDaily: 890.69, targetTbDaily: 416.42 },
                { totalDaily: 890.94, targetTbDaily: 416.56 },
                { totalDaily: 891.13, targetTbDaily: 416.65 },
                { totalDaily: 891.32, targetTbDaily: 416.74 },
                { totalDaily: 891.58, targetTbDaily: 416.86 },
                { totalDaily: 891.84, targetTbDaily: 416.97 },
                { totalDaily: 892.03, targetTbDaily: 417.07 },
                { totalDaily: 892.27, targetTbDaily: 417.17 },
                { totalDaily: 892.53, targetTbDaily: 417.31 },
                { totalDaily: 892.7, targetTbDaily: 417.37 },
                { totalDaily: 892.93, targetTbDaily: 417.47 },
                { totalDaily: 893.22, targetTbDaily: 417.61 },
                { totalDaily: 893.47, targetTbDaily: 417.74 },
                { totalDaily: 893.68, targetTbDaily: 417.83 },
                { totalDaily: 893.93, targetTbDaily: 417.94 },
                { totalDaily: 894.17, targetTbDaily: 418.05 },
                { totalDaily: 894.42, targetTbDaily: 418.19 },
                { totalDaily: 894.62, targetTbDaily: 418.3 },
                { totalDaily: 894.88, targetTbDaily: 418.4 },
                { totalDaily: 895.15, targetTbDaily: 418.54 },
                { totalDaily: 895.31, targetTbDaily: 418.61 },
                { totalDaily: 895.58, targetTbDaily: 418.73 },
                { totalDaily: 895.81, targetTbDaily: 418.84 },
                { totalDaily: 896.03, targetTbDaily: 418.93 },
                { totalDaily: 896.28, targetTbDaily: 419.05 },
                { totalDaily: 896.48, targetTbDaily: 419.16 },
                { totalDaily: 896.7, targetTbDaily: 419.26 },
                { totalDaily: 896.93, targetTbDaily: 419.36 },
                { totalDaily: 897.2, targetTbDaily: 419.47 },
                { totalDaily: 897.4, targetTbDaily: 419.59 },
                { totalDaily: 897.65, targetTbDaily: 419.68 },
                { totalDaily: 897.92, targetTbDaily: 419.81 },
                { totalDaily: 898.13, targetTbDaily: 419.91 },
                { totalDaily: 898.33, targetTbDaily: 420.02 },
                { totalDaily: 898.58, targetTbDaily: 420.13 },
                { totalDaily: 898.86, targetTbDaily: 420.25 },
                { totalDaily: 1048.78, targetTbDaily: 484.66 },
                { totalDaily: 1049.01, targetTbDaily: 484.76 },
                { totalDaily: 1049.43, targetTbDaily: 484.95 },
                { totalDaily: 1049.61, targetTbDaily: 485.03 },
                { totalDaily: 1049.83, targetTbDaily: 485.15 },
                { totalDaily: 1050.15, targetTbDaily: 485.28 },
                { totalDaily: 1050.41, targetTbDaily: 485.41 },
                { totalDaily: 1050.69, targetTbDaily: 485.54 },
                { totalDaily: 1050.99, targetTbDaily: 485.66 },
                { totalDaily: 1051.32, targetTbDaily: 485.82 },
                { totalDaily: 1051.53, targetTbDaily: 485.91 },
                { totalDaily: 1051.78, targetTbDaily: 486.04 },
                { totalDaily: 1052.04, targetTbDaily: 486.14 },
                { totalDaily: 1052.3, targetTbDaily: 486.27 },
                { totalDaily: 1052.58, targetTbDaily: 486.41 },
                { totalDaily: 1052.92, targetTbDaily: 486.57 },
                { totalDaily: 1053.18, targetTbDaily: 486.67 },
                { totalDaily: 1053.48, targetTbDaily: 486.8 },
                { totalDaily: 1053.74, targetTbDaily: 486.93 },
                { totalDaily: 1054.01, targetTbDaily: 487.05 },
                { totalDaily: 1054.25, targetTbDaily: 487.16 },
                { totalDaily: 1054.54, targetTbDaily: 487.34 },
                { totalDaily: 1054.87, targetTbDaily: 487.48 },
                { totalDaily: 1055.1, targetTbDaily: 487.58 },
                { totalDaily: 1055.29, targetTbDaily: 487.65 },
                { totalDaily: 1055.65, targetTbDaily: 487.81 },
                { totalDaily: 1055.91, targetTbDaily: 487.95 },
                { totalDaily: 1056.15, targetTbDaily: 488.05 },
                { totalDaily: 1056.5, targetTbDaily: 488.23 },
                { totalDaily: 1056.74, targetTbDaily: 488.33 },
                { totalDaily: 1057.04, targetTbDaily: 488.48 },
                { totalDaily: 1057.34, targetTbDaily: 488.62 },
                { totalDaily: 1057.58, targetTbDaily: 488.72 },
                { totalDaily: 1057.82, targetTbDaily: 488.84 },
                { totalDaily: 1058.08, targetTbDaily: 488.96 },
                { totalDaily: 1058.39, targetTbDaily: 489.09 },
                { totalDaily: 1058.68, targetTbDaily: 489.23 },
                { totalDaily: 1058.98, targetTbDaily: 489.35 },
                { totalDaily: 1059.25, targetTbDaily: 489.48 },
                { totalDaily: 1059.47, targetTbDaily: 489.57 },
                { totalDaily: 1059.8, targetTbDaily: 489.76 },
                { totalDaily: 1060.04, targetTbDaily: 489.85 },
                { totalDaily: 1060.36, targetTbDaily: 489.99 },
                { totalDaily: 1060.6, targetTbDaily: 490.13 },
                { totalDaily: 1060.88, targetTbDaily: 490.23 },
                { totalDaily: 1061.19, targetTbDaily: 490.37 },
                { totalDaily: 1061.43, targetTbDaily: 490.49 },
                { totalDaily: 1061.7, targetTbDaily: 490.64 },
                { totalDaily: 1062, targetTbDaily: 490.79 },
                { totalDaily: 1062.26, targetTbDaily: 490.85 },
                { totalDaily: 1062.54, targetTbDaily: 491.01 },
                { totalDaily: 1062.83, targetTbDaily: 491.14 },
                { totalDaily: 1063.1, targetTbDaily: 491.27 },
                { totalDaily: 1063.37, targetTbDaily: 491.4 },
                { totalDaily: 1063.68, targetTbDaily: 491.53 },
                { totalDaily: 1063.95, targetTbDaily: 491.67 },
                { totalDaily: 1064.21, targetTbDaily: 491.78 },
                { totalDaily: 1064.52, targetTbDaily: 491.94 },
                { totalDaily: 1064.78, targetTbDaily: 492.05 },
                { totalDaily: 1065.06, targetTbDaily: 492.16 },
                { totalDaily: 1065.38, targetTbDaily: 492.32 },
                { totalDaily: 1065.66, targetTbDaily: 492.45 },
                { totalDaily: 1065.94, targetTbDaily: 492.61 },
                { totalDaily: 1066.19, targetTbDaily: 492.69 },
                { totalDaily: 1066.4, targetTbDaily: 492.8 },
                { totalDaily: 1066.76, targetTbDaily: 492.95 },
                { totalDaily: 1067.01, targetTbDaily: 493.08 },
                { totalDaily: 1067.31, targetTbDaily: 493.22 },
                { totalDaily: 1067.67, targetTbDaily: 493.38 },
                { totalDaily: 1067.85, targetTbDaily: 493.46 },
                { totalDaily: 1068.13, targetTbDaily: 493.58 },
                { totalDaily: 1068.42, targetTbDaily: 493.73 },
                { totalDaily: 1068.65, targetTbDaily: 493.84 },
                { totalDaily: 1068.93, targetTbDaily: 493.96 },
                { totalDaily: 1069.24, targetTbDaily: 494.1 },
                { totalDaily: 1069.54, targetTbDaily: 494.25 },
                { totalDaily: 1069.82, targetTbDaily: 494.39 },
                { totalDaily: 1070.08, targetTbDaily: 494.49 },
                { totalDaily: 1070.36, targetTbDaily: 494.63 },
                { totalDaily: 1070.62, targetTbDaily: 494.73 },
                { totalDaily: 1070.89, targetTbDaily: 494.87 },
                { totalDaily: 1071.17, targetTbDaily: 494.99 },
                { totalDaily: 1071.48, targetTbDaily: 495.13 },
                { totalDaily: 1071.78, targetTbDaily: 495.27 },
                { totalDaily: 1072.01, targetTbDaily: 495.4 },
                { totalDaily: 1072.37, targetTbDaily: 495.56 },
                { totalDaily: 1072.61, targetTbDaily: 495.66 },
                { totalDaily: 1072.9, targetTbDaily: 495.79 },
                { totalDaily: 1073.22, targetTbDaily: 495.95 },
                { totalDaily: 1073.44, targetTbDaily: 496.05 },
                { totalDaily: 1073.76, targetTbDaily: 496.2 },
                { totalDaily: 1074.02, targetTbDaily: 496.29 },
                { totalDaily: 1074.25, targetTbDaily: 496.41 },
                { totalDaily: 1074.53, targetTbDaily: 496.57 },
                { totalDaily: 1074.86, targetTbDaily: 496.72 },
                { totalDaily: 1075.11, targetTbDaily: 496.82 },
                { totalDaily: 1075.44, targetTbDaily: 496.98 },
                { totalDaily: 1075.68, targetTbDaily: 497.09 },
                { totalDaily: 1075.92, targetTbDaily: 497.2 },
                { totalDaily: 1076.24, targetTbDaily: 497.32 },
                { totalDaily: 1076.52, targetTbDaily: 497.45 },
                { totalDaily: 1076.81, targetTbDaily: 497.58 },
                { totalDaily: 1077.05, targetTbDaily: 497.73 },
                { totalDaily: 1077.34, targetTbDaily: 497.87 },
                { totalDaily: 1077.61, targetTbDaily: 497.98 },
                { totalDaily: 1077.91, targetTbDaily: 498.11 },
                { totalDaily: 1078.23, targetTbDaily: 498.26 },
                { totalDaily: 1078.51, targetTbDaily: 498.39 },
                { totalDaily: 1078.82, targetTbDaily: 498.53 },
                { totalDaily: 1079.08, targetTbDaily: 498.66 },
                { totalDaily: 1079.32, targetTbDaily: 498.75 },
                { totalDaily: 1079.6, targetTbDaily: 498.88 },
                { totalDaily: 1079.88, targetTbDaily: 499.01 },
                { totalDaily: 1080.2, targetTbDaily: 499.17 },
                { totalDaily: 1080.5, targetTbDaily: 499.29 },
                { totalDaily: 1080.76, targetTbDaily: 499.42 },
                { totalDaily: 1081.03, targetTbDaily: 499.54 },
                { totalDaily: 1081.3, targetTbDaily: 499.69 },
                { totalDaily: 1081.58, targetTbDaily: 499.81 },
                { totalDaily: 1081.9, targetTbDaily: 499.95 },
                { totalDaily: 1082.19, targetTbDaily: 500.09 },
                { totalDaily: 1082.47, targetTbDaily: 500.21 },
                { totalDaily: 1082.75, targetTbDaily: 500.37 },
                { totalDaily: 1082.97, targetTbDaily: 500.47 },
                { totalDaily: 1083.32, targetTbDaily: 500.62 },
                { totalDaily: 1083.61, targetTbDaily: 500.74 },
                { totalDaily: 1083.85, targetTbDaily: 500.87 },
                { totalDaily: 1084.17, targetTbDaily: 501.01 },
                { totalDaily: 1084.46, targetTbDaily: 501.14 },
                { totalDaily: 1084.7, targetTbDaily: 501.25 },
                { totalDaily: 1085.04, targetTbDaily: 501.4 },
                { totalDaily: 1085.25, targetTbDaily: 501.5 },
                { totalDaily: 1085.52, targetTbDaily: 501.63 },
                { totalDaily: 1085.86, targetTbDaily: 501.8 },
                { totalDaily: 1086.15, targetTbDaily: 501.92 },
                { totalDaily: 1086.43, targetTbDaily: 502.05 },
                { totalDaily: 1086.66, targetTbDaily: 502.16 },
                { totalDaily: 1086.98, targetTbDaily: 502.29 },
                { totalDaily: 1087.18, targetTbDaily: 502.39 },
                { totalDaily: 1087.51, targetTbDaily: 502.53 },
                { totalDaily: 1087.84, targetTbDaily: 502.71 },
                { totalDaily: 1088.15, targetTbDaily: 502.85 },
                { totalDaily: 1088.4, targetTbDaily: 502.95 },
                { totalDaily: 1088.74, targetTbDaily: 503.13 },
                { totalDaily: 1088.98, targetTbDaily: 503.23 },
                { totalDaily: 1089.21, targetTbDaily: 503.35 },
                { totalDaily: 1089.57, targetTbDaily: 503.5 },
                { totalDaily: 1089.8, targetTbDaily: 503.58 },
                { totalDaily: 1090.07, targetTbDaily: 503.71 },
                { totalDaily: 1090.38, targetTbDaily: 503.88 },
                { totalDaily: 1090.62, targetTbDaily: 503.98 },
                { totalDaily: 1090.93, targetTbDaily: 504.12 },
                { totalDaily: 1091.25, targetTbDaily: 504.27 },
                { totalDaily: 1091.52, targetTbDaily: 504.38 },
                { totalDaily: 1091.83, targetTbDaily: 504.52 },
                { totalDaily: 1092.11, targetTbDaily: 504.67 },
                { totalDaily: 1092.39, targetTbDaily: 504.81 },
                { totalDaily: 1092.66, targetTbDaily: 504.91 },
                { totalDaily: 1092.96, targetTbDaily: 505.05 },
                { totalDaily: 1093.29, targetTbDaily: 505.23 },
                { totalDaily: 1093.52, targetTbDaily: 505.32 },
                { totalDaily: 1093.77, targetTbDaily: 505.44 },
                { totalDaily: 1094.12, targetTbDaily: 505.62 },
                { totalDaily: 1094.36, targetTbDaily: 505.72 },
                { totalDaily: 1094.68, targetTbDaily: 505.86 },
                { totalDaily: 1095, targetTbDaily: 506 },
                { totalDaily: 1095.28, targetTbDaily: 506.12 },
                { totalDaily: 1095.52, targetTbDaily: 506.25 },
                { totalDaily: 1095.78, targetTbDaily: 506.37 },
                { totalDaily: 1096.05, targetTbDaily: 506.51 },
                { totalDaily: 1096.34, targetTbDaily: 506.62 },
                { totalDaily: 1096.66, targetTbDaily: 506.79 },
                { totalDaily: 1096.97, targetTbDaily: 506.92 },
                { totalDaily: 1097.28, targetTbDaily: 507.05 },
                { totalDaily: 1097.51, targetTbDaily: 507.17 },
                { totalDaily: 1097.81, targetTbDaily: 507.32 },
                { totalDaily: 1098.05, targetTbDaily: 507.42 },
                { totalDaily: 1098.36, targetTbDaily: 507.54 },
                { totalDaily: 1098.71, targetTbDaily: 507.7 },
                { totalDaily: 1098.96, targetTbDaily: 507.85 },
                { totalDaily: 1099.26, targetTbDaily: 507.97 },
                { totalDaily: 1099.53, targetTbDaily: 508.1 },
                { totalDaily: 1099.84, targetTbDaily: 508.27 },
                { totalDaily: 1100.17, targetTbDaily: 508.41 },
                { totalDaily: 1100.41, targetTbDaily: 508.52 },
                { totalDaily: 1100.69, targetTbDaily: 508.65 },
                { totalDaily: 1101.02, targetTbDaily: 508.79 },
                { totalDaily: 1101.26, targetTbDaily: 508.9 },
                { totalDaily: 1101.55, targetTbDaily: 509.03 },
                { totalDaily: 1101.84, targetTbDaily: 509.18 },
                { totalDaily: 1102.13, targetTbDaily: 509.29 },
                { totalDaily: 1102.42, targetTbDaily: 509.43 },
                { totalDaily: 1102.75, targetTbDaily: 509.59 },
                { totalDaily: 1102.98, targetTbDaily: 509.69 },
                { totalDaily: 1103.27, targetTbDaily: 509.84 },
                { totalDaily: 1103.59, targetTbDaily: 509.98 },
                { totalDaily: 1103.87, targetTbDaily: 510.11 },
                { totalDaily: 1104.13, targetTbDaily: 510.23 },
                { totalDaily: 1104.41, targetTbDaily: 510.35 },
                { totalDaily: 1104.71, targetTbDaily: 510.51 },
                { totalDaily: 1105.01, targetTbDaily: 510.65 },
                { totalDaily: 1105.3, targetTbDaily: 510.76 },
                { totalDaily: 1105.58, targetTbDaily: 510.91 },
                { totalDaily: 1105.88, targetTbDaily: 511.05 },
                { totalDaily: 1106.15, targetTbDaily: 511.17 },
                { totalDaily: 1106.46, targetTbDaily: 511.31 },
                { totalDaily: 1106.78, targetTbDaily: 511.45 },
                { totalDaily: 1107.01, targetTbDaily: 511.56 },
                { totalDaily: 1107.33, targetTbDaily: 511.71 },
                { totalDaily: 1107.64, targetTbDaily: 511.87 },
                { totalDaily: 1107.88, targetTbDaily: 511.97 },
                { totalDaily: 1108.18, targetTbDaily: 512.1 },
                { totalDaily: 1108.46, targetTbDaily: 512.24 },
                { totalDaily: 1108.76, targetTbDaily: 512.37 },
                { totalDaily: 1109.06, targetTbDaily: 512.49 },
                { totalDaily: 1109.35, targetTbDaily: 512.66 },
                { totalDaily: 1109.64, targetTbDaily: 512.78 },
                { totalDaily: 1109.93, targetTbDaily: 512.9 },
                { totalDaily: 1110.21, targetTbDaily: 513.03 },
                { totalDaily: 1110.48, targetTbDaily: 513.16 },
                { totalDaily: 1110.75, targetTbDaily: 513.28 },
                { totalDaily: 1111.15, targetTbDaily: 513.49 },
                { totalDaily: 1111.4, targetTbDaily: 513.61 },
                { totalDaily: 1111.66, targetTbDaily: 513.73 },
                { totalDaily: 1111.98, targetTbDaily: 513.85 },
                { totalDaily: 1112.22, targetTbDaily: 513.97 },
                { totalDaily: 1112.49, targetTbDaily: 514.07 },
                { totalDaily: 1112.83, targetTbDaily: 514.25 },
                { totalDaily: 1113.14, targetTbDaily: 514.4 },
                { totalDaily: 1113.39, targetTbDaily: 514.51 },
                { totalDaily: 1113.72, targetTbDaily: 514.65 },
                { totalDaily: 1113.97, targetTbDaily: 514.76 },
                { totalDaily: 1114.24, targetTbDaily: 514.91 },
                { totalDaily: 1114.58, targetTbDaily: 515.04 },
                { totalDaily: 1114.89, targetTbDaily: 515.21 },
                { totalDaily: 1115.15, targetTbDaily: 515.32 },
                { totalDaily: 1115.48, targetTbDaily: 515.47 },
                { totalDaily: 1115.75, targetTbDaily: 515.57 },
                { totalDaily: 1116, targetTbDaily: 515.71 },
                { totalDaily: 1116.37, targetTbDaily: 515.89 },
                { totalDaily: 1116.6, targetTbDaily: 516.01 },
                { totalDaily: 1116.87, targetTbDaily: 516.12 },
                { totalDaily: 1117.23, targetTbDaily: 516.3 },
                { totalDaily: 1117.51, targetTbDaily: 516.41 },
                { totalDaily: 1117.75, targetTbDaily: 516.52 },
                { totalDaily: 1118.05, targetTbDaily: 516.65 },
                { totalDaily: 1118.36, targetTbDaily: 516.79 },
                { totalDaily: 1118.61, targetTbDaily: 516.92 },
                { totalDaily: 1118.93, targetTbDaily: 517.06 },
                { totalDaily: 1119.24, targetTbDaily: 517.22 },
                { totalDaily: 1119.54, targetTbDaily: 517.35 },
                { totalDaily: 1119.83, targetTbDaily: 517.49 },
                { totalDaily: 1120.14, targetTbDaily: 517.62 },
                { totalDaily: 1120.37, targetTbDaily: 517.73 },
                { totalDaily: 1120.69, targetTbDaily: 517.89 },
                { totalDaily: 1120.98, targetTbDaily: 518.01 },
                { totalDaily: 1121.24, targetTbDaily: 518.12 },
                { totalDaily: 1121.6, targetTbDaily: 518.29 },
                { totalDaily: 1121.93, targetTbDaily: 518.44 },
                { totalDaily: 1122.18, targetTbDaily: 518.56 },
                { totalDaily: 1122.48, targetTbDaily: 518.72 },
                { totalDaily: 1122.79, targetTbDaily: 518.85 },
                { totalDaily: 1123.02, targetTbDaily: 518.97 },
                { totalDaily: 1123.33, targetTbDaily: 519.11 },
                { totalDaily: 1123.61, targetTbDaily: 519.25 },
                { totalDaily: 1123.9, targetTbDaily: 519.37 },
                { totalDaily: 1124.23, targetTbDaily: 519.52 },
                { totalDaily: 1124.5, targetTbDaily: 519.64 },
                { totalDaily: 1124.8, targetTbDaily: 519.79 },
                { totalDaily: 1125.07, targetTbDaily: 519.91 },
                { totalDaily: 1125.4, targetTbDaily: 520.07 },
                { totalDaily: 1125.69, targetTbDaily: 520.18 },
                { totalDaily: 1126.05, targetTbDaily: 520.37 },
                { totalDaily: 1126.3, targetTbDaily: 520.48 },
                { totalDaily: 1126.6, targetTbDaily: 520.62 },
                { totalDaily: 1126.83, targetTbDaily: 520.71 },
                { totalDaily: 1127.19, targetTbDaily: 520.87 },
                { totalDaily: 1127.42, targetTbDaily: 521 },
                { totalDaily: 1127.74, targetTbDaily: 521.14 },
                { totalDaily: 1128.04, targetTbDaily: 521.27 },
                { totalDaily: 1128.34, targetTbDaily: 521.41 },
                { totalDaily: 1128.58, targetTbDaily: 521.53 },
                { totalDaily: 1128.91, targetTbDaily: 521.66 },
                { totalDaily: 1129.2, targetTbDaily: 521.81 },
                { totalDaily: 1129.49, targetTbDaily: 521.94 },
                { totalDaily: 1129.8, targetTbDaily: 522.07 },
                { totalDaily: 1130.11, targetTbDaily: 522.23 },
                { totalDaily: 1130.41, targetTbDaily: 522.37 },
                { totalDaily: 1130.69, targetTbDaily: 522.51 },
                { totalDaily: 1130.96, targetTbDaily: 522.63 },
                { totalDaily: 1131.34, targetTbDaily: 522.82 },
                { totalDaily: 1131.57, targetTbDaily: 522.88 },
                { totalDaily: 1131.84, targetTbDaily: 523.03 },
                { totalDaily: 1132.18, targetTbDaily: 523.19 },
                { totalDaily: 1132.47, targetTbDaily: 523.29 },
                { totalDaily: 1132.75, targetTbDaily: 523.43 },
                { totalDaily: 1133.01, targetTbDaily: 523.57 },
                { totalDaily: 1133.29, targetTbDaily: 523.71 },
                { totalDaily: 1133.6, targetTbDaily: 523.86 },
                { totalDaily: 1133.92, targetTbDaily: 524.02 },
                { totalDaily: 1134.22, targetTbDaily: 524.12 },
                { totalDaily: 1134.55, targetTbDaily: 524.29 },
                { totalDaily: 1134.79, targetTbDaily: 524.39 },
                { totalDaily: 1135.12, targetTbDaily: 524.54 },
                { totalDaily: 1135.46, targetTbDaily: 524.71 },
                { totalDaily: 1135.69, targetTbDaily: 524.83 },
                { totalDaily: 1136.02, targetTbDaily: 524.95 },
                { totalDaily: 1136.31, targetTbDaily: 525.09 },
                { totalDaily: 1136.6, targetTbDaily: 525.23 },
                { totalDaily: 1136.9, targetTbDaily: 525.39 },
                { totalDaily: 1137.19, targetTbDaily: 525.5 },
                { totalDaily: 1137.54, targetTbDaily: 525.69 },
                { totalDaily: 1137.81, targetTbDaily: 525.77 },
                { totalDaily: 1138.1, targetTbDaily: 525.91 },
                { totalDaily: 1138.44, targetTbDaily: 526.08 },
                { totalDaily: 1138.74, targetTbDaily: 526.22 },
                { totalDaily: 1139.03, targetTbDaily: 526.34 },
                { totalDaily: 1139.29, targetTbDaily: 526.47 },
                { totalDaily: 1139.53, targetTbDaily: 526.6 },
                { totalDaily: 1139.92, targetTbDaily: 526.77 },
                { totalDaily: 1140.17, targetTbDaily: 526.88 },
                { totalDaily: 1140.48, targetTbDaily: 527.03 },
                { totalDaily: 1140.78, targetTbDaily: 527.16 },
                { totalDaily: 1141.08, targetTbDaily: 527.29 },
                { totalDaily: 1141.35, targetTbDaily: 527.43 },
                { totalDaily: 1141.63, targetTbDaily: 527.53 },
                { totalDaily: 1141.9, targetTbDaily: 527.68 },
                { totalDaily: 1142.27, targetTbDaily: 527.85 },
                { totalDaily: 1142.55, targetTbDaily: 528 },
                { totalDaily: 1142.86, targetTbDaily: 528.12 },
                { totalDaily: 1143.17, targetTbDaily: 528.28 },
                { totalDaily: 1143.47, targetTbDaily: 528.39 },
                { totalDaily: 1143.78, targetTbDaily: 528.53 },
                { totalDaily: 1144.05, targetTbDaily: 528.66 },
                { totalDaily: 1144.34, targetTbDaily: 528.81 },
                { totalDaily: 1144.64, targetTbDaily: 528.94 },
                { totalDaily: 1144.96, targetTbDaily: 529.09 },
                { totalDaily: 1145.26, targetTbDaily: 529.25 },
                { totalDaily: 1145.51, targetTbDaily: 529.36 },
                { totalDaily: 1145.89, targetTbDaily: 529.52 },
                { totalDaily: 1146.14, targetTbDaily: 529.63 },
                { totalDaily: 1146.46, targetTbDaily: 529.77 },
                { totalDaily: 1146.78, targetTbDaily: 529.93 },
                { totalDaily: 1147.04, targetTbDaily: 530.07 },
                { totalDaily: 1147.31, targetTbDaily: 530.16 },
                { totalDaily: 1147.64, targetTbDaily: 530.34 },
                { totalDaily: 1147.95, targetTbDaily: 530.48 },
                { totalDaily: 1148.24, targetTbDaily: 530.61 },
                { totalDaily: 1148.55, targetTbDaily: 530.75 },
                { totalDaily: 1148.83, targetTbDaily: 530.89 },
                { totalDaily: 1149.15, targetTbDaily: 531.05 },
                { totalDaily: 1149.42, targetTbDaily: 531.13 },
                { totalDaily: 1149.75, targetTbDaily: 531.29 },
                { totalDaily: 1150.03, targetTbDaily: 531.43 },
                { totalDaily: 1150.35, targetTbDaily: 531.58 },
                { totalDaily: 1150.62, targetTbDaily: 531.7 },
                { totalDaily: 1150.95, targetTbDaily: 531.89 },
                { totalDaily: 1151.28, targetTbDaily: 532 },
                { totalDaily: 1151.54, targetTbDaily: 532.12 },
                { totalDaily: 1151.84, targetTbDaily: 532.29 },
                { totalDaily: 1152.12, targetTbDaily: 532.41 },
                { totalDaily: 1152.43, targetTbDaily: 532.54 },
                { totalDaily: 1152.78, targetTbDaily: 532.71 },
                { totalDaily: 1153.07, targetTbDaily: 532.83 },
                { totalDaily: 1153.38, targetTbDaily: 532.99 },
                { totalDaily: 1302.69, targetTbDaily: 597.12 },
                { totalDaily: 1303.06, targetTbDaily: 597.3 },
                { totalDaily: 1303.36, targetTbDaily: 597.44 },
                { totalDaily: 1303.71, targetTbDaily: 597.6 },
                { totalDaily: 1304.1, targetTbDaily: 597.77 },
                { totalDaily: 1304.39, targetTbDaily: 597.9 },
                { totalDaily: 1304.82, targetTbDaily: 598.08 },
                { totalDaily: 1305.11, targetTbDaily: 598.24 },
                { totalDaily: 1305.4, targetTbDaily: 598.36 },
                { totalDaily: 1305.76, targetTbDaily: 598.53 },
                { totalDaily: 1306.1, targetTbDaily: 598.69 },
                { totalDaily: 1306.44, targetTbDaily: 598.84 },
                { totalDaily: 1306.82, targetTbDaily: 599.01 },
                { totalDaily: 1307.13, targetTbDaily: 599.16 },
                { totalDaily: 1307.42, targetTbDaily: 599.29 },
                { totalDaily: 1307.82, targetTbDaily: 599.46 },
                { totalDaily: 1308.13, targetTbDaily: 599.58 },
                { totalDaily: 1308.51, targetTbDaily: 599.77 },
                { totalDaily: 1308.88, targetTbDaily: 599.93 },
                { totalDaily: 1309.19, targetTbDaily: 600.08 },
                { totalDaily: 1309.52, targetTbDaily: 600.26 },
                { totalDaily: 1309.89, targetTbDaily: 600.42 },
                { totalDaily: 1310.22, targetTbDaily: 600.57 },
                { totalDaily: 1310.54, targetTbDaily: 600.71 },
                { totalDaily: 1310.9, targetTbDaily: 600.88 },
                { totalDaily: 1311.31, targetTbDaily: 601.05 },
                { totalDaily: 1311.61, targetTbDaily: 601.22 },
                { totalDaily: 1311.94, targetTbDaily: 601.34 },
                { totalDaily: 1312.26, targetTbDaily: 601.51 },
                { totalDaily: 1312.58, targetTbDaily: 601.65 },
                { totalDaily: 1312.96, targetTbDaily: 601.82 },
                { totalDaily: 1313.25, targetTbDaily: 601.97 },
                { totalDaily: 1313.63, targetTbDaily: 602.15 },
                { totalDaily: 1313.98, targetTbDaily: 602.29 },
                { totalDaily: 1314.34, targetTbDaily: 602.44 },
                { totalDaily: 1314.65, targetTbDaily: 602.56 },
                { totalDaily: 1314.98, targetTbDaily: 602.75 },
                { totalDaily: 1315.34, targetTbDaily: 602.9 },
                { totalDaily: 1315.69, targetTbDaily: 603.06 },
                { totalDaily: 1316.02, targetTbDaily: 603.22 },
                { totalDaily: 1316.36, targetTbDaily: 603.38 },
                { totalDaily: 1316.73, targetTbDaily: 603.55 },
                { totalDaily: 1317.02, targetTbDaily: 603.69 },
                { totalDaily: 1317.38, targetTbDaily: 603.84 },
                { totalDaily: 1317.74, targetTbDaily: 604.01 },
                { totalDaily: 1318.13, targetTbDaily: 604.18 },
                { totalDaily: 1318.46, targetTbDaily: 604.33 },
                { totalDaily: 1318.78, targetTbDaily: 604.46 },
                { totalDaily: 1319.12, targetTbDaily: 604.62 },
                { totalDaily: 1319.44, targetTbDaily: 604.8 },
                { totalDaily: 1319.83, targetTbDaily: 604.98 },
                { totalDaily: 1320.19, targetTbDaily: 605.14 },
                { totalDaily: 1320.51, targetTbDaily: 605.29 },
                { totalDaily: 1320.9, targetTbDaily: 605.46 },
                { totalDaily: 1321.23, targetTbDaily: 605.59 },
                { totalDaily: 1321.57, targetTbDaily: 605.77 },
                { totalDaily: 1321.87, targetTbDaily: 605.92 },
                { totalDaily: 1322.23, targetTbDaily: 606.05 },
                { totalDaily: 1322.55, targetTbDaily: 606.23 },
                { totalDaily: 1322.92, targetTbDaily: 606.4 },
                { totalDaily: 1323.26, targetTbDaily: 606.54 },
                { totalDaily: 1323.62, targetTbDaily: 606.71 },
                { totalDaily: 1323.91, targetTbDaily: 606.84 },
                { totalDaily: 1324.27, targetTbDaily: 607 },
                { totalDaily: 1324.61, targetTbDaily: 607.15 },
                { totalDaily: 1324.98, targetTbDaily: 607.33 },
                { totalDaily: 1325.38, targetTbDaily: 607.51 },
                { totalDaily: 1325.75, targetTbDaily: 607.67 },
                { totalDaily: 1326.1, targetTbDaily: 607.85 },
                { totalDaily: 1326.42, targetTbDaily: 608.01 },
                { totalDaily: 1326.74, targetTbDaily: 608.15 },
                { totalDaily: 1327.12, targetTbDaily: 608.3 },
                { totalDaily: 1327.45, targetTbDaily: 608.45 },
                { totalDaily: 1327.78, targetTbDaily: 608.6 },
                { totalDaily: 1328.12, targetTbDaily: 608.76 },
                { totalDaily: 1328.46, targetTbDaily: 608.94 },
                { totalDaily: 1328.79, targetTbDaily: 609.08 },
                { totalDaily: 1329.13, targetTbDaily: 609.24 },
                { totalDaily: 1329.52, targetTbDaily: 609.41 },
                { totalDaily: 1329.86, targetTbDaily: 609.54 },
                { totalDaily: 1330.14, targetTbDaily: 609.68 },
                { totalDaily: 1330.54, targetTbDaily: 609.88 },
                { totalDaily: 1330.86, targetTbDaily: 610.03 },
                { totalDaily: 1331.25, targetTbDaily: 610.2 },
                { totalDaily: 1331.6, targetTbDaily: 610.36 },
                { totalDaily: 1331.91, targetTbDaily: 610.52 },
                { totalDaily: 1332.27, targetTbDaily: 610.68 },
                { totalDaily: 1332.66, targetTbDaily: 610.85 },
                { totalDaily: 1332.98, targetTbDaily: 611.02 },
                { totalDaily: 1333.37, targetTbDaily: 611.17 },
                { totalDaily: 1333.68, targetTbDaily: 611.32 },
                { totalDaily: 1334.1, targetTbDaily: 611.53 },
                { totalDaily: 1334.42, targetTbDaily: 611.66 },
                { totalDaily: 1334.73, targetTbDaily: 611.79 },
                { totalDaily: 1335.11, targetTbDaily: 611.95 },
                { totalDaily: 1335.4, targetTbDaily: 612.12 },
                { totalDaily: 1335.75, targetTbDaily: 612.26 },
                { totalDaily: 1336.11, targetTbDaily: 612.41 },
                { totalDaily: 1336.43, targetTbDaily: 612.57 },
                { totalDaily: 1336.76, targetTbDaily: 612.72 },
                { totalDaily: 1337.2, targetTbDaily: 612.91 },
                { totalDaily: 1337.54, targetTbDaily: 613.1 },
                { totalDaily: 1337.82, targetTbDaily: 613.23 },
                { totalDaily: 1338.19, targetTbDaily: 613.39 },
                { totalDaily: 1338.54, targetTbDaily: 613.54 },
                { totalDaily: 1338.88, targetTbDaily: 613.7 },
                { totalDaily: 1339.22, targetTbDaily: 613.86 },
                { totalDaily: 1339.56, targetTbDaily: 614.04 },
                { totalDaily: 1339.9, targetTbDaily: 614.17 },
                { totalDaily: 1340.36, targetTbDaily: 614.38 },
                { totalDaily: 1340.69, targetTbDaily: 614.53 },
                { totalDaily: 1341.06, targetTbDaily: 614.67 },
                { totalDaily: 1341.39, targetTbDaily: 614.87 },
                { totalDaily: 1341.72, targetTbDaily: 615 },
                { totalDaily: 1342.1, targetTbDaily: 615.17 },
                { totalDaily: 1342.44, targetTbDaily: 615.35 },
                { totalDaily: 1342.75, targetTbDaily: 615.47 },
                { totalDaily: 1343.14, targetTbDaily: 615.66 },
                { totalDaily: 1343.53, targetTbDaily: 615.85 },
                { totalDaily: 1343.83, targetTbDaily: 615.97 },
                { totalDaily: 1344.22, targetTbDaily: 616.14 },
                { totalDaily: 1344.54, targetTbDaily: 616.3 },
                { totalDaily: 1344.88, targetTbDaily: 616.42 },
                { totalDaily: 1345.22, targetTbDaily: 616.6 },
                { totalDaily: 1345.56, targetTbDaily: 616.77 },
                { totalDaily: 1345.93, targetTbDaily: 616.91 },
                { totalDaily: 1346.26, targetTbDaily: 617.1 },
                { totalDaily: 1346.58, targetTbDaily: 617.22 },
                { totalDaily: 1346.94, targetTbDaily: 617.39 },
                { totalDaily: 1347.33, targetTbDaily: 617.58 },
                { totalDaily: 1347.68, targetTbDaily: 617.75 },
                { totalDaily: 1348.08, targetTbDaily: 617.91 },
                { totalDaily: 1348.41, targetTbDaily: 618.05 },
                { totalDaily: 1348.76, targetTbDaily: 618.25 },
                { totalDaily: 1349.11, targetTbDaily: 618.4 },
                { totalDaily: 1349.41, targetTbDaily: 618.5 },
                { totalDaily: 1349.75, targetTbDaily: 618.68 },
                { totalDaily: 1350.14, targetTbDaily: 618.85 },
                { totalDaily: 1350.46, targetTbDaily: 619.01 },
                { totalDaily: 1350.86, targetTbDaily: 619.18 },
                { totalDaily: 1351.24, targetTbDaily: 619.35 },
                { totalDaily: 1351.55, targetTbDaily: 619.5 },
                { totalDaily: 1351.92, targetTbDaily: 619.68 },
                { totalDaily: 1352.23, targetTbDaily: 619.81 },
                { totalDaily: 1352.61, targetTbDaily: 620.01 },
                { totalDaily: 1352.98, targetTbDaily: 620.16 },
                { totalDaily: 1353.36, targetTbDaily: 620.34 },
                { totalDaily: 1353.72, targetTbDaily: 620.52 },
                { totalDaily: 1354.05, targetTbDaily: 620.66 },
                { totalDaily: 1354.4, targetTbDaily: 620.82 },
                { totalDaily: 1354.72, targetTbDaily: 620.97 },
                { totalDaily: 1355.1, targetTbDaily: 621.14 },
                { totalDaily: 1355.45, targetTbDaily: 621.28 },
                { totalDaily: 1355.82, targetTbDaily: 621.47 },
                { totalDaily: 1356.2, targetTbDaily: 621.64 },
                { totalDaily: 1356.5, targetTbDaily: 621.79 },
                { totalDaily: 1356.84, targetTbDaily: 621.93 },
                { totalDaily: 1357.24, targetTbDaily: 622.14 },
                { totalDaily: 1357.61, targetTbDaily: 622.29 },
                { totalDaily: 1357.93, targetTbDaily: 622.43 },
                { totalDaily: 1358.31, targetTbDaily: 622.62 },
                { totalDaily: 1358.64, targetTbDaily: 622.77 },
                { totalDaily: 1359.02, targetTbDaily: 622.93 },
                { totalDaily: 1359.38, targetTbDaily: 623.11 },
                { totalDaily: 1359.7, targetTbDaily: 623.24 },
                { totalDaily: 1360.08, targetTbDaily: 623.41 },
                { totalDaily: 1360.42, targetTbDaily: 623.57 },
                { totalDaily: 1360.76, targetTbDaily: 623.72 },
                { totalDaily: 1361.09, targetTbDaily: 623.87 },
                { totalDaily: 1361.44, targetTbDaily: 624.06 },
                { totalDaily: 1361.8, targetTbDaily: 624.21 },
                { totalDaily: 1362.15, targetTbDaily: 624.38 },
                { totalDaily: 1362.51, targetTbDaily: 624.52 },
                { totalDaily: 1362.91, targetTbDaily: 624.71 },
                { totalDaily: 1363.28, targetTbDaily: 624.87 },
                { totalDaily: 1363.63, targetTbDaily: 625.04 },
                { totalDaily: 1363.93, targetTbDaily: 625.2 },
                { totalDaily: 1364.32, targetTbDaily: 625.35 },
                { totalDaily: 1364.66, targetTbDaily: 625.5 },
                { totalDaily: 1365.08, targetTbDaily: 625.73 },
                { totalDaily: 1365.42, targetTbDaily: 625.84 },
                { totalDaily: 1365.75, targetTbDaily: 626.02 },
                { totalDaily: 1366.11, targetTbDaily: 626.18 },
                { totalDaily: 1366.47, targetTbDaily: 626.36 },
                { totalDaily: 1366.85, targetTbDaily: 626.52 },
                { totalDaily: 1367.17, targetTbDaily: 626.66 },
                { totalDaily: 1367.52, targetTbDaily: 626.84 },
                { totalDaily: 1367.88, targetTbDaily: 626.99 },
                { totalDaily: 1368.23, targetTbDaily: 627.16 },
                { totalDaily: 1368.59, targetTbDaily: 627.3 },
                { totalDaily: 1368.94, targetTbDaily: 627.49 },
                { totalDaily: 1369.34, targetTbDaily: 627.66 },
                { totalDaily: 1369.69, targetTbDaily: 627.82 },
                { totalDaily: 1370.01, targetTbDaily: 627.98 },
                { totalDaily: 1370.46, targetTbDaily: 628.18 },
                { totalDaily: 1370.79, targetTbDaily: 628.36 },
                { totalDaily: 1371.1, targetTbDaily: 628.47 },
                { totalDaily: 1371.47, targetTbDaily: 628.65 },
                { totalDaily: 1371.84, targetTbDaily: 628.8 },
                { totalDaily: 1372.16, targetTbDaily: 628.94 },
                { totalDaily: 1372.57, targetTbDaily: 629.16 },
                { totalDaily: 1372.93, targetTbDaily: 629.31 },
                { totalDaily: 1373.27, targetTbDaily: 629.48 },
                { totalDaily: 1373.65, targetTbDaily: 629.65 },
                { totalDaily: 1373.98, targetTbDaily: 629.79 },
                { totalDaily: 1374.33, targetTbDaily: 629.93 },
                { totalDaily: 1374.67, targetTbDaily: 630.12 },
                { totalDaily: 1375.01, targetTbDaily: 630.25 },
                { totalDaily: 1375.41, targetTbDaily: 630.47 },
                { totalDaily: 1375.8, targetTbDaily: 630.64 },
                { totalDaily: 1376.1, targetTbDaily: 630.77 },
                { totalDaily: 1376.55, targetTbDaily: 630.96 },
                { totalDaily: 1376.9, targetTbDaily: 631.14 },
                { totalDaily: 1377.23, targetTbDaily: 631.29 },
                { totalDaily: 1377.59, targetTbDaily: 631.44 },
                { totalDaily: 1377.86, targetTbDaily: 631.58 },
                { totalDaily: 1378.26, targetTbDaily: 631.75 },
                { totalDaily: 1378.64, targetTbDaily: 631.94 },
                { totalDaily: 1379, targetTbDaily: 632.09 },
                { totalDaily: 1379.41, targetTbDaily: 632.3 },
                { totalDaily: 1379.77, targetTbDaily: 632.47 },
                { totalDaily: 1380.09, targetTbDaily: 632.59 },
                { totalDaily: 1380.47, targetTbDaily: 632.75 },
                { totalDaily: 1380.79, targetTbDaily: 632.9 },
                { totalDaily: 1381.16, targetTbDaily: 633.08 },
                { totalDaily: 1381.54, targetTbDaily: 633.27 },
                { totalDaily: 1381.89, targetTbDaily: 633.41 },
                { totalDaily: 1382.22, targetTbDaily: 633.58 },
                { totalDaily: 1382.64, targetTbDaily: 633.76 },
                { totalDaily: 1382.94, targetTbDaily: 633.89 },
                { totalDaily: 1383.31, targetTbDaily: 634.07 },
                { totalDaily: 1383.72, targetTbDaily: 634.25 },
                { totalDaily: 1384.08, targetTbDaily: 634.42 },
                { totalDaily: 1384.4, targetTbDaily: 634.56 },
                { totalDaily: 1384.77, targetTbDaily: 634.75 },
                { totalDaily: 1385.08, targetTbDaily: 634.88 },
                { totalDaily: 1385.51, targetTbDaily: 635.09 },
                { totalDaily: 1385.89, targetTbDaily: 635.26 },
                { totalDaily: 1386.22, targetTbDaily: 635.42 },
                { totalDaily: 1386.65, targetTbDaily: 635.58 },
                { totalDaily: 1386.97, targetTbDaily: 635.76 },
                { totalDaily: 1387.31, targetTbDaily: 635.89 },
                { totalDaily: 1387.69, targetTbDaily: 636.07 },
                { totalDaily: 1388.02, targetTbDaily: 636.21 },
                { totalDaily: 1388.43, targetTbDaily: 636.42 },
                { totalDaily: 1388.76, targetTbDaily: 636.58 },
                { totalDaily: 1389.1, targetTbDaily: 636.69 },
                { totalDaily: 1389.52, targetTbDaily: 636.9 },
                { totalDaily: 1389.87, targetTbDaily: 637.1 },
                { totalDaily: 1390.18, targetTbDaily: 637.24 },
                { totalDaily: 1390.62, targetTbDaily: 637.41 },
                { totalDaily: 1390.97, targetTbDaily: 637.61 },
                { totalDaily: 1391.29, targetTbDaily: 637.74 },
                { totalDaily: 1391.65, targetTbDaily: 637.88 },
                { totalDaily: 1391.98, targetTbDaily: 638.04 },
                { totalDaily: 1392.36, targetTbDaily: 638.2 },
                { totalDaily: 1392.75, targetTbDaily: 638.4 },
                { totalDaily: 1393.12, targetTbDaily: 638.58 },
                { totalDaily: 1393.54, targetTbDaily: 638.76 },
                { totalDaily: 1393.88, targetTbDaily: 638.92 },
                { totalDaily: 1394.21, targetTbDaily: 639.08 },
                { totalDaily: 1394.54, targetTbDaily: 639.21 },
                { totalDaily: 1394.9, targetTbDaily: 639.37 },
                { totalDaily: 1395.31, targetTbDaily: 639.58 },
                { totalDaily: 1395.63, targetTbDaily: 639.73 },
                { totalDaily: 1396.07, targetTbDaily: 639.94 },
                { totalDaily: 1396.43, targetTbDaily: 640.09 },
                { totalDaily: 1396.75, targetTbDaily: 640.23 },
                { totalDaily: 1397.14, targetTbDaily: 640.42 },
                { totalDaily: 1397.52, targetTbDaily: 640.59 },
                { totalDaily: 1397.92, targetTbDaily: 640.75 },
                { totalDaily: 1398.26, targetTbDaily: 640.93 },
                { totalDaily: 1398.56, targetTbDaily: 641.06 },
                { totalDaily: 1399.02, targetTbDaily: 641.28 },
                { totalDaily: 1399.31, targetTbDaily: 641.43 },
                { totalDaily: 1399.69, targetTbDaily: 641.57 },
                { totalDaily: 1400.05, targetTbDaily: 641.74 },
                { totalDaily: 1400.41, targetTbDaily: 641.91 },
                { totalDaily: 1400.84, targetTbDaily: 642.11 },
                { totalDaily: 1401.18, targetTbDaily: 642.26 },
                { totalDaily: 1401.52, targetTbDaily: 642.43 },
                { totalDaily: 1401.84, targetTbDaily: 642.6 },
                { totalDaily: 1402.31, targetTbDaily: 642.78 },
                { totalDaily: 1402.65, targetTbDaily: 642.92 },
                { totalDaily: 1402.99, targetTbDaily: 643.08 },
                { totalDaily: 1403.39, targetTbDaily: 643.28 },
                { totalDaily: 1403.72, targetTbDaily: 643.43 },
                { totalDaily: 1404.08, targetTbDaily: 643.59 },
                { totalDaily: 1404.38, targetTbDaily: 643.73 },
                { totalDaily: 1404.75, targetTbDaily: 643.89 },
                { totalDaily: 1405.19, targetTbDaily: 644.11 },
                { totalDaily: 1405.53, targetTbDaily: 644.26 },
                { totalDaily: 1405.9, targetTbDaily: 644.42 },
                { totalDaily: 1406.29, targetTbDaily: 644.6 },
                { totalDaily: 1406.6, targetTbDaily: 644.76 },
                { totalDaily: 1407, targetTbDaily: 644.91 },
                { totalDaily: 1407.34, targetTbDaily: 645.07 },
                { totalDaily: 1407.76, targetTbDaily: 645.28 },
                { totalDaily: 1408.12, targetTbDaily: 645.44 },
                { totalDaily: 1408.5, targetTbDaily: 645.6 },
                { totalDaily: 1408.89, targetTbDaily: 645.79 },
                { totalDaily: 1409.25, targetTbDaily: 645.94 },
                { totalDaily: 1409.65, targetTbDaily: 646.16 },
                { totalDaily: 1409.98, targetTbDaily: 646.29 },
                { totalDaily: 1410.32, targetTbDaily: 646.42 },
                { totalDaily: 1410.7, targetTbDaily: 646.62 },
                { totalDaily: 1410.99, targetTbDaily: 646.77 },
                { totalDaily: 1411.42, targetTbDaily: 646.96 },
                { totalDaily: 1411.85, targetTbDaily: 647.14 },
                { totalDaily: 1412.16, targetTbDaily: 647.27 },
                { totalDaily: 1412.59, targetTbDaily: 647.49 },
                { totalDaily: 1412.91, targetTbDaily: 647.64 },
                { totalDaily: 1413.25, targetTbDaily: 647.8 },
                { totalDaily: 1413.64, targetTbDaily: 647.97 },
                { totalDaily: 1414.01, targetTbDaily: 648.14 },
                { totalDaily: 1414.37, targetTbDaily: 648.3 },
                { totalDaily: 1414.8, targetTbDaily: 648.49 },
                { totalDaily: 1415.12, targetTbDaily: 648.66 },
                { totalDaily: 1415.47, targetTbDaily: 648.82 },
                { totalDaily: 1415.88, targetTbDaily: 649 },
                { totalDaily: 1416.22, targetTbDaily: 649.14 },
                { totalDaily: 1416.6, targetTbDaily: 649.32 },
                { totalDaily: 1416.97, targetTbDaily: 649.48 },
                { totalDaily: 1417.31, targetTbDaily: 649.63 },
                { totalDaily: 1417.72, targetTbDaily: 649.84 },
                { totalDaily: 1418.05, targetTbDaily: 650 },
                { totalDaily: 1418.45, targetTbDaily: 650.17 },
                { totalDaily: 1418.87, targetTbDaily: 650.39 },
                { totalDaily: 1419.2, targetTbDaily: 650.52 },
                { totalDaily: 1419.51, targetTbDaily: 650.65 },
                { totalDaily: 1419.95, targetTbDaily: 650.86 },
                { totalDaily: 1420.28, targetTbDaily: 651.02 },
                { totalDaily: 1420.65, targetTbDaily: 651.17 },
                { totalDaily: 1421.02, targetTbDaily: 651.34 },
                { totalDaily: 1421.36, targetTbDaily: 651.53 },
                { totalDaily: 1421.85, targetTbDaily: 651.73 },
                { totalDaily: 1422.16, targetTbDaily: 651.89 },
                { totalDaily: 1422.57, targetTbDaily: 652.06 },
                { totalDaily: 1422.91, targetTbDaily: 652.23 },
                { totalDaily: 1423.25, targetTbDaily: 652.39 },
                { totalDaily: 1423.63, targetTbDaily: 652.54 },
                { totalDaily: 1424.03, targetTbDaily: 652.72 },
                { totalDaily: 1424.35, targetTbDaily: 652.9 },
                { totalDaily: 1424.79, targetTbDaily: 653.08 },
                { totalDaily: 1425.15, targetTbDaily: 653.24 },
                { totalDaily: 1425.55, targetTbDaily: 653.44 },
                { totalDaily: 1425.93, targetTbDaily: 653.62 },
                { totalDaily: 1426.26, targetTbDaily: 653.76 },
                { totalDaily: 1426.62, targetTbDaily: 653.93 },
                { totalDaily: 1426.97, targetTbDaily: 654.06 },
                { totalDaily: 1427.33, targetTbDaily: 654.24 },
                { totalDaily: 1427.73, targetTbDaily: 654.41 },
                { totalDaily: 1428.14, targetTbDaily: 654.63 },
                { totalDaily: 1428.49, targetTbDaily: 654.78 },
                { totalDaily: 1428.92, targetTbDaily: 654.95 },
                { totalDaily: 1429.23, targetTbDaily: 655.1 },
                { totalDaily: 1429.57, targetTbDaily: 655.27 },
                { totalDaily: 1429.92, targetTbDaily: 655.41 },
                { totalDaily: 1430.3, targetTbDaily: 655.59 },
                { totalDaily: 1430.73, targetTbDaily: 655.81 },
                { totalDaily: 1431.12, targetTbDaily: 655.97 },
                { totalDaily: 1431.5, targetTbDaily: 656.17 },
                { totalDaily: 1431.91, targetTbDaily: 656.36 },
                { totalDaily: 1432.25, targetTbDaily: 656.52 },
                { totalDaily: 1432.6, targetTbDaily: 656.67 },
                { totalDaily: 1436.99, targetTbDaily: 658.17 },
                { totalDaily: 1437.38, targetTbDaily: 658.37 },
                { totalDaily: 1437.73, targetTbDaily: 658.52 },
                { totalDaily: 1438.1, targetTbDaily: 658.7 },
                { totalDaily: 1438.44, targetTbDaily: 658.84 },
                { totalDaily: 1438.86, targetTbDaily: 659.02 },
                { totalDaily: 1439.28, targetTbDaily: 659.24 },
                { totalDaily: 1439.58, targetTbDaily: 659.33 },
                { totalDaily: 1439.98, targetTbDaily: 659.55 },
                { totalDaily: 1440.38, targetTbDaily: 659.72 },
                { totalDaily: 1440.69, targetTbDaily: 659.83 },
                { totalDaily: 1441.1, targetTbDaily: 660.07 },
                { totalDaily: 1441.46, targetTbDaily: 660.23 },
                { totalDaily: 1441.83, targetTbDaily: 660.39 },
                { totalDaily: 1442.2, targetTbDaily: 660.55 },
                { totalDaily: 1442.61, targetTbDaily: 660.74 },
                { totalDaily: 1443.02, targetTbDaily: 660.95 },
                { totalDaily: 1443.4, targetTbDaily: 661.11 },
                { totalDaily: 1443.75, targetTbDaily: 661.28 },
                { totalDaily: 1444.14, targetTbDaily: 661.45 },
                { totalDaily: 1444.54, targetTbDaily: 661.65 },
                { totalDaily: 1444.88, targetTbDaily: 661.81 },
                { totalDaily: 1445.28, targetTbDaily: 661.99 },
                { totalDaily: 1445.66, targetTbDaily: 662.14 },
                { totalDaily: 1446.01, targetTbDaily: 662.32 },
                { totalDaily: 1446.39, targetTbDaily: 662.47 },
                { totalDaily: 1446.77, targetTbDaily: 662.68 },
                { totalDaily: 1447.12, targetTbDaily: 662.83 },
                { totalDaily: 1447.54, targetTbDaily: 663 },
                { totalDaily: 1447.87, targetTbDaily: 663.14 },
                { totalDaily: 1448.26, targetTbDaily: 663.33 },
                { totalDaily: 1448.67, targetTbDaily: 663.54 },
                { totalDaily: 1449.06, targetTbDaily: 663.7 },
                { totalDaily: 1449.41, targetTbDaily: 663.85 },
                { totalDaily: 1449.75, targetTbDaily: 664 },
                { totalDaily: 1450.22, targetTbDaily: 664.24 },
                { totalDaily: 1450.56, targetTbDaily: 664.4 },
                { totalDaily: 1450.93, targetTbDaily: 664.56 },
                { totalDaily: 1451.33, targetTbDaily: 664.74 },
                { totalDaily: 1451.69, targetTbDaily: 664.91 },
                { totalDaily: 1452.04, targetTbDaily: 665.06 },
                { totalDaily: 1452.44, targetTbDaily: 665.26 },
                { totalDaily: 1452.81, targetTbDaily: 665.42 },
                { totalDaily: 1453.15, targetTbDaily: 665.56 },
                { totalDaily: 1453.59, targetTbDaily: 665.78 },
                { totalDaily: 1453.95, targetTbDaily: 665.94 },
                { totalDaily: 1454.31, targetTbDaily: 666.1 },
                { totalDaily: 1454.69, targetTbDaily: 666.26 },
                { totalDaily: 1455.16, targetTbDaily: 666.52 },
                { totalDaily: 1455.5, targetTbDaily: 666.65 },
                { totalDaily: 1455.86, targetTbDaily: 666.84 },
                { totalDaily: 1456.24, targetTbDaily: 667.01 },
                { totalDaily: 1456.54, targetTbDaily: 667.13 },
                { totalDaily: 1457.03, targetTbDaily: 667.35 },
                { totalDaily: 1457.4, targetTbDaily: 667.52 },
                { totalDaily: 1457.71, targetTbDaily: 667.66 },
                { totalDaily: 1458.19, targetTbDaily: 667.88 },
                { totalDaily: 1458.51, targetTbDaily: 668.03 },
                { totalDaily: 1458.88, targetTbDaily: 668.18 },
                { totalDaily: 1459.26, targetTbDaily: 668.39 },
                { totalDaily: 1459.69, targetTbDaily: 668.57 },
                { totalDaily: 1460.05, targetTbDaily: 668.74 },
                { totalDaily: 1460.42, targetTbDaily: 668.92 },
                { totalDaily: 1460.81, targetTbDaily: 669.07 },
                { totalDaily: 1461.17, targetTbDaily: 669.24 },
                { totalDaily: 1461.59, targetTbDaily: 669.46 },
                { totalDaily: 1461.97, targetTbDaily: 669.63 },
                { totalDaily: 1462.38, targetTbDaily: 669.8 },
                { totalDaily: 1462.73, targetTbDaily: 669.99 },
                { totalDaily: 1463.1, targetTbDaily: 670.12 },
                { totalDaily: 1463.48, targetTbDaily: 670.29 },
                { totalDaily: 1463.81, targetTbDaily: 670.47 },
                { totalDaily: 1464.21, targetTbDaily: 670.66 },
                { totalDaily: 1464.58, targetTbDaily: 670.81 },
                { totalDaily: 1465.03, targetTbDaily: 671.03 },
                { totalDaily: 1465.4, targetTbDaily: 671.19 },
                { totalDaily: 1465.82, targetTbDaily: 671.36 },
                { totalDaily: 1466.17, targetTbDaily: 671.52 },
                { totalDaily: 1466.5, targetTbDaily: 671.69 },
                { totalDaily: 1466.91, targetTbDaily: 671.88 },
                { totalDaily: 1467.32, targetTbDaily: 672.06 },
                { totalDaily: 1467.7, targetTbDaily: 672.25 },
                { totalDaily: 1468.1, targetTbDaily: 672.44 },
                { totalDaily: 1468.5, targetTbDaily: 672.59 },
                { totalDaily: 1468.83, targetTbDaily: 672.74 },
                { totalDaily: 1469.23, targetTbDaily: 672.98 },
                { totalDaily: 1469.61, targetTbDaily: 673.13 },
                { totalDaily: 1469.99, targetTbDaily: 673.27 },
                { totalDaily: 1470.37, targetTbDaily: 673.45 },
                { totalDaily: 1470.69, targetTbDaily: 673.62 },
                { totalDaily: 1471.2, targetTbDaily: 673.85 },
                { totalDaily: 1471.52, targetTbDaily: 674 },
                { totalDaily: 1471.93, targetTbDaily: 674.17 },
                { totalDaily: 1472.33, targetTbDaily: 674.37 },
                { totalDaily: 1472.66, targetTbDaily: 674.5 },
                { totalDaily: 1473.09, targetTbDaily: 674.7 },
                { totalDaily: 1473.47, targetTbDaily: 674.91 },
                { totalDaily: 1473.83, targetTbDaily: 675.05 },
                { totalDaily: 1474.23, targetTbDaily: 675.26 },
                { totalDaily: 1474.58, targetTbDaily: 675.38 },
                { totalDaily: 1475, targetTbDaily: 675.58 },
                { totalDaily: 1475.38, targetTbDaily: 675.76 },
                { totalDaily: 1475.82, targetTbDaily: 675.96 },
                { totalDaily: 1476.15, targetTbDaily: 676.11 },
                { totalDaily: 1476.57, targetTbDaily: 676.31 },
                { totalDaily: 1476.86, targetTbDaily: 676.44 },
                { totalDaily: 1477.25, targetTbDaily: 676.61 },
                { totalDaily: 1477.69, targetTbDaily: 676.8 },
                { totalDaily: 1478.04, targetTbDaily: 676.98 },
                { totalDaily: 1478.45, targetTbDaily: 677.16 },
                { totalDaily: 1478.85, targetTbDaily: 677.37 },
                { totalDaily: 1479.2, targetTbDaily: 677.5 },
                { totalDaily: 1479.59, targetTbDaily: 677.67 },
                { totalDaily: 1480.01, targetTbDaily: 677.87 },
                { totalDaily: 1480.32, targetTbDaily: 677.99 },
                { totalDaily: 1480.7, targetTbDaily: 678.22 },
                { totalDaily: 1481.16, targetTbDaily: 678.42 },
                { totalDaily: 1481.48, targetTbDaily: 678.55 },
                { totalDaily: 1481.94, targetTbDaily: 678.75 },
                { totalDaily: 1482.35, targetTbDaily: 678.95 },
                { totalDaily: 1482.74, targetTbDaily: 679.14 },
                { totalDaily: 1483.1, targetTbDaily: 679.31 },
                { totalDaily: 1483.44, targetTbDaily: 679.47 },
                { totalDaily: 1483.85, targetTbDaily: 679.66 },
                { totalDaily: 1484.28, targetTbDaily: 679.83 },
                { totalDaily: 1484.69, targetTbDaily: 680.01 },
                { totalDaily: 1485.03, targetTbDaily: 680.18 },
                { totalDaily: 1485.46, targetTbDaily: 680.37 },
                { totalDaily: 1485.87, targetTbDaily: 680.55 },
                { totalDaily: 1486.19, targetTbDaily: 680.71 },
                { totalDaily: 1486.6, targetTbDaily: 680.91 },
                { totalDaily: 1486.98, targetTbDaily: 681.08 },
                { totalDaily: 1487.35, targetTbDaily: 681.23 },
                { totalDaily: 1487.78, targetTbDaily: 681.43 },
                { totalDaily: 1488.17, targetTbDaily: 681.61 },
                { totalDaily: 1488.54, targetTbDaily: 681.79 },
                { totalDaily: 1488.97, targetTbDaily: 681.95 },
                { totalDaily: 1489.34, targetTbDaily: 682.15 },
                { totalDaily: 1489.66, targetTbDaily: 682.3 },
                { totalDaily: 1490.06, targetTbDaily: 682.5 },
                { totalDaily: 1490.43, targetTbDaily: 682.65 },
                { totalDaily: 1490.89, targetTbDaily: 682.87 },
                { totalDaily: 1491.22, targetTbDaily: 683.02 },
                { totalDaily: 1491.62, targetTbDaily: 683.19 },
                { totalDaily: 1492.05, targetTbDaily: 683.39 },
                { totalDaily: 1492.43, targetTbDaily: 683.57 },
                { totalDaily: 1492.81, targetTbDaily: 683.73 },
                { totalDaily: 1493.17, targetTbDaily: 683.92 },
                { totalDaily: 1493.65, targetTbDaily: 684.14 },
                { totalDaily: 1494, targetTbDaily: 684.3 },
                { totalDaily: 1494.38, targetTbDaily: 684.47 },
                { totalDaily: 1494.79, targetTbDaily: 684.64 },
                { totalDaily: 1495.11, targetTbDaily: 684.8 },
                { totalDaily: 1495.5, targetTbDaily: 684.98 },
                { totalDaily: 1495.91, targetTbDaily: 685.16 },
                { totalDaily: 1496.34, targetTbDaily: 685.34 },
                { totalDaily: 1496.75, targetTbDaily: 685.53 },
                { totalDaily: 1497.1, targetTbDaily: 685.71 },
                { totalDaily: 1497.5, targetTbDaily: 685.91 },
                { totalDaily: 1497.89, targetTbDaily: 686.06 },
                { totalDaily: 1498.28, targetTbDaily: 686.26 },
                { totalDaily: 1498.67, targetTbDaily: 686.42 },
                { totalDaily: 1499.05, targetTbDaily: 686.61 },
                { totalDaily: 1499.46, targetTbDaily: 686.81 },
                { totalDaily: 1499.88, targetTbDaily: 686.98 },
                { totalDaily: 1500.2, targetTbDaily: 687.12 },
                { totalDaily: 1500.71, targetTbDaily: 687.33 },
                { totalDaily: 1501.04, targetTbDaily: 687.49 },
                { totalDaily: 1501.43, targetTbDaily: 687.71 },
                { totalDaily: 1501.8, targetTbDaily: 687.84 },
                { totalDaily: 1502.15, targetTbDaily: 688.01 },
                { totalDaily: 1502.59, targetTbDaily: 688.22 },
                { totalDaily: 1502.94, targetTbDaily: 688.37 },
                { totalDaily: 1503.39, targetTbDaily: 688.57 },
                { totalDaily: 1503.79, targetTbDaily: 688.76 },
                { totalDaily: 1504.11, targetTbDaily: 688.93 },
                { totalDaily: 1504.61, targetTbDaily: 689.14 },
                { totalDaily: 1504.96, targetTbDaily: 689.3 },
                { totalDaily: 1505.33, targetTbDaily: 689.48 },
                { totalDaily: 1505.71, targetTbDaily: 689.65 },
                { totalDaily: 1506.11, targetTbDaily: 689.84 },
                { totalDaily: 1506.51, targetTbDaily: 690.02 },
                { totalDaily: 1506.87, targetTbDaily: 690.2 },
                { totalDaily: 1507.34, targetTbDaily: 690.41 },
                { totalDaily: 1507.71, targetTbDaily: 690.56 },
                { totalDaily: 1508.08, targetTbDaily: 690.72 },
                { totalDaily: 1508.49, targetTbDaily: 690.92 },
                { totalDaily: 1508.85, targetTbDaily: 691.09 },
                { totalDaily: 1509.26, targetTbDaily: 691.3 },
                { totalDaily: 1509.66, targetTbDaily: 691.48 },
                { totalDaily: 1510.03, targetTbDaily: 691.65 },
                { totalDaily: 1510.5, targetTbDaily: 691.84 },
                { totalDaily: 1510.89, targetTbDaily: 692.03 },
                { totalDaily: 1511.32, targetTbDaily: 692.23 },
                { totalDaily: 1511.68, targetTbDaily: 692.37 },
                { totalDaily: 1512.1, targetTbDaily: 692.56 },
                { totalDaily: 1512.43, targetTbDaily: 692.75 },
                { totalDaily: 1512.79, targetTbDaily: 692.9 },
                { totalDaily: 1513.24, targetTbDaily: 693.1 },
                { totalDaily: 1513.63, targetTbDaily: 693.28 },
                { totalDaily: 1514, targetTbDaily: 693.46 },
                { totalDaily: 1514.37, targetTbDaily: 693.63 },
                { totalDaily: 1514.8, targetTbDaily: 693.81 },
                { totalDaily: 1515.18, targetTbDaily: 693.98 },
                { totalDaily: 1515.59, targetTbDaily: 694.14 },
                { totalDaily: 1515.95, targetTbDaily: 694.32 },
                { totalDaily: 1516.37, targetTbDaily: 694.53 },
                { totalDaily: 1516.82, targetTbDaily: 694.75 },
                { totalDaily: 1517.21, targetTbDaily: 694.93 },
                { totalDaily: 1517.64, targetTbDaily: 695.1 },
                { totalDaily: 1517.97, targetTbDaily: 695.24 },
                { totalDaily: 1518.39, targetTbDaily: 695.46 },
                { totalDaily: 1518.79, targetTbDaily: 695.65 },
                { totalDaily: 1519.19, targetTbDaily: 695.81 },
                { totalDaily: 1519.58, targetTbDaily: 696.01 },
                { totalDaily: 1519.95, targetTbDaily: 696.17 },
                { totalDaily: 1520.37, targetTbDaily: 696.35 },
                { totalDaily: 1520.75, targetTbDaily: 696.54 },
                { totalDaily: 1521.17, targetTbDaily: 696.74 },
                { totalDaily: 1521.56, targetTbDaily: 696.91 },
                { totalDaily: 1521.94, targetTbDaily: 697.07 },
                { totalDaily: 1522.31, targetTbDaily: 697.26 },
                { totalDaily: 1522.68, targetTbDaily: 697.44 },
                { totalDaily: 1523.14, targetTbDaily: 697.65 },
                { totalDaily: 1523.48, targetTbDaily: 697.77 },
                { totalDaily: 1523.86, targetTbDaily: 697.97 },
                { totalDaily: 1524.34, targetTbDaily: 698.2 },
                { totalDaily: 1524.69, targetTbDaily: 698.32 },
                { totalDaily: 1525.14, targetTbDaily: 698.56 },
                { totalDaily: 1525.5, targetTbDaily: 698.71 },
                { totalDaily: 1525.91, targetTbDaily: 698.9 },
                { totalDaily: 1526.33, targetTbDaily: 699.08 },
                { totalDaily: 1526.74, targetTbDaily: 699.28 },
                { totalDaily: 1527.1, targetTbDaily: 699.45 },
                { totalDaily: 1527.48, targetTbDaily: 699.59 },
                { totalDaily: 1527.91, targetTbDaily: 699.82 },
                { totalDaily: 1528.32, targetTbDaily: 700.02 },
                { totalDaily: 1528.73, targetTbDaily: 700.19 },
                { totalDaily: 1529.09, targetTbDaily: 700.36 },
                { totalDaily: 1529.49, targetTbDaily: 700.56 },
                { totalDaily: 1529.93, targetTbDaily: 700.75 },
                { totalDaily: 1530.29, targetTbDaily: 700.91 },
                { totalDaily: 1530.68, targetTbDaily: 701.11 },
                { totalDaily: 1531.13, targetTbDaily: 701.31 },
                { totalDaily: 1531.48, targetTbDaily: 701.45 },
                { totalDaily: 1531.91, targetTbDaily: 701.66 },
                { totalDaily: 1532.29, targetTbDaily: 701.84 },
                { totalDaily: 1532.69, targetTbDaily: 702.01 },
                { totalDaily: 1533.12, targetTbDaily: 702.21 },
                { totalDaily: 1533.53, targetTbDaily: 702.39 },
                { totalDaily: 1533.96, targetTbDaily: 702.58 },
                { totalDaily: 1534.33, targetTbDaily: 702.77 },
                { totalDaily: 1534.76, targetTbDaily: 702.93 },
                { totalDaily: 1535.13, targetTbDaily: 703.15 },
                { totalDaily: 1535.54, targetTbDaily: 703.33 },
                { totalDaily: 1535.92, targetTbDaily: 703.47 },
                { totalDaily: 1536.29, targetTbDaily: 703.65 },
                { totalDaily: 1536.68, targetTbDaily: 703.82 },
                { totalDaily: 1537.1, targetTbDaily: 704.01 },
                { totalDaily: 1537.51, targetTbDaily: 704.21 },
                { totalDaily: 1537.96, targetTbDaily: 704.43 },
                { totalDaily: 1538.31, targetTbDaily: 704.58 },
                { totalDaily: 1538.65, targetTbDaily: 704.74 },
                { totalDaily: 1539.12, targetTbDaily: 704.96 },
                { totalDaily: 1539.47, targetTbDaily: 705.11 },
                { totalDaily: 1539.95, targetTbDaily: 705.31 },
                { totalDaily: 1540.31, targetTbDaily: 705.48 },
                { totalDaily: 1540.69, targetTbDaily: 705.67 },
                { totalDaily: 1541.16, targetTbDaily: 705.9 },
                { totalDaily: 1541.56, targetTbDaily: 706.08 },
                { totalDaily: 1541.97, targetTbDaily: 706.26 },
                { totalDaily: 1542.38, targetTbDaily: 706.46 },
                { totalDaily: 1542.77, targetTbDaily: 706.65 },
                { totalDaily: 1543.22, targetTbDaily: 706.83 },
                { totalDaily: 1543.57, targetTbDaily: 706.99 },
                { totalDaily: 1543.98, targetTbDaily: 707.19 },
                { totalDaily: 1544.37, targetTbDaily: 707.36 },
                { totalDaily: 1544.74, targetTbDaily: 707.53 },
                { totalDaily: 1545.13, targetTbDaily: 707.71 },
                { totalDaily: 1545.56, targetTbDaily: 707.9 },
                { totalDaily: 1545.99, targetTbDaily: 708.07 },
                { totalDaily: 1546.35, targetTbDaily: 708.28 },
                { totalDaily: 1546.76, targetTbDaily: 708.45 },
                { totalDaily: 1547.15, targetTbDaily: 708.62 },
                { totalDaily: 1547.53, targetTbDaily: 708.81 },
                { totalDaily: 1547.97, targetTbDaily: 709 },
                { totalDaily: 1548.36, targetTbDaily: 709.18 },
                { totalDaily: 1548.76, targetTbDaily: 709.37 },
                { totalDaily: 1549.22, targetTbDaily: 709.58 },
                { totalDaily: 1549.62, targetTbDaily: 709.77 },
                { totalDaily: 1550.04, targetTbDaily: 709.97 },
                { totalDaily: 1550.45, targetTbDaily: 710.15 },
                { totalDaily: 1550.81, targetTbDaily: 710.3 },
                { totalDaily: 1551.24, targetTbDaily: 710.49 },
                { totalDaily: 1551.63, targetTbDaily: 710.68 },
                { totalDaily: 1552.02, targetTbDaily: 710.87 },
                { totalDaily: 1552.44, targetTbDaily: 711.03 },
                { totalDaily: 1552.83, targetTbDaily: 711.22 },
                { totalDaily: 1553.25, targetTbDaily: 711.41 },
                { totalDaily: 1553.66, targetTbDaily: 711.62 },
                { totalDaily: 1554.12, targetTbDaily: 711.81 },
                { totalDaily: 1554.46, targetTbDaily: 711.97 },
                { totalDaily: 1554.88, targetTbDaily: 712.17 },
                { totalDaily: 1555.24, targetTbDaily: 712.34 },
                { totalDaily: 1555.66, targetTbDaily: 712.52 },
                { totalDaily: 1556.1, targetTbDaily: 712.72 },
                { totalDaily: 1556.57, targetTbDaily: 712.92 },
                { totalDaily: 1556.96, targetTbDaily: 713.13 },
                { totalDaily: 1557.33, targetTbDaily: 713.31 },
                { totalDaily: 1557.72, targetTbDaily: 713.46 },
                { totalDaily: 1558.09, targetTbDaily: 713.64 },
                { totalDaily: 1558.55, targetTbDaily: 713.85 },
                { totalDaily: 1558.92, targetTbDaily: 714.03 },
                { totalDaily: 1559.39, targetTbDaily: 714.23 },
                { totalDaily: 1559.74, targetTbDaily: 714.4 },
                { totalDaily: 1560.17, targetTbDaily: 714.59 },
                { totalDaily: 1560.58, targetTbDaily: 714.77 },
                { totalDaily: 1560.94, targetTbDaily: 714.95 },
                { totalDaily: 1561.35, targetTbDaily: 715.14 },
                { totalDaily: 1561.84, targetTbDaily: 715.33 },
                { totalDaily: 1562.21, targetTbDaily: 715.54 },
                { totalDaily: 1562.68, targetTbDaily: 715.75 },
                { totalDaily: 1563.03, targetTbDaily: 715.9 },
                { totalDaily: 1563.4, targetTbDaily: 716.1 },
                { totalDaily: 1563.85, targetTbDaily: 716.3 },
                { totalDaily: 1564.23, targetTbDaily: 716.45 },
                { totalDaily: 1564.65, targetTbDaily: 716.65 },
                { totalDaily: 1565.07, targetTbDaily: 716.83 },
                { totalDaily: 1565.49, targetTbDaily: 717.01 },
                { totalDaily: 1565.88, targetTbDaily: 717.22 },
                { totalDaily: 1566.29, targetTbDaily: 717.4 },
                { totalDaily: 1566.69, targetTbDaily: 717.57 },
                { totalDaily: 1567.1, targetTbDaily: 717.78 },
                { totalDaily: 1567.5, targetTbDaily: 717.95 },
                { totalDaily: 1567.88, targetTbDaily: 718.15 },
                { totalDaily: 1568.36, targetTbDaily: 718.34 },
                { totalDaily: 1568.75, targetTbDaily: 718.54 },
                { totalDaily: 1569.15, targetTbDaily: 718.71 },
                { totalDaily: 1569.56, targetTbDaily: 718.89 },
                { totalDaily: 1569.95, targetTbDaily: 719.07 },
                { totalDaily: 1570.4, targetTbDaily: 719.27 },
                { totalDaily: 1570.81, targetTbDaily: 719.46 },
                { totalDaily: 1571.17, targetTbDaily: 719.62 },
                { totalDaily: 1571.6, targetTbDaily: 719.83 },
                { totalDaily: 1572, targetTbDaily: 719.99 },
                { totalDaily: 1572.43, targetTbDaily: 720.19 },
                { totalDaily: 1572.86, targetTbDaily: 720.43 },
                { totalDaily: 1573.28, targetTbDaily: 720.59 },
                { totalDaily: 1573.7, targetTbDaily: 720.79 },
                { totalDaily: 1574.04, targetTbDaily: 720.96 },
                { totalDaily: 1574.52, targetTbDaily: 721.16 },
                { totalDaily: 1574.9, targetTbDaily: 721.33 },
                { totalDaily: 1575.36, targetTbDaily: 721.56 },
                { totalDaily: 1575.69, targetTbDaily: 721.72 },
                { totalDaily: 1576.11, targetTbDaily: 721.87 },
                { totalDaily: 1576.57, targetTbDaily: 722.11 },
                { totalDaily: 1576.94, targetTbDaily: 722.28 },
                { totalDaily: 1577.4, targetTbDaily: 722.48 },
                { totalDaily: 1577.77, targetTbDaily: 722.66 },
                { totalDaily: 1578.21, targetTbDaily: 722.85 },
                { totalDaily: 1578.58, targetTbDaily: 723.05 },
                { totalDaily: 1579.06, targetTbDaily: 723.25 },
                { totalDaily: 1579.44, targetTbDaily: 723.42 },
                { totalDaily: 1579.87, targetTbDaily: 723.63 },
            ],
            monthlyData: [
                { total: 533.37, target: 258.11, creditsMonthly: 121.89, cumulatedCredits: 121.89 },
                { total: 546.91, target: 263.9, creditsMonthly: 195.76, cumulatedCredits: 317.65 },
                { total: 584.51, target: 280.27, creditsMonthly: 208.72, cumulatedCredits: 526.37 },
                { total: 622.29, target: 296.77, creditsMonthly: 221.66, cumulatedCredits: 748.02 },
                { total: 628.03, target: 299.35, creditsMonthly: 224.22, cumulatedCredits: 972.25 },
                { total: 633.81, target: 302.02, creditsMonthly: 225.99, cumulatedCredits: 1198.24 },
                { total: 639.53, target: 304.59, creditsMonthly: 227.76, cumulatedCredits: 1426 },
                { total: 645.45, target: 307.31, creditsMonthly: 229.76, cumulatedCredits: 1655.76 },
                { total: 651.29, target: 309.98, creditsMonthly: 232.22, cumulatedCredits: 1888 },
                { total: 657.24, target: 312.7, creditsMonthly: 234.08, cumulatedCredits: 2122.09 },
                { total: 663.18, target: 315.39, creditsMonthly: 236.18, cumulatedCredits: 2358.27 },
                { total: 669.26, target: 318.2, creditsMonthly: 238.11, cumulatedCredits: 2596.37 },
                { total: 822.23, target: 384.43, creditsMonthly: 280.89, cumulatedCredits: 2877.29 },
                { total: 828.72, target: 387.48, creditsMonthly: 293.33, cumulatedCredits: 3170.62 },
                { total: 835.25, target: 390.51, creditsMonthly: 295.99, cumulatedCredits: 3466.61 },
                { total: 841.79, target: 393.57, creditsMonthly: 298.27, cumulatedCredits: 3764.9 },
                { total: 848.42, target: 396.68, creditsMonthly: 300.33, cumulatedCredits: 4065.24 },
                { total: 855.11, target: 399.79, creditsMonthly: 302.88, cumulatedCredits: 4368.1 },
                { total: 861.86, target: 402.97, creditsMonthly: 305.08, cumulatedCredits: 4673.21 },
                { total: 868.62, target: 406.12, creditsMonthly: 307.65, cumulatedCredits: 4980.86 },
                { total: 875.42, target: 409.3, creditsMonthly: 309.97, cumulatedCredits: 5290.85 },
                { total: 882.36, target: 412.54, creditsMonthly: 312.69, cumulatedCredits: 5603.54 },
                { total: 889.24, target: 415.74, creditsMonthly: 314.98, cumulatedCredits: 5918.52 },
                { total: 896.28, target: 419.05, creditsMonthly: 317.21, cumulatedCredits: 6235.73 },
                { total: 1053.74, target: 486.93, creditsMonthly: 352.86, cumulatedCredits: 6588.59 },
                { total: 1062, target: 490.79, creditsMonthly: 374.55, cumulatedCredits: 6963.13 },
                { total: 1070.36, target: 494.63, creditsMonthly: 377.62, cumulatedCredits: 7340.77 },
                { total: 1078.82, target: 498.53, creditsMonthly: 380.4, cumulatedCredits: 7721.17 },
                { total: 1087.18, target: 502.39, creditsMonthly: 383.56, cumulatedCredits: 8104.73 },
                { total: 1095.78, target: 506.37, creditsMonthly: 386.68, cumulatedCredits: 8491.41 },
                { total: 1104.41, target: 510.35, creditsMonthly: 389.59, cumulatedCredits: 8881 },
                { total: 1113.14, target: 514.4, creditsMonthly: 392.71, cumulatedCredits: 9273.7 },
                { total: 1121.93, target: 518.44, creditsMonthly: 395.7, cumulatedCredits: 9669.41 },
                { total: 1130.69, target: 522.51, creditsMonthly: 398.92, cumulatedCredits: 10068.34 },
                { total: 1139.53, target: 526.6, creditsMonthly: 402.06, cumulatedCredits: 10470.4 },
                { total: 1148.55, target: 530.75, creditsMonthly: 405.11, cumulatedCredits: 10875.51 },
                { total: 1307.13, target: 599.16, creditsMonthly: 432.51, cumulatedCredits: 11308.02 },
                { total: 1317.38, target: 603.84, creditsMonthly: 463.63, cumulatedCredits: 11771.65 },
                { total: 1327.78, target: 608.6, creditsMonthly: 467.04, cumulatedCredits: 12238.7 },
                { total: 1338.19, target: 613.39, creditsMonthly: 470.74, cumulatedCredits: 12709.43 },
                { total: 1348.76, target: 618.25, creditsMonthly: 474.66, cumulatedCredits: 13184.09 },
                { total: 1359.38, target: 623.11, creditsMonthly: 478.22, cumulatedCredits: 13662.32 },
                { total: 1370.01, target: 627.98, creditsMonthly: 482.02, cumulatedCredits: 14144.34 },
                { total: 1380.79, target: 632.9, creditsMonthly: 485.72, cumulatedCredits: 14630.07 },
                { total: 1391.65, target: 637.88, creditsMonthly: 489.64, cumulatedCredits: 15119.71 },
                { total: 1402.65, target: 642.92, creditsMonthly: 493.59, cumulatedCredits: 15613.3 },
                { total: 1413.64, target: 647.97, creditsMonthly: 497.19, cumulatedCredits: 16110.49 },
                { total: 1424.79, target: 653.08, creditsMonthly: 501.24, cumulatedCredits: 16611.73 },
                { total: 1439.98, target: 659.55, creditsMonthly: 505.53, cumulatedCredits: 17117.25 },
                { total: 1451.33, target: 664.74, creditsMonthly: 510.25, cumulatedCredits: 17627.5 },
                { total: 1462.73, target: 669.99, creditsMonthly: 514.33, cumulatedCredits: 18141.84 },
                { total: 1474.23, target: 675.26, creditsMonthly: 518.23, cumulatedCredits: 18660.09 },
                { total: 1485.87, target: 680.55, creditsMonthly: 522.32, cumulatedCredits: 19182.41 },
                { total: 1497.5, target: 685.91, creditsMonthly: 526.55, cumulatedCredits: 19708.96 },
                { total: 1509.26, target: 691.3, creditsMonthly: 530.59, cumulatedCredits: 20239.56 },
                { total: 1521.17, target: 696.74, creditsMonthly: 534.75, cumulatedCredits: 20774.3 },
                { total: 1533.12, target: 702.21, creditsMonthly: 539.1, cumulatedCredits: 21313.41 },
                { total: 1545.13, target: 707.71, creditsMonthly: 543.29, cumulatedCredits: 21856.71 },
                { total: 1557.33, target: 713.31, creditsMonthly: 547.36, cumulatedCredits: 22404.07 },
                { total: 1569.56, target: 718.89, creditsMonthly: 551.8, cumulatedCredits: 22955.87 },
            ],
        },
    },
};
