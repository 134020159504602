import { UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useRootState, useRootDispatch } from 'store/StateProvider';
import { saveCustomerDetails } from 'store/slices/actions';
import { creditDetails } from 'types';
import { parseStringToFloat } from 'utils/utils';
import { useAPIQuery } from '../useAPIQuery';

export function useGetCreditDetails({ selectedProductCustomerId, protocol, endpoint }, enabled = false) {
    const dispatch = useRootDispatch();
    const {
        sourcesData: {
            customerDetails: {
                instanceList,
                customerSfdcOemId,
                balanceCredits,
                ignorePrepopulateCreditBalance,
                firebirdData,
            },
        },
    } = useRootState();

    const url = `/creditsizer/firebird/v2/credits/balance`;
    const method = 'GET';

    const handleSelect = (data: { result: creditDetails[]; statusCode: number; success: boolean }) => {
        if (data.result?.length > 0) {
            const [instanceData] = data.result;
            const { creditPools } = instanceData;
            const [creditPoolState] = creditPools;
            const currentDay = new Date().getTime() / (1000 * 3600 * 24);

            let newBalanceCredits = '0';
            let lastUpdateDateBalanceCredits = 0;
            if (currentDay - creditPoolState.lastUpdatedDay <= 30) {
                newBalanceCredits = parseStringToFloat(creditPoolState.currentCreditBalance, 2);
                lastUpdateDateBalanceCredits = creditPoolState.lastUpdatedDay;
            }

            let calculateBalanceCreditData = {};
            if (!+balanceCredits && !ignorePrepopulateCreditBalance) {
                // TODO:- API Integration to populate creditsPurchasedCurrentTerm, daysRemainingCurrentTerm, and dailyAvgCreditConsumption
                calculateBalanceCreditData = {
                    balanceCredits: newBalanceCredits,
                    // creditsPurchasedCurrentTerm: '',
                    // daysRemainingCurrentTerm: '',
                    // dailyAvgCreditConsumption: '',
                };
            }

            const parsedData = {
                ...calculateBalanceCreditData,
                lastUpdateDateBalanceCredits,
                firebirdData: {
                    ...firebirdData,
                    balanceCredits: newBalanceCredits,
                    // creditsPurchasedCurrentTerm: '',
                    // daysRemainingCurrentTerm: '',
                    // dailyAvgCreditConsumption: '',
                },
            };
            return { ...data, result: parsedData };
        }
        return data;
    };
    const phoenixCloudUrl = instanceList.find((obj) => obj.instanceId === selectedProductCustomerId)?.cloudName;
    const currentDay = parseInt(`${new Date().valueOf() / (60 * 60 * 24 * 1000)}`, 10);

    const payload = {
        dayStart: currentDay - 1,
        dayEnd: currentDay,
        cloudName: phoenixCloudUrl,
        instanceIds: [selectedProductCustomerId],
        amountsAsCredits: true,
    };
    const query: UseQueryResult<{ result: {}; statusCode: number; success: boolean }, Error> = useAPIQuery({
        key: ['creditDetails', customerSfdcOemId, selectedProductCustomerId],
        method,
        payload,
        url,
        baseUrl: `${protocol}://${endpoint}`,
        select: handleSelect,
        enabled,
        options: {
            staleTime: 24 * 60 * 60 * 1000, // 24 hour stale time to avoid refetching the same API
        },
    });

    useEffect(() => {
        if (query?.data?.success && !query.isError && enabled) {
            dispatch(saveCustomerDetails(query.data.result));
        }
    }, [query.data, query.isError, enabled]);
    return query;
}
