import React, { memo } from 'react';
import { Form, FieldArray } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import uuid from 'uuid';
import {
    nextButton,
    nextButtonDisabled,
    resetButton,
    resetModalButton,
    saveButton,
    cancelButton,
    saveButtonDisabled,
} from 'components/Button/styles';
import Button from 'components/Button/Button';
import useSources from 'views/Sources/hooks/useSources';
import {
    resetSources,
    resetSku,
    resetSteps,
    resetCount,
    resetTCOYear,
    resetCustomerDetails,
} from 'store/slices/actions';
import 'views/Sources/SourcesForm/SourcesForm.scss';
import { useDialogContext } from 'config/context/dialogContext';
import { formatSources } from 'utils/utils';
import { sourceDefaultValues } from 'store/slices';
import SourceFields from './SourceFields/SourceFields';
import './SourcesForm.scss';

type Props = {
    isEditDialog: boolean;
    formikProps: any;
    setIsOpenDailyChangeConfirmation: (boolean) => void;
};

const SourcesForm = memo(
    ({
        isEditDialog,
        formikProps: { values, resetForm, dirty, isValid, touched, setFieldValue },
        setIsOpenDailyChangeConfirmation,
    }: Props) => {
        const {
            dispatch,
            state: {
                sourcesData: {
                    customerDetails: { deploymentSummary, customerType },
                },
            },
        } = useSources();

        const { close } = useDialogContext();
        const queryClient = useQueryClient();

        const disableNextButton =
            !isValid ||
            (!values.sources.length && customerType === 'New') ||
            (!dirty && values.sources.length && values.sources[0].type === 'select') ||
            (!deploymentSummary && customerType === 'Existing');

        return (
            <div className="sources">
                <Form className="source">
                    {!isEditDialog && (
                        <Button
                            type="button"
                            handleClick={() => {
                                resetForm();
                                dispatch(resetSku());
                                dispatch(resetSteps());
                                dispatch(resetSources());
                                dispatch(resetCount());
                                dispatch(resetTCOYear());
                                dispatch(resetCustomerDetails());
                                setFieldValue('sources', formatSources([{ id: uuid(), ...sourceDefaultValues }]));
                                queryClient.removeQueries();
                            }}
                            style={resetButton}
                        >
                            Reset
                        </Button>
                    )}
                    <FieldArray
                        name="sources"
                        validateOnChange
                        render={(sourcesArray) => (
                            <>
                                <div className="source__form__container">
                                    {values.sources.map((source, index) => (
                                        <div key={source.id} className="source__form">
                                            <SourceFields
                                                index={index}
                                                fieldsArray={sourcesArray}
                                                touched={touched}
                                                showDailyChangeConfirmation={() =>
                                                    setIsOpenDailyChangeConfirmation(true)
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>

                                {isEditDialog ? (
                                    <>
                                        <Button
                                            handleClick={() => {
                                                for (let i = 0; i < values.sources.length; i += 1) {
                                                    sourcesArray.pop();
                                                }
                                            }}
                                            style={resetModalButton}
                                            className="qa-button-reset"
                                        >
                                            Reset
                                        </Button>
                                        <Button handleClick={close} style={cancelButton}>
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            style={
                                                !values.sources.length || !dirty || !isValid
                                                    ? saveButtonDisabled
                                                    : saveButton
                                            }
                                            isDisabled={!values.sources.length || !dirty || !isValid}
                                            className="qa-btn-save-sources"
                                        >
                                            Save
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        type="submit"
                                        style={disableNextButton ? nextButtonDisabled : nextButton}
                                        isDisabled={disableNextButton}
                                        className="qa-button-next"
                                    >
                                        Next
                                    </Button>
                                )}
                            </>
                        )}
                    />
                </Form>
                {!values.sources.length && <div className="source__no-sources">No sources, add new one.</div>}
            </div>
        );
    },
);

export default SourcesForm;
