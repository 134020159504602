import React, { memo, useState } from 'react';
import { Formik, Form } from 'formik';
import './Sources.scss';
import CustomerType from 'components/CustomerDetails/CustomerType';
import CustomerName from 'components/CustomerDetails/CustomerName';
import SelectTCOYear from 'components/CustomerDetails/SelectTCOYear';
import BalanceCredit from 'components/CustomerDetails/BalanceCredit';
import CustomerSfdcOemId from 'components/CustomerDetails/CustomerSfdcOemId';
import SfdcInstanceList from 'components/CustomerDetails/SfdcInstanceList';
import { useRootState } from 'store/StateProvider';
import { Loader } from 'components/Loader/Loader';

type Props = {
    formikProps: any;
};

const CustomerDetails = memo(({ formikProps }: Props) => {
    const {
        sourcesData: {
            customerDetails: { instanceList },
        },
    } = useRootState();

    const [isLoading, setIsLoading] = useState(false);
    return (
        <Formik initialValues={{}} onSubmit={() => {}} validateOnBlur={false}>
            {({ resetForm }) => (
                <Form className="customer-details">
                    <div className="customer-details-hint">
                        <h3 className="section-heading">Customer Details</h3>
                        <Loader isLoading={isLoading}>
                            <div className="details-container">
                                <CustomerType resetForm={resetForm} formikProps={formikProps} />
                                <CustomerSfdcOemId setIsLoading={setIsLoading} />
                                <CustomerName />
                                {instanceList.length > 1 && <SfdcInstanceList setIsLoading={setIsLoading} />}
                                <SelectTCOYear />
                                <BalanceCredit />
                            </div>
                        </Loader>
                    </div>
                </Form>
            )}
        </Formik>
    );
});

export default CustomerDetails;
