import React, { useCallback, useState } from 'react';
import { signUp } from 'aws-amplify/auth';
import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import druvaLogoPath from 'assets/logo-druva.png';
import dellLogoPath from 'assets/logo-dell.svg';
import MaterialButton from 'components/Button/Button';
import { resetPasswordButton } from 'components/Button/styles';
import LegalText from 'components/LegalText/LegalText';
import Input from 'components/forms/Input/Input';
import './sign-up.scss';
import SignUpSuccess from 'components/SignUpSuccess/SignUpSuccess';
import TooltipIcon from 'components/Tooltip/TooltipIcon';
import { useRootState } from 'store/StateProvider';

const SignUpValidation = yup.object().shape({
    newPassword: yup.string().min(12).required(),
    confirmNewPassword: yup.string().min(12).required(),
});
const SignUp = (): JSX.Element => {
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const checkIfPasswordsMatches = (password, confirmPassword) => password === confirmPassword;
    const {
        location: { isDell },
    } = useRootState();

    const createUsernameFromEmail = (email: string) => {
        const splitEmail = email.split('@');
        const createHash = `_${Math.random().toString(36).substr(2, 9)}`;
        return `${splitEmail[0]}${createHash}`;
    };

    const handleSignUp = useCallback((values) => {
        setLoading(true);
        const passwordsCheck = checkIfPasswordsMatches(values.password, values.confirmPassword);

        if (passwordsCheck) {
            signUp({
                username: createUsernameFromEmail(values.email),
                password: values.password,
                options: {
                    userAttributes: {
                        email: values.email,
                    },
                },
            })
                .then((data) => {
                    if (data) {
                        setLoading(false);
                        setSignUpSuccess(true);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error(err.message, {
                        position: 'bottom-right',
                    });
                });
        } else {
            toast.error("Passwords doesn't match.", {
                position: 'bottom-right',
            });
        }
    }, []);
    const FormComponent = (
        <Formik
            initialValues={{
                email: '',
                password: '',
                confirmPassword: '',
            }}
            validationSchema={SignUpValidation}
            onSubmit={() => {}}
        >
            {({ values }) => (
                <Form className="form-password-reset" autoComplete="off">
                    <div className="form-password-reset__group">
                        <label htmlFor="email">Email</label>
                        <Input type="email" name="email" isEditable defaultValue="" />
                        <ErrorMessage name="email" />
                    </div>
                    <div className="form-password-reset__group">
                        <div className="label-with-tooltip">
                            <label htmlFor="password">Password</label>
                            <TooltipIcon
                                title="Min. length 12, need to include: uppercase, lowercase, number and special character."
                                arrow
                                placement="right"
                            />
                        </div>
                        <Input type="password" name="password" isEditable defaultValue="" />
                        <ErrorMessage name="password" />
                    </div>
                    <div className="form-password-reset__group">
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <Input type="password" name="confirmPassword" isEditable defaultValue="" />
                        <ErrorMessage name="confirmPassword" />
                    </div>
                    <div className="form-password-reset__actions">
                        <MaterialButton
                            type="submit"
                            style={resetPasswordButton}
                            handleClick={() => {
                                handleSignUp(values);
                            }}
                        >
                            Sign Up
                        </MaterialButton>
                    </div>
                </Form>
            )}
        </Formik>
    );
    const ContentOne = loading ? <CircularProgress /> : FormComponent;
    const RenderContent = signUpSuccess ? <SignUpSuccess /> : ContentOne;

    return (
        <div className="login">
            <div className="login__container">
                {!isDell && <img className="login__logo" src={druvaLogoPath} alt="logo" />}
                {isDell && <img className="login__logo" src={dellLogoPath} alt="logo" />}

                <p className="login__signin-text">Sign Up</p>
                {RenderContent}
            </div>
            <LegalText />
        </div>
    );
};

export default SignUp;
