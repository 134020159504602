import React from 'react';
import SKUForm from 'views/Sku/SKUForm/SKUForm';
import { useDialogContext } from 'config/context/dialogContext';
import { PaperStyled } from './styles';
import './tile.scss';

type Props = {
    label: string;
    value: number | string;
    isEditable: boolean;
};

const Tile: React.FC<Props> = ({ label, value, isEditable }) => {
    const { open } = useDialogContext();
    return (
        <PaperStyled>
            <h4 className="label qa-tile-label">{label}</h4>
            <h3 className="value qa-tile-value">{value}</h3>
            {isEditable ? (
                <button
                    type="button"
                    className="edit qa-btn-tile-edit"
                    onClick={() => open(<SKUForm isEditSkuDialog />)}
                >
                    Edit
                </button>
            ) : null}
        </PaperStyled>
    );
};

export default Tile;
