import uuid from 'uuid';
import * as Actions from 'store/slices/sources/sourcesActions';
import { ActionType, getType } from 'typesafe-actions';
import { sourcesInitialState, sourceDefaultValues } from './sources.initialState';

const {
    updateSources,
    addCount,
    subtractCount,
    resetCount,
    resetSources,
    resetTCOYear,
    updateTCOYear,
    resetFirstYearMonths,
    updateFirstYearMonths,
    updateSourcesResults,
    saveCustomerDetails,
    resetCustomerDetails,
} = Actions;

export const sourcesReducer = (state: SourcesState, action: SourcesActionTypes): SourcesState => {
    switch (action.type) {
        case getType(updateSources): {
            return {
                ...state,
                sources: [...action.payload],
                count: action.payload.length,
            };
        }
        case getType(addCount): {
            return {
                ...state,
                count: state.count + 1,
            };
        }
        case getType(subtractCount): {
            return {
                ...state,
                count: state.count - 1,
            };
        }
        case getType(resetCount): {
            return {
                ...state,
                count: 1,
            };
        }
        case getType(resetSources): {
            return {
                ...state,
                sources: [
                    {
                        id: uuid(),
                        ...sourceDefaultValues,
                    },
                ],
            };
        }
        case getType(resetTCOYear): {
            return {
                ...state,
                tcoYear: sourcesInitialState.tcoYear,
                firstYearMonths: sourcesInitialState.firstYearMonths,
            };
        }
        case getType(updateTCOYear): {
            return {
                ...state,
                tcoYear: action.payload,
            };
        }
        case getType(resetFirstYearMonths): {
            return {
                ...state,
                firstYearMonths: sourcesInitialState.firstYearMonths,
            };
        }
        case getType(updateFirstYearMonths): {
            return {
                ...state,
                firstYearMonths: action.payload,
            };
        }
        case getType(updateSourcesResults):
            return { ...state, data: { ...action.payload } };

        case getType(saveCustomerDetails):
            return { ...state, customerDetails: { ...state.customerDetails, ...action.payload } };

        case getType(resetCustomerDetails):
            return { ...state, customerDetails: sourcesInitialState.customerDetails };

        default: {
            return state;
        }
    }
};

export type SourcesActionTypes = ActionType<typeof Actions>;
export type SourcesState = typeof sourcesInitialState;
