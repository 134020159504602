import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem } from '@material-ui/core';
import { SelectStyled, useDropdownStyles } from 'components/forms/Select/styles';
import useSources from 'views/Sources/hooks/useSources';
import { useRootState } from 'store/StateProvider';
import { saveCustomerDetails } from 'store/slices/actions';
import TooltipIcon from 'components/Tooltip/TooltipIcon';
import { useGetCreditDeploymentSummaryDetails } from 'hooks/query/useGetCreditDeploymentSummaryDetails';
import { getCloudType } from 'utils/utils';

const SfdcInstanceList = ({ setIsLoading }): JSX.Element => {
    const { dropdownStyle } = useDropdownStyles({});
    const {
        sourcesData: {
            customerDetails: { instanceList, selectedProductCustomerId },
        },
    } = useRootState();
    const { dispatch } = useSources();

    const selectedInstance = instanceList.find((item) => item.instanceId === selectedProductCustomerId);
    const [fetchCreditDeploymentSummaryDetails, setFetchCreditDeploymentSummaryDetails] = useState(false);
    const { isFetching } = useGetCreditDeploymentSummaryDetails(
        selectedProductCustomerId,
        fetchCreditDeploymentSummaryDetails,
    );

    useEffect(() => setIsLoading(isFetching), [isFetching]);

    return (
        <>
            <div className="details-item qa-label-customer-instance">Customer Instance</div>
            <div className="details-item qa-select-customer-instance-container">
                <FormControl variant="outlined">
                    <SelectStyled
                        className="qa-select-customer-instance"
                        renderValue={(selected: any) => {
                            if (selected.length === 0) {
                                return <div style={{ color: 'lightgray' }}>Select Instance</div>;
                            }
                            const instanceObj = instanceList.find((instance) => instance.instanceId === selected);
                            return `${getCloudType(instanceObj.cloudName)} (${instanceObj.instanceId})`;
                        }}
                        value={selectedInstance ? selectedInstance.instanceId : ''}
                        displayEmpty
                        onChange={(e) => {
                            setFetchCreditDeploymentSummaryDetails(true);
                            dispatch(
                                saveCustomerDetails({
                                    selectedProductCustomerId: e.target.value as string,
                                    balanceCredits: '0',
                                    ignorePrepopulateCreditBalance: false,
                                }),
                            );
                        }}
                        MenuProps={{ classes: { paper: dropdownStyle } }}
                        style={{ width: '300px', alignSelf: 'flex-start' }}
                    >
                        {instanceList.map((sfdcInstance) => (
                            <MenuItem key={sfdcInstance.instanceId} value={sfdcInstance.instanceId}>
                                {getCloudType(sfdcInstance.cloudName)} ({sfdcInstance.instanceId})
                            </MenuItem>
                        ))}
                    </SelectStyled>
                </FormControl>
                <TooltipIcon
                    title="Select a customer instance for which you want to do credit sizing"
                    placement="right"
                    arrow
                    style={{ marginLeft: '10px' }}
                />
            </div>
        </>
    );
};

export default SfdcInstanceList;
