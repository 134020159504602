import { createAction } from 'typesafe-actions';

type LoginInfo = {
    email: string;
    familyName: string;
    firstName: string;
};
export const authenticate = createAction('AUTHENTICATE')<null>();
export const logOut = createAction('LOG_OUT')<null>();
export const saveLoginMethod = createAction('SAVE_LOGIN_METHOD', (method: string) => method)<string>();
export const saveUserInfo = createAction('SAVE_LOGIN_INFO', (loginInfo: LoginInfo) => ({
    email: loginInfo.email,
    familyName: loginInfo.familyName,
    firstName: loginInfo.firstName,
}))<LoginInfo>();
