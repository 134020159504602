import uuid from 'uuid';
import { getDailyGrowth } from 'utils/utils';
import { WorkloadTypesEnum } from '../../../types';
import { SourceType, SourcesState } from './sources.types';

export const sourceDefaultValues: SourceType = {
    type: 'select',
    dedupe: 0,
    size: 0,
    annualGrowth: 10,
    dailyGrowth: getDailyGrowth(10),
    dailyChange: 1,
    deploymentPeriod: 15,
    retention: {
        daily: 14,
        weekly: 4,
        monthly: 3,
        yearly: 3,
    },
    ltr: false,
    dtc: false,
};

export const ec2SourceDefaultValues: SourceType = {
    ...sourceDefaultValues,
    retention: {
        daily: 7,
        weekly: 4,
        monthly: 12,
        yearly: 7,
    },
    ltr: true,
};

export const archiveFsNasSourceDefaultValues: SourceType = {
    ...sourceDefaultValues,
    retention: {
        daily: 365,
        weekly: 52,
        monthly: 12,
        yearly: 1,
    },
    dailyChange: 0,
    ltr: false,
    dtc: true,
};

export const saphanaSourceDefaultValues: SourceType = {
    ...sourceDefaultValues,
    retention: {
        daily: 14,
        weekly: 0,
        monthly: 0,
        yearly: 0,
    },
};

export const sourcesInitialState: SourcesState = {
    sources: [
        {
            id: uuid(),
            ...sourceDefaultValues,
        },
    ],
    count: 1,
    workloadsOptions: [
        WorkloadTypesEnum.SELECT,
        WorkloadTypesEnum.EC2,
        WorkloadTypesEnum.AZUREVM,
        WorkloadTypesEnum.VMWARE,
        WorkloadTypesEnum.HYPERV,
        WorkloadTypesEnum.NUTANIXAHV,
        WorkloadTypesEnum.NAS,
        WorkloadTypesEnum.WINDOWS,
        WorkloadTypesEnum.LINUX,
        WorkloadTypesEnum.ARCHIVEFSNAS,
        WorkloadTypesEnum.MSSQL,
        WorkloadTypesEnum.ORACLE,
        WorkloadTypesEnum.SAPHANA,
    ],
    data: null,
    tcoYear: 3,
    firstYearMonths: 12,
    customerDetails: {
        customername: '',
        organization: '',
        customerType: 'New',
        customerSfdcOemId: '',
        balanceCredits: '0',
        ignorePrepopulateCreditBalance: false,
        creditsPurchasedCurrentTerm: '',
        daysRemainingCurrentTerm: '',
        dailyAvgCreditConsumption: '',
        lastUpdateDateBalanceCredits: 0,
        instanceList: [],
        selectedProductCustomerId: null,
        deploymentSummary: null,
        fetchCustomerDetails: false,
        sizingId: null,
        firebirdData: {
            balanceCredits: '0',
            creditsPurchasedCurrentTerm: '',
            daysRemainingCurrentTerm: '',
            dailyAvgCreditConsumption: '',
        },
    },
};
