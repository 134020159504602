import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { toast } from 'react-toastify';

const MAX_ALLOWED_TOKEN_REFRESH_COUNTS = 3;

export const getTokens = () => {
    const token = sessionStorage.getItem('token');
    return { token };
};

export const setTokens = (token) => {
    sessionStorage.setItem('token', token);
};

const logout = async () => {
    await signOut();
    // Reload Window so that it will navigate to okta login page as it is signed out
    window.location.reload();
};

class APIRequestUtil {
    private refreshTokenPromise: Promise<any> | null = null;

    private resolveRefreshTokenPromise: ((Boolean) => void) | null = null;

    private refreshInProgress: Boolean = false;

    waitTillTokenRefreshed = () => this.refreshTokenPromise;

    refreshToken = async () => {
        try {
            const authSession = await fetchAuthSession({ forceRefresh: true });
            const token = authSession.tokens.idToken.toString();
            if (token) {
                setTokens(token);
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    makeRequest = async ({
        method,
        url,
        payload,
        baseUrl,
        suppressNotification = false,
        customHeaders = {},
        errorMessage = '',
        withoutToken = false,
        retryAttemptCount = 1,
    }) => {
        const { token } = getTokens();

        const headers: any = {
            ...(!withoutToken
                ? {
                      Authorization: `Bearer ${token}`,
                  }
                : {}),
            'Content-Type': 'application/json',
            ...customHeaders,
        };
        const fetchOptions: any = {
            method,
            headers,
            ...(method !== 'GET' ? { body: JSON.stringify(payload) } : {}),
        };

        try {
            let responseJSON = null;
            const response = await fetch(`${baseUrl}${url}`, fetchOptions);

            if (!response.ok) {
                if (response.status === 403 && retryAttemptCount <= MAX_ALLOWED_TOKEN_REFRESH_COUNTS && !withoutToken) {
                    let tokenRefreshed = null;
                    const { token: latestToken } = getTokens();

                    /**
                     * If token being used in request does not match with latest
                     * access token (latestToken), it's already been refreshed
                     * through another (previous) request
                     */
                    if (latestToken && token !== latestToken) {
                        tokenRefreshed = true;
                    } else if (this.refreshInProgress) {
                        /**
                         * 'this.refreshInProgress' indicates that refresh token request is already
                         * in progress and we can 'await' for it's cached promise 'this.refreshTokenPromise'
                         */
                        tokenRefreshed = await this.waitTillTokenRefreshed();
                    } else {
                        this.refreshInProgress = true;

                        /**
                         * To cache token request promise, to be used for requests made
                         * while original token refresh request is in progress.
                         */
                        this.refreshTokenPromise = new Promise((resolve) => {
                            this.resolveRefreshTokenPromise = (tokenRefreshedValue) => {
                                resolve(tokenRefreshedValue);
                            };
                        });

                        tokenRefreshed = await this.refreshToken();
                        this.refreshInProgress = false;
                        if (this.resolveRefreshTokenPromise !== null) {
                            /**
                             * To resolve cached promise (this.refreshTokenPromise) after token
                             * refresh request (this.refreshToken()) is completed
                             */
                            this.resolveRefreshTokenPromise(tokenRefreshed);
                        }
                    }

                    if (tokenRefreshed) {
                        /**
                         * Retry original request with same arguments & increased attempt count
                         */
                        return this.makeRequest({
                            method,
                            url,
                            payload,
                            baseUrl,
                            suppressNotification,
                            customHeaders,
                            errorMessage,
                            withoutToken,
                            retryAttemptCount: retryAttemptCount + 1,
                        });
                    }

                    /**
                     * Failed to refresh token, log out
                     */
                    await logout();

                    return {
                        result: {},
                        statusCode: response.status,
                        success: response.ok,
                    };
                }

                responseJSON = await response.json();

                if (suppressNotification !== true) {
                    const message = errorMessage || responseJSON?.message || 'Could not make API request.';
                    toast.error(message, {
                        position: 'bottom-right',
                    });
                }
                return {
                    result: responseJSON,
                    statusCode: response.status,
                    success: response.ok,
                };
            }

            responseJSON = await response.json();

            return {
                result: responseJSON,
                statusCode: response.status,
                success: response.ok,
            };
        } catch (error) {
            // Handle network errors
            if (error instanceof Error && !suppressNotification) {
                toast.error(error.message || 'Could not make API request.', {
                    position: 'bottom-right',
                });
            }
            throw error; // Rethrow the error for the calling code to handle
        }
    };
}

export default new APIRequestUtil();
