export const getDeploymentDetailsViewResponse = {
    results: {
        'Archive (FS/NAS)': {
            averageDedupRatio: 2.49994994,
            monthlyData: [
                { total: 69.2, target: 27.68, creditsMonthly: 10.76, cumulatedCredits: 10.76 },
                { total: 72.54, target: 29.02, creditsMonthly: 22.7, cumulatedCredits: 33.46 },
                { total: 77.98, target: 31.19, creditsMonthly: 24.5, cumulatedCredits: 57.96 },
                { total: 83.44, target: 33.38, creditsMonthly: 26.19, cumulatedCredits: 84.15 },
                { total: 84.1, target: 33.64, creditsMonthly: 26.4, cumulatedCredits: 110.55 },
                { total: 84.77, target: 33.91, creditsMonthly: 26.7, cumulatedCredits: 137.25 },
                { total: 85.43, target: 34.17, creditsMonthly: 26.87, cumulatedCredits: 164.12 },
                { total: 86.1, target: 34.44, creditsMonthly: 27.04, cumulatedCredits: 191.16 },
                { total: 86.78, target: 34.71, creditsMonthly: 27.3, cumulatedCredits: 218.46 },
                { total: 87.46, target: 34.98, creditsMonthly: 27.52, cumulatedCredits: 245.98 },
                { total: 88.15, target: 35.26, creditsMonthly: 27.7, cumulatedCredits: 273.68 },
                { total: 88.84, target: 35.54, creditsMonthly: 27.9, cumulatedCredits: 301.58 },
                { total: 111.53, target: 44.61, creditsMonthly: 33.72, cumulatedCredits: 335.3 },
                { total: 112.41, target: 44.96, creditsMonthly: 35.35, cumulatedCredits: 370.65 },
                { total: 113.3, target: 45.32, creditsMonthly: 35.63, cumulatedCredits: 406.28 },
                { total: 114.18, target: 45.67, creditsMonthly: 35.91, cumulatedCredits: 442.19 },
                { total: 115.08, target: 46.03, creditsMonthly: 36.19, cumulatedCredits: 478.38 },
                { total: 116, target: 46.4, creditsMonthly: 36.47, cumulatedCredits: 514.85 },
                { total: 116.91, target: 46.76, creditsMonthly: 36.76, cumulatedCredits: 551.61 },
                { total: 117.82, target: 47.13, creditsMonthly: 37.05, cumulatedCredits: 588.66 },
                { total: 118.75, target: 47.5, creditsMonthly: 37.34, cumulatedCredits: 626 },
                { total: 119.69, target: 47.88, creditsMonthly: 37.63, cumulatedCredits: 663.63 },
                { total: 120.63, target: 48.25, creditsMonthly: 37.93, cumulatedCredits: 701.56 },
                { total: 121.58, target: 48.63, creditsMonthly: 38.23, cumulatedCredits: 739.79 },
                { total: 144.45, target: 57.78, creditsMonthly: 42.89, cumulatedCredits: 782.68 },
                { total: 145.59, target: 58.24, creditsMonthly: 45.77, cumulatedCredits: 828.45 },
                { total: 146.73, target: 58.69, creditsMonthly: 46.11, cumulatedCredits: 874.56 },
                { total: 147.87, target: 59.15, creditsMonthly: 46.49, cumulatedCredits: 921.05 },
                { total: 149.03, target: 59.61, creditsMonthly: 46.88, cumulatedCredits: 967.93 },
                { total: 150.21, target: 60.08, creditsMonthly: 47.23, cumulatedCredits: 1015.16 },
                { total: 151.41, target: 60.56, creditsMonthly: 47.59, cumulatedCredits: 1062.75 },
                { total: 152.58, target: 61.03, creditsMonthly: 47.96, cumulatedCredits: 1110.71 },
                { total: 153.78, target: 61.51, creditsMonthly: 48.37, cumulatedCredits: 1159.08 },
                { total: 154.99, target: 62, creditsMonthly: 48.74, cumulatedCredits: 1207.82 },
                { total: 156.2, target: 62.48, creditsMonthly: 49.1, cumulatedCredits: 1256.92 },
                { total: 157.45, target: 62.98, creditsMonthly: 49.51, cumulatedCredits: 1306.43 },
                { total: 180.47, target: 72.19, creditsMonthly: 53.1, cumulatedCredits: 1359.53 },
                { total: 181.87, target: 72.75, creditsMonthly: 57.18, cumulatedCredits: 1416.71 },
                { total: 183.31, target: 73.32, creditsMonthly: 57.65, cumulatedCredits: 1474.36 },
                { total: 184.76, target: 73.9, creditsMonthly: 58.08, cumulatedCredits: 1532.44 },
                { total: 186.21, target: 74.48, creditsMonthly: 58.56, cumulatedCredits: 1591 },
                { total: 187.67, target: 75.07, creditsMonthly: 58.99, cumulatedCredits: 1649.99 },
                { total: 189.15, target: 75.66, creditsMonthly: 59.49, cumulatedCredits: 1709.48 },
                { total: 190.63, target: 76.25, creditsMonthly: 59.93, cumulatedCredits: 1769.41 },
                { total: 192.13, target: 76.85, creditsMonthly: 60.43, cumulatedCredits: 1829.84 },
                { total: 193.65, target: 77.46, creditsMonthly: 60.89, cumulatedCredits: 1890.73 },
                { total: 195.17, target: 78.07, creditsMonthly: 61.36, cumulatedCredits: 1952.09 },
                { total: 196.71, target: 78.68, creditsMonthly: 61.86, cumulatedCredits: 2013.95 },
                { total: 198.25, target: 79.3, creditsMonthly: 62.32, cumulatedCredits: 2076.27 },
                { total: 199.81, target: 79.92, creditsMonthly: 62.83, cumulatedCredits: 2139.1 },
                { total: 201.39, target: 80.56, creditsMonthly: 63.32, cumulatedCredits: 2202.42 },
                { total: 202.97, target: 81.19, creditsMonthly: 63.8, cumulatedCredits: 2266.22 },
                { total: 204.57, target: 81.83, creditsMonthly: 64.33, cumulatedCredits: 2330.55 },
                { total: 206.17, target: 82.47, creditsMonthly: 64.83, cumulatedCredits: 2395.38 },
                { total: 207.79, target: 83.12, creditsMonthly: 65.32, cumulatedCredits: 2460.7 },
                { total: 209.42, target: 83.77, creditsMonthly: 65.85, cumulatedCredits: 2526.55 },
                { total: 211.07, target: 84.43, creditsMonthly: 66.38, cumulatedCredits: 2592.93 },
                { total: 212.73, target: 85.09, creditsMonthly: 66.89, cumulatedCredits: 2659.82 },
                { total: 214.41, target: 85.76, creditsMonthly: 67.41, cumulatedCredits: 2727.23 },
                { total: 216.08, target: 86.43, creditsMonthly: 67.95, cumulatedCredits: 2795.18 },
            ],
        },
        'Azure VM': {
            averageDedupRatio: 3.0230020002,
            monthlyData: [
                { total: 48.17, target: 16.06, creditsMonthly: 8.98, cumulatedCredits: 8.98 },
                { total: 48.55, target: 16.18, creditsMonthly: 12.68, cumulatedCredits: 21.66 },
                { total: 52.18, target: 17.39, creditsMonthly: 13.71, cumulatedCredits: 35.37 },
                { total: 55.85, target: 18.62, creditsMonthly: 14.67, cumulatedCredits: 50.04 },
                { total: 56.29, target: 18.76, creditsMonthly: 14.7, cumulatedCredits: 64.74 },
                { total: 56.73, target: 18.91, creditsMonthly: 14.88, cumulatedCredits: 79.62 },
                { total: 57.17, target: 19.06, creditsMonthly: 15, cumulatedCredits: 94.62 },
                { total: 57.62, target: 19.21, creditsMonthly: 15.01, cumulatedCredits: 109.63 },
                { total: 58.08, target: 19.36, creditsMonthly: 15.3, cumulatedCredits: 124.93 },
                { total: 58.53, target: 19.51, creditsMonthly: 15.3, cumulatedCredits: 140.23 },
                { total: 58.99, target: 19.66, creditsMonthly: 15.46, cumulatedCredits: 155.69 },
                { total: 59.46, target: 19.82, creditsMonthly: 15.6, cumulatedCredits: 171.29 },
                { total: 74.64, target: 24.88, creditsMonthly: 18.72, cumulatedCredits: 190.01 },
                { total: 75.23, target: 25.08, creditsMonthly: 19.77, cumulatedCredits: 209.78 },
                { total: 75.82, target: 25.27, creditsMonthly: 19.8, cumulatedCredits: 229.58 },
                { total: 76.41, target: 25.47, creditsMonthly: 20.09, cumulatedCredits: 249.67 },
                { total: 77.03, target: 25.68, creditsMonthly: 20.12, cumulatedCredits: 269.79 },
                { total: 77.63, target: 25.88, creditsMonthly: 20.4, cumulatedCredits: 290.19 },
                { total: 78.24, target: 26.08, creditsMonthly: 20.46, cumulatedCredits: 310.65 },
                { total: 78.85, target: 26.28, creditsMonthly: 20.7, cumulatedCredits: 331.35 },
                { total: 79.48, target: 26.49, creditsMonthly: 20.81, cumulatedCredits: 352.16 },
                { total: 80.12, target: 26.71, creditsMonthly: 21, cumulatedCredits: 373.16 },
                { total: 80.74, target: 26.91, creditsMonthly: 21.15, cumulatedCredits: 394.31 },
                { total: 81.37, target: 27.12, creditsMonthly: 21.3, cumulatedCredits: 415.61 },
                { total: 96.66, target: 32.22, creditsMonthly: 23.92, cumulatedCredits: 439.53 },
                { total: 97.43, target: 32.48, creditsMonthly: 25.5, cumulatedCredits: 465.03 },
                { total: 98.2, target: 32.73, creditsMonthly: 25.77, cumulatedCredits: 490.8 },
                { total: 98.98, target: 32.99, creditsMonthly: 25.92, cumulatedCredits: 516.72 },
                { total: 99.75, target: 33.25, creditsMonthly: 26.1, cumulatedCredits: 542.82 },
                { total: 100.53, target: 33.51, creditsMonthly: 26.39, cumulatedCredits: 569.21 },
                { total: 101.33, target: 33.78, creditsMonthly: 26.55, cumulatedCredits: 595.76 },
                { total: 102.12, target: 34.04, creditsMonthly: 26.72, cumulatedCredits: 622.48 },
                { total: 102.93, target: 34.31, creditsMonthly: 27, cumulatedCredits: 649.48 },
                { total: 103.74, target: 34.58, creditsMonthly: 27.2, cumulatedCredits: 676.68 },
                { total: 104.54, target: 34.85, creditsMonthly: 27.38, cumulatedCredits: 704.06 },
                { total: 105.36, target: 35.12, creditsMonthly: 27.6, cumulatedCredits: 731.66 },
                { total: 120.78, target: 40.26, creditsMonthly: 29.68, cumulatedCredits: 761.34 },
                { total: 121.74, target: 40.58, creditsMonthly: 31.88, cumulatedCredits: 793.22 },
                { total: 122.69, target: 40.9, creditsMonthly: 32.12, cumulatedCredits: 825.34 },
                { total: 123.65, target: 41.22, creditsMonthly: 32.4, cumulatedCredits: 857.74 },
                { total: 124.62, target: 41.54, creditsMonthly: 32.67, cumulatedCredits: 890.41 },
                { total: 125.62, target: 41.87, creditsMonthly: 32.92, cumulatedCredits: 923.33 },
                { total: 126.59, target: 42.2, creditsMonthly: 33.17, cumulatedCredits: 956.5 },
                { total: 127.6, target: 42.53, creditsMonthly: 33.43, cumulatedCredits: 989.93 },
                { total: 128.59, target: 42.86, creditsMonthly: 33.69, cumulatedCredits: 1023.62 },
                { total: 129.61, target: 43.2, creditsMonthly: 33.95, cumulatedCredits: 1057.57 },
                { total: 130.63, target: 43.54, creditsMonthly: 34.21, cumulatedCredits: 1091.78 },
                { total: 131.66, target: 43.89, creditsMonthly: 34.5, cumulatedCredits: 1126.28 },
                { total: 132.68, target: 44.23, creditsMonthly: 34.78, cumulatedCredits: 1161.06 },
                { total: 133.74, target: 44.58, creditsMonthly: 35.05, cumulatedCredits: 1196.11 },
                { total: 134.78, target: 44.93, creditsMonthly: 35.32, cumulatedCredits: 1231.43 },
                { total: 135.86, target: 45.29, creditsMonthly: 35.6, cumulatedCredits: 1267.03 },
                { total: 136.91, target: 45.64, creditsMonthly: 35.87, cumulatedCredits: 1302.9 },
                { total: 137.99, target: 46, creditsMonthly: 36.16, cumulatedCredits: 1339.06 },
                { total: 139.07, target: 46.36, creditsMonthly: 36.44, cumulatedCredits: 1375.5 },
                { total: 140.16, target: 46.72, creditsMonthly: 36.73, cumulatedCredits: 1412.23 },
                { total: 141.26, target: 47.09, creditsMonthly: 37.02, cumulatedCredits: 1449.25 },
                { total: 142.38, target: 47.46, creditsMonthly: 37.31, cumulatedCredits: 1486.56 },
                { total: 143.49, target: 47.83, creditsMonthly: 37.6, cumulatedCredits: 1524.16 },
                { total: 144.63, target: 48.21, creditsMonthly: 37.9, cumulatedCredits: 1562.06 },
            ],
        },
        EC2: {
            averageDedupRatio: 3.00001,
            monthlyData: [
                { total: 24.09, target: 8.03, creditsMonthly: 5.63, cumulatedCredits: 5.63 },
                { total: 24.28, target: 8.09, creditsMonthly: 7.94, cumulatedCredits: 13.57 },
                { total: 26.1, target: 8.7, creditsMonthly: 8.55, cumulatedCredits: 22.12 },
                { total: 27.92, target: 9.31, creditsMonthly: 9.13, cumulatedCredits: 31.25 },
                { total: 28.15, target: 9.38, creditsMonthly: 9.3, cumulatedCredits: 40.55 },
                { total: 28.37, target: 9.46, creditsMonthly: 9.3, cumulatedCredits: 49.85 },
                { total: 28.58, target: 9.53, creditsMonthly: 9.3, cumulatedCredits: 59.15 },
                { total: 28.81, target: 9.6, creditsMonthly: 9.39, cumulatedCredits: 68.54 },
                { total: 29.04, target: 9.68, creditsMonthly: 9.6, cumulatedCredits: 78.14 },
                { total: 29.27, target: 9.76, creditsMonthly: 9.6, cumulatedCredits: 87.74 },
                { total: 29.49, target: 9.83, creditsMonthly: 9.6, cumulatedCredits: 97.34 },
                { total: 29.73, target: 9.91, creditsMonthly: 9.71, cumulatedCredits: 107.05 },
                { total: 37.32, target: 12.44, creditsMonthly: 11.82, cumulatedCredits: 118.87 },
                { total: 37.62, target: 12.54, creditsMonthly: 12.3, cumulatedCredits: 131.17 },
                { total: 37.92, target: 12.64, creditsMonthly: 12.34, cumulatedCredits: 143.51 },
                { total: 38.22, target: 12.74, creditsMonthly: 12.6, cumulatedCredits: 156.11 },
                { total: 38.51, target: 12.84, creditsMonthly: 12.6, cumulatedCredits: 168.71 },
                { total: 38.81, target: 12.94, creditsMonthly: 12.64, cumulatedCredits: 181.35 },
                { total: 39.12, target: 13.04, creditsMonthly: 12.9, cumulatedCredits: 194.25 },
                { total: 39.44, target: 13.15, creditsMonthly: 12.9, cumulatedCredits: 207.15 },
                { total: 39.74, target: 13.25, creditsMonthly: 12.94, cumulatedCredits: 220.09 },
                { total: 40.06, target: 13.35, creditsMonthly: 13.2, cumulatedCredits: 233.29 },
                { total: 40.36, target: 13.45, creditsMonthly: 13.2, cumulatedCredits: 246.49 },
                { total: 40.69, target: 13.56, creditsMonthly: 13.28, cumulatedCredits: 259.77 },
                { total: 48.34, target: 16.11, creditsMonthly: 15.02, cumulatedCredits: 274.79 },
                { total: 48.71, target: 16.24, creditsMonthly: 15.9, cumulatedCredits: 290.69 },
                { total: 49.1, target: 16.37, creditsMonthly: 16.12, cumulatedCredits: 306.81 },
                { total: 49.5, target: 16.5, creditsMonthly: 16.2, cumulatedCredits: 323.01 },
                { total: 49.87, target: 16.62, creditsMonthly: 16.31, cumulatedCredits: 339.32 },
                { total: 50.27, target: 16.76, creditsMonthly: 16.5, cumulatedCredits: 355.82 },
                { total: 50.66, target: 16.89, creditsMonthly: 16.51, cumulatedCredits: 372.33 },
                { total: 51.06, target: 17.02, creditsMonthly: 16.8, cumulatedCredits: 389.13 },
                { total: 51.48, target: 17.16, creditsMonthly: 16.8, cumulatedCredits: 405.93 },
                { total: 51.87, target: 17.29, creditsMonthly: 17.04, cumulatedCredits: 422.97 },
                { total: 52.27, target: 17.42, creditsMonthly: 17.1, cumulatedCredits: 440.07 },
                { total: 52.68, target: 17.56, creditsMonthly: 17.28, cumulatedCredits: 457.35 },
                { total: 60.39, target: 20.13, creditsMonthly: 18.52, cumulatedCredits: 475.87 },
                { total: 60.87, target: 20.29, creditsMonthly: 19.93, cumulatedCredits: 495.8 },
                { total: 61.34, target: 20.45, creditsMonthly: 20.1, cumulatedCredits: 515.9 },
                { total: 61.83, target: 20.61, creditsMonthly: 20.24, cumulatedCredits: 536.14 },
                { total: 62.32, target: 20.77, creditsMonthly: 20.4, cumulatedCredits: 556.54 },
                { total: 62.81, target: 20.94, creditsMonthly: 20.58, cumulatedCredits: 577.12 },
                { total: 63.3, target: 21.1, creditsMonthly: 20.7, cumulatedCredits: 597.82 },
                { total: 63.79, target: 21.26, creditsMonthly: 20.94, cumulatedCredits: 618.76 },
                { total: 64.3, target: 21.43, creditsMonthly: 21, cumulatedCredits: 639.76 },
                { total: 64.79, target: 21.6, creditsMonthly: 21.28, cumulatedCredits: 661.04 },
                { total: 65.31, target: 21.77, creditsMonthly: 21.35, cumulatedCredits: 682.39 },
                { total: 65.83, target: 21.94, creditsMonthly: 21.6, cumulatedCredits: 703.99 },
                { total: 66.34, target: 22.11, creditsMonthly: 21.71, cumulatedCredits: 725.7 },
                { total: 66.87, target: 22.29, creditsMonthly: 21.9, cumulatedCredits: 747.6 },
                { total: 67.4, target: 22.47, creditsMonthly: 22.09, cumulatedCredits: 769.69 },
                { total: 67.91, target: 22.64, creditsMonthly: 22.2, cumulatedCredits: 791.89 },
                { total: 68.46, target: 22.82, creditsMonthly: 22.47, cumulatedCredits: 814.36 },
                { total: 68.99, target: 23, creditsMonthly: 22.56, cumulatedCredits: 836.92 },
                { total: 69.54, target: 23.18, creditsMonthly: 22.8, cumulatedCredits: 859.72 },
                { total: 70.08, target: 23.36, creditsMonthly: 22.96, cumulatedCredits: 882.68 },
                { total: 70.63, target: 23.54, creditsMonthly: 23.1, cumulatedCredits: 905.78 },
                { total: 71.18, target: 23.73, creditsMonthly: 23.36, cumulatedCredits: 929.14 },
                { total: 71.75, target: 23.92, creditsMonthly: 23.47, cumulatedCredits: 952.61 },
                { total: 72.31, target: 24.1, creditsMonthly: 23.7, cumulatedCredits: 976.31 },
            ],
        },
        'Hyper-V': {
            averageDedupRatio: 2.4999504214179478,
            monthlyData: [
                { total: 80.73, target: 32.29, creditsMonthly: 12.54, cumulatedCredits: 12.54 },
                { total: 84.64, target: 33.86, creditsMonthly: 26.55, cumulatedCredits: 39.09 },
                { total: 90.98, target: 36.39, creditsMonthly: 28.53, cumulatedCredits: 67.62 },
                { total: 97.34, target: 38.94, creditsMonthly: 30.54, cumulatedCredits: 98.16 },
                { total: 98.12, target: 39.25, creditsMonthly: 30.87, cumulatedCredits: 129.03 },
                { total: 98.89, target: 39.56, creditsMonthly: 31.1, cumulatedCredits: 160.13 },
                { total: 99.66, target: 39.86, creditsMonthly: 31.33, cumulatedCredits: 191.46 },
                { total: 100.45, target: 40.18, creditsMonthly: 31.57, cumulatedCredits: 223.03 },
                { total: 101.23, target: 40.49, creditsMonthly: 31.8, cumulatedCredits: 254.83 },
                { total: 102.03, target: 40.81, creditsMonthly: 32.1, cumulatedCredits: 286.93 },
                { total: 102.83, target: 41.13, creditsMonthly: 32.35, cumulatedCredits: 319.28 },
                { total: 103.64, target: 41.46, creditsMonthly: 32.59, cumulatedCredits: 351.87 },
                { total: 130.14, target: 52.06, creditsMonthly: 39.3, cumulatedCredits: 391.17 },
                { total: 131.14, target: 52.46, creditsMonthly: 41.24, cumulatedCredits: 432.41 },
                { total: 132.18, target: 52.87, creditsMonthly: 41.56, cumulatedCredits: 473.97 },
                { total: 133.21, target: 53.28, creditsMonthly: 41.89, cumulatedCredits: 515.86 },
                { total: 134.27, target: 53.71, creditsMonthly: 42.22, cumulatedCredits: 558.08 },
                { total: 135.33, target: 54.13, creditsMonthly: 42.54, cumulatedCredits: 600.62 },
                { total: 136.39, target: 54.56, creditsMonthly: 42.88, cumulatedCredits: 643.5 },
                { total: 137.46, target: 54.98, creditsMonthly: 43.23, cumulatedCredits: 686.73 },
                { total: 138.53, target: 55.41, creditsMonthly: 43.57, cumulatedCredits: 730.3 },
                { total: 139.63, target: 55.85, creditsMonthly: 43.91, cumulatedCredits: 774.21 },
                { total: 140.73, target: 56.29, creditsMonthly: 44.25, cumulatedCredits: 818.46 },
                { total: 141.84, target: 56.74, creditsMonthly: 44.59, cumulatedCredits: 863.05 },
                { total: 168.51, target: 67.4, creditsMonthly: 50.04, cumulatedCredits: 913.09 },
                { total: 169.84, target: 67.94, creditsMonthly: 53.4, cumulatedCredits: 966.49 },
                { total: 171.17, target: 68.47, creditsMonthly: 53.83, cumulatedCredits: 1020.32 },
                { total: 172.53, target: 69.01, creditsMonthly: 54.23, cumulatedCredits: 1074.55 },
                { total: 173.88, target: 69.55, creditsMonthly: 54.69, cumulatedCredits: 1129.24 },
                { total: 175.25, target: 70.1, creditsMonthly: 55.09, cumulatedCredits: 1184.33 },
                { total: 176.62, target: 70.65, creditsMonthly: 55.55, cumulatedCredits: 1239.88 },
                { total: 178.01, target: 71.2, creditsMonthly: 55.97, cumulatedCredits: 1295.85 },
                { total: 179.42, target: 71.77, creditsMonthly: 56.42, cumulatedCredits: 1352.27 },
                { total: 180.83, target: 72.33, creditsMonthly: 56.86, cumulatedCredits: 1409.13 },
                { total: 182.24, target: 72.9, creditsMonthly: 57.3, cumulatedCredits: 1466.43 },
                { total: 183.68, target: 73.47, creditsMonthly: 57.75, cumulatedCredits: 1524.18 },
                { total: 210.54, target: 84.22, creditsMonthly: 61.94, cumulatedCredits: 1586.12 },
                { total: 212.2, target: 84.88, creditsMonthly: 66.73, cumulatedCredits: 1652.85 },
                { total: 213.87, target: 85.55, creditsMonthly: 67.25, cumulatedCredits: 1720.1 },
                { total: 215.55, target: 86.22, creditsMonthly: 67.77, cumulatedCredits: 1787.87 },
                { total: 217.24, target: 86.9, creditsMonthly: 68.29, cumulatedCredits: 1856.16 },
                { total: 218.95, target: 87.58, creditsMonthly: 68.85, cumulatedCredits: 1925.01 },
                { total: 220.67, target: 88.27, creditsMonthly: 69.39, cumulatedCredits: 1994.4 },
                { total: 222.41, target: 88.96, creditsMonthly: 69.94, cumulatedCredits: 2064.34 },
                { total: 224.17, target: 89.67, creditsMonthly: 70.48, cumulatedCredits: 2134.82 },
                { total: 225.93, target: 90.37, creditsMonthly: 71.04, cumulatedCredits: 2205.86 },
                { total: 227.7, target: 91.08, creditsMonthly: 71.59, cumulatedCredits: 2277.45 },
                { total: 229.49, target: 91.8, creditsMonthly: 72.15, cumulatedCredits: 2349.6 },
                { total: 231.3, target: 92.52, creditsMonthly: 72.72, cumulatedCredits: 2422.32 },
                { total: 233.1, target: 93.24, creditsMonthly: 73.3, cumulatedCredits: 2495.62 },
                { total: 234.94, target: 93.98, creditsMonthly: 73.87, cumulatedCredits: 2569.49 },
                { total: 236.79, target: 94.72, creditsMonthly: 74.45, cumulatedCredits: 2643.94 },
                { total: 238.66, target: 95.46, creditsMonthly: 75.04, cumulatedCredits: 2718.98 },
                { total: 240.54, target: 96.22, creditsMonthly: 75.63, cumulatedCredits: 2794.61 },
                { total: 242.42, target: 96.97, creditsMonthly: 76.22, cumulatedCredits: 2870.83 },
                { total: 244.33, target: 97.73, creditsMonthly: 76.83, cumulatedCredits: 2947.66 },
                { total: 246.26, target: 98.5, creditsMonthly: 77.43, cumulatedCredits: 3025.09 },
                { total: 248.19, target: 99.28, creditsMonthly: 78.03, cumulatedCredits: 3103.12 },
                { total: 250.14, target: 100.06, creditsMonthly: 78.65, cumulatedCredits: 3181.77 },
                { total: 252.12, target: 100.85, creditsMonthly: 79.27, cumulatedCredits: 3261.04 },
            ],
        },
        Linux: {
            averageDedupRatio: 3,
            monthlyData: [
                { total: 60.22, target: 20.07, creditsMonthly: 11.2, cumulatedCredits: 11.2 },
                { total: 60.69, target: 20.23, creditsMonthly: 15.9, cumulatedCredits: 27.1 },
                { total: 65.23, target: 21.74, creditsMonthly: 17.06, cumulatedCredits: 44.16 },
                { total: 69.81, target: 23.27, creditsMonthly: 18.26, cumulatedCredits: 62.42 },
                { total: 70.35, target: 23.45, creditsMonthly: 18.42, cumulatedCredits: 80.84 },
                { total: 70.92, target: 23.64, creditsMonthly: 18.6, cumulatedCredits: 99.44 },
                { total: 71.46, target: 23.82, creditsMonthly: 18.69, cumulatedCredits: 118.13 },
                { total: 72.04, target: 24.01, creditsMonthly: 18.9, cumulatedCredits: 137.03 },
                { total: 72.59, target: 24.2, creditsMonthly: 18.99, cumulatedCredits: 156.02 },
                { total: 73.17, target: 24.39, creditsMonthly: 19.2, cumulatedCredits: 175.22 },
                { total: 73.75, target: 24.58, creditsMonthly: 19.29, cumulatedCredits: 194.51 },
                { total: 74.33, target: 24.78, creditsMonthly: 19.5, cumulatedCredits: 214.01 },
                { total: 93.3, target: 31.1, creditsMonthly: 23.49, cumulatedCredits: 237.5 },
                { total: 94.05, target: 31.35, creditsMonthly: 24.6, cumulatedCredits: 262.1 },
                { total: 94.77, target: 31.59, creditsMonthly: 24.89, cumulatedCredits: 286.99 },
                { total: 95.53, target: 31.84, creditsMonthly: 25.02, cumulatedCredits: 312.01 },
                { total: 96.28, target: 32.09, creditsMonthly: 25.2, cumulatedCredits: 337.21 },
                { total: 97.03, target: 32.34, creditsMonthly: 25.47, cumulatedCredits: 362.68 },
                { total: 97.81, target: 32.6, creditsMonthly: 25.62, cumulatedCredits: 388.3 },
                { total: 98.57, target: 32.86, creditsMonthly: 25.8, cumulatedCredits: 414.1 },
                { total: 99.35, target: 33.12, creditsMonthly: 26.07, cumulatedCredits: 440.17 },
                { total: 100.12, target: 33.37, creditsMonthly: 26.23, cumulatedCredits: 466.4 },
                { total: 100.91, target: 33.64, creditsMonthly: 26.4, cumulatedCredits: 492.8 },
                { total: 101.71, target: 33.9, creditsMonthly: 26.7, cumulatedCredits: 519.5 },
                { total: 120.85, target: 40.28, creditsMonthly: 29.9, cumulatedCredits: 549.4 },
                { total: 121.79, target: 40.6, creditsMonthly: 31.9, cumulatedCredits: 581.3 },
                { total: 122.76, target: 40.92, creditsMonthly: 32.14, cumulatedCredits: 613.44 },
                { total: 123.72, target: 41.24, creditsMonthly: 32.4, cumulatedCredits: 645.84 },
                { total: 124.67, target: 41.56, creditsMonthly: 32.69, cumulatedCredits: 678.53 },
                { total: 125.66, target: 41.89, creditsMonthly: 32.94, cumulatedCredits: 711.47 },
                { total: 126.64, target: 42.21, creditsMonthly: 33.19, cumulatedCredits: 744.66 },
                { total: 127.65, target: 42.55, creditsMonthly: 33.44, cumulatedCredits: 778.1 },
                { total: 128.65, target: 42.88, creditsMonthly: 33.7, cumulatedCredits: 811.8 },
                { total: 129.65, target: 43.22, creditsMonthly: 33.96, cumulatedCredits: 845.76 },
                { total: 130.69, target: 43.56, creditsMonthly: 34.23, cumulatedCredits: 879.99 },
                { total: 131.71, target: 43.9, creditsMonthly: 34.5, cumulatedCredits: 914.49 },
                { total: 150.97, target: 50.32, creditsMonthly: 37.04, cumulatedCredits: 951.53 },
                { total: 152.17, target: 50.72, creditsMonthly: 39.87, cumulatedCredits: 991.4 },
                { total: 153.36, target: 51.12, creditsMonthly: 40.18, cumulatedCredits: 1031.58 },
                { total: 154.56, target: 51.52, creditsMonthly: 40.5, cumulatedCredits: 1072.08 },
                { total: 155.79, target: 51.93, creditsMonthly: 40.83, cumulatedCredits: 1112.91 },
                { total: 157.01, target: 52.34, creditsMonthly: 41.15, cumulatedCredits: 1154.06 },
                { total: 158.24, target: 52.75, creditsMonthly: 41.47, cumulatedCredits: 1195.53 },
                { total: 159.48, target: 53.16, creditsMonthly: 41.8, cumulatedCredits: 1237.33 },
                { total: 160.73, target: 53.58, creditsMonthly: 42.12, cumulatedCredits: 1279.45 },
                { total: 162.02, target: 54.01, creditsMonthly: 42.45, cumulatedCredits: 1321.9 },
                { total: 163.28, target: 54.43, creditsMonthly: 42.78, cumulatedCredits: 1364.68 },
                { total: 164.56, target: 54.85, creditsMonthly: 43.11, cumulatedCredits: 1407.79 },
                { total: 165.87, target: 55.29, creditsMonthly: 43.44, cumulatedCredits: 1451.23 },
                { total: 167.16, target: 55.72, creditsMonthly: 43.79, cumulatedCredits: 1495.02 },
                { total: 168.48, target: 56.16, creditsMonthly: 44.16, cumulatedCredits: 1539.18 },
                { total: 169.8, target: 56.6, creditsMonthly: 44.5, cumulatedCredits: 1583.68 },
                { total: 171.15, target: 57.05, creditsMonthly: 44.84, cumulatedCredits: 1628.52 },
                { total: 172.48, target: 57.49, creditsMonthly: 45.18, cumulatedCredits: 1673.7 },
                { total: 173.84, target: 57.95, creditsMonthly: 45.53, cumulatedCredits: 1719.23 },
                { total: 175.21, target: 58.4, creditsMonthly: 45.91, cumulatedCredits: 1765.14 },
                { total: 176.59, target: 58.86, creditsMonthly: 46.28, cumulatedCredits: 1811.42 },
                { total: 177.97, target: 59.32, creditsMonthly: 46.63, cumulatedCredits: 1858.05 },
                { total: 179.38, target: 59.79, creditsMonthly: 46.99, cumulatedCredits: 1905.04 },
                { total: 180.78, target: 60.26, creditsMonthly: 47.34, cumulatedCredits: 1952.38 },
            ],
        },
        'MS-SQL': {
            averageDedupRatio: 1.3000119860961283,
            monthlyData: [
                { total: 36.13, target: 27.79, creditsMonthly: 15.55, cumulatedCredits: 15.55 },
                { total: 36.41, target: 28.01, creditsMonthly: 21.99, cumulatedCredits: 37.54 },
                { total: 39.14, target: 30.11, creditsMonthly: 23.65, cumulatedCredits: 61.19 },
                { total: 41.88, target: 32.22, creditsMonthly: 25.27, cumulatedCredits: 86.46 },
                { total: 42.21, target: 32.47, creditsMonthly: 25.5, cumulatedCredits: 111.96 },
                { total: 42.55, target: 32.73, creditsMonthly: 25.77, cumulatedCredits: 137.73 },
                { total: 42.88, target: 32.98, creditsMonthly: 25.92, cumulatedCredits: 163.65 },
                { total: 43.23, target: 33.25, creditsMonthly: 26.1, cumulatedCredits: 189.75 },
                { total: 43.56, target: 33.51, creditsMonthly: 26.38, cumulatedCredits: 216.13 },
                { total: 43.91, target: 33.78, creditsMonthly: 26.55, cumulatedCredits: 242.68 },
                { total: 44.24, target: 34.03, creditsMonthly: 26.71, cumulatedCredits: 269.39 },
                { total: 44.6, target: 34.31, creditsMonthly: 27, cumulatedCredits: 296.39 },
                { total: 55.98, target: 43.06, creditsMonthly: 32.52, cumulatedCredits: 328.91 },
                { total: 56.43, target: 43.41, creditsMonthly: 34.13, cumulatedCredits: 363.04 },
                { total: 56.88, target: 43.75, creditsMonthly: 34.39, cumulatedCredits: 397.43 },
                { total: 57.32, target: 44.09, creditsMonthly: 34.66, cumulatedCredits: 432.09 },
                { total: 57.76, target: 44.43, creditsMonthly: 34.93, cumulatedCredits: 467.02 },
                { total: 58.22, target: 44.78, creditsMonthly: 35.21, cumulatedCredits: 502.23 },
                { total: 58.69, target: 45.15, creditsMonthly: 35.48, cumulatedCredits: 537.71 },
                { total: 59.14, target: 45.49, creditsMonthly: 35.76, cumulatedCredits: 573.47 },
                { total: 59.61, target: 45.85, creditsMonthly: 36.05, cumulatedCredits: 609.52 },
                { total: 60.08, target: 46.22, creditsMonthly: 36.32, cumulatedCredits: 645.84 },
                { total: 60.54, target: 46.57, creditsMonthly: 36.6, cumulatedCredits: 682.44 },
                { total: 61.02, target: 46.94, creditsMonthly: 36.9, cumulatedCredits: 719.34 },
                { total: 72.51, target: 55.78, creditsMonthly: 41.42, cumulatedCredits: 760.76 },
                { total: 73.07, target: 56.21, creditsMonthly: 44.19, cumulatedCredits: 804.95 },
                { total: 73.64, target: 56.65, creditsMonthly: 44.53, cumulatedCredits: 849.48 },
                { total: 74.23, target: 57.1, creditsMonthly: 44.88, cumulatedCredits: 894.36 },
                { total: 74.8, target: 57.54, creditsMonthly: 45.22, cumulatedCredits: 939.58 },
                { total: 75.39, target: 57.99, creditsMonthly: 45.58, cumulatedCredits: 985.16 },
                { total: 75.99, target: 58.45, creditsMonthly: 45.97, cumulatedCredits: 1031.13 },
                { total: 76.61, target: 58.93, creditsMonthly: 46.32, cumulatedCredits: 1077.45 },
                { total: 77.2, target: 59.38, creditsMonthly: 46.67, cumulatedCredits: 1124.12 },
                { total: 77.8, target: 59.85, creditsMonthly: 47.03, cumulatedCredits: 1171.15 },
                { total: 78.41, target: 60.32, creditsMonthly: 47.42, cumulatedCredits: 1218.57 },
                { total: 79.03, target: 60.79, creditsMonthly: 47.8, cumulatedCredits: 1266.37 },
                { total: 90.59, target: 69.68, creditsMonthly: 51.23, cumulatedCredits: 1317.6 },
                { total: 91.29, target: 70.22, creditsMonthly: 55.21, cumulatedCredits: 1372.81 },
                { total: 92.02, target: 70.78, creditsMonthly: 55.64, cumulatedCredits: 1428.45 },
                { total: 92.74, target: 71.34, creditsMonthly: 56.07, cumulatedCredits: 1484.52 },
                { total: 93.48, target: 71.91, creditsMonthly: 56.53, cumulatedCredits: 1541.05 },
                { total: 94.21, target: 72.47, creditsMonthly: 56.96, cumulatedCredits: 1598.01 },
                { total: 94.94, target: 73.03, creditsMonthly: 57.43, cumulatedCredits: 1655.44 },
                { total: 95.7, target: 73.62, creditsMonthly: 57.84, cumulatedCredits: 1713.28 },
                { total: 96.44, target: 74.18, creditsMonthly: 58.33, cumulatedCredits: 1771.61 },
                { total: 97.21, target: 74.78, creditsMonthly: 58.76, cumulatedCredits: 1830.37 },
                { total: 97.97, target: 75.36, creditsMonthly: 59.24, cumulatedCredits: 1889.61 },
                { total: 98.74, target: 75.95, creditsMonthly: 59.69, cumulatedCredits: 1949.3 },
                { total: 99.52, target: 76.55, creditsMonthly: 60.16, cumulatedCredits: 2009.46 },
                { total: 100.31, target: 77.16, creditsMonthly: 60.65, cumulatedCredits: 2070.11 },
                { total: 101.09, target: 77.76, creditsMonthly: 61.11, cumulatedCredits: 2131.22 },
                { total: 101.89, target: 78.38, creditsMonthly: 61.62, cumulatedCredits: 2192.84 },
                { total: 102.69, target: 78.99, creditsMonthly: 62.08, cumulatedCredits: 2254.92 },
                { total: 103.49, target: 79.61, creditsMonthly: 62.57, cumulatedCredits: 2317.49 },
                { total: 104.3, target: 80.23, creditsMonthly: 63.08, cumulatedCredits: 2380.57 },
                { total: 105.14, target: 80.88, creditsMonthly: 63.56, cumulatedCredits: 2444.13 },
                { total: 105.95, target: 81.5, creditsMonthly: 64.06, cumulatedCredits: 2508.19 },
                { total: 106.78, target: 82.14, creditsMonthly: 64.58, cumulatedCredits: 2572.77 },
                { total: 107.63, target: 82.79, creditsMonthly: 65.06, cumulatedCredits: 2637.83 },
                { total: 108.46, target: 83.43, creditsMonthly: 65.57, cumulatedCredits: 2703.4 },
            ],
        },
        NAS: {
            averageDedupRatio: 2.9999999999999996,
            monthlyData: [
                { total: 11.64, target: 3.88, creditsMonthly: 2.23, cumulatedCredits: 2.23 },
                { total: 11.74, target: 3.91, creditsMonthly: 3, cumulatedCredits: 5.23 },
                { total: 12.64, target: 4.21, creditsMonthly: 3.29, cumulatedCredits: 8.52 },
                { total: 13.55, target: 4.52, creditsMonthly: 3.59, cumulatedCredits: 12.11 },
                { total: 14.47, target: 4.82, creditsMonthly: 3.89, cumulatedCredits: 16 },
                { total: 15.4, target: 5.13, creditsMonthly: 3.9, cumulatedCredits: 19.9 },
                { total: 16.33, target: 5.44, creditsMonthly: 4.19, cumulatedCredits: 24.09 },
                { total: 17.27, target: 5.76, creditsMonthly: 4.49, cumulatedCredits: 28.58 },
                { total: 18.22, target: 6.07, creditsMonthly: 4.79, cumulatedCredits: 33.37 },
                { total: 19.17, target: 6.39, creditsMonthly: 5.09, cumulatedCredits: 38.46 },
                { total: 20.14, target: 6.71, creditsMonthly: 5.39, cumulatedCredits: 43.85 },
                { total: 21.11, target: 7.04, creditsMonthly: 5.43, cumulatedCredits: 49.28 },
                { total: 22.08, target: 7.36, creditsMonthly: 5.7, cumulatedCredits: 54.98 },
                { total: 22.27, target: 7.42, creditsMonthly: 5.74, cumulatedCredits: 60.72 },
                { total: 22.43, target: 7.48, creditsMonthly: 6, cumulatedCredits: 66.72 },
                { total: 22.6, target: 7.53, creditsMonthly: 6, cumulatedCredits: 72.72 },
                { total: 22.79, target: 7.6, creditsMonthly: 6, cumulatedCredits: 78.72 },
                { total: 22.97, target: 7.66, creditsMonthly: 6, cumulatedCredits: 84.72 },
                { total: 23.15, target: 7.72, creditsMonthly: 6, cumulatedCredits: 90.72 },
                { total: 23.33, target: 7.78, creditsMonthly: 6, cumulatedCredits: 96.72 },
                { total: 23.51, target: 7.84, creditsMonthly: 6.22, cumulatedCredits: 102.94 },
                { total: 23.7, target: 7.9, creditsMonthly: 6.3, cumulatedCredits: 109.24 },
                { total: 23.88, target: 7.96, creditsMonthly: 6.3, cumulatedCredits: 115.54 },
                { total: 24.07, target: 8.02, creditsMonthly: 6.3, cumulatedCredits: 121.84 },
                { total: 28.31, target: 9.44, creditsMonthly: 7.06, cumulatedCredits: 128.9 },
                { total: 28.53, target: 9.51, creditsMonthly: 7.5, cumulatedCredits: 136.4 },
                { total: 28.77, target: 9.59, creditsMonthly: 7.5, cumulatedCredits: 143.9 },
                { total: 28.97, target: 9.66, creditsMonthly: 7.5, cumulatedCredits: 151.4 },
                { total: 29.19, target: 9.73, creditsMonthly: 7.66, cumulatedCredits: 159.06 },
                { total: 29.43, target: 9.81, creditsMonthly: 7.8, cumulatedCredits: 166.86 },
                { total: 29.67, target: 9.89, creditsMonthly: 7.8, cumulatedCredits: 174.66 },
                { total: 29.91, target: 9.97, creditsMonthly: 7.8, cumulatedCredits: 182.46 },
                { total: 30.15, target: 10.05, creditsMonthly: 7.8, cumulatedCredits: 190.26 },
                { total: 30.38, target: 10.13, creditsMonthly: 8, cumulatedCredits: 198.26 },
                { total: 30.61, target: 10.2, creditsMonthly: 8.1, cumulatedCredits: 206.36 },
                { total: 30.86, target: 10.29, creditsMonthly: 8.1, cumulatedCredits: 214.46 },
                { total: 35.13, target: 11.71, creditsMonthly: 8.66, cumulatedCredits: 223.12 },
                { total: 35.4, target: 11.8, creditsMonthly: 9.3, cumulatedCredits: 232.42 },
                { total: 35.68, target: 11.89, creditsMonthly: 9.3, cumulatedCredits: 241.72 },
                { total: 35.95, target: 11.98, creditsMonthly: 9.34, cumulatedCredits: 251.06 },
                { total: 36.23, target: 12.08, creditsMonthly: 9.6, cumulatedCredits: 260.66 },
                { total: 36.53, target: 12.18, creditsMonthly: 9.6, cumulatedCredits: 270.26 },
                { total: 36.82, target: 12.27, creditsMonthly: 9.6, cumulatedCredits: 279.86 },
                { total: 37.1, target: 12.37, creditsMonthly: 9.65, cumulatedCredits: 289.51 },
                { total: 37.39, target: 12.46, creditsMonthly: 9.9, cumulatedCredits: 299.41 },
                { total: 37.69, target: 12.56, creditsMonthly: 9.9, cumulatedCredits: 309.31 },
                { total: 37.99, target: 12.66, creditsMonthly: 9.9, cumulatedCredits: 319.21 },
                { total: 38.28, target: 12.76, creditsMonthly: 9.99, cumulatedCredits: 329.2 },
                { total: 42.59, target: 14.2, creditsMonthly: 10.47, cumulatedCredits: 339.67 },
                { total: 42.94, target: 14.31, creditsMonthly: 11.26, cumulatedCredits: 350.93 },
                { total: 43.26, target: 14.42, creditsMonthly: 11.4, cumulatedCredits: 362.33 },
                { total: 43.61, target: 14.54, creditsMonthly: 11.4, cumulatedCredits: 373.73 },
                { total: 43.95, target: 14.65, creditsMonthly: 11.44, cumulatedCredits: 385.17 },
                { total: 44.29, target: 14.76, creditsMonthly: 11.7, cumulatedCredits: 396.87 },
                { total: 44.65, target: 14.88, creditsMonthly: 11.7, cumulatedCredits: 408.57 },
                { total: 45, target: 15, creditsMonthly: 11.7, cumulatedCredits: 420.27 },
                { total: 45.36, target: 15.12, creditsMonthly: 11.96, cumulatedCredits: 432.23 },
                { total: 45.71, target: 15.24, creditsMonthly: 12, cumulatedCredits: 444.23 },
                { total: 46.07, target: 15.36, creditsMonthly: 12, cumulatedCredits: 456.23 },
                { total: 46.44, target: 15.48, creditsMonthly: 12.2, cumulatedCredits: 468.43 },
            ],
        },
        'Nutanix AHV': {
            averageDedupRatio: 1,
            monthlyData: [
                { total: 50.2, target: 50.2, creditsMonthly: 18.87, cumulatedCredits: 18.87 },
                { total: 50.59, target: 50.59, creditsMonthly: 24.9, cumulatedCredits: 43.77 },
                { total: 50.99, target: 50.99, creditsMonthly: 25.05, cumulatedCredits: 68.82 },
                { total: 51.39, target: 51.39, creditsMonthly: 25.2, cumulatedCredits: 94.02 },
                { total: 51.79, target: 51.79, creditsMonthly: 25.49, cumulatedCredits: 119.51 },
                { total: 52.2, target: 52.2, creditsMonthly: 25.64, cumulatedCredits: 145.15 },
                { total: 52.61, target: 52.61, creditsMonthly: 25.8, cumulatedCredits: 170.95 },
                { total: 53.03, target: 53.03, creditsMonthly: 26.1, cumulatedCredits: 197.05 },
                { total: 53.44, target: 53.44, creditsMonthly: 26.26, cumulatedCredits: 223.31 },
                { total: 53.86, target: 53.86, creditsMonthly: 26.42, cumulatedCredits: 249.73 },
                { total: 54.29, target: 54.29, creditsMonthly: 26.7, cumulatedCredits: 276.43 },
                { total: 54.71, target: 54.71, creditsMonthly: 26.89, cumulatedCredits: 303.32 },
                { total: 55.14, target: 55.14, creditsMonthly: 27.07, cumulatedCredits: 330.39 },
                { total: 55.58, target: 55.58, creditsMonthly: 27.3, cumulatedCredits: 357.69 },
                { total: 56.01, target: 56.01, creditsMonthly: 27.54, cumulatedCredits: 385.23 },
                { total: 56.46, target: 56.46, creditsMonthly: 27.73, cumulatedCredits: 412.96 },
                { total: 56.9, target: 56.9, creditsMonthly: 27.92, cumulatedCredits: 440.88 },
                { total: 57.35, target: 57.35, creditsMonthly: 28.2, cumulatedCredits: 469.08 },
                { total: 57.8, target: 57.8, creditsMonthly: 28.41, cumulatedCredits: 497.49 },
                { total: 58.25, target: 58.25, creditsMonthly: 28.61, cumulatedCredits: 526.1 },
                { total: 58.71, target: 58.71, creditsMonthly: 28.81, cumulatedCredits: 554.91 },
                { total: 59.17, target: 59.17, creditsMonthly: 29.1, cumulatedCredits: 584.01 },
                { total: 59.64, target: 59.64, creditsMonthly: 29.31, cumulatedCredits: 613.32 },
                { total: 60.11, target: 60.11, creditsMonthly: 29.52, cumulatedCredits: 642.84 },
                { total: 60.58, target: 60.58, creditsMonthly: 29.74, cumulatedCredits: 672.58 },
                { total: 61.06, target: 61.06, creditsMonthly: 30, cumulatedCredits: 702.58 },
                { total: 61.54, target: 61.54, creditsMonthly: 30.26, cumulatedCredits: 732.84 },
                { total: 62.02, target: 62.02, creditsMonthly: 30.47, cumulatedCredits: 763.31 },
                { total: 62.51, target: 62.51, creditsMonthly: 30.7, cumulatedCredits: 794.01 },
                { total: 63, target: 63, creditsMonthly: 30.93, cumulatedCredits: 824.94 },
                { total: 63.49, target: 63.49, creditsMonthly: 31.2, cumulatedCredits: 856.14 },
                { total: 63.99, target: 63.99, creditsMonthly: 31.46, cumulatedCredits: 887.6 },
                { total: 64.5, target: 64.5, creditsMonthly: 31.7, cumulatedCredits: 919.3 },
                { total: 65, target: 65, creditsMonthly: 31.93, cumulatedCredits: 951.23 },
                { total: 65.52, target: 65.52, creditsMonthly: 32.18, cumulatedCredits: 983.41 },
                { total: 66.03, target: 66.03, creditsMonthly: 32.42, cumulatedCredits: 1015.83 },
                { total: 66.55, target: 66.55, creditsMonthly: 32.7, cumulatedCredits: 1048.53 },
                { total: 67.07, target: 67.07, creditsMonthly: 32.97, cumulatedCredits: 1081.5 },
                { total: 67.6, target: 67.6, creditsMonthly: 33.22, cumulatedCredits: 1114.72 },
                { total: 68.13, target: 68.13, creditsMonthly: 33.48, cumulatedCredits: 1148.2 },
                { total: 68.67, target: 68.67, creditsMonthly: 33.74, cumulatedCredits: 1181.94 },
                { total: 69.21, target: 69.21, creditsMonthly: 34, cumulatedCredits: 1215.94 },
                { total: 69.75, target: 69.75, creditsMonthly: 34.26, cumulatedCredits: 1250.2 },
                { total: 70.3, target: 70.3, creditsMonthly: 34.52, cumulatedCredits: 1284.72 },
                { total: 70.85, target: 70.85, creditsMonthly: 34.8, cumulatedCredits: 1319.52 },
                { total: 71.41, target: 71.41, creditsMonthly: 35.09, cumulatedCredits: 1354.61 },
                { total: 71.97, target: 71.97, creditsMonthly: 35.37, cumulatedCredits: 1389.98 },
                { total: 72.54, target: 72.54, creditsMonthly: 35.65, cumulatedCredits: 1425.63 },
                { total: 73.11, target: 73.11, creditsMonthly: 35.92, cumulatedCredits: 1461.55 },
                { total: 73.68, target: 73.68, creditsMonthly: 36.2, cumulatedCredits: 1497.75 },
                { total: 74.26, target: 74.26, creditsMonthly: 36.49, cumulatedCredits: 1534.24 },
                { total: 74.85, target: 74.85, creditsMonthly: 36.77, cumulatedCredits: 1571.01 },
                { total: 75.44, target: 75.44, creditsMonthly: 37.06, cumulatedCredits: 1608.07 },
                { total: 76.03, target: 76.03, creditsMonthly: 37.35, cumulatedCredits: 1645.42 },
                { total: 76.63, target: 76.63, creditsMonthly: 37.65, cumulatedCredits: 1683.07 },
                { total: 77.23, target: 77.23, creditsMonthly: 37.94, cumulatedCredits: 1721.01 },
                { total: 77.84, target: 77.84, creditsMonthly: 38.24, cumulatedCredits: 1759.25 },
                { total: 78.45, target: 78.45, creditsMonthly: 38.54, cumulatedCredits: 1797.79 },
                { total: 79.07, target: 79.07, creditsMonthly: 38.85, cumulatedCredits: 1836.64 },
                { total: 79.69, target: 79.69, creditsMonthly: 39.15, cumulatedCredits: 1875.79 },
            ],
        },
        Oracle: {
            averageDedupRatio: 1.3000719165767711,
            monthlyData: [
                { total: 24.09, target: 18.53, creditsMonthly: 10.34, cumulatedCredits: 10.34 },
                { total: 24.28, target: 18.68, creditsMonthly: 14.7, cumulatedCredits: 25.04 },
                { total: 26.1, target: 20.08, creditsMonthly: 15.79, cumulatedCredits: 40.83 },
                { total: 27.92, target: 21.48, creditsMonthly: 16.77, cumulatedCredits: 57.6 },
                { total: 28.15, target: 21.65, creditsMonthly: 17.09, cumulatedCredits: 74.69 },
                { total: 28.37, target: 21.82, creditsMonthly: 17.1, cumulatedCredits: 91.79 },
                { total: 28.58, target: 21.98, creditsMonthly: 17.31, cumulatedCredits: 109.1 },
                { total: 28.81, target: 22.16, creditsMonthly: 17.4, cumulatedCredits: 126.5 },
                { total: 29.04, target: 22.34, creditsMonthly: 17.57, cumulatedCredits: 144.07 },
                { total: 29.27, target: 22.52, creditsMonthly: 17.7, cumulatedCredits: 161.77 },
                { total: 29.49, target: 22.68, creditsMonthly: 17.83, cumulatedCredits: 179.6 },
                { total: 29.73, target: 22.87, creditsMonthly: 18, cumulatedCredits: 197.6 },
                { total: 37.32, target: 28.71, creditsMonthly: 21.61, cumulatedCredits: 219.21 },
                { total: 37.62, target: 28.94, creditsMonthly: 22.8, cumulatedCredits: 242.01 },
                { total: 37.92, target: 29.17, creditsMonthly: 22.91, cumulatedCredits: 264.92 },
                { total: 38.22, target: 29.4, creditsMonthly: 23.1, cumulatedCredits: 288.02 },
                { total: 38.51, target: 29.62, creditsMonthly: 23.31, cumulatedCredits: 311.33 },
                { total: 38.81, target: 29.85, creditsMonthly: 23.41, cumulatedCredits: 334.74 },
                { total: 39.12, target: 30.09, creditsMonthly: 23.7, cumulatedCredits: 358.44 },
                { total: 39.44, target: 30.34, creditsMonthly: 23.84, cumulatedCredits: 382.28 },
                { total: 39.74, target: 30.57, creditsMonthly: 24, cumulatedCredits: 406.28 },
                { total: 40.06, target: 30.82, creditsMonthly: 24.27, cumulatedCredits: 430.55 },
                { total: 40.36, target: 31.05, creditsMonthly: 24.39, cumulatedCredits: 454.94 },
                { total: 40.69, target: 31.3, creditsMonthly: 24.6, cumulatedCredits: 479.54 },
                { total: 48.34, target: 37.18, creditsMonthly: 27.61, cumulatedCredits: 507.15 },
                { total: 48.71, target: 37.47, creditsMonthly: 29.42, cumulatedCredits: 536.57 },
                { total: 49.1, target: 37.77, creditsMonthly: 29.7, cumulatedCredits: 566.27 },
                { total: 49.5, target: 38.08, creditsMonthly: 29.94, cumulatedCredits: 596.21 },
                { total: 49.87, target: 38.36, creditsMonthly: 30.16, cumulatedCredits: 626.37 },
                { total: 50.27, target: 38.67, creditsMonthly: 30.38, cumulatedCredits: 656.75 },
                { total: 50.66, target: 38.97, creditsMonthly: 30.6, cumulatedCredits: 687.35 },
                { total: 51.06, target: 39.28, creditsMonthly: 30.89, cumulatedCredits: 718.24 },
                { total: 51.48, target: 39.6, creditsMonthly: 31.12, cumulatedCredits: 749.36 },
                { total: 51.87, target: 39.9, creditsMonthly: 31.36, cumulatedCredits: 780.72 },
                { total: 52.27, target: 40.21, creditsMonthly: 31.6, cumulatedCredits: 812.32 },
                { total: 52.68, target: 40.52, creditsMonthly: 31.83, cumulatedCredits: 844.15 },
                { total: 60.39, target: 46.45, creditsMonthly: 34.2, cumulatedCredits: 878.35 },
                { total: 60.87, target: 46.82, creditsMonthly: 36.81, cumulatedCredits: 915.16 },
                { total: 61.34, target: 47.18, creditsMonthly: 37.1, cumulatedCredits: 952.26 },
                { total: 61.83, target: 47.56, creditsMonthly: 37.39, cumulatedCredits: 989.65 },
                { total: 62.32, target: 47.94, creditsMonthly: 37.69, cumulatedCredits: 1027.34 },
                { total: 62.81, target: 48.32, creditsMonthly: 37.97, cumulatedCredits: 1065.31 },
                { total: 63.3, target: 48.69, creditsMonthly: 38.27, cumulatedCredits: 1103.58 },
                { total: 63.79, target: 49.07, creditsMonthly: 38.57, cumulatedCredits: 1142.15 },
                { total: 64.3, target: 49.46, creditsMonthly: 38.88, cumulatedCredits: 1181.03 },
                { total: 64.79, target: 49.84, creditsMonthly: 39.18, cumulatedCredits: 1220.21 },
                { total: 65.31, target: 50.24, creditsMonthly: 39.49, cumulatedCredits: 1259.7 },
                { total: 65.83, target: 50.64, creditsMonthly: 39.8, cumulatedCredits: 1299.5 },
                { total: 66.34, target: 51.03, creditsMonthly: 40.11, cumulatedCredits: 1339.61 },
                { total: 66.87, target: 51.44, creditsMonthly: 40.43, cumulatedCredits: 1380.04 },
                { total: 67.4, target: 51.85, creditsMonthly: 40.75, cumulatedCredits: 1420.79 },
                { total: 67.91, target: 52.24, creditsMonthly: 41.07, cumulatedCredits: 1461.86 },
                { total: 68.46, target: 52.66, creditsMonthly: 41.4, cumulatedCredits: 1503.26 },
                { total: 68.99, target: 53.07, creditsMonthly: 41.73, cumulatedCredits: 1544.99 },
                { total: 69.54, target: 53.49, creditsMonthly: 42.06, cumulatedCredits: 1587.05 },
                { total: 70.08, target: 53.91, creditsMonthly: 42.39, cumulatedCredits: 1629.44 },
                { total: 70.63, target: 54.33, creditsMonthly: 42.73, cumulatedCredits: 1672.17 },
                { total: 71.18, target: 54.75, creditsMonthly: 43.05, cumulatedCredits: 1715.22 },
                { total: 71.75, target: 55.19, creditsMonthly: 43.38, cumulatedCredits: 1758.6 },
                { total: 72.31, target: 55.62, creditsMonthly: 43.71, cumulatedCredits: 1802.31 },
            ],
        },
        'SAP HANA': {
            averageDedupRatio: 1.3,
            monthlyData: [
                { total: 12.04, target: 9.26, creditsMonthly: 6.5, cumulatedCredits: 6.5 },
                { total: 12.14, target: 9.34, creditsMonthly: 9.25, cumulatedCredits: 15.75 },
                { total: 13.05, target: 10.04, creditsMonthly: 9.88, cumulatedCredits: 25.63 },
                { total: 13.97, target: 10.75, creditsMonthly: 10.48, cumulatedCredits: 36.11 },
                { total: 14.07, target: 10.82, creditsMonthly: 10.59, cumulatedCredits: 46.7 },
                { total: 14.19, target: 10.92, creditsMonthly: 10.8, cumulatedCredits: 57.5 },
                { total: 14.29, target: 10.99, creditsMonthly: 10.8, cumulatedCredits: 68.3 },
                { total: 14.41, target: 11.08, creditsMonthly: 10.8, cumulatedCredits: 79.1 },
                { total: 14.52, target: 11.17, creditsMonthly: 11.04, cumulatedCredits: 90.14 },
                { total: 14.64, target: 11.26, creditsMonthly: 11.1, cumulatedCredits: 101.24 },
                { total: 14.75, target: 11.35, creditsMonthly: 11.1, cumulatedCredits: 112.34 },
                { total: 14.87, target: 11.44, creditsMonthly: 11.2, cumulatedCredits: 123.54 },
                { total: 18.66, target: 14.35, creditsMonthly: 13.56, cumulatedCredits: 137.1 },
                { total: 18.81, target: 14.47, creditsMonthly: 14.16, cumulatedCredits: 151.26 },
                { total: 18.96, target: 14.58, creditsMonthly: 14.4, cumulatedCredits: 165.66 },
                { total: 19.11, target: 14.7, creditsMonthly: 14.4, cumulatedCredits: 180.06 },
                { total: 19.26, target: 14.82, creditsMonthly: 14.53, cumulatedCredits: 194.59 },
                { total: 19.41, target: 14.93, creditsMonthly: 14.7, cumulatedCredits: 209.29 },
                { total: 19.57, target: 15.05, creditsMonthly: 14.7, cumulatedCredits: 223.99 },
                { total: 19.72, target: 15.17, creditsMonthly: 14.99, cumulatedCredits: 238.98 },
                { total: 19.86, target: 15.28, creditsMonthly: 15, cumulatedCredits: 253.98 },
                { total: 20.02, target: 15.4, creditsMonthly: 15.1, cumulatedCredits: 269.08 },
                { total: 20.18, target: 15.52, creditsMonthly: 15.3, cumulatedCredits: 284.38 },
                { total: 20.34, target: 15.65, creditsMonthly: 15.3, cumulatedCredits: 299.68 },
                { total: 24.17, target: 18.59, creditsMonthly: 17.27, cumulatedCredits: 316.95 },
                { total: 24.36, target: 18.74, creditsMonthly: 18.37, cumulatedCredits: 335.32 },
                { total: 24.55, target: 18.88, creditsMonthly: 18.6, cumulatedCredits: 353.92 },
                { total: 24.74, target: 19.03, creditsMonthly: 18.64, cumulatedCredits: 372.56 },
                { total: 24.93, target: 19.18, creditsMonthly: 18.9, cumulatedCredits: 391.46 },
                { total: 25.13, target: 19.33, creditsMonthly: 18.95, cumulatedCredits: 410.41 },
                { total: 25.34, target: 19.49, creditsMonthly: 19.2, cumulatedCredits: 429.61 },
                { total: 25.54, target: 19.65, creditsMonthly: 19.28, cumulatedCredits: 448.89 },
                { total: 25.73, target: 19.79, creditsMonthly: 19.5, cumulatedCredits: 468.39 },
                { total: 25.93, target: 19.95, creditsMonthly: 19.55, cumulatedCredits: 487.94 },
                { total: 26.13, target: 20.1, creditsMonthly: 19.8, cumulatedCredits: 507.74 },
                { total: 26.36, target: 20.28, creditsMonthly: 19.87, cumulatedCredits: 527.61 },
                { total: 30.2, target: 23.23, creditsMonthly: 21.36, cumulatedCredits: 548.97 },
                { total: 30.42, target: 23.4, creditsMonthly: 23.05, cumulatedCredits: 572.02 },
                { total: 30.68, target: 23.6, creditsMonthly: 23.15, cumulatedCredits: 595.17 },
                { total: 30.91, target: 23.78, creditsMonthly: 23.4, cumulatedCredits: 618.57 },
                { total: 31.16, target: 23.97, creditsMonthly: 23.54, cumulatedCredits: 642.11 },
                { total: 31.4, target: 24.15, creditsMonthly: 23.7, cumulatedCredits: 665.81 },
                { total: 31.64, target: 24.34, creditsMonthly: 23.95, cumulatedCredits: 689.76 },
                { total: 31.89, target: 24.53, creditsMonthly: 24.08, cumulatedCredits: 713.84 },
                { total: 32.14, target: 24.72, creditsMonthly: 24.3, cumulatedCredits: 738.14 },
                { total: 32.41, target: 24.93, creditsMonthly: 24.51, cumulatedCredits: 762.65 },
                { total: 32.64, target: 25.11, creditsMonthly: 24.63, cumulatedCredits: 787.28 },
                { total: 32.92, target: 25.32, creditsMonthly: 24.9, cumulatedCredits: 812.18 },
                { total: 33.17, target: 25.52, creditsMonthly: 25.08, cumulatedCredits: 837.26 },
                { total: 33.44, target: 25.72, creditsMonthly: 25.24, cumulatedCredits: 862.5 },
                { total: 33.7, target: 25.92, creditsMonthly: 25.5, cumulatedCredits: 888 },
                { total: 33.97, target: 26.13, creditsMonthly: 25.68, cumulatedCredits: 913.68 },
                { total: 34.23, target: 26.33, creditsMonthly: 25.82, cumulatedCredits: 939.5 },
                { total: 34.5, target: 26.54, creditsMonthly: 26.1, cumulatedCredits: 965.6 },
                { total: 34.77, target: 26.75, creditsMonthly: 26.28, cumulatedCredits: 991.88 },
                { total: 35.05, target: 26.96, creditsMonthly: 26.46, cumulatedCredits: 1018.34 },
                { total: 35.33, target: 27.18, creditsMonthly: 26.7, cumulatedCredits: 1045.04 },
                { total: 35.59, target: 27.38, creditsMonthly: 26.92, cumulatedCredits: 1071.96 },
                { total: 35.88, target: 27.6, creditsMonthly: 27.1, cumulatedCredits: 1099.06 },
                { total: 36.16, target: 27.82, creditsMonthly: 27.3, cumulatedCredits: 1126.36 },
            ],
        },
        VMware: {
            averageDedupRatio: 2.5,
            monthlyData: [
                { total: 80.73, target: 32.29, creditsMonthly: 12.54, cumulatedCredits: 12.54 },
                { total: 84.64, target: 33.86, creditsMonthly: 26.55, cumulatedCredits: 39.09 },
                { total: 90.98, target: 36.39, creditsMonthly: 28.53, cumulatedCredits: 67.62 },
                { total: 97.34, target: 38.94, creditsMonthly: 30.54, cumulatedCredits: 98.16 },
                { total: 98.12, target: 39.25, creditsMonthly: 30.87, cumulatedCredits: 129.03 },
                { total: 98.89, target: 39.56, creditsMonthly: 31.1, cumulatedCredits: 160.13 },
                { total: 99.66, target: 39.86, creditsMonthly: 31.33, cumulatedCredits: 191.46 },
                { total: 100.45, target: 40.18, creditsMonthly: 31.57, cumulatedCredits: 223.03 },
                { total: 101.23, target: 40.49, creditsMonthly: 31.8, cumulatedCredits: 254.83 },
                { total: 102.03, target: 40.81, creditsMonthly: 32.1, cumulatedCredits: 286.93 },
                { total: 102.83, target: 41.13, creditsMonthly: 32.35, cumulatedCredits: 319.28 },
                { total: 103.64, target: 41.46, creditsMonthly: 32.59, cumulatedCredits: 351.87 },
                { total: 130.14, target: 52.06, creditsMonthly: 39.3, cumulatedCredits: 391.17 },
                { total: 131.14, target: 52.46, creditsMonthly: 41.24, cumulatedCredits: 432.41 },
                { total: 132.18, target: 52.87, creditsMonthly: 41.56, cumulatedCredits: 473.97 },
                { total: 133.21, target: 53.28, creditsMonthly: 41.89, cumulatedCredits: 515.86 },
                { total: 134.27, target: 53.71, creditsMonthly: 42.22, cumulatedCredits: 558.08 },
                { total: 135.33, target: 54.13, creditsMonthly: 42.54, cumulatedCredits: 600.62 },
                { total: 136.39, target: 54.56, creditsMonthly: 42.88, cumulatedCredits: 643.5 },
                { total: 137.46, target: 54.98, creditsMonthly: 43.23, cumulatedCredits: 686.73 },
                { total: 138.53, target: 55.41, creditsMonthly: 43.57, cumulatedCredits: 730.3 },
                { total: 139.63, target: 55.85, creditsMonthly: 43.91, cumulatedCredits: 774.21 },
                { total: 140.73, target: 56.29, creditsMonthly: 44.25, cumulatedCredits: 818.46 },
                { total: 141.84, target: 56.74, creditsMonthly: 44.59, cumulatedCredits: 863.05 },
                { total: 168.51, target: 67.4, creditsMonthly: 50.04, cumulatedCredits: 913.09 },
                { total: 169.84, target: 67.94, creditsMonthly: 53.4, cumulatedCredits: 966.49 },
                { total: 171.17, target: 68.47, creditsMonthly: 53.83, cumulatedCredits: 1020.32 },
                { total: 172.53, target: 69.01, creditsMonthly: 54.23, cumulatedCredits: 1074.55 },
                { total: 173.88, target: 69.55, creditsMonthly: 54.69, cumulatedCredits: 1129.24 },
                { total: 175.25, target: 70.1, creditsMonthly: 55.09, cumulatedCredits: 1184.33 },
                { total: 176.62, target: 70.65, creditsMonthly: 55.55, cumulatedCredits: 1239.88 },
                { total: 178.01, target: 71.2, creditsMonthly: 55.97, cumulatedCredits: 1295.85 },
                { total: 179.42, target: 71.77, creditsMonthly: 56.42, cumulatedCredits: 1352.27 },
                { total: 180.83, target: 72.33, creditsMonthly: 56.86, cumulatedCredits: 1409.13 },
                { total: 182.24, target: 72.9, creditsMonthly: 57.3, cumulatedCredits: 1466.43 },
                { total: 183.68, target: 73.47, creditsMonthly: 57.75, cumulatedCredits: 1524.18 },
                { total: 210.54, target: 84.22, creditsMonthly: 61.94, cumulatedCredits: 1586.12 },
                { total: 212.2, target: 84.88, creditsMonthly: 66.73, cumulatedCredits: 1652.85 },
                { total: 213.87, target: 85.55, creditsMonthly: 67.25, cumulatedCredits: 1720.1 },
                { total: 215.55, target: 86.22, creditsMonthly: 67.77, cumulatedCredits: 1787.87 },
                { total: 217.24, target: 86.9, creditsMonthly: 68.29, cumulatedCredits: 1856.16 },
                { total: 218.95, target: 87.58, creditsMonthly: 68.85, cumulatedCredits: 1925.01 },
                { total: 220.67, target: 88.27, creditsMonthly: 69.39, cumulatedCredits: 1994.4 },
                { total: 222.41, target: 88.96, creditsMonthly: 69.94, cumulatedCredits: 2064.34 },
                { total: 224.17, target: 89.67, creditsMonthly: 70.48, cumulatedCredits: 2134.82 },
                { total: 225.93, target: 90.37, creditsMonthly: 71.04, cumulatedCredits: 2205.86 },
                { total: 227.7, target: 91.08, creditsMonthly: 71.59, cumulatedCredits: 2277.45 },
                { total: 229.49, target: 91.8, creditsMonthly: 72.15, cumulatedCredits: 2349.6 },
                { total: 231.3, target: 92.52, creditsMonthly: 72.72, cumulatedCredits: 2422.32 },
                { total: 233.1, target: 93.24, creditsMonthly: 73.3, cumulatedCredits: 2495.62 },
                { total: 234.94, target: 93.98, creditsMonthly: 73.87, cumulatedCredits: 2569.49 },
                { total: 236.79, target: 94.72, creditsMonthly: 74.45, cumulatedCredits: 2643.94 },
                { total: 238.66, target: 95.46, creditsMonthly: 75.04, cumulatedCredits: 2718.98 },
                { total: 240.54, target: 96.22, creditsMonthly: 75.63, cumulatedCredits: 2794.61 },
                { total: 242.42, target: 96.97, creditsMonthly: 76.22, cumulatedCredits: 2870.83 },
                { total: 244.33, target: 97.73, creditsMonthly: 76.83, cumulatedCredits: 2947.66 },
                { total: 246.26, target: 98.5, creditsMonthly: 77.43, cumulatedCredits: 3025.09 },
                { total: 248.19, target: 99.28, creditsMonthly: 78.03, cumulatedCredits: 3103.12 },
                { total: 250.14, target: 100.06, creditsMonthly: 78.65, cumulatedCredits: 3181.77 },
                { total: 252.12, target: 100.85, creditsMonthly: 79.27, cumulatedCredits: 3261.04 },
            ],
        },
        Windows: {
            averageDedupRatio: 3,
            monthlyData: [
                { total: 36.13, target: 12.04, creditsMonthly: 6.75, cumulatedCredits: 6.75 },
                { total: 36.41, target: 12.14, creditsMonthly: 9.6, cumulatedCredits: 16.35 },
                { total: 39.14, target: 13.05, creditsMonthly: 10.18, cumulatedCredits: 26.53 },
                { total: 41.88, target: 13.96, creditsMonthly: 11.02, cumulatedCredits: 37.55 },
                { total: 42.21, target: 14.07, creditsMonthly: 11.1, cumulatedCredits: 48.65 },
                { total: 42.55, target: 14.18, creditsMonthly: 11.1, cumulatedCredits: 59.75 },
                { total: 42.88, target: 14.29, creditsMonthly: 11.22, cumulatedCredits: 70.97 },
                { total: 43.23, target: 14.41, creditsMonthly: 11.4, cumulatedCredits: 82.37 },
                { total: 43.56, target: 14.52, creditsMonthly: 11.4, cumulatedCredits: 93.77 },
                { total: 43.91, target: 14.64, creditsMonthly: 11.41, cumulatedCredits: 105.18 },
                { total: 44.24, target: 14.75, creditsMonthly: 11.7, cumulatedCredits: 116.88 },
                { total: 44.6, target: 14.87, creditsMonthly: 11.7, cumulatedCredits: 128.58 },
                { total: 55.98, target: 18.66, creditsMonthly: 14.1, cumulatedCredits: 142.68 },
                { total: 56.43, target: 18.81, creditsMonthly: 14.7, cumulatedCredits: 157.38 },
                { total: 56.88, target: 18.96, creditsMonthly: 14.97, cumulatedCredits: 172.35 },
                { total: 57.32, target: 19.11, creditsMonthly: 15, cumulatedCredits: 187.35 },
                { total: 57.76, target: 19.25, creditsMonthly: 15.1, cumulatedCredits: 202.45 },
                { total: 58.22, target: 19.41, creditsMonthly: 15.3, cumulatedCredits: 217.75 },
                { total: 58.69, target: 19.56, creditsMonthly: 15.3, cumulatedCredits: 233.05 },
                { total: 59.14, target: 19.71, creditsMonthly: 15.55, cumulatedCredits: 248.6 },
                { total: 59.61, target: 19.87, creditsMonthly: 15.6, cumulatedCredits: 264.2 },
                { total: 60.08, target: 20.03, creditsMonthly: 15.72, cumulatedCredits: 279.92 },
                { total: 60.54, target: 20.18, creditsMonthly: 15.9, cumulatedCredits: 295.82 },
                { total: 61.02, target: 20.34, creditsMonthly: 15.9, cumulatedCredits: 311.72 },
                { total: 72.51, target: 24.17, creditsMonthly: 17.95, cumulatedCredits: 329.67 },
                { total: 73.07, target: 24.36, creditsMonthly: 19.2, cumulatedCredits: 348.87 },
                { total: 73.64, target: 24.55, creditsMonthly: 19.24, cumulatedCredits: 368.11 },
                { total: 74.23, target: 24.74, creditsMonthly: 19.5, cumulatedCredits: 387.61 },
                { total: 74.8, target: 24.93, creditsMonthly: 19.56, cumulatedCredits: 407.17 },
                { total: 75.39, target: 25.13, creditsMonthly: 19.8, cumulatedCredits: 426.97 },
                { total: 75.99, target: 25.33, creditsMonthly: 19.88, cumulatedCredits: 446.85 },
                { total: 76.61, target: 25.54, creditsMonthly: 20.1, cumulatedCredits: 466.95 },
                { total: 77.2, target: 25.73, creditsMonthly: 20.2, cumulatedCredits: 487.15 },
                { total: 77.8, target: 25.93, creditsMonthly: 20.4, cumulatedCredits: 507.55 },
                { total: 78.41, target: 26.14, creditsMonthly: 20.55, cumulatedCredits: 528.1 },
                { total: 79.03, target: 26.34, creditsMonthly: 20.7, cumulatedCredits: 548.8 },
                { total: 90.59, target: 30.2, creditsMonthly: 22.14, cumulatedCredits: 570.94 },
                { total: 91.29, target: 30.43, creditsMonthly: 23.97, cumulatedCredits: 594.91 },
                { total: 92.02, target: 30.67, creditsMonthly: 24.09, cumulatedCredits: 619 },
                { total: 92.74, target: 30.91, creditsMonthly: 24.3, cumulatedCredits: 643.3 },
                { total: 93.48, target: 31.16, creditsMonthly: 24.52, cumulatedCredits: 667.82 },
                { total: 94.21, target: 31.4, creditsMonthly: 24.65, cumulatedCredits: 692.47 },
                { total: 94.94, target: 31.65, creditsMonthly: 24.9, cumulatedCredits: 717.37 },
                { total: 95.7, target: 31.9, creditsMonthly: 25.09, cumulatedCredits: 742.46 },
                { total: 96.44, target: 32.15, creditsMonthly: 25.23, cumulatedCredits: 767.69 },
                { total: 97.21, target: 32.4, creditsMonthly: 25.5, cumulatedCredits: 793.19 },
                { total: 97.97, target: 32.66, creditsMonthly: 25.68, cumulatedCredits: 818.87 },
                { total: 98.74, target: 32.91, creditsMonthly: 25.84, cumulatedCredits: 844.71 },
                { total: 99.52, target: 33.17, creditsMonthly: 26.1, cumulatedCredits: 870.81 },
                { total: 100.31, target: 33.44, creditsMonthly: 26.3, cumulatedCredits: 897.11 },
                { total: 101.09, target: 33.7, creditsMonthly: 26.46, cumulatedCredits: 923.57 },
                { total: 101.89, target: 33.96, creditsMonthly: 26.7, cumulatedCredits: 950.27 },
                { total: 102.69, target: 34.23, creditsMonthly: 26.93, cumulatedCredits: 977.2 },
                { total: 103.49, target: 34.5, creditsMonthly: 27.11, cumulatedCredits: 1004.31 },
                { total: 104.3, target: 34.77, creditsMonthly: 27.3, cumulatedCredits: 1031.61 },
                { total: 105.14, target: 35.05, creditsMonthly: 27.59, cumulatedCredits: 1059.2 },
                { total: 105.95, target: 35.32, creditsMonthly: 27.77, cumulatedCredits: 1086.97 },
                { total: 106.78, target: 35.59, creditsMonthly: 27.96, cumulatedCredits: 1114.93 },
                { total: 107.63, target: 35.88, creditsMonthly: 28.2, cumulatedCredits: 1143.13 },
                { total: 108.46, target: 36.15, creditsMonthly: 28.45, cumulatedCredits: 1171.58 },
            ],
        },
    },
};
