import { useEffect, useState } from 'react';
import { useRootState } from 'store/StateProvider';
import { SIZER_V2_BASE_URL, SIZING_STATUS_PROCESSING } from 'views/constants';
import { SizingStatusResponse } from 'types';
import { useAPIQuery } from '../useAPIQuery';

const useGetSizingStatus = (enabled = false) => {
    const {
        deploymentConfig: {
            directorySvcResponse: { endpoint, protocol },
        },
        sourcesData: {
            customerDetails: { selectedProductCustomerId, sizingId },
        },
        deploymentConfig: { envData },
    } = useRootState();
    const [refetchInterval, setRefetchInterval] = useState(true);

    const url = `${SIZER_V2_BASE_URL}/sizing/${sizingId}/status`;
    const method = 'GET';

    const query = useAPIQuery<null, { result: SizingStatusResponse; statusCode: number; success: boolean }>({
        key: ['sizingStatus', sizingId],
        method,
        url,
        baseUrl: protocol ? `${protocol}://${endpoint}` : envData.MAINLINE_DEP0_API_GATEWAY_URL,
        enabled,
        customHeaders: {
            productID: 12289,
            ...(selectedProductCustomerId ? { productCustomerID: selectedProductCustomerId } : {}),
        },
        options: {
            refetchInterval: refetchInterval ? 10000 : false,
        },
    });
    useEffect(() => {
        setRefetchInterval(true);
    }, [sizingId]);
    useEffect(() => {
        if (query?.data?.success && enabled) {
            if (query.data.result?.sizingStatus !== SIZING_STATUS_PROCESSING) {
                setRefetchInterval(false);
            }
        }
        if (query.isError || query?.data?.success === false) {
            setRefetchInterval(false);
        }
    }, [query.data, query.isError, enabled]);
    return query;
};

export default useGetSizingStatus;
