import { DeploymentConfigResponse } from 'types';

export const deploymentConfigInitialState: DeploymentConfigResponse = {
    envData: {
        USER_POOL_ID: '',
        USER_POOL_CLIENT_ID: '',
        COGNITO_DOMAIN: '',
        SIGN_IN_URL: '',
        CLOUD_NAME_DIRECTORY_SVC_BASE_URL_MAP: '',
        OKTA_IDENTITY_PROVIDER_NAME: '',
        PING_IDENTITY_PROVIDER_NAME: '',
        MAINLINE_DEP0_API_GATEWAY_URL: '',
    },
    globalApiUrlCloudNameMap: {},
    directorySvcResponse: {
        serviceName: '',
        protocol: '',
        endpoint: '',
        port: null,
        deploymentID: null,
    },
};
