import React, { useEffect, useState } from 'react';
import Table from 'generic/Table';
import 'views/ResultsTabs/Summary/SourceSummaryTable/SourceSummaryTable.scss';
import SectionLabel from 'components/SectionLabel/SectionLabel';
import { useRootState } from 'store/StateProvider';
import { useDialogContext } from 'config/context/dialogContext';
import Sources from 'views/Sources/Sources';
import { workloadTypeAliases } from 'utils/utils';
import { columns, existingDataSourceColumns } from './columns';
import { WorkloadTypesEnum } from '../../../../types';

const SourceSummaryTable = (): React.ReactElement => {
    const [sourcesTableData, setSourcesTableData] = useState([]);
    const {
        sourcesData: {
            sources,
            customerDetails: { deploymentSummary },
        },
    } = useRootState();
    const { open } = useDialogContext();

    useEffect(() => {
        setSourcesTableData(
            sources.map((source, index) => ({
                ...source,
                type: WorkloadTypesEnum[source.type.toUpperCase()],
                index: `${index + 1}.`,
                ltr: source.ltr ? 'LTR' : 'Warm',
                ...(WorkloadTypesEnum[source.type.toUpperCase()] === workloadTypeAliases.archiveFsNas
                    ? {
                          retention: {
                              daily: 'N/A',
                              weekly: 'N/A',
                              monthly: 'N/A',
                              yearly: source.retention.yearly,
                          },
                          ltr: 'N/A',
                      }
                    : {}),
                ...(WorkloadTypesEnum[source.type.toUpperCase()] === workloadTypeAliases.sapHana
                    ? {
                          retention: {
                              daily: source.retention.daily,
                              weekly: 'N/A',
                              monthly: 'N/A',
                              yearly: 'N/A',
                          },
                      }
                    : {}),
            })),
        );
    }, [sources]);

    return (
        <div className="sourceSummaryTableWrapper">
            <SectionLabel>Sources</SectionLabel>
            <button
                type="button"
                className="source-table-edit qa-btn-source-edit"
                onClick={() => open(<Sources isEditDialog />, 'Sources')}
            >
                Edit
            </button>
            {deploymentSummary?.details?.length > 0 && (
                <>
                    <span className="sub-section-label">Existing Data Sources</span>
                    <Table
                        data={deploymentSummary.details}
                        columns={existingDataSourceColumns}
                        rowKey="serviceName"
                        tableClassName="sourceSummaryTable qa-table-result-existing-sources"
                    />
                    {sourcesTableData?.length > 0 && <span className="sub-section-label">New Data Sources</span>}
                </>
            )}
            {sourcesTableData?.length > 0 && (
                <Table
                    data={sourcesTableData}
                    columns={columns}
                    rowKey="id"
                    tableClassName="sourceSummaryTable qa-table-result-new-sources"
                />
            )}
        </div>
    );
};

export default SourceSummaryTable;
