import React from 'react';
import { edit } from 'store/slices/assumptions/assumptionsActions';
import { useRootDispatch } from 'store/StateProvider';

const AssumptionsButtons = ({ isEditing, resetForm }: { isEditing: boolean; resetForm: () => void }): JSX.Element => {
    const dispatch = useRootDispatch();
    return (
        <div className="drawer-buttons-container">
            {isEditing ? (
                <>
                    <button
                        type="button"
                        className="drawer-button drawer-button-cancel qa-btn-assumption-cancel"
                        onClick={() => {
                            dispatch(edit(false));
                            resetForm();
                        }}
                    >
                        Cancel
                    </button>
                    <button type="submit" className="drawer-button drawer-button-save qa-btn-assumption-submit">
                        Save
                    </button>
                </>
            ) : (
                <button
                    type="button"
                    className="drawer-button drawer-button-edit qa-btn-assumption-edit"
                    onClick={() => {
                        dispatch(edit(true));
                    }}
                >
                    Edit
                </button>
            )}
        </div>
    );
};

export default AssumptionsButtons;
