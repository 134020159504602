import React, { memo, useCallback } from 'react';
import { Formik, Form } from 'formik';
import Discounts from 'views/Sku/SKUForm/Forms/Discounts';
import Sku from 'views/Sku/SKUForm/Forms/Sku';
import schema from 'views/Sku/SKUForm/SKUForm.validation';
import Button from 'components/Button/Button';
import {
    nextButton,
    nextButtonDisabled,
    prevButton,
    resetButtonSku,
    cancelButton,
    saveButton,
    saveButtonDisabled,
} from 'components/Button/styles';
import { updateSku, resetSku } from 'store/slices/sku/skuActions';
import { resetSources, resetTCOYear, resetCustomerDetails, resetCount } from 'store/slices/sources/sourcesActions';
import { nextStep, prevStep, resetSteps } from 'store/slices/steps/stepsActions';
import { useRootDispatch, useRootState } from 'store/StateProvider';
import 'views/Sku/sku.scss';
import { useDialogContext } from 'config/context/dialogContext';

type Props = {
    isEditSkuDialog?: boolean;
    isEditDiscountsDialog?: boolean;
};

const SKUComponent = ({ isEditSkuDialog, isEditDiscountsDialog }) => {
    const isEditDialog = isEditSkuDialog || isEditDiscountsDialog;
    const dispatch = useRootDispatch();
    const {
        sku: { defaultValues, plan, cloud, discounts },
        sourcesData: { sources },
    } = useRootState();

    const disableBusiness = sources.some((source) => source.ltr || source.dtc);

    const handleFormSubmit = useCallback(
        (values) => {
            const payload = {
                plan: values.plan,
                cloud: values.cloud,
                discounts: [...values.discounts],
            };
            dispatch(updateSku(payload));
            if (!isEditDialog) dispatch(nextStep());
        },
        [dispatch, isEditDialog],
    );
    const { close } = useDialogContext();

    const disabledNextButton = (!plan || plan === 'select') && (!cloud || cloud === 'select');

    return (
        <Formik
            initialValues={plan && cloud && discounts ? { plan, cloud, discounts } : defaultValues}
            validationSchema={schema}
            onSubmit={(values) => handleFormSubmit(values)}
        >
            {({ values, resetForm, handleSubmit, isValid, dirty }) =>
                !isEditDialog ? (
                    <>
                        <div className="container">
                            <Form className="form">
                                <Sku disableBusiness={disableBusiness} values={values} />
                                <Button
                                    type="button"
                                    style={resetButtonSku}
                                    className="qa-btn-reset-sku"
                                    handleClick={() => {
                                        resetForm();
                                        dispatch(resetSku());
                                        dispatch(resetSteps());
                                        dispatch(resetSources());
                                        dispatch(resetTCOYear());
                                        dispatch(resetCount());
                                        dispatch(resetCustomerDetails());
                                    }}
                                >
                                    Reset
                                </Button>
                                <div className="empty-container" />
                                <Discounts values={values} defaults={defaultValues} />
                            </Form>
                        </div>
                        <div className="button-container">
                            <Button
                                className="qa-button-prev"
                                type="button"
                                style={prevButton}
                                handleClick={() => {
                                    dispatch(prevStep());
                                }}
                                // TODO: Write custom hook to reuse nextStep , prevStep
                            >
                                Previous
                            </Button>
                            <Button
                                style={(!dirty && disabledNextButton) || !isValid ? nextButtonDisabled : nextButton}
                                isDisabled={(!dirty && disabledNextButton) || !isValid}
                                handleClick={handleSubmit}
                                className="qa-button-next"
                            >
                                Next
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="dialog-container">
                            <Form className="form">
                                {!isEditDiscountsDialog && <Sku />}
                                {!isEditSkuDialog && <Discounts values={values} defaults={defaultValues} />}
                            </Form>
                        </div>

                        <Button handleClick={close} style={cancelButton}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            handleClick={() => {
                                close();
                                handleSubmit();
                            }}
                            style={!isValid || !dirty ? saveButtonDisabled : saveButton}
                            isDisabled={!isValid || !dirty}
                            className="qa-btn-sku-save"
                        >
                            Save
                        </Button>
                    </>
                )
            }
        </Formik>
    );
};

SKUComponent.defaultProps = {
    isEditSkuDialog: false,
    isEditDiscountsDialog: false,
};

export default memo<Props>((props) => <SKUComponent {...props} />);
