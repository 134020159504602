import { withStyles, Input } from '@material-ui/core';

export const InputBaseStyled = withStyles({
    root: {
        background: '#fff',
        borderRadius: 3,
        color: '#232F3E',
        height: '100%',
        border: '1px solid rgba(35, 47, 62, 0.3)',
        fontFamily: 'Lato',
    },
    input: {
        textAlign: 'center',
        fontSize: '1.5rem',
        width: '100%',
    },
    focused: {
        border: '1px solid #ff9900',
    },
    error: {
        border: '1px solid #ff0000',
    },
})(Input);
