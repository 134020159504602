import { useEffect } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { useRootDispatch } from 'store/StateProvider';
import { envVariableState } from 'types';
import { useAPIQuery } from 'hooks/useAPIQuery';
import { saveEnvData } from 'store/slices/deploymentConfig/deploymentConfigActions';

export function useGetEnvData(
    enabled = true,
): UseQueryResult<{ result: envVariableState; statusCode: number; success: boolean }, Error> {
    const dispatch = useRootDispatch();

    const url = `./env-config.json`;
    const method = 'GET';

    const query: UseQueryResult<{ result: envVariableState; statusCode: number; success: boolean }, Error> =
        useAPIQuery({
            key: ['envVariable'],
            method,
            payload: {},
            url,
            baseUrl: `/`,
            enabled,
            withoutToken: true,
            suppressNotification: true,
            options: {
                staleTime: 24 * 60 * 60 * 1000, // 24 hour stale time to avoid refetching the same API
            },
        });

    useEffect(() => {
        if (query?.data?.success && !query.isError && enabled) {
            dispatch(saveEnvData(query.data.result));
        }
    }, [query.data, query.isError, enabled]);
    return query;
}
