import { getType, ActionType } from 'typesafe-actions';
import * as Actions from './deploymentConfigActions';
import { deploymentConfigInitialState } from './deploymentConfig.initialState';

const { setDeploymentConfig, saveEnvData } = Actions;

export const deploymentConfigReducer = (
    state: DeploymentConfigState,
    action: DeploymentConfigActionTypes,
): DeploymentConfigState => {
    switch (action.type) {
        case getType(setDeploymentConfig):
            return { ...state, directorySvcResponse: action.payload };
        case getType(saveEnvData):
            return {
                ...state,
                envData: action.payload,
                globalApiUrlCloudNameMap: action.payload?.CLOUD_NAME_DIRECTORY_SVC_BASE_URL_MAP,
            };
        default: {
            return state;
        }
    }
};
export type DeploymentConfigActionTypes = ActionType<typeof Actions>;
export type DeploymentConfigState = typeof deploymentConfigInitialState;
