import React, { useState, useEffect } from 'react';
import Table from 'generic/Table';
import { useRootState } from 'store/StateProvider';
import { createTableObj } from './helpers';
import { columns } from './summaryTableColumns';
import './summaryTable.scss';

const SummaryTable = (): React.ReactElement => {
    const {
        calculatedData,
        sourcesData: { firstYearMonths },
    } = useRootState();
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (calculatedData.results) {
            const {
                results: { ltr, noLtr, dtc, allSources },
            } = calculatedData;

            const data = allSources.monthlyData.map((el, index) => ({
                month: 12 - firstYearMonths + index + 1,
                year: Math.ceil((12 - firstYearMonths + index + 1) / 12),
                ltr: createTableObj(ltr.monthlyData, index),
                dtc: createTableObj(dtc.monthlyData, index),
                noLtr: createTableObj(noLtr.monthlyData, index),
                allSources: createTableObj(allSources.monthlyData, index),
            }));

            setTableData(data);
        }
    }, [calculatedData]);

    return (
        <div className="summaryTableWrapper">
            {tableData.length > 0 ? (
                <Table
                    data={tableData}
                    columns={columns}
                    rowKey="month"
                    tableClassName="summaryTable qa-table-result-summary"
                    applyEvenClass
                />
            ) : (
                <div className="no-data-container">No Data</div>
            )}
        </div>
    );
};

export default SummaryTable;
