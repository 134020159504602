import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApplicationParamsProvider } from 'ApplicationParamsProvider';
import { Loader } from 'components/Loader/Loader';
import React, { memo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'router/Routes';
import { StateProvider } from 'store/StateProvider';

const queryClient = new QueryClient();
const App = memo(() => (
    <Router>
        <QueryClientProvider client={queryClient}>
            <StateProvider>
                <ApplicationParamsProvider>
                    {(loadApplication) => (
                        <Loader isLoading={!loadApplication} fullPage>
                            {loadApplication && <Routes />}
                        </Loader>
                    )}
                </ApplicationParamsProvider>
            </StateProvider>
        </QueryClientProvider>
    </Router>
));

export default App;
