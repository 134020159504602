import { Dialog, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { InputBaseStyled } from 'components/forms/Input/styles';
import React, { useState } from 'react';
import './dialog.scss';
import MaterialButton from 'components/Button/Button';
import { saveCustomerDetails } from 'store/slices/actions';
import useSources from 'views/Sources/hooks/useSources';
import { parseStringToFloat } from 'utils/utils';
import { useRootState } from 'store/StateProvider';
import { confirmCancelButton } from 'components/Button/styles';

const CalculateCreditBalanceDialog = ({ onClose, lastUpdated }) => {
    const {
        sourcesData: {
            customerDetails: {
                creditsPurchasedCurrentTerm: creditPurchasedValue,
                daysRemainingCurrentTerm: daysRemainingValue,
                dailyAvgCreditConsumption: dailyConsumptionValue,
                firebirdData: {
                    balanceCredits,
                    creditsPurchasedCurrentTerm,
                    daysRemainingCurrentTerm,
                    dailyAvgCreditConsumption,
                },
            },
        },
    } = useRootState();
    const { dispatch } = useSources();

    const [creditPurchase, setCreditPurchase] = useState(creditPurchasedValue);
    const [daysRemaining, setDaysRemaining] = useState(daysRemainingValue);
    const [dailyConsumption, setDailyConsumption] = useState(dailyConsumptionValue);

    const [output, setOutput] = useState({
        creditNeeded: '',
        balanceRemaining: '',
        balanceToBeConsidered: '',
    });

    const saveDataInStore = () =>
        dispatch(
            saveCustomerDetails({
                balanceCredits: output.balanceToBeConsidered,
                creditsPurchasedCurrentTerm: creditPurchase,
                daysRemainingCurrentTerm: daysRemaining,
                dailyAvgCreditConsumption: dailyConsumption,
            }),
        );

    const onReset = () => {
        setCreditPurchase(creditsPurchasedCurrentTerm ?? '');
        setDaysRemaining(daysRemainingCurrentTerm ?? '');
        setDailyConsumption(dailyAvgCreditConsumption ?? '');
        setOutput({
            creditNeeded: '',
            balanceRemaining: '',
            balanceToBeConsidered: '',
        });
    };

    const onCalculate = () => {
        const creditNeeded = +dailyConsumption * +daysRemaining;
        const balanceRemaining = +balanceCredits - creditNeeded;
        const maxBalanceToBeConsidered = 0.2 * +creditPurchase;
        const balanceToBeConsidered =
            balanceRemaining < maxBalanceToBeConsidered ? balanceRemaining : maxBalanceToBeConsidered;

        setOutput({
            creditNeeded: `${creditNeeded}`,
            balanceRemaining: `${balanceRemaining}`,
            balanceToBeConsidered: `${balanceToBeConsidered.toFixed(balanceToBeConsidered % 1 === 0 ? 0 : 2)}`,
        });
    };

    const handleUpdateBalanceClick = () => {
        saveDataInStore();
        onClose();
    };

    return (
        <Dialog
            open
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            classes={{
                container: 'dialog',
                root: 'qa-dialog-calculate-balance',
            }}
        >
            <div className="credit-dialog">
                <DialogTitle id="dialog-title">Calculate Credit Balance</DialogTitle>
                <Close className="dialog-close-icon" color="disabled" onClick={onClose} />
                Calculate credit balance to be considered for sizing
                <div className="credit-dialog-container">
                    <span style={{ marginBottom: '20px' }}>
                        Balance credits for current term
                        <br />
                        <span style={{ color: 'gray', fontSize: 'small' }} className="qa-last-updated-date">
                            (Last updated on {lastUpdated})
                        </span>
                    </span>
                    <InputBaseStyled
                        disableUnderline
                        disabled
                        className="input qa-input-balance-credits"
                        value={balanceCredits}
                    />
                    <span>Total credits purchased in current term</span>
                    <InputBaseStyled
                        disableUnderline
                        className="input qa-input-total-credits-purchased"
                        value={creditPurchase}
                        onChange={(e) => setCreditPurchase(parseStringToFloat(e.target.value))}
                    />
                    <span>Days remaining in current term</span>
                    <InputBaseStyled
                        disableUnderline
                        className="input qa-input-days-remaining"
                        value={daysRemaining}
                        onChange={(e) => setDaysRemaining(parseStringToFloat(e.target.value))}
                    />
                    <span>Daily average credit consumption</span>
                    <InputBaseStyled
                        disableUnderline
                        className="input qa-input-daily-consumption"
                        value={dailyConsumption}
                        onChange={(e) => setDailyConsumption(parseStringToFloat(e.target.value))}
                    />
                    <div>
                        <MaterialButton
                            style={{ ...confirmCancelButton, float: 'right', marginTop: '0px', marginRight: '0px' }}
                            handleClick={onReset}
                            className="button qa-btn-reset"
                            isDisabled={!creditPurchase || !daysRemaining || !dailyConsumption}
                        >
                            Reset
                        </MaterialButton>
                    </div>
                    <MaterialButton
                        handleClick={onCalculate}
                        className={
                            !creditPurchase || !daysRemaining || !dailyConsumption
                                ? 'disabled button qa-btn-calculate'
                                : 'button qa-btn-calculate'
                        }
                        isDisabled={!creditPurchase || !daysRemaining || !dailyConsumption}
                    >
                        Calculate
                    </MaterialButton>
                    <span>Credits needed for remaining days in current term</span>
                    <InputBaseStyled
                        disableUnderline
                        disabled
                        className="input qa-input-credits-needed"
                        value={output.creditNeeded}
                    />
                    <span className="qa-credit-balance-deficit">
                        Credit {+output.balanceRemaining < 0 ? 'deficit' : 'balance'} by the end of current term
                    </span>
                    <InputBaseStyled
                        disableUnderline
                        disabled
                        className={
                            +output.balanceRemaining < 0
                                ? 'input label-red qa-input-credit-balance'
                                : 'input qa-input-credit-balance'
                        }
                        value={+output.balanceRemaining !== 0 ? (+output.balanceRemaining).toFixed(2) : ''}
                    />
                    <span
                        style={{ marginTop: '30px', fontWeight: 'bold' }}
                        className="qa-credit-balance-deficit-carryforward"
                    >
                        Credit {+output.balanceToBeConsidered < 0 ? 'deficit' : 'balance'} to carry forward to next term
                    </span>
                    <InputBaseStyled
                        style={{ marginTop: '30px' }}
                        disableUnderline
                        disabled
                        className={
                            +output.balanceToBeConsidered < 0
                                ? 'input label-red font-bold qa-input-credit-balance-deficit-carry-forward'
                                : 'input font-bold qa-input-credit-balance-deficit-carry-forward'
                        }
                        value={output.balanceToBeConsidered}
                    />
                    <span style={{ color: 'gray', fontSize: 'small', marginTop: '-10px' }}>
                        (Use this value for sizing for a new term)
                    </span>
                    <MaterialButton
                        style={{ marginTop: '20px' }}
                        handleClick={handleUpdateBalanceClick}
                        className={
                            !output.balanceToBeConsidered
                                ? 'disabled button qa-btn-update-balance'
                                : 'button qa-btn-update-balance'
                        }
                        isDisabled={!output.balanceToBeConsidered}
                    >
                        Update Balance
                    </MaterialButton>
                </div>
            </div>
        </Dialog>
    );
};

export default CalculateCreditBalanceDialog;
