import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';
import { confirmButton, confirmCancelButton } from 'components/Button/styles';
import './dialog.scss';

const SourcesConfirmationDialog = ({
    isOpen,
    onClose,
    onContinue,
    archiveDailyChanged,
    actualsChanged,
    archiveSelected,
}): JSX.Element => (
    <Dialog
        open={isOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{
            container: 'dialog',
            root: 'qa-dialog-sources-confirmation',
        }}
    >
        <div className="confirm-dialog">
            <DialogTitle id="dialog-title">Confirmation</DialogTitle>
            <Close className="dialog-close-icon" color="disabled" onClick={onClose} />

            <div className="confirm-dialog-content" style={{ paddingTop: '0px' }}>
                {actualsChanged ? (
                    <p>
                        For accurate sizing, it is recommended to utilize the Dedupe Ratio, Annual Growth, and Daily
                        Change values as actuals from the existing data.
                    </p>
                ) : null}
                {archiveSelected ? (
                    <p>
                        Default dedupe ratio for Archive storage is 1, indicating no deduplication as unstructured data
                        usually has less redundancy. Adjust dedupe ratio according to customer data
                    </p>
                ) : null}
                {archiveDailyChanged ? (
                    <p>
                        <span className="label-red">
                            Regular data updates or modifications in the Archive storage tier may increase credit usage.
                        </span>
                        Archive is intended for static data; ongoing modifications may incur additional cost. Choose
                        storage option based on data usage.
                    </p>
                ) : null}

                <p>Do you wish to proceed with the selected values?</p>
            </div>
            <div className="confirm-dialog-buttons">
                <Button className="qa-button-cancel" style={confirmCancelButton} onClick={onClose}>
                    Cancel
                </Button>
                <Button className="qa-button-continue" onClick={onContinue} style={confirmButton}>
                    Continue
                </Button>
            </div>
        </div>
    </Dialog>
);

export default SourcesConfirmationDialog;
