import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';
import { confirmButton } from 'components/Button/styles';
import './dialog.scss';

const DailyChangeConfirmationDialog = ({ isOpen, onClose, onContinue }): JSX.Element => (
    <Dialog
        open={isOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{
            container: 'dialog',
            root: 'qa-dialog-daily-change-confirmation',
        }}
    >
        <div className="confirm-dialog">
            <DialogTitle id="dialog-title">Daily Change Update</DialogTitle>
            <Close className="dialog-close-icon" color="disabled" onClick={onClose} />

            <div className="confirm-dialog-content">
                You have entered a daily change rate for the Archive storage tier.{' '}
                <span className="label-red">
                    This may result in increased credit consumption due to the frequent data changes.
                </span>{' '}
                Archive is recommended for storing static data that is not expected to change frequently. Adding new
                data to Archive is acceptable, but ongoing data modifications may lead to higher costs.
            </div>
            <div className="confirm-dialog-buttons">
                <Button className="qa-button-ok-confirmation" onClick={onContinue} style={confirmButton}>
                    Ok
                </Button>
            </div>
        </div>
    </Dialog>
);

export default DailyChangeConfirmationDialog;
