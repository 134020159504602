import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, Checkbox } from '@material-ui/core';
import { SelectStyled, useDropdownStyles } from 'components/forms/Select/styles';
import { YEARS_LABEL_ARRAY } from 'config/setup/constants';
import TooltipIcon from 'components/Tooltip/TooltipIcon';
import useSources from 'views/Sources/hooks/useSources';
import { useRootState } from 'store/StateProvider';
import { updateFirstYearMonths, updateTCOYear } from 'store/slices/actions';
import { InputBaseStyled } from 'components/forms/Input/styles';
import { sourcesInitialState } from 'store/slices';

const SelectTCOYear = (): JSX.Element => {
    const {
        sourcesData: {
            customerDetails: { customerType },
            tcoYear,
            firstYearMonths,
        },
    } = useRootState();
    const { dispatch } = useSources();
    const [enable, setEnable] = useState(firstYearMonths !== 12);
    const { dropdownStyle } = useDropdownStyles({});

    useEffect(() => {
        if (customerType === 'New') {
            setEnable(false);
            dispatch(updateFirstYearMonths(sourcesInitialState.firstYearMonths));
        }
    }, [customerType]);

    return (
        <>
            <div className="details-item qa-label-calculate-tco">Calculate TCO For</div>
            <FormControl variant="outlined" className="details-item">
                <SelectStyled
                    value={tcoYear}
                    onChange={(e) => dispatch(updateTCOYear(e.target.value as number))}
                    MenuProps={{ classes: { paper: dropdownStyle } }}
                    style={{ width: '200px', alignSelf: 'flex-start' }}
                    className="qa-select-tco-year"
                >
                    {YEARS_LABEL_ARRAY.map((label, index) => (
                        <MenuItem key={`${label}`} value={index + 1}>
                            {label}
                        </MenuItem>
                    ))}
                </SelectStyled>
            </FormControl>
            {customerType === 'Existing' ? (
                <>
                    <div className="details-item" />
                    <div className="details-item qa-consider-only-container">
                        <Checkbox
                            style={{ padding: '0px', marginRight: '10px' }}
                            checked={enable}
                            onChange={(e) => {
                                setEnable(e.target.checked);
                                if (!e.target.checked) dispatch(updateFirstYearMonths(12));
                            }}
                            className="tco-checkbox"
                        />
                        Consider only
                        <InputBaseStyled
                            style={{ width: '50px', margin: '0px 10px' }}
                            inputProps={
                                enable
                                    ? {}
                                    : {
                                          style: { backgroundColor: '#f0f0f0' },
                                      }
                            }
                            name="firstYearMonths"
                            type={enable ? 'number' : 'text'}
                            value={enable ? firstYearMonths : '--'}
                            onChange={(e) => {
                                let input = +e.target.value;
                                if (input < 0) {
                                    input = 0;
                                }
                                if (input > 12) {
                                    input = 12;
                                }
                                dispatch(updateFirstYearMonths(input));
                            }}
                            disabled={!enable}
                            disableUnderline
                        />
                        months for first year
                        <TooltipIcon
                            title="Select to consider balance duration in current term for credit sizing"
                            placement="right"
                            arrow
                            style={{ marginLeft: '10px' }}
                        />
                    </div>
                </>
            ) : null}
        </>
    );
};

export default SelectTCOYear;
