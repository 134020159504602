import { useGetCreditDeploymentSummaryDetails } from './useGetCreditDeploymentSummaryDetails';
import { useGetCustomerDetails } from './useGetCustomerDetails';

export function useGetExistingCustomerData(customerSfdcOemId, fetchCustomerDetails) {
    const customerDetailsQuery = useGetCustomerDetails({ id: customerSfdcOemId }, fetchCustomerDetails);
    const { isLoading: isLoadingCustomerDetails, isFetching: isFetchingCustomerDetails } =
        useGetCreditDeploymentSummaryDetails(
            customerDetailsQuery.data?.result?.selectedProductCustomerId,
            !!customerDetailsQuery.data?.result?.selectedProductCustomerId && customerDetailsQuery.isFetched,
        );

    return {
        ...customerDetailsQuery,
        isLoading: customerDetailsQuery.isLoading || isLoadingCustomerDetails,
        isFetching: customerDetailsQuery.isFetching || isFetchingCustomerDetails,
    };
}
