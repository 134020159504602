import { CloudOptionsEnum } from 'store/slices/sku/sku.types';
import { WorkloadEnum } from 'types';

export const SOURCE_TABLE_PRIMARY_HEADERS = {
    sources: 'Sources',
    serverDetails: 'Data details',
    dataChange: 'Data change',
    storageTier: 'Storage Tier',
    retention: 'Retention',
};

export const EXISTING_SOURCE_TABLE_PRIMARY_HEADERS = {
    serviceName: 'Workload Type',
    configuredBackupsets: '# Backup Sets',
    dataSize: 'Total Data Size',
    annualGrowthRate: 'Avg. Annual Growth (%)',
    dailyChangeRate: 'Avg. Daily Change (%)',
    effectiveDedupRatio: 'Effective Dedupe',
};

export const SOURCE_TABLE_SECONDARY_HEADERS = {
    workload: 'Workload type',
    dedupe: 'Dedupe Ratio',
    dataSize: 'Data size (TB)',
    annualGrowth: 'Annual growth (%)',
    dailyGrowth: 'Daily growth (%)',
    dailyChange: 'Daily change (%)',
    deploymentPeriod: 'Deployment Period (# Days)',
    warmLtr: 'Warm/LTR',
    dailies: 'Dailies',
    weeklies: 'Weeklies',
    monthlies: 'Monthlies',
    yearlies: 'Yearlies',
};

export const SUMMARY_TABLE_PRIMARY_HEADERS = {
    standard: 'Standard',
    ltr: 'LTR',
    archive: 'Archive',
    allSources: 'All Sources',
};

export const SUMMARY_TABLE_SECONDARY_HEADERS = {
    standardSourcePlusChange: 'S+C Standard (TB)',
    phoenixStorageStandard: 'EWL Storage Standard (TB)',
    standardCreditsCumul: 'Credits Cumul Standard',
    standardMonthlyCredits: 'Monthly Credits Standard',
    ltrSourcePlusChange: 'S+C LTR (TB)',
    phoenixStorageLtr: 'EWL Storage LTR (TB)',
    ltrCreditsCumul: 'Credits Cumul LTR',
    ltrMonthlyCredits: 'Monthly Credits LTR',
    archiveSourcePlusChange: 'S+C Archive(TB)',
    phoenixStorageArchive: 'EWL Storage Archive(TB)',
    archiveCreditsCumul: 'Credits Cumul Archive',
    archiveMonthlyCredits: 'Monthly Credits Archive',
    totalSourcePlusChange: 'Standard S+C (TB)',
    phoenixStorageTotal: 'EWL Storage (TB)',
    totalCreditsCumul: 'Total Credits Cumul',
    totalMonthlyCredits: 'Total Monthly Credits',
};

export const getBackupTableTitles = (isDell, isCreditDeficit = false) => ({
    dataSourceChanges: 'Average Source+Changes (TB)',
    dataTargetData: `Average ${isDell ? '' : 'EWL '}Storage (TB)`,
    dataCreditsStandard: 'Credits Standard',
    dataCreditsLtr: 'Credits LTR',
    dataCreditsDtc: 'Credits Archive',
    dataCreditsBalance: `Credits ${isCreditDeficit ? 'Deficit' : 'Balance'}`,
    dataCreditsTotal: 'Credits Total',
});

export const PRICING_INFO_TITLES = {
    dataDiscount: 'Discounts (%)',
    dataBackupACV: 'Backup ACV',
    dataEffectivePrice: 'Effective Price($/TB/Month)',
};

export const cloudTypeMappingForAPI = {
    [CloudOptionsEnum.PUBLIC_CLOUD]: 'mainline',
    [CloudOptionsEnum.GOV_CLOUD]: 'gov',
    [CloudOptionsEnum.FED_CLOUD]: 'fedramp',
};

export const workloadTypeMappingForAPI = {
    [WorkloadEnum.EC2]: 'EC2', // TODO
    [WorkloadEnum.AZUREVM]: 'Azure VM', // TODO
    [WorkloadEnum.VMWARE]: 'VMware',
    [WorkloadEnum.HYPERV]: 'Hyper-V',
    [WorkloadEnum.NUTANIXAHV]: 'Nutanix AHV',
    [WorkloadEnum.NAS]: 'NAS',
    [WorkloadEnum.WINDOWS]: 'Windows', // TODO
    [WorkloadEnum.LINUX]: 'Linux', // TODO
    [WorkloadEnum.ARCHIVEFSNAS]: 'Archive (FS/NAS)', // TODO
    [WorkloadEnum.MSSQL]: 'MS-SQL',
    [WorkloadEnum.ORACLE]: 'Oracle', // TODO
    [WorkloadEnum.SAPHANA]: 'SAP HANA',
};

export const SIZER_V2_BASE_URL = `/creditsizer/sizer/v2`;

export const SIZING_STATUS_COMPLETE = 'RequestProcessingComplete';
export const SIZING_STATUS_PROCESSING = 'RequestProcessing';
export const SIZING_STATUS_FAILED = 'RequestProcessingFailed';
