/* eslint import/no-unresolved: [2, { ignore: ['csstype'] }] */
import { Properties } from 'csstype';

export const nextButton: Properties = {
    backgroundColor: '#FF9900',
    textTransform: 'uppercase',
    width: '162px',
    fontSize: '15px',
    letterSpacing: '0.3',
    color: '#ffffff',
    padding: '18px 60px',
    fontWeight: 'bold',
    zIndex: 2,
    position: 'absolute',
    right: 0,
    fontFamily: 'Lato',
};

export const nextButtonDisabled: Properties = {
    ...nextButton,
    backgroundColor: 'rgba(255, 153, 0, 0.4)',
};

export const prevButton: Properties = {
    ...nextButton,
    backgroundColor: '#C4C4C4',
    left: 0,
};

export const addButton: () => Properties = () => ({
    backgroundColor: '#ff9900',
    borderRadius: '3px',
    margin: '0.8rem',
    marginLeft: '0rem',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '1.3rem',
    width: 'fit-content',
    textTransform: 'none',
    fontFamily: 'Lato',
});

export const resetButton: Properties = {
    position: 'absolute',
    top: '-6rem',
    right: '0',
    border: '1px solid #FF9900',
    color: '#FF9900',
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    fontFamily: 'Lato',
    letterSpacing: '0.3em',
    fontWeight: 700,
    padding: '0.95rem 2.4rem',
};

export const loginButtonWithOkta: Properties = {
    backgroundColor: '#146EB4',
    color: 'white',
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: 'normal',
    fontStyle: 'normal',
    borderRadius: '4px',
    width: '330px',
    height: '48px',
    textTransform: 'unset',
};

export const normalLoginButton: Properties = {
    backgroundColor: '#146EB4',
    color: 'white',
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: 'normal',
    fontStyle: 'normal',
    borderRadius: '4px',
    textTransform: 'unset',
    width: '100px',
};

export const logoutButton: Properties = {
    border: '1px solid #FF9900',
    color: '#FF9900',
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontFamily: 'Lato',
    letterSpacing: '0.15em',
    padding: '0.5rem 0.4rem',
};

export const resetPasswordButton: Properties = {
    backgroundColor: '#146EB4',
    color: 'white',
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Lato',
    fontWeight: 'normal',
    fontStyle: 'normal',
    borderRadius: '4px',
    textTransform: 'unset',
    padding: '10px 0',
};

export const resetButtonSku: Properties = {
    ...resetButton,
    top: '-6.5rem',
};

export const exportPdfButton: Properties = {
    backgroundColor: '#FF9900',
    color: '#fff',
    borderRadius: '4px',
    padding: '0.9rem 2rem',
    fontSize: '15px',
    lineHeight: '18px',
    letterSpacing: '0.2rem',
    fontFamily: 'Lato',
    textTransform: 'uppercase',
};

export const exportPdfButtonDisabled: Properties = {
    ...exportPdfButton,
    backgroundColor: '#ffc165',
    cursor: 'default',
};

export const SourceButton: Properties = {
    background: '#E9EBEC',
    borderRadius: '2px',
    color: '#232F3E',
    fontSize: '13px',
    width: '17rem',
    marginBottom: '0.7rem',
    padding: '0.75rem 1.3rem',
    textAlign: 'left',
    fontWeight: 400,
    justifyContent: 'start',
    fontFamily: 'Lato',
};

export const ActiveSourceButton: Properties = {
    ...SourceButton,
    background: '#146EB4',
    color: '#ffffff',
};

export const saveButton: Properties = {
    position: 'absolute',
    bottom: '-7.5rem',
    width: '10rem',
    right: 0,
    color: '#ffffff',
    backgroundColor: '#FF9900',
    fontSize: '1.5rem',
    letterSpacing: '0.1rem',
    fontWeight: 700,
    boxShadow: 'none',
    fontFamily: 'Lato',
};

export const saveButtonDisabled: Properties = {
    ...saveButton,
    backgroundColor: 'rgba(255, 153, 0, 0.4)',
};
export const cancelButton: Properties = {
    ...saveButton,
    right: '12rem',
    backgroundColor: '#c4c4c4',
};
export const resetModalButton: Properties = {
    ...saveButton,
    right: '24rem',
    backgroundColor: '#c4c4c4',
};

export const confirmButton: Properties = {
    color: '#ffffff',
    width: '11rem',
    height: '40px',
    fontSize: '1.5rem',
    letterSpacing: '0.1rem',
    fontWeight: 700,
    boxShadow: 'none',
    backgroundColor: '#ff9900',
    marginTop: '5px',
    fontFamily: 'Lato',
};

export const confirmButtonDisabled: Properties = {
    ...confirmButton,
    backgroundColor: '#ffc165',
    cursor: 'default',
};

export const confirmCancelButton: Properties = {
    ...confirmButton,
    backgroundColor: '#c4c4c4',
    marginRight: '5px',
};

export const importButton: Properties = {
    border: '1px solid #FF9900',
    color: '#FF9900',
    fontSize: '1.3rem',
    fontWeight: 700,
    padding: '0.5rem 5rem',
    width: '1.5rem',
    textTransform: 'none',
    fontFamily: 'Lato',
};

export const filledButton: Properties = {
    color: '#ffffff',
    width: '11rem',
    height: '40px',
    fontSize: '1.5rem',
    letterSpacing: '0.1rem',
    fontWeight: 700,
    boxShadow: 'none',
    backgroundColor: '#ff9900',
    marginTop: '5px',
    fontFamily: 'Lato',
};

export const filledButtonDisabled: Properties = {
    ...confirmButton,
    backgroundColor: '#ffc165',
    cursor: 'default',
};
