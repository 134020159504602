import React, { useReducer, createContext, ReactElement } from 'react';
import { useStateContext } from 'hooks/useStateContext';
import { AuthActionTypes, authReducer } from 'store/slices/auth/authStore';
import { StepsActionTypes, stepsReducer } from 'store/slices/steps/stepsStore';
import { AssumptionsActionTypes, assumptionsReducer } from 'store/slices/assumptions/assumptionsStore';
import { SourcesActionTypes, sourcesReducer } from 'store/slices/sources/sourcesStore';
import { SkuActionTypes, skuReducer } from 'store/slices/sku/skuStore';
import { CalculatedDataActionTypes, calculatedDataReducer } from 'store/slices/calculatedData/calculatedDataStore';
import { LocationActionTypes, locationReducer } from 'store/slices/location/locationStore';

import { storeInitialState } from './store-initial-state';
import { RootActionTypes, AppState } from './store.types';
import { DeploymentConfigActionTypes, deploymentConfigReducer } from './slices/deploymentConfig/deploymentConfigStore';

type RootDispatchType = (action: RootActionTypes) => void;

const rootReducer = (
    { auth, steps, sourcesData, sku, assumptions, calculatedData, location, deploymentConfig }: AppState,
    action: RootActionTypes,
) => ({
    auth: authReducer(auth, action as AuthActionTypes),
    steps: stepsReducer(steps, action as StepsActionTypes),
    assumptions: assumptionsReducer(assumptions, action as AssumptionsActionTypes),
    sourcesData: sourcesReducer(sourcesData, action as SourcesActionTypes),
    sku: skuReducer(sku, action as SkuActionTypes),
    location: locationReducer(location, action as LocationActionTypes),
    calculatedData: calculatedDataReducer(calculatedData, action as CalculatedDataActionTypes),
    deploymentConfig: deploymentConfigReducer(deploymentConfig, action as DeploymentConfigActionTypes),
});

const RootStateContext = createContext<AppState>(storeInitialState);
const RootDispatchContext = createContext<RootDispatchType>(() => {});

type Props = {
    children: ReactElement;
    initialState?: AppState; // Allow optional custom initial state
};

const StateProvider = ({ children, initialState }: Props): ReactElement => {
    const { Provider: State } = RootStateContext;
    const { Provider: DispatchProvider } = RootDispatchContext;
    const [state, dispatch] = useReducer(rootReducer, initialState);
    return (
        <State value={state}>
            <DispatchProvider value={dispatch}>{children}</DispatchProvider>
        </State>
    );
};

StateProvider.defaultProps = {
    initialState: storeInitialState,
};

const useRootState = (): AppState => useStateContext(RootStateContext);
const useRootDispatch = (): RootDispatchType => useStateContext(RootDispatchContext);

export { StateProvider, useRootState, useRootDispatch };
