import React from 'react';
import { CircularProgress } from '@material-ui/core';
import './Loader.scss';

type loaderProps = {
    isLoading: boolean;
    fullPage?: boolean;
    text?: string;
    children: React.ReactNode;
};
export const Loader = ({ isLoading, fullPage, text, children }: loaderProps): JSX.Element => (
    <div className={[isLoading ? 'loader show' : 'loader', fullPage ? 'full-page' : null].join(' ')}>
        {isLoading && (
            <div className="loader-content">
                <CircularProgress />
                {text ? <div className="font11px gray-text pt-3">{text}</div> : null}
            </div>
        )}
        {children}
    </div>
);

Loader.defaultProps = {
    fullPage: false,
    text: null,
};
