import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import APIRequestUtil, { getTokens } from './APIRequestUtil';

const encodeQueryString = (data) => {
    const searchParams = new URLSearchParams(data);
    return searchParams.toString();
};

/**
 * Return useQuery which calls API using fetch
 * @method fetch method GET, POST etc
 * @url url of the API after baseurl
 * @payload payload of the API
 * @baseUrl optionsl baseUrl of the API, if not passed, default baseurl will be taken
 * @options useQuery options
 * @enabled if passed true, will trigger queryFn, default value is false
 * @select the function that transforms parts of the query result
 * @key Query key, if not passed, query key will be assigned with [url, method]
 * @refetchOnWindowFocus if passed as true, it will refetch on Window Focus
 * @withoutToken if passed true, it will fetch api without token
 * @customHeaders object having custom headers that needs to pass
 * @errorMessage custom error message
 * @returns
 */
export function useAPIQuery<T, T1>({
    method,
    url,
    payload,
    baseUrl,
    options,
    enabled = false,
    select,
    key,
    refetchOnWindowFocus = false,
    withoutToken = false,
    customHeaders = {},
    errorMessage = '',
    suppressNotification = false,
}: {
    method: 'GET' | 'POST';
    payload?: T;
    url: string;
    baseUrl?: string;
    options?: Partial<UseQueryOptions<T1, Error, T1>>;
    enabled?: boolean;
    select?: any;
    key?: string[];
    refetchOnWindowFocus?: boolean;
    withoutToken?: boolean;
    customHeaders?: any;
    errorMessage?: string;
    suppressNotification?: boolean;
}) {
    const token = getTokens();

    if (method === 'GET') {
        const queryString = encodeQueryString(payload);
        if (queryString) {
            url = `${url}?${queryString}`;
        }
    }

    return useQuery<T1>({
        queryKey: key || [url, method], // Unique key for the query
        enabled: (!!token || withoutToken) && enabled, // Only fetch if token is available
        queryFn: () =>
            APIRequestUtil.makeRequest({
                method,
                url,
                payload,
                baseUrl,
                suppressNotification,
                customHeaders,
                errorMessage,
                withoutToken,
            }),
        select,
        refetchOnWindowFocus,
        retry: 0,
        ...options,
    });
}
