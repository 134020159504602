import { AuthState } from './auth.types';

export const authInitialState: AuthState = {
    isAuthenticated: false,
    token: '',
    loginMethod: '',
    email: '',
    familyName: '',
    firstName: '',
    isDruvaUser: false,
    isDellUser: false,
    isPartnerUser: false,
};
