import { parse, unparse } from 'papaparse';
import { DynamicObjectType, SheetData } from 'types';
import {
    BACKUP_DETAILS,
    CUSTOMER_DETAILS,
    EXISTING_SOURCE_DATA_SHEET,
    NEW_SOURCE_DATA_SHEET,
    SUMMARY_DETAILS,
} from './utils';

const recursiveFlatten = (obj: DynamicObjectType, parentKey = '') => {
    const flattened = {};

    Object.keys(obj).forEach((key) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(flattened, recursiveFlatten(obj[key], newKey));
        } else {
            flattened[newKey] = obj[key];
        }
    });

    return flattened;
};

export const flattenArrayOfObject = (arr: DynamicObjectType[]) => {
    const result = [];
    arr.forEach((obj) => {
        result.push(recursiveFlatten(obj));
    });
    return result;
};

const reconstructObject = (data: DynamicObjectType) => {
    const nestedObject = {};
    Object.keys(data).forEach((key) => {
        const keys = key.split('.');
        let currentLevel = nestedObject;

        keys.forEach((nestedKey, index) => {
            if (!currentLevel[nestedKey]) {
                // Create nested object if it doesn't exist
                if (index === keys.length - 1) {
                    currentLevel[nestedKey] = data[key];
                } else {
                    currentLevel[nestedKey] = {};
                }
            }
            // Move to the next level
            currentLevel = currentLevel[nestedKey];
        });
    });
    return nestedObject;
};

export const deFlateArray = (arr: DynamicObjectType[]) => {
    const result = [];
    arr.forEach((obj) => {
        result.push(reconstructObject(obj));
    });
    return result;
};

const sheetMapHeader = {
    [CUSTOMER_DETAILS]: false,
    [NEW_SOURCE_DATA_SHEET]: true,
    [SUMMARY_DETAILS]: false,
    [BACKUP_DETAILS]: true,
    [EXISTING_SOURCE_DATA_SHEET]: true,
};

export const exportCSV = (dataSets: SheetData[], csvName: string): Promise<void> =>
    new Promise((resolve, reject) => {
        try {
            let combinedCSV = '';

            // Check if dataSets is empty
            if (dataSets.length === 0) {
                throw new Error('No data to export');
            }

            dataSets.forEach((dataSet) => {
                // Check if dataSet is valid
                if (!dataSet || !dataSet.sheetName || !dataSet.data) {
                    throw new Error('Invalid dataSet');
                }

                // Convert each dataset to CSV string
                const csvString = unparse(dataSet.data, {
                    header: sheetMapHeader[dataSet.sheetName],
                });

                // Combine CSV string with sheetName as separator
                combinedCSV += `=== ${dataSet.sheetName} ===\n${csvString}\n\n`;
            });

            // Check if combinedCSV is empty
            if (!combinedCSV.trim()) {
                throw new Error('No data to export');
            }

            // Create a Blob from the combined CSV string
            const blob = new Blob([combinedCSV], { type: 'text/csv' });

            // Create a link to trigger the download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = csvName;

            // Append the link to the document and trigger a click event
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);

            resolve(); // Resolve the Promise when export is successful
        } catch (error) {
            reject(error); // Reject the Promise if any error occurs
        }
    });

export const importCSV = (file: File | null): Promise<any[]> =>
    new Promise((resolve, reject) => {
        if (!file || !(file instanceof File)) {
            reject(new Error('Invalid file'));
        }
        const reader = new FileReader();

        reader.onload = (e) => {
            const csvData = e.target.result;

            let csvString: string;

            if (typeof csvData === 'string') {
                csvString = csvData;
            } else {
                // Convert ArrayBuffer to string
                const decoder = new TextDecoder('utf-8');
                csvString = decoder.decode(csvData);
            }

            // Split the CSV data into sections based on the separator
            const sections = csvString.split(/=== (.+?) ===/);

            // Remove the first empty entry, if any
            if (sections[0].trim() === '') {
                sections.shift();
            }

            // Parse each section using Papa.parse
            const parsedData = sections
                .map((section, index) => {
                    if (index % 2 !== 0) {
                        // Extract sheetName from the separator
                        const sheetName = sections[index - 1];

                        // Remove sheetName and trim the section
                        const sectionData = section.trim();

                        // Parse the section data using Papa.parse
                        return {
                            sheetName,
                            data: parse(sectionData, { header: sheetMapHeader[sheetName] }).data,
                        };
                    }

                    return null;
                })
                .filter(Boolean);

            resolve(parsedData);
        };

        reader.onerror = (event) => {
            reject(event.target.error);
        };

        reader.readAsText(file);
    });
