import React, { createContext, useContext, useState, useEffect } from 'react';
import { BackupData } from 'types';
import _ from 'lodash';
import { useRootState } from 'store/StateProvider';
import {
    calculateAllResults,
    calculateBackupAcv,
    calculateEffectivePrice,
    calculatePriceByCloud,
} from 'views/ResultsTabs/Summary/BackupTable/helpers';

type PdfDataContextType = {
    graphCompleted: boolean;
    backupData: BackupData;
    backupACV: number[];
    effectivePrice: number[];
    tabIndex: number;
    timeRange: number;
    timeRangeInSummaryGraph: number;
    priceInformation: boolean;
    isCustomerInfoVisible: boolean;
    changeGraphCompleted: React.Dispatch<React.SetStateAction<boolean>>;
    changeBackupData: React.Dispatch<React.SetStateAction<BackupData>>;
    changeBackupACV: React.Dispatch<React.SetStateAction<number[]>>;
    changeTabIndex: React.Dispatch<React.SetStateAction<number>>;
    changeTimeRange: React.Dispatch<React.SetStateAction<number>>;
    changeTimeRangeInSummaryGraph: React.Dispatch<React.SetStateAction<number>>;
    changePriceInformation: React.Dispatch<React.SetStateAction<boolean>>;
    changeIsCustomerInfoVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const pdfDataInitialState = {
    graphCompleted: false,
    backupData: null,
    backupACV: null,
    effectivePrice: null,
    tabIndex: 0,
    timeRange: 3,
    priceInformation: true,
    timeRangeInSummaryGraph: 3,
    isCustomerInfoVisible: true,
    changeGraphCompleted: (): void => {},
    changeBackupData: (): void => {},
    changeBackupACV: (): void => {},
    changeTabIndex: (): void => {},
    changeTimeRange: (): void => {},
    changePriceInformation: (): void => {},
    changeTimeRangeInSummaryGraph: (): void => {},
    changeIsCustomerInfoVisible: (): void => {},
};

const PdfDataContext = createContext<PdfDataContextType>(pdfDataInitialState);
const { Provider } = PdfDataContext;

interface Props {
    children: React.ReactNode;
}

const PdfDataProvider = ({ children }: Props): JSX.Element => {
    const [backupData, changeBackupData] = useState<BackupData>(null);
    const [backupACV, changeBackupACV] = useState<number[]>(null);
    const [graphCompleted, changeGraphCompleted] = useState<boolean>(false);
    const [tabIndex, changeTabIndex] = useState<number>(0);
    const {
        calculatedData,
        assumptions,
        sku,
        sourcesData: {
            tcoYear,
            customerDetails: { balanceCredits, customerType },
            firstYearMonths,
        },
    } = useRootState();

    const [timeRange, changeTimeRange] = useState<number>(tcoYear);
    const [effectivePrice, changeEffectivePrice] = useState<number[]>(null);

    const [timeRangeInSummaryGraph, changeTimeRangeInSummaryGraph] = useState<number>(tcoYear);
    const [priceInformation, changePriceInformation] = useState<boolean>(true);
    const [isCustomerInfoVisible, changeIsCustomerInfoVisible] = useState<boolean>(true);

    useEffect(() => {
        changeGraphCompleted(!!backupACV && !!backupData);
    }, [backupACV, backupData]);

    useEffect(() => {
        if (calculatedData.results)
            changeBackupData(calculateAllResults(calculatedData, balanceCredits, customerType, firstYearMonths));
    }, [calculatedData, calculatedData.results]);

    useEffect(() => {
        if (assumptions.pricing && backupData && sku.discounts && sku.plan) {
            changeBackupACV(
                _.range(tcoYear).map((index) =>
                    calculateBackupAcv(
                        sku.discounts[index],
                        calculatePriceByCloud(
                            assumptions.pricing[sku.plan],
                            sku.cloud,
                            assumptions.pricing.govCloudAddon,
                        ),
                        backupData.creditsTotal[index],
                    ),
                ),
            );
        }
    }, [assumptions.pricing, backupData, sku.discounts, sku.plan, sku.cloud, tcoYear]);

    useEffect(() => {
        if (backupData?.targetData && backupACV) {
            changeEffectivePrice(
                _.range(tcoYear).map((index) =>
                    calculateEffectivePrice(backupACV[index], backupData.targetData[index]),
                ),
            );
        }
    }, [backupData, backupACV, tcoYear]);

    return (
        <Provider
            value={{
                graphCompleted,
                backupData,
                backupACV,
                effectivePrice,
                tabIndex,
                timeRange,
                timeRangeInSummaryGraph,
                priceInformation,
                isCustomerInfoVisible,
                changeGraphCompleted,
                changeBackupData,
                changeBackupACV,
                changeTabIndex,
                changeTimeRange,
                changeTimeRangeInSummaryGraph,
                changePriceInformation,
                changeIsCustomerInfoVisible,
            }}
        >
            {children}
        </Provider>
    );
};

const usePdfDataContext = (): PdfDataContextType => useContext(PdfDataContext);
export { PdfDataProvider, usePdfDataContext };
