/* eslint-disable import/no-extraneous-dependencies */
import { http } from 'msw';

/* wrapper functions for prepending the Base URL to the URL.
 * usage is same as rest.get()
 */
const gwget = (url, cb, baseUrl) => http.get(`${baseUrl}${url}`, cb);
const gwpost = (url, cb, baseUrl) => http.post(`${baseUrl}${url}`, cb);
const gwdelete = (url, cb, baseUrl) => http.delete(`${baseUrl}${url}`, cb);
const gwput = (url, cb, baseUrl) => http.put(`${baseUrl}${url}`, cb);

export * from 'msw';
export { gwget, gwpost, gwdelete, gwput };
