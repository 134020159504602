import { CircularProgress } from '@material-ui/core';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { getCurrentUser, signIn, confirmSignIn, updateUserAttributes, signOut } from 'aws-amplify/auth';
import MaterialButton from 'components/Button/Button';
import { normalLoginButton } from 'components/Button/styles';
import { useRootDispatch, useRootState } from 'store/StateProvider';
import { authenticate, logOut } from 'store/slices/actions';
import { Formik, Form, ErrorMessage } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import druvaLogoPath from 'assets/logo-druva.png';
import dellLogoPath from 'assets/logo-dell.svg';
import Input from 'components/forms/Input/Input';

const LoginValidation = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().min(12).max(16).required(),
});

const LoginWithoutOkta: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [isForceChangePassword, setIsForceChangePassword] = useState(false);
    const [userObj, setUserObj] = useState(null);
    const history = useHistory();
    const dispatch = useRootDispatch();
    const { path } = useRouteMatch();
    const defaultPath = `/${path.split('/')[1]}`;
    const url = `${defaultPath}/home`;
    const {
        location: { isDell },
    } = useRootState();

    useEffect(() => {
        getCurrentUser()
            .then((data) => {
                if (data) {
                    dispatch(authenticate());
                    // TODO:
                    // Can we try to write a wrapper for that ? to not use .then and we definitely should not use dispatch as dependency in useEffect
                    history.push(url);
                }
            })
            .catch((err) => err);
    }, [history, dispatch, url]);

    const handleLogOut = async () => {
        await signOut();
        dispatch(logOut());
    };

    const forceChangePassword = useCallback(
        (userPassword: string) => {
            setLoading(true);
            const attributes = {
                email: userObj?.challangeParams?.email,
            };
            confirmSignIn({ challengeResponse: userPassword, options: { userAttributes: attributes } })
                .then(() => {
                    const attrs = {
                        updated_at: new Date().getTime().toString(),
                    };
                    updateUserAttributes({ userAttributes: attrs })
                        .then(() => {
                            setIsForceChangePassword(false);
                            setLoading(false);
                            toast.success('You have successfully changed password.', {
                                position: 'bottom-right',
                            });
                            handleLogOut();
                        })
                        .catch((err) => {
                            setLoading(false);
                            toast.error(err.message, {
                                position: 'bottom-right',
                            });
                        });
                })
                .catch((e) => {
                    setLoading(false);
                    toast.error(e.message, {
                        position: 'bottom-right',
                    });
                });
        },
        [userObj],
    );

    const handleSignIn = useCallback(
        (values) => {
            setLoading(true);
            signIn({ username: values.username, password: values.password })
                .then((user) => {
                    setLoading(false);
                    if (user.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                        setIsForceChangePassword(true);
                        setUserObj(user);
                    }
                    if (user && user.nextStep?.signInStep !== 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                        dispatch(authenticate());

                        const vDefaultPath = `/${path.split('/')[1]}`;
                        history.push(vDefaultPath);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    if (e.code === 'PasswordResetRequiredException') {
                        history.push('/forgot-password', { locationStep: 1, locationUsername: values.username });
                    }
                    toast.error(e.message, {
                        position: 'bottom-right',
                    });
                });
        },
        [dispatch, history, path],
    );

    return (
        <div className="login">
            <div className="login__container">
                {!isDell && <img className="login__logo margin-top-60" src={druvaLogoPath} alt="logo" />}
                {isDell && <img className="login__logo margin-top-60" src={dellLogoPath} alt="logo" />}
                <p className="login__signin-text">Sign in to Credit Sizing Tool V2</p>
                {loading ? (
                    <div className="loading-container">
                        <CircularProgress />
                    </div>
                ) : (
                    <Formik
                        initialValues={{
                            username: '',
                            password: '',
                            newUserPassword: '',
                        }}
                        validationSchema={LoginValidation}
                        onSubmit={() => {}}
                    >
                        {({ values }) => (
                            <Form className="form" autoComplete="off">
                                {isForceChangePassword ? (
                                    <>
                                        <div className="form__group">
                                            <p>You are forced to change a password due to password expiration.</p>
                                        </div>
                                        <div className="form__group">
                                            <label htmlFor="newUserPassword">New Password</label>
                                            <Input type="password" name="newUserPassword" isEditable defaultValue="" />
                                            <ErrorMessage name="email" />
                                        </div>
                                        <div className="form__actions">
                                            <MaterialButton
                                                type="submit"
                                                style={normalLoginButton}
                                                handleClick={() => forceChangePassword(values.newUserPassword)}
                                            >
                                                Change password
                                            </MaterialButton>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="form__group">
                                            <label htmlFor="username">Email</label>
                                            <Input type="text" name="username" isEditable defaultValue="" />
                                            <ErrorMessage name="username" />
                                        </div>
                                        <div className="form__group">
                                            <label htmlFor="">Password</label>
                                            <Input type="password" name="password" isEditable defaultValue="" />
                                            <ErrorMessage name="password" />
                                        </div>
                                        <div className="form__actions">
                                            <MaterialButton
                                                type="submit"
                                                style={normalLoginButton}
                                                handleClick={() => handleSignIn(values)}
                                            >
                                                Log in
                                            </MaterialButton>
                                            <div className="form__additional-actions">
                                                <Link to={`${defaultPath}/forgot-password`} className="form__link">
                                                    Lost your password ?
                                                </Link>
                                                <Link to={`${defaultPath}/sign-up`} className="form__link">
                                                    Register
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

export default LoginWithoutOkta;
