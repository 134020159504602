import React from 'react';
import Button from 'components/Button/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { buttonStyles } from './styles';

type Props = {
    isVisible: boolean;
    handleClick: (args: 'backward' | 'forward') => void;
    subHeader: string;
    disableBackward: boolean;
    disableForward: boolean;
    header: string;
};
const Navigation = ({
    isVisible,
    handleClick,
    subHeader,
    disableBackward,
    disableForward,
    header,
}: Props): JSX.Element => (
    <div className="navigation">
        {isVisible ? (
            <div className="navigation__controllers">
                <div className="navigation__buttons">
                    <Button
                        type="button"
                        handleClick={() => handleClick('backward')}
                        isDisabled={disableBackward}
                        style={buttonStyles}
                        className="qa-btn-result-sources-prev"
                    >
                        <ArrowBackIcon fontSize="large" />
                    </Button>
                    <span className="navigation__label qa-label-result-sources-current">{header}</span>
                    <Button
                        type="button"
                        handleClick={() => handleClick('forward')}
                        isDisabled={disableForward}
                        style={buttonStyles}
                        className="qa-btn-result-sources-next"
                    >
                        <ArrowForwardIcon fontSize="large" />
                    </Button>
                </div>
                <span className="navigation__pointer">{subHeader}</span>
            </div>
        ) : null}
    </div>
);

export default Navigation;
