import { createAction } from 'typesafe-actions';
import { DirectorySvcResponseState, envVariableState, globalAPIUrlCloudNameMapState } from 'types';

export const setDeploymentConfig = createAction(
    'SET_DEPLOYMENT_CONFIGS',
    (payload: DirectorySvcResponseState) => payload,
)<DirectorySvcResponseState>();

export const setGlobalAPIUrlCloudNameMap = createAction(
    'SET_GLOBAL_API_URL_CLOUD_NAME_MAP',
    (payload: globalAPIUrlCloudNameMapState) => payload,
)<globalAPIUrlCloudNameMapState>();

export const saveEnvData = createAction('SET_ENV_DATA', (payload: envVariableState) => payload)<envVariableState>();
