import { Amplify } from 'aws-amplify';
import { useGetEnvData } from 'hooks/query/useGetEnvData';
import { useEffect, useState } from 'react';

type applicationParamsProps = {
    children: Function;
};
export const ApplicationParamsProvider = ({ children }: applicationParamsProps): JSX.Element => {
    const { isFetching, isError, data } = useGetEnvData();
    const [loadApplication, setLoadApplication] = useState(false);

    useEffect(() => {
        if (data?.result || isError) {
            if (data?.result) {
                Amplify.configure({
                    Auth: {
                        Cognito: {
                            userPoolId: data.result.USER_POOL_ID,
                            userPoolClientId: data.result.USER_POOL_CLIENT_ID,
                            loginWith: {
                                oauth: {
                                    domain: data.result.COGNITO_DOMAIN,
                                    scopes: ['email', 'openid', 'aws.cognito.signin.user.admin'],
                                    redirectSignIn: [data.result.SIGN_IN_URL],
                                    redirectSignOut: [`${data.result.SIGN_IN_URL}/logout`],
                                    responseType: 'code',
                                },
                            },
                        },
                    },
                });
            }
            setLoadApplication(true);
        }
    }, [isFetching, isError, data]);

    return children(loadApplication);
};
