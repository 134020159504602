import { UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useRootState, useRootDispatch } from 'store/StateProvider';
import { saveCustomerDetails } from 'store/slices/actions';
import { SfdcInstanceState } from 'store/slices/sources/sources.types';
import { useAPIQuery } from '../useAPIQuery';

type customerDetailsState = {
    customername?: string;
    instanceList?: Array<SfdcInstanceState>;
    selectedProductCustomerId?: string;
    errors?: any;
    message?: string;
};
type queryResponseState = {
    recordId: string;
    name: string;
    instances: SfdcInstanceState[];
    errors?: any;
    message?: string;
};
export function useGetCustomerDetails(
    payload,
    enabled,
): UseQueryResult<{ result: customerDetailsState; statusCode: number; success: boolean }, Error> {
    const dispatch = useRootDispatch();
    const {
        deploymentConfig: { envData },
    } = useRootState();

    const url = `/creditsizer/sfdc/v1/accountdetails`;
    const method = 'GET';

    const handleSelect = (data: { result: queryResponseState }) => {
        if (data?.result?.instances) {
            const parsedData = {
                customername: data.result?.name,
                instanceList: data.result.instances,
                ...(data.result.instances?.length === 1
                    ? { selectedProductCustomerId: data.result.instances[0]?.instanceId }
                    : {}),
            };
            return { ...data, result: parsedData };
        }
        return data;
    };

    const query: UseQueryResult<{ result: customerDetailsState; statusCode: number; success: boolean }, Error> =
        useAPIQuery({
            key: ['customerDetails', payload.id],
            method,
            payload,
            url,
            baseUrl: envData.MAINLINE_DEP0_API_GATEWAY_URL,
            select: handleSelect,
            enabled,
            suppressNotification: true,
            options: {
                staleTime: 24 * 60 * 60 * 1000, // 24 hour stale time to avoid refetching the same API
            },
        });

    useEffect(() => {
        if (query?.data?.success && !query.isError && enabled) {
            dispatch(saveCustomerDetails(query.data.result));
        }
        if (query.error || query?.data?.success === false) {
            dispatch(saveCustomerDetails({ fetchCustomerDetails: false }));
        }
    }, [query.data, query.isError, enabled]);
    return query;
}
