import React, { useEffect, ReactElement } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Login from 'views/Login/Login';
import Home from 'views/Home/Home';
import ForgotPassword from 'views/ForgotPassword/ForgotPassword';
import ProtectedRoute from 'router/ProtectedRoute';
import { useRootDispatch } from 'store/StateProvider';
import Layout from 'layout/index';
import { ThemeProvider } from '@material-ui/core';
import theme from 'layout/materialTheme';
import { DialogProvider, dialogInitialState } from 'config/context/dialogContext';
import { PdfDataProvider } from 'config/context/pdfDataContext';
import SignUp from 'views/SignUp/SignUp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginWithoutOkta from 'views/LoginWithoutOkta/LoginWithoutOkta';
import { routes } from 'config';
import { updateCurrentLocationRoute } from 'store/slices/location/locationActions';

const BrandedRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    const serviceProvider = path.substr(1);
    const dispatch = useRootDispatch();

    useEffect(() => {
        localStorage.setItem('service-provider', serviceProvider);
        dispatch(updateCurrentLocationRoute({ location: serviceProvider }));
    }, [dispatch, serviceProvider]);

    return (
        <Switch>
            <Route exact path={routes[serviceProvider].default}>
                <Login />
            </Route>
            <Route exact path={routes[serviceProvider].forgotPassword}>
                <ForgotPassword />
            </Route>
            <Route exact path={routes[serviceProvider].signUp}>
                <SignUp />
            </Route>
            <Route exact path={routes[serviceProvider].login}>
                <LoginWithoutOkta />
            </Route>
            <ProtectedRoute exact path={routes[serviceProvider].home}>
                <Layout>
                    <div className="content-container">
                        <Home />
                    </div>
                </Layout>
            </ProtectedRoute>
            <Redirect to={routes[serviceProvider].default} />
        </Switch>
    );
};

const Routes = (): ReactElement => {
    const serviceProvider = localStorage.getItem('service-provider');

    return (
        <ThemeProvider theme={theme}>
            <PdfDataProvider>
                <DialogProvider {...dialogInitialState}>
                    <Switch>
                        <Route path={[routes.druva.default, routes.dell.default]}>
                            <BrandedRoutes />
                        </Route>
                        <Redirect to={serviceProvider ? routes[serviceProvider].default : routes.druva.default} />
                    </Switch>
                    <ToastContainer autoClose={5000} />
                </DialogProvider>
            </PdfDataProvider>
        </ThemeProvider>
    );
};

export default Routes;
