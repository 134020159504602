import { useEffect, useState } from 'react';
import { useRootState } from 'store/StateProvider';
import { TilesType } from 'types';
import { calculatePriceByCloud } from 'views/ResultsTabs/Summary/BackupTable/helpers';
import { skuPlanAliases, skuCloudAliases, getFormattedBytes, getFormattedBytesUnitObject } from 'utils/utils';

const dataType = 'monthlyData';
const useTilesData = (): TilesType[] => {
    const {
        calculatedData: { results },
        location: { isDell },
        sku,
        assumptions,
        sourcesData,
    } = useRootState();
    const [tilesData, setTilesData] = useState([]);

    const getSourcesCount = () => {
        let totalSources = sourcesData.sources.length;
        if (sourcesData?.customerDetails?.deploymentSummary?.details) {
            sourcesData.customerDetails.deploymentSummary.details.forEach((workload) => {
                totalSources += workload.configuredBackupsets;
            });
        }
        return totalSources;
    };

    const getTotalSourceData = () => {
        let totalSourceData =
            sourcesData.sources.reduce((prev, curr) => prev + parseFloat(curr.size.toString()), 0) * 1024 ** 4;

        totalSourceData += sourcesData?.customerDetails?.deploymentSummary?.dataSize ?? 0;

        return totalSourceData;
    };

    const getDeploymentRate = () => {
        const deploymentPeriod = Math.max(...sourcesData.sources.map((source) => source.deploymentPeriod));
        const totalSourceData =
            sourcesData.sources.reduce((prev, curr) => prev + parseFloat(curr.size.toString()), 0) * 1024 ** 4;
        const deploymentRate = totalSourceData / deploymentPeriod;
        const { formattedBytes, unit } = getFormattedBytesUnitObject(deploymentRate);
        return deploymentPeriod ? `${formattedBytes} ${unit}/day` : 'NA';
    };

    useEffect(() => {
        if (results) {
            const tilesList = [
                {
                    label: '# Data Sources',
                    value: getSourcesCount(),
                    isEditable: false,
                },
                {
                    label: 'Effective dedupe',
                    value:
                        Math.round(
                            (results.allSources[dataType][0]?.total / results.allSources[dataType][0]?.target) * 10,
                        ) / 10 || 0,
                    isEditable: false,
                },
                {
                    label: 'Total Data Size',
                    value: `${getFormattedBytes(getTotalSourceData())}`,
                    isEditable: false,
                    width: '12.5vw',
                },
                {
                    label: 'New Source Deployment Rate',
                    value: getDeploymentRate(),
                    isEditable: false,
                    width: '20vw',
                },
            ];
            const druvaOnlyTiles = [
                {
                    label: 'EWL Storage SKU',
                    value: `${skuPlanAliases[sku.plan]}, ${skuCloudAliases[sku.cloud]}`,
                    isEditable: true,
                    width: '16vw',
                },
                {
                    label: 'List price per credit',
                    value: `$${calculatePriceByCloud(
                        assumptions.pricing[sku.plan],
                        sku.cloud,
                        assumptions.pricing.govCloudAddon,
                    )}`,
                    isEditable: false,
                    width: '13vw',
                },
            ];
            setTilesData(isDell ? tilesList : [...tilesList, ...druvaOnlyTiles]);
        }
    }, [isDell, sourcesData.sources, assumptions.pricing, results, sku.cloud, sku.plan]);
    return tilesData;
};

export default useTilesData;
