import React, { useEffect, memo } from 'react';
import 'views/Login/login.scss';
import { authenticate } from 'store/slices/auth/authActions';
import { getCurrentUser, signInWithRedirect } from 'aws-amplify/auth';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { loginButtonWithOkta } from 'components/Button/styles';
import LegalText from 'components/LegalText/LegalText';
import { useRootDispatch, useRootState } from 'store/StateProvider';
import druvaLogoPath from 'assets/logo-druva.png';
import dellLogoPath from 'assets/logo-dell.svg';
import MaterialButton from '../../components/Button/Button';

const Login: React.FC = memo(() => {
    const history = useHistory();
    const dispatch = useRootDispatch();
    const { path } = useRouteMatch();
    const defaultPath = `/${path.split('/')[1]}`;
    const url = `${defaultPath}/home`;

    const {
        location: { isDell },
        deploymentConfig: {
            envData: { OKTA_IDENTITY_PROVIDER_NAME, PING_IDENTITY_PROVIDER_NAME },
        },
    } = useRootState();

    useEffect(() => {
        getCurrentUser()
            .then((data) => {
                if (data) {
                    dispatch(authenticate());
                    // TODO:
                    // Can we try to write a wrapper for that ? to not use .then and we definitely should not use dispatch as dependency in useEffect

                    // Added this to avoid calling sizing api when clicked on browser back button
                    setTimeout(() => {
                        history.push(url);
                    }, 0);
                }
            })
            .catch((err) => err);
    }, [history, dispatch, url]);

    return (
        <div className="login">
            <div className="login__container">
                {!isDell && <img className="login__logo" src={druvaLogoPath} alt="logo" />}
                {isDell && <img className="login__logo" src={dellLogoPath} alt="logo" />}
                <p className="login__signin-text">Sign in to Credit Sizing Tool V2</p>
                {isDell ? (
                    <MaterialButton
                        type="button"
                        style={loginButtonWithOkta}
                        handleClick={() => {
                            signInWithRedirect({ provider: { custom: PING_IDENTITY_PROVIDER_NAME } });
                        }}
                    >
                        Sign in with Ping
                    </MaterialButton>
                ) : (
                    <MaterialButton
                        type="button"
                        style={loginButtonWithOkta}
                        handleClick={() => {
                            signInWithRedirect({ provider: { custom: OKTA_IDENTITY_PROVIDER_NAME } });
                        }}
                    >
                        Sign in with Okta
                    </MaterialButton>
                )}
            </div>
            <LegalText />
        </div>
    );
});

export default Login;
