import React from 'react';
import { useRootState } from 'store/StateProvider';
import 'components/AssumptionsDrawer/assumptions-drawer.scss';
import Input from 'components/forms/Input/Input';

const ChangesTable = () => {
    const { assumptions } = useRootState();
    return (
        <table className="table">
            <thead>
                <tr>
                    <th>Changes Rates</th>
                    <th>Multiplier</th>
                    <th>Cap</th>
                </tr>
            </thead>
            <tbody>
                {assumptions.changeRates.data.map((row) => (
                    <tr key={row.changesRates}>
                        <td className="qa-label-container">{row.changesRates}</td>
                        <td className="table-cell-editable qa-value-container">
                            <Input
                                type="number"
                                name={`changeRates.${row.keyMultiplier}`}
                                defaultValue={assumptions.changeRates[row.keyMultiplier]}
                                isEditable={assumptions.isEditing}
                                className="qa-input-assumption-changerate-multiplier"
                            />
                        </td>
                        <td className="table-cell-editable qa-value-container">
                            <Input
                                type="number"
                                name={`changeRates.${row.keyCap}`}
                                defaultValue={assumptions.changeRates[row.keyCap]}
                                isEditable={assumptions.isEditing}
                                className="qa-input-assumption-changerate-cap"
                            />
                            %
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default React.memo(ChangesTable);
