import React from 'react';
import { chunk } from 'lodash';
import { CalculatedDataResponse, dailyMonthlyData } from 'types';
import TooltipIcon from 'components/Tooltip/TooltipIcon';

const defaultCreditsResult = [0, 0, 0, 0, 0];
export const calculateYearlyDataGrow = (
    input: dailyMonthlyData[],
    key: 'target' | 'total',
    firstYearMonths: number,
): number[] => {
    let newInput: dailyMonthlyData[] = [];
    if (firstYearMonths < 12) {
        newInput = new Array(12 - firstYearMonths)
            .fill(0)
            .map(() => ({ creditsMonthly: 0, cumulatedCredits: 0, target: 0, total: 0 }));
    }
    newInput = [...newInput, ...input];
    return chunk(newInput, 12)
        .map((year) => year.map((values) => values[key]).reduce((acc, curr) => acc + curr, 0))
        .map((output) => Math.round((output / 12) * 100) / 100);
};

export const calculateYearlyCredits = (input: dailyMonthlyData[], firstYearMonths: number): number[] => {
    let newInput: dailyMonthlyData[] = [];
    if (firstYearMonths < 12) {
        newInput = new Array(12 - firstYearMonths)
            .fill(0)
            .map(() => ({ creditsMonthly: 0, cumulatedCredits: 0, target: 0, total: 0 }));
    }
    newInput = [...newInput, ...input];

    return !input.length
        ? defaultCreditsResult
        : chunk(newInput, 12).map((year) =>
              year
                  .map((values) => values.creditsMonthly)
                  .reduce((acc, curr) => Math.round((acc + curr) * 100) / 100, 0),
          );
};

export const cumulateYearlyCredits = (
    creditsLtr: number[],
    creditsNoLtr: number[],
    dtc: number[],
    balanceCredits: number,
): { creditsTotal: number[]; yearWiseCreditBalance: number[] } => {
    let creditBalanceForCurrentYear;
    let creditBalanceRemaining = balanceCredits;
    const yearWiseCreditBalance = [];
    const vCreditsLtr = creditsLtr.map((credits, index) => Math.round((credits + creditsNoLtr[index]) * 100) / 100);
    const creditsTotal = vCreditsLtr.map((credits, index) => {
        let totalCredits = credits + dtc[index] - creditBalanceRemaining;
        if (totalCredits < 0) {
            // For Current year, credit balance will be credits + dtc[index] as totalCredits < 0 after deducting creditBalanceRemaining
            creditBalanceForCurrentYear = credits + dtc[index];
            // when total credits for current year < 0, forward the balance credits to next year
            creditBalanceRemaining = totalCredits * -1;
            totalCredits = 0;
        } else {
            // For Current year, credit balance will be creditBalanceRemaining as totalCredits >= 0 after deducting creditBalanceForNextYear
            creditBalanceForCurrentYear = creditBalanceRemaining;
            creditBalanceRemaining = 0;
        }
        yearWiseCreditBalance.push(Math.round(creditBalanceForCurrentYear * 100) / 100);
        return Math.round(totalCredits * 100) / 100;
    });
    return { creditsTotal, yearWiseCreditBalance };
};

export const calculateAllResults = (
    { results }: CalculatedDataResponse,
    balanceCredits,
    customerType,
    firstYearMonths,
): {
    sourceChanges: number[];
    targetData: number[];
    creditsNoLtr: number[];
    creditsLtr: number[];
    dtc: number[];
    creditsTotal: number[];
    creditsBalance?: number[];
} => {
    const [creditsLtr, creditsNoLtr, dtc] = [
        calculateYearlyCredits(results.ltr.monthlyData, firstYearMonths),
        calculateYearlyCredits(results.noLtr.monthlyData, firstYearMonths),
        calculateYearlyCredits(results.dtc.monthlyData, firstYearMonths),
    ];
    const { creditsTotal, yearWiseCreditBalance } = cumulateYearlyCredits(
        creditsLtr,
        creditsNoLtr,
        dtc,
        balanceCredits,
    );
    return {
        sourceChanges: calculateYearlyDataGrow(results.allSources.monthlyData, 'total', firstYearMonths),
        targetData: calculateYearlyDataGrow(results.allSources.monthlyData, 'target', firstYearMonths),
        creditsNoLtr,
        creditsLtr,
        dtc,
        ...(customerType === 'Existing'
            ? {
                  creditsBalance: yearWiseCreditBalance,
              }
            : {}),
        creditsTotal,
    };
};

export const calculateBackupAcv = (discount: number, pricing: number, creditsTotal: number): number => {
    const discountDecimal = discount / 100;
    return Math.round(pricing * (1 - discountDecimal) * creditsTotal * 100) / 100;
};
export const calculateEffectivePrice = (totalCost: number, target: number): number =>
    Math.round((totalCost / target / 12) * 100) / 100;
export const createTableDataObj = (title: string, dataArr: number[]): any => {
    let obj = {};
    dataArr.map((el, index) => {
        obj = {
            ...obj,
            [index]: el || 0,
            title,
        };
        return el;
    });
    return obj;
};
export const calculatePriceByCloud = (planPrice: number, cloud: string, govCloudAddon: number): number =>
    cloud === 'govCloud' ? planPrice + govCloudAddon : planPrice;

export const createDiscountsTableDataObj = (
    data: { title: string; transform: (input, target) => void }[],
    { creditsTotal, targetData }: { creditsTotal: number[]; targetData: number[] },
    discounts: number[],
    planPrice: number,
): {
    title: string;
}[] =>
    data.map((obj) => {
        let tableObj = { title: obj.title };
        // eslint-disable-next-line array-callback-return
        discounts.map((_, index) => {
            tableObj = {
                ...tableObj,
                [index]: `$${obj.transform(
                    calculateBackupAcv(discounts[index], planPrice, creditsTotal[index]),
                    targetData[index],
                )}`,
            };
        });
        return tableObj;
    });

export const createColumns = (
    title: string,
    iterator: Array<string | number>,
    unit = '',
    cells = [],
): Array<{ Header: string; accessor: string; Cell?: () => any }> => {
    const columns = [
        {
            Header: title,
            accessor: 'title',
            Cell: (data) => {
                const {
                    cell: { value },
                    row: { index },
                } = data;
                const tooltipData = cells[index];
                if (tooltipData?.tooltip)
                    return (
                        <span className="cell-with-tooltip">
                            {value}
                            <TooltipIcon
                                title={tooltipData.title}
                                placement={tooltipData.placement}
                                arrow={tooltipData.arrow}
                            />
                        </span>
                    );
                return value;
            },
        },
    ];
    const restColumns = iterator.map((iteration, index) => ({
        Header: `${iteration}${unit}`,
        accessor: `${index}`,
    }));
    return [...columns, ...restColumns];
};
