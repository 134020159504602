import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { MOCK_MODE } from './msw/mswFlags';

async function enableMocking() {
    if (process.env.NODE_ENV !== 'development' || !MOCK_MODE) {
        return null;
    }

    /* eslint-disable global-require */
    /* eslint-disable @typescript-eslint/no-var-requires */
    const { worker } = require('./msw/browser');
    return worker.start();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
enableMocking().then(() => {
    root.render(<App />);
});
