import { useState, useEffect } from 'react';
import { chunk } from 'lodash';
import { useRootState } from 'store/StateProvider';
import { SummaryGraphsTransformArgs, GraphsTransformArgs, dailyMonthlyData } from 'types';
import { calculateYearlyDataGrow } from 'views/ResultsTabs/Summary/BackupTable/helpers';

export function useCurrentTableData<T>(
    graphRange: number,
    year: number,
    dataType: string,
    transform?: GraphsTransformArgs<T>,
    transformSummary?: SummaryGraphsTransformArgs<T>,
    timeRange?: number,
    isDell?: boolean,
    firstYearMonths?: number,
): { currentSourceData: Array<T> | [] } {
    const {
        calculatedData: { results },
    } = useRootState();
    const [sources, setSources] = useState<Array<T> | []>([]);
    const [currentSourceData, setCurrentSourceData] = useState<Array<T> | []>([]);
    useEffect(() => {
        if (results && transform) {
            setSources(
                results.allSources[dataType].map((el: dailyMonthlyData, index: number) =>
                    transform(el as dailyMonthlyData, index, results, isDell, firstYearMonths),
                ),
            );
        } else if (results && transformSummary) {
            setSources(
                calculateYearlyDataGrow(results.allSources[dataType], 'total', firstYearMonths).map(
                    (el: number, index: number) => transformSummary(el, index, results, isDell, firstYearMonths),
                ),
            );
        }
    }, [results, transform, transformSummary, isDell, dataType]);
    useEffect(() => {
        if (graphRange === 1) {
            setCurrentSourceData(chunk(sources, 365)[year]?.slice(0, timeRange));
        } else {
            const test = chunk(sources, 365 * graphRange);
            setCurrentSourceData(test[0]);
        }
    }, [graphRange, sources, year, timeRange]);
    return {
        currentSourceData,
    };
}
