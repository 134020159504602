export const columns = (
    num: number | string,
    source: string,
    dedupe: number,
): Array<{
    Header: string;
    columns: Array<{
        Header: string;
        accessor: string;
    }>;
}> => [
    {
        Header: `${num}:  ${source} `,
        columns: [
            {
                Header: 'Year',
                accessor: 'year',
            },
            {
                Header: 'Month',
                accessor: 'month',
            },
        ],
    },
    {
        Header: `Dedupe: ${dedupe}`,
        columns: [
            {
                Header: 'S+C (TB)',
                accessor: 'total',
            },
            {
                Header: 'EWL Storage (TB)',
                accessor: 'target',
            },
            {
                Header: 'Cred/m',
                accessor: 'creditsMonthly',
            },
            {
                Header: 'Cred cum',
                accessor: 'cumulatedCredits',
            },
        ],
    },
];
