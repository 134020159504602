import React, { useState, useEffect, useCallback } from 'react';
import Table from 'generic/Table';
import './sourceTable.scss';
import Button from 'components/Button/Button';
import { SourceButton, ActiveSourceButton } from 'components/Button/styles';
import Navigation from 'views/ProgressGraphs/Navigation/Navigation';
import { useRootState } from 'store/StateProvider';
import { capitalize, replace } from 'lodash';
import { columns } from './columns';

const useSourceTable = () => {
    const {
        sourcesData: { data, firstYearMonths },
    } = useRootState();
    const [tableData, setTableData] = useState([]);
    const [dedupeRatio, setDedupeRatio] = useState(0);
    const [activeSourceIndex, setActiveSourceIndex] = useState(0);
    const sources = data?.results ? Object.keys(data.results) : [];

    useEffect(() => {
        if (data?.results && data.results[sources[activeSourceIndex]]?.monthlyData) {
            setTableData(
                data.results[sources[activeSourceIndex]]?.monthlyData.map((obj, index) => ({
                    ...obj,
                    month: 12 - firstYearMonths + index + 1,
                    year: Math.ceil((12 - firstYearMonths + index + 1) / 12),
                })),
            );
            setDedupeRatio(data.results[sources[activeSourceIndex]]?.averageDedupRatio ?? 0);
        }
    }, [data, activeSourceIndex]);
    return {
        tableData,
        activeSourceIndex,
        setActiveSourceIndex,
        sources,
        dedupeRatio,
    };
};
const SourceTable = (): React.ReactElement => {
    const { tableData, activeSourceIndex, setActiveSourceIndex, sources, dedupeRatio } = useSourceTable();
    const handleClick = useCallback(
        (direction) => {
            if (direction === 'forward') setActiveSourceIndex(activeSourceIndex + 1);
            if (direction === 'backward') setActiveSourceIndex(activeSourceIndex - 1);
        },
        [activeSourceIndex, setActiveSourceIndex],
    );
    return (
        <div className="source-table-container">
            {sources.length > 0 ? (
                <>
                    <div className="sources-navigation">
                        {sources.map((workoad, index) => (
                            <Button
                                type="button"
                                key={workoad}
                                handleClick={() => setActiveSourceIndex(index)}
                                style={index === activeSourceIndex ? ActiveSourceButton : SourceButton}
                                className="qa-btn-result-sources-navigation"
                            >
                                {`${index + 1}. ${workoad}`}
                            </Button>
                        ))}
                    </div>
                    <div className="sourceTableWrapper">
                        <Navigation
                            isVisible
                            handleClick={handleClick}
                            subHeader={`of ${sources.length}`}
                            disableBackward={activeSourceIndex === 0}
                            disableForward={activeSourceIndex === sources.length - 1}
                            header={`${activeSourceIndex + 1}. ${sources[activeSourceIndex]}`}
                        />
                        <Table
                            columns={columns(
                                capitalize(replace(`source-${activeSourceIndex + 1}`, '-', ' ')),
                                sources[activeSourceIndex],
                                +dedupeRatio.toFixed(2),
                            )}
                            data={tableData}
                            tableClassName="sourceTable qa-table-result-sources"
                            rowKey="month"
                            applyEvenClass
                        />
                    </div>
                </>
            ) : (
                <div className="no-data-container">No Data</div>
            )}
        </div>
    );
};
export default SourceTable;
