import { useEffect } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { useRootDispatch, useRootState } from 'store/StateProvider';
import { saveCustomerDetails } from 'store/slices/actions';
import { getFormattedBytes } from 'utils/utils';
import { useAPIQuery } from '../useAPIQuery';

export function useGetDeploymentSummary({ selectedProductCustomerId, protocol, endpoint }, enabled = false) {
    const dispatch = useRootDispatch();
    const {
        sourcesData: {
            customerDetails: { customerSfdcOemId },
        },
    } = useRootState();

    const url = `/creditsizer/phoenix/deployment/stats/v1/summary`;
    const method = 'GET';

    const handleSelect = (data) => {
        if (data?.result?.details) {
            const details =
                data.result.details?.map((serviceData) => {
                    const annualGrowthRate = serviceData?.annualGrowthRate * 100 ?? 0;
                    const dailyChangeRate = serviceData?.dailyChangeRate * 100 ?? 0;
                    return {
                        ...serviceData,
                        dataSize: getFormattedBytes(serviceData.dataSize),
                        effectiveDedupRatio: serviceData?.effectiveDedupRatio?.toFixed(2) ?? 0,
                        annualGrowthRate: annualGrowthRate > 0 ? annualGrowthRate.toFixed(5) : 0,
                        dailyChangeRate: dailyChangeRate > 0 ? dailyChangeRate.toFixed(5) : 0,
                    };
                }) ?? [];
            const annualGrowthRate = data.result?.annualGrowthRate * 100 ?? 0;
            const dailyChangeRate = data.result?.dailyChangeRate * 100 ?? 0;
            return {
                ...data,
                result: {
                    deploymentSummary: {
                        ...data.result,
                        effectiveDedupRatio: data.result?.effectiveDedupRatio?.toFixed(2) ?? 0,
                        annualGrowthRate: annualGrowthRate > 0 ? annualGrowthRate.toFixed(5) : 0,
                        dailyChangeRate: dailyChangeRate > 0 ? dailyChangeRate.toFixed(5) : 0,
                        details,
                    },
                },
            };
        }
        return data;
    };

    const payload = {
        serviceTypes: [],
    };

    const query: UseQueryResult<{ result: {}; statusCode: number; success: boolean }, Error> = useAPIQuery({
        key: ['customerDeploymentSummary', customerSfdcOemId, selectedProductCustomerId],
        method,
        payload,
        url,
        baseUrl: `${protocol}://${endpoint}`,
        select: handleSelect,
        enabled,
        customHeaders: { productCustomerID: selectedProductCustomerId },
        options: {
            staleTime: 24 * 60 * 60 * 1000, // 24 hour stale time to avoid refetching the same API
        },
    });

    useEffect(() => {
        if (query?.data?.success && !query.isError && enabled) {
            dispatch(saveCustomerDetails(query.data.result));
        }
    }, [query.data, query.isError, enabled]);
    return query;
}
