import { useGetCreditDetails } from './useGetCreditDetails';
import { useGetDeployementDetails } from './useGetDeployementDetails';
import { useGetDeploymentSummary } from './useGetDeploymentSummary';

export function useGetCreditDeploymentSummaryDetails(selectedProductCustomerId, fetchCreditDeploymentSummaryDetails) {
    const deploymentDetailsQuery = useGetDeployementDetails(
        selectedProductCustomerId,
        fetchCreditDeploymentSummaryDetails,
    );
    const {
        isLoading: isLoadingCreditDetails,
        isFetched: isFetchedCreditDetails,
        isFetching: isFetchingCreditDetails,
    } = useGetCreditDetails(
        {
            selectedProductCustomerId,
            protocol: deploymentDetailsQuery?.data?.result?.protocol,
            endpoint: deploymentDetailsQuery?.data?.result?.endpoint,
        },
        deploymentDetailsQuery.isFetched && deploymentDetailsQuery?.data?.success,
    );
    const {
        isLoading: isLoadingDeploymentSummary,
        isFetched: isFetchedDeploymentSummary,
        isFetching: isFetchingDeploymentSummary,
    } = useGetDeploymentSummary(
        {
            selectedProductCustomerId,
            protocol: deploymentDetailsQuery?.data?.result?.protocol,
            endpoint: deploymentDetailsQuery?.data?.result?.endpoint,
        },
        deploymentDetailsQuery.isFetched && deploymentDetailsQuery?.data?.success,
    );

    return {
        ...deploymentDetailsQuery,
        isLoading: deploymentDetailsQuery.isLoading || isLoadingCreditDetails || isLoadingDeploymentSummary,
        isFetching: deploymentDetailsQuery.isFetching || isFetchingCreditDetails || isFetchingDeploymentSummary,
        isFetched: deploymentDetailsQuery.isFetched && isFetchedCreditDetails && isFetchedDeploymentSummary,
    };
}
