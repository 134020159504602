import React from 'react';
import { useRootState } from 'store/StateProvider';
import Input from 'components/forms/Input/Input';
import 'components/AssumptionsDrawer/assumptions-drawer.scss';

const PricingTable = () => {
    const { assumptions } = useRootState();
    const {
        location: { isDell },
    } = useRootState();

    if (isDell) return null;
    return (
        <table className="table">
            <thead>
                <tr>
                    <th>Pricing ($/TB/month)</th>
                    <th> </th>
                    <th> </th>
                </tr>
            </thead>
            <tbody>
                {assumptions.pricing.data.map((row) => (
                    <tr key={row.pricing}>
                        <td>{row.pricing}</td>
                        <td className="table-cell-editable">
                            $
                            <Input
                                type="number"
                                name={`pricing.${row.key}`}
                                isEditable={assumptions.isEditing}
                                defaultValue={assumptions.pricing[row.key]}
                                className="qa-input-assumption-pricing"
                            />
                        </td>
                        <td>{row.perMonth}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default React.memo(PricingTable);
