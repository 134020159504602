import { UseQueryResult } from '@tanstack/react-query';
import { useAPIQuery } from 'hooks/useAPIQuery';
import { useEffect } from 'react';
import { useRootDispatch, useRootState } from 'store/StateProvider';
import { updateSourcesResults } from 'store/slices/actions';
import { updateCalculatedData } from 'store/slices/calculatedData/calculatedDataActions';
import { SIZER_V2_BASE_URL } from 'views/constants';

const useGetResult = (enabled = false) => {
    const {
        deploymentConfig: {
            directorySvcResponse: { endpoint, protocol },
        },
        sourcesData: {
            customerDetails: { sizingId, selectedProductCustomerId },
        },
        deploymentConfig: { envData },
    } = useRootState();
    const dispatch = useRootDispatch();

    const detailsUrl = `${SIZER_V2_BASE_URL}/sizing/${sizingId}/detail`;
    const SummaryUrl = `${SIZER_V2_BASE_URL}/sizing/${sizingId}/summary`;
    const method = 'GET';

    const detailsQuery: UseQueryResult<{ result: {}; statusCode: number; success: boolean }, Error> = useAPIQuery({
        key: ['resultDetails', sizingId],
        method,
        url: detailsUrl,
        baseUrl: protocol ? `${protocol}://${endpoint}` : envData.MAINLINE_DEP0_API_GATEWAY_URL,
        enabled,
        customHeaders: {
            productID: 12289,
            ...(selectedProductCustomerId ? { productCustomerID: selectedProductCustomerId } : {}),
        },
        options: {
            staleTime: 24 * 60 * 60 * 1000, // 24 hour stale time to avoid refetching the same API
        },
    });
    const summaryQuery: UseQueryResult<{ result: {}; statusCode: number; success: boolean }, Error> = useAPIQuery({
        key: ['resultSummary', sizingId],
        method,
        url: SummaryUrl,
        baseUrl: protocol ? `${protocol}://${endpoint}` : envData.MAINLINE_DEP0_API_GATEWAY_URL,
        enabled,
        customHeaders: {
            productID: 12289,
            ...(selectedProductCustomerId ? { productCustomerID: selectedProductCustomerId } : {}),
        },
        options: {
            staleTime: 24 * 60 * 60 * 1000, // 24 hour stale time to avoid refetching the same API
        },
    });

    useEffect(() => {
        if (summaryQuery?.data?.success && !summaryQuery.isError && enabled) {
            dispatch(updateCalculatedData(summaryQuery.data.result as any));
        }
    }, [summaryQuery.data, summaryQuery.isError, enabled]);
    useEffect(() => {
        if (detailsQuery?.data?.success && !detailsQuery.isError && enabled) {
            dispatch(updateSourcesResults(detailsQuery.data.result as any));
        }
    }, [detailsQuery.data, detailsQuery.isError, enabled]);
    return detailsQuery.isFetching || summaryQuery.isFetching;
};

export default useGetResult;
