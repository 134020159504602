import React from 'react';
import { useRootState } from 'store/StateProvider';
import useSources from 'views/Sources/hooks/useSources';
import { saveCustomerDetails } from 'store/slices/actions';
import { FormControl } from '@material-ui/core';
import { InputBaseStyled } from 'components/forms/Input/styles';

const CustomerName = (): JSX.Element => {
    const {
        sourcesData: {
            customerDetails: { customername, customerType },
        },
    } = useRootState();
    const { dispatch } = useSources();
    if (customerType === 'Existing' && !customername) {
        return null;
    }
    return (
        <>
            <div className="details-item qa-label-customer-name">Customer Name</div>
            <div className="details-item qa-input-customer-name-container">
                {customerType === 'New' ? (
                    <FormControl variant="outlined">
                        <InputBaseStyled
                            name="customername"
                            style={{ width: '300px' }}
                            value={customername}
                            type="text"
                            placeholder="Customer Name"
                            disableUnderline
                            onChange={(e) => {
                                dispatch(saveCustomerDetails({ customername: e.target.value }));
                            }}
                        />
                    </FormControl>
                ) : (
                    <div className="qa-customer-name">{customername}</div>
                )}
            </div>
        </>
    );
};
export default CustomerName;
