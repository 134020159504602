import { getType, ActionType } from 'typesafe-actions';
import * as Actions from 'store/slices/auth/authActions';
import { authInitialState } from './auth.initialState';

const { authenticate, logOut, saveLoginMethod, saveUserInfo } = Actions;

export const authReducer = (state: AuthState, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        case getType(authenticate): {
            return {
                ...state,
                isAuthenticated: true,
            };
        }
        case getType(logOut): {
            return {
                ...state,
                isAuthenticated: false,
                email: '',
                firstName: '',
                familyName: '',
            };
        }
        case getType(saveLoginMethod): {
            return {
                ...state,
                loginMethod: action.payload,
            };
        }
        case getType(saveUserInfo): {
            return {
                ...state,
                email: action.payload.email,
                firstName: action.payload.firstName,
                familyName: action.payload.familyName,
                isDruvaUser: action.payload.email.endsWith('druva.com'),
                isDellUser: action.payload.email.endsWith('dell.com'),
                isPartnerUser:
                    !action.payload.email.endsWith('druva.com') && !action.payload.email.endsWith('dell.com'),
            };
        }
        default: {
            return state;
        }
    }
};

export type AuthActionTypes = ActionType<typeof Actions>;
export type AuthState = typeof authInitialState;
