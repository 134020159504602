import React from 'react';
import { useTable } from 'react-table';
import { useRootState } from '../store/StateProvider';

const Table = ({ columns, data, rowKey, tableClassName, applyEvenClass = false }) => {
    const {
        location: { isDell },
    } = useRootState();

    // If location is dell page then remove "EWL" word from all headers.
    if (isDell) {
        columns.forEach((columnObject) => {
            const innerColumns = columnObject.columns;
            if (innerColumns !== undefined) {
                columnObject.columns = innerColumns.map((innerColumn) => {
                    const { Header } = innerColumn;
                    if (Header !== undefined) innerColumn.Header = Header.replace('EWL ', '');
                    return innerColumn;
                });
            }
        });
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
        getRowId: React.useCallback((row) => row[rowKey], [rowKey]),
    });

    return (
        <table {...getTableProps()} className={tableClassName}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()} key={column.getHeaderProps().key}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            key={row.index}
                            className={applyEvenClass && parseInt(row.cells[0].value, 10) % 2 === 0 ? 'even' : ''}
                        >
                            {row.cells.map((cell) => (
                                <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default React.memo(Table);
