import { useRootState } from 'store/StateProvider';
import { useAPIMutation } from 'hooks/useAPIMutation';
import { SIZER_V2_BASE_URL } from 'views/constants';

export function usePostExportToSFDC() {
    const {
        deploymentConfig: {
            directorySvcResponse: { endpoint, protocol },
        },
        sourcesData: {
            customerDetails: { sizingId, selectedProductCustomerId },
        },
        deploymentConfig: { envData },
    } = useRootState();

    const url = `${SIZER_V2_BASE_URL}/sizing/${sizingId}/submit`;

    const mutation = useAPIMutation({
        key: ['exportToSFDC'],
        url,
        baseUrl: protocol ? `${protocol}://${endpoint}` : envData.MAINLINE_DEP0_API_GATEWAY_URL,
        customHeaders: {
            productID: 12289,
            ...(selectedProductCustomerId ? { productCustomerID: selectedProductCustomerId } : {}),
        },
    });
    return mutation;
}
