import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import { useRootState } from 'store/StateProvider';
import useSources from 'views/Sources/hooks/useSources';
import { resetSources, saveCustomerDetails } from 'store/slices/actions';
import { sourceDefaultValues, sourcesInitialState } from 'store/slices/sources/sources.initialState';
import { formatSources } from 'utils/utils';
import uuid from 'uuid';

const CustomerType = ({ resetForm, formikProps }): JSX.Element => {
    const {
        sourcesData: {
            customerDetails: { customerType },
        },
        auth: { isPartnerUser },
    } = useRootState();
    const { dispatch } = useSources();
    const queryClient = useQueryClient();
    return isPartnerUser ? null : (
        <>
            <div className="details-item qa-label-customer-type">Customer Type</div>
            <div className="details-item qa-radio-button-container">
                <FormControl>
                    <RadioGroup
                        value={customerType}
                        onChange={(e) => {
                            const newCustomerType = e.target.value;
                            dispatch(
                                saveCustomerDetails({
                                    ...sourcesInitialState.customerDetails,
                                    customerType: newCustomerType,
                                }),
                            );
                            dispatch(resetSources());

                            let sources = [];
                            if (newCustomerType === 'New') {
                                sources = formatSources([{ id: uuid(), ...sourceDefaultValues }]);
                            }
                            formikProps.setFieldValue('sources', sources);

                            resetForm();
                            queryClient.removeQueries();
                        }}
                        row
                    >
                        <FormControlLabel
                            control={
                                <Radio
                                    icon={<RadioButtonUnchecked fontSize="large" color="disabled" />}
                                    checkedIcon={<RadioButtonChecked fontSize="large" />}
                                />
                            }
                            className="qa-radio-button-new"
                            label="New"
                            value="New"
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    icon={<RadioButtonUnchecked fontSize="large" color="disabled" />}
                                    checkedIcon={<RadioButtonChecked fontSize="large" />}
                                />
                            }
                            className="qa-radio-button-existing"
                            label="Existing"
                            value="Existing"
                        />
                    </RadioGroup>
                </FormControl>
            </div>
        </>
    );
};

export default CustomerType;
