import uuid from 'uuid';
import React, { useState } from 'react';
import { Button, DialogTitle, Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { confirmCancelButton, filledButtonDisabled, filledButton, importButton } from 'components/Button/styles';
import './ImportCSV.scss';
import { importCSV, deFlateArray } from 'utils/exportImportCSVUtils';
import { toast } from 'react-toastify';
import { useRootDispatch } from 'store/StateProvider';
import { updateSources } from 'store/slices/actions';
import {
    NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING,
    NEW_CUSTOMER_SOURCE_LABEL_KEY_MAPPING,
    NEW_SOURCE_DATA_SHEET,
    formatSources,
    workloadLabelMapType,
} from 'utils/utils';

const getParsedSourceData = (data) => {
    const newData = [];
    data.forEach((row) => {
        const obj = {
            id: uuid(),
            ltr: false,
            dtc: false,
        };
        Object.keys(row).forEach((key) => {
            if (key === 'Warm/LTR') {
                obj.ltr = `${row[key]}` === 'LTR';
                obj.dtc = key === workloadLabelMapType[row.type] ? false : `${row[key]}` === 'Warm';
            } else if (key === NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING.type) {
                obj[NEW_CUSTOMER_SOURCE_LABEL_KEY_MAPPING[key]] = workloadLabelMapType[row[key]];
            } else if (NEW_CUSTOMER_SOURCE_LABEL_KEY_MAPPING[key]) {
                obj[NEW_CUSTOMER_SOURCE_LABEL_KEY_MAPPING[key]] = row[key];
            }
        });
        newData.push(obj);
    });

    return deFlateArray(newData);
};

const ImportCSV = ({ formikProps }): JSX.Element => {
    const dispatch = useRootDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [file, setFile] = useState(null);
    const handleImportClick = () => {
        importCSV(file)
            .then((data) => {
                // TODO: Add Logic to check if data is valid or not, if not throw error, if valid set in store
                const newSourceData = data.find((sheet) => sheet.sheetName === NEW_SOURCE_DATA_SHEET);
                if (newSourceData?.data) {
                    const parsedData = getParsedSourceData(newSourceData.data);
                    dispatch(updateSources(parsedData));
                    formikProps.setFieldValue('sources', formatSources(parsedData));
                    toast.success('CSV Imported Successfully', {
                        position: 'bottom-right',
                    });
                    setShowDialog(false);
                } else {
                    throw new Error('Invalid CSV File');
                }
            })
            .catch((error) => {
                // show error import failed due to invalid csv file etc
                toast.error(`Failed to import CSV ${error}`, {
                    position: 'bottom-right',
                });
            });
    };
    return (
        <>
            <Button
                onClick={() => {
                    setShowDialog(true);
                    setFile(null);
                }}
                style={importButton}
                className="qa-button-import-csv"
            >
                Import
            </Button>
            <Dialog
                open={showDialog}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                classes={{
                    container: 'dialog',
                }}
            >
                <div style={{ width: '500px' }} className="import-dialog">
                    <DialogTitle id="dialog-title">Import Source Data</DialogTitle>
                    <Close className="dialog-close-icon" color="disabled" onClick={() => setShowDialog(false)} />
                    <div style={{ margin: '25px 0px', display: 'flex', alignItems: 'center' }}>
                        Select CSV File
                        <input
                            id="file"
                            type="file"
                            hidden
                            accept=".csv"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                        <label
                            htmlFor="file"
                            style={{
                                color: file ? 'black' : 'lightgray',
                            }}
                            className="label1"
                        >
                            {file ? file.name : 'Select'}
                        </label>
                        <label htmlFor="file" className="label2">
                            Browse
                        </label>
                    </div>
                    <div style={{ float: 'right' }}>
                        <Button style={confirmCancelButton} onClick={() => setShowDialog(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleImportClick}
                            style={file ? filledButton : filledButtonDisabled}
                            disabled={!file}
                        >
                            Import
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ImportCSV;
