export const customerDeploymentSummaryResponse = {
    productCustomerID: 100004,
    globalCustomerID: '6d538bbd-5c72-433a-a64e-42ab2c150c46',
    dataSize: 64095154827500,
    annualGrowthRate: 0.11999999731779099,
    dailyChangeRate: 0.99393432323,
    effectiveDedupRatio: 6.71999979019165,
    configuredBackupsets: 59,
    lastUpdatedDay: 1712742179,
    details: [
        {
            // serviceType: 4,
            serviceName: 'Linux',
            dataSize: 21474836449966,
            annualGrowthRate: 0.7798999713897705,
            dailyChangeRate: 0.99393432323,
            effectiveDedupRatio: 6.320100171661377,
            configuredBackupsets: 365,
        },
        {
            // serviceType: 6,
            serviceName: 'VMware',
            dataSize: 21474836488866,
            annualGrowthRate: -0.774382,
            dailyChangeRate: 0.99393432323,
            effectiveDedupRatio: 6.320000171661377,
            configuredBackupsets: 36,
        },
        {
            // serviceType: 9,
            serviceName: 'NAS',
            dataSize: 22574936500,
            annualGrowthRate: 0.18000000715255737,
            dailyChangeRate: -0.99393432323,
            effectiveDedupRatio: 3.700000047683716,
            configuredBackupsets: 23,
        },
    ],
    sizerVersion: 'v1',
};
