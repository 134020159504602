import { CloudOptionsEnum, SkuOptionsEnum } from 'store/slices/sku/sku.types';
import { StepsEnum } from '../../store/slices/steps/steps.types';

export const SKU_OPTIONS = [
    SkuOptionsEnum.SELECT,
    SkuOptionsEnum.BUSINESS,
    SkuOptionsEnum.ELITE,
    SkuOptionsEnum.ENTERPRISE,
];

export const STEPS_OPTIONS = [StepsEnum.SOURCES, StepsEnum.SKU_DISCOUNTS, StepsEnum.RESULTS];

export const CLOUD_OPTIONS = [CloudOptionsEnum.SELECT, CloudOptionsEnum.GOV_CLOUD, CloudOptionsEnum.PUBLIC_CLOUD];

export const BACKUP_TABLE_CLASSES_MAPPED_YEAR = {
    FLEX_ROW: [1, 2, 3],
    ROW: {
        WRAPPER_CLASS: 'backupTableWrapper',
        TABLES_CLASS: 'tables',
    },
    COL: {
        WRAPPER_CLASS: 'backupTableWrapper flexDirectionCol',
        TABLES_CLASS: 'tables width-100',
    },
};

export const SUMMARY_GRAPH_CLASSES_MAPPING_YEAR = {
    FLEX_ROW: [1, 2, 3],
    ROW_WRAPPER_CLASS: 'summaryGraphWrapper alignFlexEnd',
    COL_WRAPPER_CLASS: 'summaryGraphWrapper alignFlexCenter padding-top-40',
    vertical: {
        BARCHART_WIDTH: 500,
        BARCHART_HEIGHT: 400,
    },
    horizontal: {
        BARCHART_WIDTH: 900,
        BARCHART_HEIGHT: 600,
    },
};

export const YEARS_LABEL_ARRAY = ['1 Year', '2 Years', '3 Years', '4 Years', '5 Years'];

export default {
    SKU_OPTIONS,
    CLOUD_OPTIONS,
};
