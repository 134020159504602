import React, { useEffect, useState } from 'react';
import Table from 'generic/Table';
import _ from 'lodash';
import { useRootState } from 'store/StateProvider';
import 'views/ResultsTabs/Summary/BackupTable/backupTable.scss';
import SectionLabel from 'components/SectionLabel/SectionLabel';
import SKUForm from 'views/Sku/SKUForm/SKUForm';
import { useDialogContext } from 'config/context/dialogContext';
import { BACKUP_TABLE_CLASSES_MAPPED_YEAR } from 'config/setup/constants';

import SummaryGraph from './SummaryGraph/SummaryGraph';
import {
    calculateAllResults,
    createColumns,
    createTableDataObj,
    createDiscountsTableDataObj,
    calculateEffectivePrice,
    calculatePriceByCloud,
} from './helpers';
import { PRICING_INFO_TITLES, getBackupTableTitles } from '../../../constants';

const BackupTable = (): React.ReactElement => {
    const [backupTableData, setBackupTableData] = useState([]);
    const [priceTableData, setPriceTableData] = useState([]);
    const [backupColumns, setBackupColumns] = useState([]);
    const [priceColumns, setPriceColumns] = useState([]);
    const { calculatedData, sku, assumptions } = useRootState();
    const { open } = useDialogContext();
    const {
        location: { isDell },
        sourcesData: {
            tcoYear,
            customerDetails: { balanceCredits, customerType },
            firstYearMonths,
        },
    } = useRootState();
    const BACKUP_TABLE_CLASSES =
        _.indexOf(BACKUP_TABLE_CLASSES_MAPPED_YEAR.FLEX_ROW, tcoYear) === -1
            ? BACKUP_TABLE_CLASSES_MAPPED_YEAR.COL
            : BACKUP_TABLE_CLASSES_MAPPED_YEAR.ROW;

    const BACKUP_TABLE_TITLES = getBackupTableTitles(isDell, +balanceCredits < 0);

    useEffect(() => {
        if (!isDell) setPriceColumns(createColumns(PRICING_INFO_TITLES.dataDiscount, sku.discounts, '%'));
    }, [sku.discounts, isDell]);
    useEffect(() => {
        const tooltips = [
            {
                tooltip: true,
                title: 'S+C - Source+Changes - The amount of backup data generated at source. This includes the first full backup & the incremental data from subsequent backups. We have used the change rate % specified to derive this number in conjunction with Retention.',
                arrow: true,
                placement: 'left-start',
            },
            {
                tooltip: true,
                title: 'Amount of data stored in Cloud post deduplication.',
                arrow: true,
                placement: 'left-start',
            },
            { tooltip: false },
            {
                tooltip: true,
                title: 'Long Term Retention: Enable this option if you want to move the data to cold storage',
                arrow: true,
                placement: 'left-start',
            },
            {
                tooltip: true,
                title: 'Enable this option to move the data directly to archive storage.',
                arrow: true,
                placement: 'left-start',
            },
            { tooltip: false },
            { tooltip: false },
        ];
        setBackupColumns(
            createColumns(
                '',
                _.range(tcoYear).map((index) => `Year ${index + 1}`),
                '',
                tooltips,
            ),
        );
    }, [tcoYear]);
    useEffect(() => {
        if (calculatedData.results) {
            const BACKUP_TITLES = [
                BACKUP_TABLE_TITLES.dataSourceChanges,
                BACKUP_TABLE_TITLES.dataTargetData,
                BACKUP_TABLE_TITLES.dataCreditsStandard,
                BACKUP_TABLE_TITLES.dataCreditsLtr,
                BACKUP_TABLE_TITLES.dataCreditsDtc,
                ...(customerType === 'Existing' ? [BACKUP_TABLE_TITLES.dataCreditsBalance] : []),
                BACKUP_TABLE_TITLES.dataCreditsTotal,
            ];
            const formattedData = calculateAllResults(calculatedData, balanceCredits, customerType, firstYearMonths);
            setBackupTableData(
                Object.values(formattedData).map((calculatedDataValues, index) =>
                    createTableDataObj(BACKUP_TITLES[index], calculatedDataValues),
                ),
            );

            setPriceTableData(
                createDiscountsTableDataObj(
                    [
                        { title: PRICING_INFO_TITLES.dataBackupACV, transform: (input) => input },
                        { title: PRICING_INFO_TITLES.dataEffectivePrice, transform: calculateEffectivePrice },
                    ],
                    formattedData,
                    sku.discounts,
                    calculatePriceByCloud(assumptions.pricing[sku.plan], sku.cloud, assumptions.pricing.govCloudAddon),
                ),
            );
        }
    }, [calculatedData.results, assumptions.pricing, sku.discounts, sku.plan, sku.cloud, calculatedData, isDell]);

    return (
        <div className={BACKUP_TABLE_CLASSES.WRAPPER_CLASS}>
            {backupTableData.length > 0 ? (
                <>
                    <div className={BACKUP_TABLE_CLASSES.TABLES_CLASS}>
                        <SectionLabel>Backup</SectionLabel>
                        <Table
                            data={backupTableData}
                            columns={backupColumns}
                            rowKey="title"
                            tableClassName="backupTable qa-table-result-backup"
                        />
                        {!isDell && (
                            <div className="priceTableWrapper">
                                <button
                                    type="button"
                                    className="discounts-edit qa-btn-discounts-edit"
                                    onClick={() => open(<SKUForm isEditDiscountsDialog />)}
                                >
                                    Edit
                                </button>
                                <Table
                                    data={priceTableData}
                                    columns={priceColumns}
                                    rowKey="title"
                                    tableClassName="priceTable qa-table-result-price"
                                />
                            </div>
                        )}
                    </div>
                    <SummaryGraph />
                </>
            ) : (
                <div className="no-data-container">No Data</div>
            )}
        </div>
    );
};

export default BackupTable;
