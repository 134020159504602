import React from 'react';

import { useRootState } from 'store/StateProvider';
import Input from 'components/forms/Input/Input';
import 'components/AssumptionsDrawer/assumptions-drawer.scss';

const LtrTable = () => {
    const { assumptions } = useRootState();
    return (
        <table className="table">
            <thead>
                <tr>
                    <th>LTR</th>
                    <th> </th>
                    <th> </th>
                </tr>
            </thead>
            <tbody>
                {assumptions.ltr.data.map((row) => (
                    <tr key={row.credit}>
                        <td className="qa-label-container">{row.ltrCreditRate}</td>
                        <td>{row.empty}</td>
                        <td className="table-cell-editable qa-value-container">
                            <Input
                                type="number"
                                name={`ltr.${row.key}`}
                                isEditable={assumptions.isEditing}
                                defaultValue={assumptions.ltr.ltr}
                                className="qa-input-assumption-ltr"
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default React.memo(LtrTable);
