import { random } from 'lodash';
import { SIZER_V2_BASE_URL, SIZING_STATUS_COMPLETE, SIZING_STATUS_PROCESSING } from 'views/constants';
import { HttpResponse, delay, gwget, gwpost } from '../../msw/mswUtils';
import { getDeploymentDetailsViewResponse } from './getDeploymentDetailsViewResponse';
import { getDeploymentSummaryViewResponse } from './getDeploymentSummaryViewResponse';
import { customerDeploymentSummaryResponse } from './existingCustomerDeploymentSummaryResponse';

const DELAY = 0;
const globalApisMap = {
    mainline: 'https://globalapis.druva.com',
    fedramp: 'https://globalgovapisfederal.druva.com',
    gov: 'https://globalgovapis.druva.com',
};

export const getCustomerDetails = async () => {
    // Await a random realistic server response time.
    await delay(DELAY);

    // return new HttpResponse(JSON.stringify({ message: "User is not authorized to access this resource with an explicit deny" }), {
    //     status: 403,
    // })

    // const salesforceErrors = {
    //     "errors": [
    //         { "errorMessage": "Invalid record Id", "errorCode": "1001" },
    //         { "errorMessage": "Record does not exist", "errorCode": "1002" }]
    // };
    // return new HttpResponse(JSON.stringify(salesforceErrors), {
    //     status: 400,
    // })

    // return new HttpResponse(JSON.stringify({
    //     "message": "User is not authorized to access this resource with an explicit deny",
    //     "body": {
    //         "authorizer": {
    //             "errorMessage": "token is expired by 19 m50 .194332963 s: Invalid JWT error",
    //             "errorCode": 1005
    //         }
    //     }
    // }), {
    //     status: 401,
    // })

    return HttpResponse.json({
        recordId: '0015000000uVn5bAAC',
        name: 'TRC Companies Inc.',
        instances: [
            {
                instanceId: '228',
                instanceCreationDate: '2016-04-06',
                cloudName: 'phoenix.druva.com',
                edition: 'Enterprise',
            },
            {
                instanceId: '100707',
                instanceCreationDate: '2016-04-06',
                cloudName: 'govphoenix.druva.com',
                edition: 'Business',
            },
            {
                instanceId: '101042',
                instanceCreationDate: '2016-04-06',
                cloudName: 'fedphoenix.druva.com',
                edition: 'Elite',
            },
        ],
    });
};

const endpointMapping = {
    228: 'apis.druva.com',
    100707: 'govapis.druva.com',
    101042: 'govcloudapis.druva.com',
};
const getDeploymentDetails = async ({ request }) => {
    // Await a random realistic server response time.
    await delay(DELAY);
    const url = new URL(request.url);
    const consumerID = url.searchParams.get('consumerID');

    // return new HttpResponse(JSON.stringify({ message: "User is not authorized to access this resource with an explicit deny" }), {
    //     status: 403,
    // })

    // return new HttpResponse(JSON.stringify({
    //     "message": "User is not authorized to access this resource with an explicit deny",
    //     "body": {
    //         "authorizer": {
    //             "errorMessage": "token is expired by 19 m50 .194332963 s: Invalid JWT error",
    //             "errorCode": 1005
    //         }
    //     }
    // }), {
    //     status: 401,
    // })

    return HttpResponse.json([
        {
            serviceName: 'APIEndpoint',
            protocol: 'https',
            endpoint: endpointMapping[consumerID],
            port: 443,
            deploymentID: 0,
        },
    ]);
};

const getCreditDetails = async ({ request }) => {
    // Await a random realistic server response time.
    await delay(DELAY);
    const url = new URL(request.url);
    const instanceIds = +url.searchParams.get('instanceIds');

    // return new HttpResponse(JSON.stringify({ message: "User is not authorized to access this resource with an explicit deny" }), {
    //     status: 403,
    // })

    // return new HttpResponse(JSON.stringify({ message: "Unauthorized" }), {
    //     status: 401,
    // })

    return HttpResponse.json([
        {
            instanceId: 'someDummyProductCustomerId0',
            creditPools: [
                {
                    dailyCreditStats: [
                        {
                            day: 19701,
                            creditBalance: '24.75',
                        },
                        {
                            day: 19700,
                            creditBalance: '24.75',
                        },
                    ],
                    currentCreditBalance: `24.7${instanceIds}`,
                    lastUpdatedDay: Math.round(new Date().getTime() / (1000 * 3600 * 24)),
                },
            ],
            cloudName: 'phoenix.druva.com',
        },
    ]);
};

const getCustomerDeploymentSummary = async () => {
    // Await a random realistic server response time.
    await delay(DELAY);

    // return new HttpResponse(JSON.stringify({ message: "Unauthorized" }), {
    //     status: 401,
    // })

    return HttpResponse.json(customerDeploymentSummaryResponse);
};

export const MAINLINE_DEP0_API_GATEWAY_URL = 'https://apis.druva.com';

const getEnvVariablesS3 = async () => {
    // Await a random realistic server response time.
    await delay(DELAY);
    // return new HttpResponse(JSON.stringify({ message: "User is not authorized to access this resource with an explicit deny" }), {
    //     status: 403,
    // })
    return HttpResponse.json({
        USER_POOL_ID: 'ap-south-1_oG8fsibZ6',
        USER_POOL_CLIENT_ID: 'tnmn0rm2h8iigb470m1jdckec',
        COGNITO_DOMAIN: 'devsizingtoolokta.auth.ap-south-1.amazoncognito.com',
        SIGN_IN_URL: 'http://localhost:3000',
        CLOUD_NAME_DIRECTORY_SVC_BASE_URL_MAP: globalApisMap,
        OKTA_IDENTITY_PROVIDER_NAME: 'OKTA',
        PING_IDENTITY_PROVIDER_NAME: 'ping-identity-2',
        MAINLINE_DEP0_API_GATEWAY_URL,
    });
};

const exporttosfdc = async () => {
    // Await a random realistic server response time.
    await delay(1000);

    // return new HttpResponse(JSON.stringify({ message: "User is not authorized to access this resource with an explicit deny" }), {
    //     status: 403,
    // })

    // return new HttpResponse(JSON.stringify({ message: "Unauthorized" }), {
    //     status: 401,
    // })

    // return new HttpResponse(JSON.stringify({
    //     "code": "Sizer-1000",
    //     "message": "Internal error"
    // }), {
    //     status: 400,
    // })

    return HttpResponse.json(null);
};

const doSizing = async () => {
    // Await a random realistic server response time.
    await delay(DELAY);

    // return new HttpResponse(JSON.stringify({ message: "Unauthorized" }), {
    //     status: 401,
    // })

    return HttpResponse.json({
        sizingID: random(999),
    });
};
let count = 0;
const checkSizingStatus = async () => {
    // Await a random realistic server response time.
    await delay(DELAY);

    // return new HttpResponse(JSON.stringify({ message: "Unauthorized" }), {
    //     status: 401,
    // });

    count += 1;
    return HttpResponse.json({ sizingStatus: count % 1 ? SIZING_STATUS_PROCESSING : SIZING_STATUS_COMPLETE });
};

const getDeploymentSummaryView = async () => {
    await delay(DELAY);
    // return new HttpResponse(JSON.stringify({ message: "Internal server error" }), {
    //     status: 502,
    // })
    return HttpResponse.json(getDeploymentSummaryViewResponse);
};

const getDeploymentDetailsView = async () => {
    await delay(DELAY);
    // return new HttpResponse(JSON.stringify({ message: "Internal server error" }), {
    //     status: 502,
    // })
    return HttpResponse.json(getDeploymentDetailsViewResponse);
};

export const existingCustomerMSWMockHandlers = [
    gwget('/creditsizer/sfdc/v1/accountdetails', getCustomerDetails, MAINLINE_DEP0_API_GATEWAY_URL),
    gwget('/directorysvc/v1/services', getDeploymentDetails, globalApisMap.mainline),
    gwget('/directorysvc/v1/services', getDeploymentDetails, globalApisMap.fedramp),
    gwget('/directorysvc/v1/services', getDeploymentDetails, globalApisMap.gov),
    gwget('/creditsizer/firebird/v2/credits/balance', getCreditDetails, `https://${endpointMapping['228']}`),
    gwget('/creditsizer/firebird/v2/credits/balance', getCreditDetails, `https://${endpointMapping['100707']}`),
    gwget('/creditsizer/firebird/v2/credits/balance', getCreditDetails, `https://${endpointMapping['101042']}`),
    gwget(
        '/creditsizer/phoenix/deployment/stats/v1/summary',
        getCustomerDeploymentSummary,
        `https://${endpointMapping['228']}`,
    ),
    gwget(
        '/creditsizer/phoenix/deployment/stats/v1/summary',
        getCustomerDeploymentSummary,
        `https://${endpointMapping['100707']}`,
    ),
    gwget(
        '/creditsizer/phoenix/deployment/stats/v1/summary',
        getCustomerDeploymentSummary,
        `https://${endpointMapping['101042']}`,
    ),
    gwget('/env-config.json', getEnvVariablesS3, `http://localhost:3000`),

    // Export to SFDC
    gwpost(`${SIZER_V2_BASE_URL}/sizing/:sizingId/submit`, exporttosfdc, `https://${endpointMapping['228']}`),
    gwpost(`${SIZER_V2_BASE_URL}/sizing/:sizingId/submit`, exporttosfdc, `https://${endpointMapping['100707']}`),
    gwpost(`${SIZER_V2_BASE_URL}/sizing/:sizingId/submit`, exporttosfdc, `https://${endpointMapping['101042']}`),

    // do sizing
    gwpost(`${SIZER_V2_BASE_URL}/sizing`, doSizing, `https://${endpointMapping['228']}`),
    gwpost(`${SIZER_V2_BASE_URL}/sizing`, doSizing, `https://${endpointMapping['100707']}`),
    gwpost(`${SIZER_V2_BASE_URL}/sizing`, doSizing, `https://${endpointMapping['101042']}`),

    // check sizing status
    gwget(`${SIZER_V2_BASE_URL}/sizing/:sizingId/status`, checkSizingStatus, `https://${endpointMapping['228']}`),
    gwget(`${SIZER_V2_BASE_URL}/sizing/:sizingId/status`, checkSizingStatus, `https://${endpointMapping['100707']}`),
    gwget(`${SIZER_V2_BASE_URL}/sizing/:sizingId/status`, checkSizingStatus, `https://${endpointMapping['101042']}`),

    // deplyment summary view
    gwget(
        `${SIZER_V2_BASE_URL}/sizing/:sizingId/summary`,
        getDeploymentSummaryView,
        `https://${endpointMapping['228']}`,
    ),
    gwget(
        `${SIZER_V2_BASE_URL}/sizing/:sizingId/summary`,
        getDeploymentSummaryView,
        `https://${endpointMapping['100707']}`,
    ),
    gwget(
        `${SIZER_V2_BASE_URL}/sizing/:sizingId/summary`,
        getDeploymentSummaryView,
        `https://${endpointMapping['101042']}`,
    ),

    // deplyment details view
    gwget(
        `${SIZER_V2_BASE_URL}/sizing/:sizingId/detail`,
        getDeploymentDetailsView,
        `https://${endpointMapping['228']}`,
    ),
    gwget(
        `${SIZER_V2_BASE_URL}/sizing/:sizingId/detail`,
        getDeploymentDetailsView,
        `https://${endpointMapping['100707']}`,
    ),
    gwget(
        `${SIZER_V2_BASE_URL}/sizing/:sizingId/detail`,
        getDeploymentDetailsView,
        `https://${endpointMapping['101042']}`,
    ),
];
