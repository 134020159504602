import React from 'react';
import TooltipIcon from 'components/Tooltip/TooltipIcon';

const LabelValue = ({ label, value, unit, infoIconText = '' }) => (
    <div className="label-value-container">
        <div className="display-flex align-items-center">
            <span className="value">{value}</span>
            <span className="unit">{unit}</span>
        </div>
        <div className="display-flex align-items-center">
            <span className="label">{label}</span>
            {infoIconText && (
                <div className="label">
                    <TooltipIcon placement="right" title={infoIconText} arrow />
                </div>
            )}
        </div>
    </div>
);

export default LabelValue;
