import { useMutation } from '@tanstack/react-query';
import APIRequestUtil from './APIRequestUtil';

/**
 * Return useMutation which calls API using fetch
 * @method fetch method GET, POST etc
 * @url url of the API after baseurl
 * @baseUrl optionsl baseUrl of the API, if not passed, default baseurl will be taken
 * @key Query key, if not passed, query key will be assigned with [url, method]
 * @customHeaders object having custom headers that needs to pass
 * @returns
 */
export function useAPIMutation<T, T1>({
    method = 'POST',
    url,
    baseUrl,
    key,
    customHeaders = {},
    suppressNotification = false,
}: {
    method?: 'POST';
    url: string;
    baseUrl?: string;
    key?: string[];
    customHeaders?: any;
    suppressNotification?: boolean;
}) {
    return useMutation<T1, Error, T>({
        mutationKey: key || [url, method], // Unique key for the query
        mutationFn: (payload) =>
            APIRequestUtil.makeRequest({
                method,
                url,
                payload,
                baseUrl,
                customHeaders,
                suppressNotification,
            }),
    });
}
