import React, { memo } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useRootState } from 'store/StateProvider';
import { getFormattedBytesUnitObject, getTimeString } from 'utils/utils';
import { EXISTING_SOURCE_TABLE_PRIMARY_HEADERS } from 'views/constants';
import LabelValue from './LabelValue';

const summaryCardData = ({ dataSize, annualGrowthRate, dailyChangeRate, effectiveDedupRatio }) => {
    const { formattedBytes, unit } = getFormattedBytesUnitObject(dataSize);
    return [
        {
            label: 'Total Data Size',
            value: formattedBytes,
            unit,
            infoIconText: 'Total Existing Sources data size',
        },
        {
            label: 'Avg. Annual Growth',
            value: annualGrowthRate,
            unit: '%',
        },
        {
            label: 'Avg. Daily Change',
            value: dailyChangeRate,
            unit: '%',
        },
        {
            label: 'Effective Dedupe',
            value: effectiveDedupRatio,
            unit: 'x',
        },
    ];
};

const ExistingSourcesDetails = () => {
    const {
        sourcesData: { customerDetails },
        auth: { isDellUser },
    } = useRootState();

    return (
        <div className="existing-data-sources-container my-20">
            <h4 className="section-heading">Existing Data Sources</h4>
            {customerDetails.deploymentSummary ? (
                <>
                    <div className="qa-selected-customer-details-container">
                        <span className="qa-protected-with-dell-druva">
                            The details of the existing data sources protected with{' '}
                            {isDellUser ? 'Dell Technologies' : 'Druva'}.
                        </span>
                        {!!customerDetails.deploymentSummary?.lastUpdatedDay && (
                            <span className="last-updated-day">
                                (Last updated {getTimeString(customerDetails.deploymentSummary.lastUpdatedDay)})
                            </span>
                        )}
                    </div>
                    <div className="card-container qa-deployment-summary-container">
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            {summaryCardData(customerDetails.deploymentSummary).map((item) => (
                                <Grid item xs={3} key={item.label}>
                                    <LabelValue
                                        label={item.label}
                                        value={item.value}
                                        unit={item.unit}
                                        infoIconText={item.infoIconText}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    <div className="card-container qa-summary-per-workload-container">
                        <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Summary per Workload</div>
                        {customerDetails.deploymentSummary?.details?.length > 0 ? (
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {Object.keys(EXISTING_SOURCE_TABLE_PRIMARY_HEADERS).map((key) => (
                                                <TableCell key={key} className="table-header-cell">
                                                    {EXISTING_SOURCE_TABLE_PRIMARY_HEADERS[key]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {customerDetails.deploymentSummary?.details?.map((row) => (
                                            <TableRow key={row.serviceName}>
                                                <TableCell className="table-cell">{row.serviceName}</TableCell>
                                                <TableCell className="table-cell">{row.configuredBackupsets}</TableCell>
                                                <TableCell className="table-cell">{row.dataSize}</TableCell>
                                                <TableCell className="table-cell">{row.annualGrowthRate}</TableCell>
                                                <TableCell className="table-cell">{row.dailyChangeRate}</TableCell>
                                                <TableCell className="table-cell">{row.effectiveDedupRatio}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div className="no-data-container">No Data</div>
                        )}
                    </div>
                </>
            ) : (
                <div className="qa-select-customer-info-container">
                    Select a customer to view existing data sources protected with{' '}
                    {isDellUser ? 'Dell Technologies' : 'Druva'}.
                </div>
            )}
        </div>
    );
};

export default memo<any>((props) => <ExistingSourcesDetails {...props} />);
